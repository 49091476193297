import React from "react";
import {Provider} from "mobx-react";
import stores, {adminStores} from "@stores/index";
import Board from "./screens";
import "./App.css";


const App = () => {
  return (
      <Provider {...{...stores, ...adminStores}}>
        <Board/>
      </Provider>
  );
};

export default App;
