import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import {ObservedInput} from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import {Model} from "@stores/domain/admin/bundle";
import Input from "@containers/Admin/Input";
import sessionTypeStore from "@stores/domain/sessionTypeStore";

@inject("languageStore", "languageLevelStore", "adminCpStore")
@observer
class AddCP extends React.Component {

    @observable
    loading = false;

    @observable
    _entity = cloneStore(new Model({}), true);

    componentDidMount(): void {
        this._entity.sessionTypeId = sessionTypeStore.data[0].id;
    }


    _submit = e => {
        e.preventDefault();
        const {history} = this.props;
        this._entity.serializeAndAdd({sessionType: "sessionTypeUri"}).then(resp => {
            history.push(`${resp.data.id}`);
        });
    };

    _goBack = e => {
        const {history} = this.props;
        e.preventDefault();
        history.goBack();
    };

    render() {
        window.test = this._entity;
        return (
            <Screen.Column last basis="full">
                <DashboardBlock as="form" onSubmit={this._submit} title="Add a new Conversation Partner">
                    <FlexBox direction="row-responsive" loading={this.loading}>
                        <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                            <Input type="text" fill label="Name" validationRules="required|min:2" propName="name"
                                   store={this._entity}/>
                            <Input type="number" fill label="Credit Amount" validationRules="required"
                                   propName="creditAmount"
                                   store={this._entity}/>
                            <Input type="number" fill label="Duration" validationRules="required"
                                   propName="duration"
                                   placeholder="Duration In Minutes"
                                   store={this._entity}/>
                            <Input type="number" fill label="Price" validationRules="required" propName="price"
                                   store={this._entity}/>
                        </FlexBox>
                        <FlexBox direction="column" f={1}>
                            <ObservedInput type="select" fill label="Session Type" options={sessionTypeStore.selectMap}
                                           value={this._entity.sessionTypeId}
                                           onChange={(l) => {
                                               this._entity.sessionTypeId = ~~l.nativeEvent.target.value
                                           }}
                            />
                            <Input type="textarea" fill label="Description" validationRules="required"
                                   propName="description"
                                   store={this._entity} rows={4}/>
                            <Input type="checkbox" label="Active" propName="isActive" store={this._entity}
                                   withMargin={false}/>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                             justify={flexProps.spaceBetween}>
                        <Button text="Cancel" type="button" onClick={this._goBack} withArrowLeft/>
                        <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                    </FlexBox>
                </DashboardBlock>
            </Screen.Column>
        );
    }

}

export default AddCP;
