import {action, observable, computed} from "mobx";
import {BaseStore} from "../BaseStore";


class AdminBaseStore extends BaseStore {
  @observable
  page = 1;

  @observable
  totalPages = 1;

  @observable
  totalItems = 0;


  pageLoader: Promise;

  @computed
  get nextPage() {
    return this.page < this.totalPages ? (this.page + 1) : this.totalPages;
  }


  @action.bound
  loadNext(){
    console.log(this.nextPage);
    if (this.pageLoader){
      this.loader = this.pageLoader(this.nextPage);
    }
    super.load().then(resp => {
      const {headers} = resp;
      this.page = ~~headers["current-page"];
      this.totalPages = ~~headers["total-pages"];
      this.totalItems = ~~headers["total-items"];
    });
  };
}

export default AdminBaseStore;
