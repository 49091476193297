import React from "react";
import {inject, observer} from "mobx-react";
import DashboardBlock from "../../components/DashboardBlock";
import Input from "../../components/Input";
import FlexBox, {flexDirection} from "../../components/FlexBox";
import Tag from "../../components/Tag";
import Button from "../../components/Button";

const AccountInformationEditBlock = {};
AccountInformationEditBlock.Information = inject("userStore")(observer(({userStore}) => {
  return (
    <DashboardBlock pad={0} title="Account Information">
      <Input type="text" label="First Name" defaultValue={userStore.firstName} onChange={t=>userStore.setFirstName(t.nativeEvent.srcElement.value)} />
      <Input type="text" label="Last Name" defaultValue={userStore.lastName} onChange={t=>userStore.setLastName(t.nativeEvent.srcElement.value)} />
      <Input type="date" label="Date" value={userStore.birthdaySelectorFormatted} onChange={(f)=>{userStore.setDob(f.nativeEvent.srcElement.value)}}/>
      <Input type="text" label="Field Of Work" value={userStore.profession} onChange={t=>userStore.setProfession(t.nativeEvent.srcElement.value)}/>
      <Input type="text" label="Skype User Name" value={userStore.skypeId} onChange={t=>userStore.setSkypeId(t.nativeEvent.srcElement.value)}/>
    </DashboardBlock>
  );
}));

AccountInformationEditBlock.Hobbies =
  @inject("userStore")
  @observer
  class extends React.Component {
    render(): React.ReactNode {
      const {userStore} = this.props;
      return (
        <DashboardBlock pad={0} title="Interests">
          <FlexBox direction={flexDirection.row} wrap>
            {userStore.hobbies.map(h=><Tag key={h} text={h} onClose={()=>{userStore.removeHobby(h)}}/>)}
          </FlexBox>
          <FlexBox direction={flexDirection.rowResponsive} full="horizontal">
            <Input type="text" placeholder="Add interests as much as you need" value={userStore.hobbyInputValue} onChange={t=>userStore.setHobbyInputValue(t.nativeEvent.srcElement.value)}/>
            <Button text="add" plain iconLeft="plus" onClick={userStore.addHobby} />
          </FlexBox>
        </DashboardBlock>
      );
    }
  };

AccountInformationEditBlock.Bio = inject("userStore")(observer(({userStore}) => {
  return (
    <DashboardBlock pad={0} title="Your Personal Goals">
      <Input type="textarea" fill="both" style={{height: 200}} value={userStore.bio} onChange={t=>userStore.setBio(t.nativeEvent.srcElement.value)}/>
    </DashboardBlock>
  );
}));

export default AccountInformationEditBlock;