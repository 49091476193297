import {action, computed, observable, set} from "mobx";
import {BaseModel} from "@stores/domain/BaseStore";
import languageStore from "@stores/domain/languageStore";
import api from "@config/api";


const service = {
    add: (data): Promise => api.post("cp_languages", data),
    update: (id)=>(data): Promise => api.put(`cp_languages/${id}`, data),
    delete: (id): Promise => api.delete(`cp_languages/${id}`),
};

class CpLanguageModel extends BaseModel {

    @observable
    id;

    @observable
    dialectId;

    @observable
    skills = [];

    @observable
    structure = [];

    @observable
    levels = [];

    @observable
    cpId;

    @computed
    get updateLevels(){
        return this.levels.map(level => `api/language_levels/${level.id}`);
    }

    @computed
    get updateDialectId(){
        return `api/language_dialects/${this.dialectId}`;
    }

    @computed
    get updateCp(){
        return `api/conversation_partners/${this.cpId}`;
    }



    updater = service.update(this.id);
    adder = service.add;

    @computed
    get dialect() {
        return languageStore.findDialect(this.dialectId);
    }

    @computed
    get language() {
        return languageStore.findByDialect(this.dialectId);
    }

    @action.bound
    add():Promise {
        const cp = `api/conversation_partners/${this.cpId}`;
        return new Promise((resolve, reject) => {
            service.add({cp, language:this.updateDialectId, levels:this.updateLevels, structure: this.structure, skills: this.skills})
                .then(r => {
                    this.id = r.id;
                    resolve(r);
                }).catch(e => reject(e));
        })
    }

    @action
    addOrRemoveLevel(levelId) {
        console.log(levelId);
        const levelExist = this.levels.find(l => l.id === levelId);
        if (levelExist) {
            this.levels = this.levels.filter(l => l.id !== levelId);
        } else {
            this.levels.push(languageLevelStore.find(levelId));
        }
    }

    @action
    addOrRemoveStructure(structure) {
        if (this.structure.includes(structure)) {
            this.structure = this.structure.filter(s => s !== structure);

        } else {
            this.structure.push(structure);
        }
    }

    @action
    addOrRemoveSkills(skill) {
        if (this.skills.includes(skill)) {
            this.skills = this.skills.filter(s => s !== skill);

        } else {
            this.skills.push(skill);
        }
    }

    serializeData(data, setLoadedOnDone = true) {
        super.serializeData(data, setLoadedOnDone);
        for (const key in data) {
            set(this, key, data[key]);
        }
    }

    @action
    delete = () =>{
        service.delete(this.id);
        this.parentStore.remove(this);
    };


    updateSchema: * = [
        "dialectId",
        "skills",
        "structure",
        "levels",
        "cpId"
    ];
}

export default CpLanguageModel;
