import cp from "./cp";
import student from "./student";
import session from "./session";
import bundle from "./bundle";
import language from "./language";
import dialect from "./dialect";
import admin from "./admin";

export default {
  cp,
  student,
  session,
  bundle,
  language,
  dialect,
  admin
}
