import styled, {css} from "styled-components";
import FlexBox, {flexDirection, flexProps} from "../FlexBox";
import colors from "../../constants/colors";

const flexStyle = css`
    flex:1;
`;

const dayBorderStyle = css`
    border-width: 0 0 0 1px;
    :first-of-type{
    border-width: 0;
    }
`;

const dayNoBorderStyle = css`
    border: solid rgb(0,0,0,0.15);
    border-width: 0 1px 0 0;
`;

const noBorderStyle = css`
    border-width: 0;
`;

/**
 * @component
 */
export const DayColumn = styled(FlexBox).attrs(props=>({direction: flexDirection.column,...props}))`
    border: solid rgb(0,0,0,0.05);
    ${props => !props.static && flexStyle}
    ${props => !props.static && dayBorderStyle || dayNoBorderStyle}
    ${props=> props.noBorder && noBorderStyle}
`;

/**
 * @component
 */
export const TimeRow = styled(FlexBox).attrs({direction: flexDirection.row})`
    border: solid rgb(0,0,0,0.05);
    height: 50px;
    min-height: 50px;
    ${props => !props.static && flexStyle}
    ${props => !props.static && dayBorderStyle || dayNoBorderStyle}
`;

/**
 * @component
 */
export const LeftBlockStyle = styled(FlexBox).attrs({direction: flexDirection.column, justify: flexProps.start})`
    flex:1;
    height:60px;
    min-height:60px;
    border: solid rgb(0,0,0,0);
    border-width: 0 1px 0 0;
    font-size: 12px;
    color:${colors.primary};
    text-align: end;
    text-justify: distribute-center-last;
    padding-right: 10px;
    margin-top:-10px;
`;

const activeTimeBlockStyle= css`
    background-color: #d8e5f5;
`;

const disabledTimeBlockStyle = css`
    opacity: 0.4;
    cursor: not-allowed !important;
`;

/**
 * @component
 */
export const TimeBlockStyle = styled(FlexBox).attrs({
  direction: flexDirection.column,
  justify: flexProps.center
})`
    flex:1;
    border: solid rgb(0,0,0,0.05);
    height:50px;
    min-height: 50px;
    cursor: pointer;
    border-width: 0 0 1px 0;
     user-select: none !important;
    ${props => props.bordered && css`
      border-width: 0 1px 1px 1px;
    `}
    ${props=>props.active && activeTimeBlockStyle}
    ${props=>props.disabled && disabledTimeBlockStyle}
`;

const timeBlockSessionColor = css`
    background-color: ${(props) => props.color};
    border-color: ${props => props.color};
`;

const timeBlockSessionBorderColor = css`
    border-color: ${colors.darkAccent};
`;

const timeBlockSessionActiveStyle = css`
    background-color: transparent;
    color: ${colors.white};
`;

/**
 * @component
 */
export const TimeBlockSessionStyle = styled(FlexBox)`
    flex:1;
    border-left: 5px solid;
    cursor: inherit;
    user-select: none !important;
    ${props => props.color && timeBlockSessionColor}
    ${props=>props.active && timeBlockSessionBorderColor}
`;

TimeBlockSessionStyle.Content = styled(FlexBox).attrs(props=>({align: flexProps.center,...props}))`
    flex:1;
    font-size:11px;
    font-style: italic;
    padding: 5px;
    user-select: none !important;
    background-color: rgba(255,255,255,0.95);
    ${props=>props.active && timeBlockSessionActiveStyle}
    > span{
    margin-left: 5px;
    }
    
    
`;

/**
 * @component
 */
export const HeaderStyle = styled(FlexBox).attrs({
  align: "center",
  justify: "center"
})`
    height: 50px;
    min-height: 50px;
    flex: 1;
    border: solid rgb(0,0,0,0.15);
    border-width: 0 0 1px 0;
    font-size: 12px;
    color: ${colors.primary};
`;

/**
 * @component
 */
export const HeaderContainerStyle = styled(FlexBox).attrs({
  direction:flexDirection.row
})`
    padding-bottom: 20px;
    border-bottom: 1px solid #F7F7F7;
    margin-bottom: 20px;
`;
