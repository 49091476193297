import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock from "@components/DashboardBlock";
import FlexBox from "@components/FlexBox";
import ProfileImage from "@components/ProfileImage";
import Button from "@components/Button";
import {viewSchema} from "@schemas/Admin";
import ViewDetails from "@containers/Admin/ViewDetails";


@inject("adminAdminStore")
@observer
class View extends React.Component {

    @computed
    get _entity() {
        const {adminAdminStore, match} = this.props;
        const id = ~~match.params.id;
        const entity = adminAdminStore.data.find(c => c.id === id);

        return entity || null;
    }

    _onEdit = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/edit`);
    };

    render() {
        window.test = this;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock title={`${this._entity.firstName} ${this._entity.lastName}`} action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
                        <FlexBox direction="row-responsive" full>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                                <ViewDetails entity={this._entity} schema={viewSchema.admin} />
                            </FlexBox>
                        </FlexBox>
                    </DashboardBlock>
                    <FlexBox direction="row-responsive">
                    </FlexBox>
                </Screen.Column>
            );

        return (
            <div>loading</div>
        );
    }
}


export default View;
