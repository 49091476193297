import React from "react";
import {observer, inject} from "mobx-react";
import {computed} from "mobx";
import moment from "moment";
import tzmoment from "moment-timezone";
import {StudentModel} from "../../../stores/domain/cp/studentStore";
import Screen from "../../../components/Screen";
import FlexBox, {flexProps} from "../../../components/FlexBox";
import ProfileImage from "../../../components/ProfileImage";
import TimeBlock from "../../../containers/dashboardBlocks/TimeBlock";
import AccountInformationBlock from "../../../containers/dashboardBlocks/AccountInformationBlock";
import UserInterestsBlock from "../../../containers/dashboardBlocks/UserInterestsBlock";
import BioBlock from "../../../containers/dashboardBlocks/BioBlock";


@inject("studentStore")
@observer
class CpStudentScreen extends React.Component {

  @computed
  get student(): StudentModel {
    const {match, studentStore} = this.props;
    const {id} = match.params;
    return studentStore.find(~~id);
  }

  render() {

      const {profilePicture} = this.student;
      return (
        <Screen>
          <Screen.Column basis="1/3">
            <FlexBox align={flexProps.center} pad={{vertical: 26}} justify={flexProps.center}>
              <ProfileImage src={profilePicture} size="big" round/>
            </FlexBox>
            <TimeBlock timeZoneType={this.student.timezoneName} timezone={this.student.timezone}/>
          </Screen.Column>
          <Screen.Column basis="2/3">
            <AccountInformationBlock userStore={this.student} type="cp"/>
            <UserInterestsBlock userStore={this.student} type="cp"/>
            <BioBlock userStore={this.student} type="cp"/>
          </Screen.Column>
        </Screen>
      );
  }
}

export default CpStudentScreen;