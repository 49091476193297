import React from "react";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import {observer, inject} from "mobx-react";
import colors from "@constants/colors";
import Icon from "@oovui/react-feather-icons";
import ProgressBar from "../../components/ProgressBar";
import FlexBox, {flexProps} from "../../components/FlexBox";
import ProfileImage from "../../components/ProfileImage";
import {ProgressCpName} from "./styled";
import {StudentLanguageStore, StudentLanguageModel} from "../../stores/domain/student/studentLanguageStore";


const LanguageTaughtBlock = observer(() => (
  <DashboardBlock title="My Students">
    <ProgressFeed/>
  </DashboardBlock>
));


const ProgressFeed = inject("cpStudentLanguageStore")(observer(({cpStudentLanguageStore}: { cpStudentLanguageStore: StudentLanguageStore }) => {
  if (cpStudentLanguageStore.isEmpty)
    return <EmptyPlaceholder/>;
  return cpStudentLanguageStore.allData.map(l => <ProgressEntry language={l}/>);
}));

const EmptyPlaceholder = () => (
  <FlexBox full="horizontal" direction="row">
    <Icon type="users" size={24}/>
    <FlexBox direction="column" margin={{left:10}}>
      <DashboardBlock.Value>You have no students taught yet</DashboardBlock.Value>
      <p>Our team will be matching you with students based on their requests.</p>
    </FlexBox>
  </FlexBox>
);

const ProgressEntry = observer(({language}: { language: StudentLanguageModel }) => (
  <FlexBox key={language.id} full="horizontal" direction="column" margin={{bottom: 25}}>
    <DashboardHeader>{language.name} | {language.dialect}</DashboardHeader>
    <ProgressBar margin={{top: 10, bottom: 10}} total={10} progress={language.completedSessionHours} color={language.color}/>

     <FlexBox margin={{top: 10}} direction="row" align={flexProps.center}>
      <ProfileImage
        src={language.student.profilePicture || require("@assets/male-placeholder.jpg")}
        round
        size="small"
        margin={{right: 15}}
      />

      <ProgressCpName to={`student/${language.student.id}`}>{language.student.name}</ProgressCpName>

    </FlexBox>
  </FlexBox>
));

export default LanguageTaughtBlock;
