import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import Input, {ObservedInput} from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import moment from "moment";
import {InputLabel} from "@components/Input/styled";
import {serverTimeFormat} from "@utils/date";
import {Model as SessionModel} from "@stores/domain/admin/sessions";


@inject("adminSessionStore", "adminStudentStore", "adminStudentLanguageStore","sessionTypeStore")
@observer
class Add extends React.Component {

  @observable
  selectedStudent;

  @observable
  selectedDialect;

  @observable
  selectedCP;

  @observable
  start;

  @observable
  end;

  @observable
  sessionState;

  @observable
  dateError = false;

  @observable
  sessionType;

  @observable
  _entity  =  cloneStore(new SessionModel());

  @computed
  get _StudentLanguages() {
    const {adminStudentLanguageStore} = this.props;
    const {Student} = this._entity;
    if (Student) {
      return adminStudentLanguageStore.data.filter(s => s.studentId === Student.id && s.cp !== null);
    }

    return []
  }

  componentDidMount(): void {
    const {sessionTypeStore} = this.props;
    this.start = moment().toDate();
    this.end = moment().add(30, "m");
    this.sessionType = sessionTypeStore.data[0].id;
    this.dateError = false;
  }

  _goBackPrevention = e => {
    e.preventDefault();
    this._goBack()
  };

  _goBack = () => {
    const {history} = this.props;
    history.goBack();
  };

  @action
  _validateDateTime = () =>{
    const startTime  = moment(`${this.startTime} ${this.startDate}`,"hh:mm A DD/MM/YYYY");
    if(startTime.isValid()){
      this.dateError = false;
    }else{
      this.dateError = "Date or Time are invalid";
    }
  };

  @action
  _submit = (e) => {
    e.preventDefault();
    const {sessionStore} = this.props;
    const customData = {};
    if (this._entity.studentLanguageSpecialId && this._entity.studentLanguageSpecialId !== this._entity.original.studentLanguageSpecialId)
      customData.studentLanguage = `/api/student_languages/${this._entity.studentLanguageSpecialId}`;

    switch (this.sessionState) {
      case "canceled":
        customData.isCanceled = true;
        break;
      case "completed":
        customData.isCompleted = true;
        break;
      default:
        break;

    }

    this._entity.status = this.sessionState;
    customData.type = `/api/session_types/${this.sessionType}`;

    const startTime = moment(this.start);
    if (startTime.isValid()) {
      customData.scheduledAt = (startTime.utc().format(serverTimeFormat));
      this._entity.scheduledAt = customData.scheduledAt;
    }

    customData.duration = this.end.diff(this.start,"m");
    this._entity.serializeAndAdd(false,sessionStore, customData).then(this._goBack).catch(error=>console.log(error));
  };

  render() {
    window.test = this;
    const {adminStudentStore,sessionTypeStore} = this.props;
    if(!this._entity.Student && adminStudentStore.data.length >0){
      this._entity.setStudent(adminStudentStore.data[0].id);
    }
    if(!this._entity.StudentLanguage && this._entity.Student){
      if(this._StudentLanguages.length>0)
        this._entity.setLanguage(this._StudentLanguages[0].id);
    }
    if (this._entity)
      return (
          <Screen.Column last basis="full">
            <DashboardBlock as="form" onSubmit={this._submit}>
              <FlexBox direction="row-responsive" loading={this._entity.loading}>
                <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                  <ObservedInput label="Student" type="select" fill
                                 onChange={(l) => {
                                   this._entity.setStudent(~~l.nativeEvent.target.value);
                                   this._entity.setLanguage(this._StudentLanguages.length > 0 ? this._StudentLanguages[0] : null);
                                   console.log(this._entity.student);
                                   console.log(this._entity.Student);
                                 }}>
                    {
                      adminStudentStore.data.map(student => <option
                          value={student.id}>{`${student.firstName} ${student.lastName}`}</option>)
                    }
                  </ObservedInput>
                  <ObservedInput label="Dialect" type="select" fill
                                 defaultValue={this._entity.StudentLanguage?this._entity.StudentLanguage.id:null}
                                 onChange={(l) => {
                                   this._entity.setLanguage(~~l.nativeEvent.target.value);
                                 }}>
                    {
                      this._StudentLanguages.map(language => <option
                          value={language.id}>{`${language.dialectName}`}</option>)
                    }
                  </ObservedInput>
                  <InputLabel>
                    CP: {this._entity.CP ? `${this._entity.CP.firstName} ${this._entity.CP.lastName}` : "Not Selected"}
                  </InputLabel>
                </FlexBox>
                <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                  <Input label="Start Time" type="dateTimePicker" value={this.start} onChange={v => {
                    this.start = v;
                  }}/>
                  <Input label="End Time" type="dateTimePicker" value={this.end} onChange={v => {
                    this.end = v;
                  }}/>
                  <ObservedInput type="select" label="Status" defaultValue={this._entity.status} fill onChange={(l) => {
                    this.sessionState = l.nativeEvent.target.value;
                  }}>
                    <option value="pending">
                      Pending
                    </option>
                    <option value="passed">
                      Passed
                    </option>
                    <option value="canceled">
                      Cancelled
                    </option>
                    <option value="completed">
                      Completed
                    </option>
                  </ObservedInput>
                  <ObservedInput type="select" label="Type" defaultValue={this.sessionType} fill
                                 onChange={(l) => {
                                   this.sessionType = l.nativeEvent.target.value;
                                 }}>
                    {sessionTypeStore.data.map(t=><option key={t.name} value={t.id}>
                      {t.name}
                    </option>)}
                  </ObservedInput>
                </FlexBox>
              </FlexBox>
              <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                       justify={flexProps.spaceBetween}>
                <Button text="Cancel" onClick={this._goBackPrevention} type="button" withArrowLeft/>
                <Button text="Submit" iconRight="check" primary/>
              </FlexBox>
            </DashboardBlock>
          </Screen.Column>
      );
    return (
        <div>loading</div>
    );
  }

}


export default Add;
