import styled from "styled-components";
import {Link} from "react-router-dom";
import FlexBox from "../../components/FlexBox";


export const ProgressCpName = styled(Link)`
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: italic;
    color: black;
    text-decoration: none;
    :hover  {
    text-decoration: underline;
    }
`;

export const AccountInformationRow = styled(FlexBox)`
    padding: 20px 0;
    font-family: Lato, sans-serif;
    font-size: 16px;
    border-bottom: 1px solid #E2E2E2;
    width:100%;
    
    :last-of-type{
      border-bottom: 0;
      padding-bottom:0;
    }
    :first-of-type{
      padding-top:0;
    }
`;

AccountInformationRow.Header = styled(FlexBox).attrs({f:1})`
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
`;

AccountInformationRow.Value = styled(FlexBox).attrs({f:1})`
    text-align: left;
`;

ProgressCpName.defaultProps = {
  to: ""
};
