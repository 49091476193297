import React from "react";
import {observer,inject} from "mobx-react";
import FlexBox,{flexDirection, flexProps} from "@components/FlexBox";
import {DashboardHeader} from "@components/DashboardBlock";
import colors from "@constants/colors";
import Modal from "@components/Modal";
import Button from "@components/Button";

const SessionBookingConfirmationModal = inject("sessionBookingStore","routerStore")(observer(({sessionBookingStore,routerStore})=>{
  return(
    <Modal show={sessionBookingStore.showConfirmationModal}>
      <FlexBox direction={flexDirection.column}>
        <DashboardHeader>{sessionBookingStore.confirmationModalText}</DashboardHeader>
        <DashboardHeader subHeader>{sessionBookingStore.confirmationModalQuestion}</DashboardHeader>
        <FlexBox direction={flexDirection.row} justify={flexProps.spaceBetween} margin={{top:20}}>
          {sessionBookingStore.defaultType? <Button text={sessionBookingStore.haveEnoughCredit?sessionBookingStore.isCreditExpired?"Buy More Credit":"Confirm Order":"Buy More Credit"}
                  background={colors.primary}
                  margin={{right:20,mobile:{right:"0"}}}
                  iconLeft={!sessionBookingStore.haveEnoughCredit || sessionBookingStore.isCreditExpired?"shopping-bag":"check"}
                  onClick={
                    sessionBookingStore.haveEnoughCredit && !sessionBookingStore.isCreditExpired?
                    sessionBookingStore.bookSelectedSessions:sessionBookingStore.goToPurchases}
          />:null}
          <Button text={sessionBookingStore.defaultType?"Cancel":"Okay"} iconLeft={sessionBookingStore.defaultType?"x":"check"}
                  onClick={sessionBookingStore.switchConfirmationModal}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}));


export default SessionBookingConfirmationModal;
