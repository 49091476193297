import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock from "@components/DashboardBlock";
import FlexBox from "@components/FlexBox";
import {viewSchema} from "@schemas/Admin";
import ViewDetails from "@containers/Admin/ViewDetails";

const dataScreen = (data) => (Component) => {
    return () => <Component {...data}/>
};

@inject("languageStore")
@observer
class ViewDialect extends React.Component {

    @computed
    get _entity() {
        const {languageStore, match} = this.props;
        const id = ~~match.params.id;
        const entity = languageStore.findDialect(id);

        return entity || null;
    }

    _onEdit = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/edit`);
    };

    render() {
        window.test = this;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock title={this._entity.name}
                                    action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
                        <FlexBox direction="row-responsive" full>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                                <ViewDetails schema={viewSchema.dialect} entity={this._entity}/>
                            </FlexBox>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );

        return (
            <div>loading</div>
        );
    }
}


export default ViewDialect;
