import React from "react";
import {inject, observer} from "mobx-react";
import {computed, isObservableArray} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import {Icon, IconButton} from "rsuite";
import _ from "lodash";
import FlexBox from "@components/FlexBox";
import {viewSchema} from "@schemas/Admin";
import ViewDetails from "@containers/Admin/ViewDetails";
import Button from "@components/Button";

// const viewSchema = [
//   "date",
//   "startTime",
//   "endTime",
//   "status",
//   "code"
// ];



@inject("adminBundleStore")
@observer
class ViewCp extends React.Component {

  @computed
  get _entity() {
    const {adminBundleStore, match} = this.props;
    const id = ~~match.params.id;
    const entity = adminBundleStore.data.find(c => c.id === id);

    return entity || null;
  }

  _onEdit = () => {
    const {history, match} = this.props;
    history.push(`${match.url}/edit`);
  };

  render() {
    window.test = this;
    if (this._entity)
      return (
        <Screen.Column last basis="full">
          <DashboardBlock title={this._entity.name} action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
            <FlexBox direction="row-responsive" full>
              <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                <ViewDetails entity={this._entity} schema={viewSchema.bundle} />
              </FlexBox>
              <FlexBox direction="column" childrenSpace={10}>
              <Button text="Purchases" />
            </FlexBox>
            </FlexBox>
          </DashboardBlock>
          <FlexBox direction="row-responsive">
          </FlexBox>
        </Screen.Column>
      );

    return (
      <div>loading</div>
    );
  }
}


export default ViewCp;
