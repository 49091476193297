const stripe_key = process.env.REACT_APP_STRIPE_KEY;
const stripeService = {
    setUp: () => {
        const stripeScript = document.createElement('script');
        stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
        document.body.appendChild(stripeScript);
        // stripe = window.Stripe('pk_test_g2VawROwW6Lr32uCl5WSeBjl');
    },
    redirectCheckout:(sessionId)=> new Promise((resolve,reject)=>{
        const stripe = window.Stripe(stripe_key);
        stripe.redirectToCheckout({
            sessionId: sessionId,
            }
        ).then(resolve)
            .catch(reject)
    })
};

window.stripeService = stripeService;

export default stripeService;
