import styled,{css} from "styled-components";
import React from "react";
import colors from "../../constants/colors";
import FlexBox, {flexDirection, flexProps} from "../FlexBox";

const colorStyle = css`
    background-color: ${props => colors.gradient[props.index%5]};
`;


const marginStyle = css`
    margin-bottom: 10px;
`;

/**
 * @type {StyledComponent<[undefined], any, never, "justify" | "align">}
 */
export const StyledBadge = styled(FlexBox).attrs({align: flexProps.center, justify: flexProps.center})`
    width:60px;
    height:60px;
    border-radius: 30px;   
     
    ${props => props.lead && marginStyle}
    ${colorStyle}
`;


StyledBadge.Text = styled.span`
    font-family: Lato, sans-serif;
    font-size: 12px;
    color: ${colors.black};
    text-transform: capitalize;
    width:80px;
    text-align: center;
    margin-bottom: 10px;
`;


StyledBadge.Container = styled(FlexBox).attrs({
  direction: flexDirection.column,
  align: flexProps.center,
  justify: flexProps.center
})`
    :not(:last-of-type){
        margin-right: 20px;
    }
`;
