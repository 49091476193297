import React, {Component} from "react";
import {Route, Router, Switch} from "react-router-dom";
import {authRoutes, mainRoutes} from "@config/routes";
import adminStore from "@stores/app/adminSpecificStore";
import {history} from "@stores/app/routerStore";
import "../globals/globalStyle";
import "../App.css";
import {inject, observer} from "mobx-react";
import Loader from "react-loading";
import colors from "@constants/colors";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import Toast from "@components/Toast";
import {Flip} from "react-toastify";
import NotFound from "./NotFound";
import FlexBox from "../components/FlexBox";


@inject("authStore")
@observer
class App extends Component {

  renderRouter = () => {
    return (<Router history={history}>
      <Switch>
        {mainRoutes.get().map(r =>
          r.parent && <Route key={r.name} path={r.path} exact={false} name={r.name} component={r.component}/>
        )}
      </Switch>
    </Router>);
  };

  renderLoginScreen = () => (
    <Router history={history}>
      <Switch>{authRoutes.map(r =>
        <Route key={r.name} path={r.path} exact name={r.name} component={r.component}/>
      )}
        <Route component={NotFound}/>
      </Switch>
    </Router>
  );

  renderLoading = (loadStores = false)=>{
    if(loadStores)
      adminStore.loadAll();
    return(
      <Loading/>
    )
  }

  render() {
    const {authStore} = this.props;
    return (<React.Fragment>
      {authStore.loaded ? authStore.isLoggedIn ?!authStore.isAdmin? this.renderRouter():adminStore.didLoad?this.renderRouter():
        this.renderLoading(true):
        this.renderLoginScreen() : <Loading/>}
      <Toast position="top-center" autoClose={5000} transition={Flip}/>
    </React.Fragment>);
  }
}

const Loading = () => (
  <FlexBox full="both" align="center" backgroundColor={colors.white} justify="center" direction="column"
           style={{textAlign: "center"}}>
    <Typist avgTypingDelay={100} cursor={{show: false}}>
      <span
        style={{fontSize: 24, color: colors.secondary, fontWeight: "600", textAlign: "center"}}>Please wait</span><br/>
    </Typist>
    <Loader type="cylon" width={80} color={colors.secondary}/>
  </FlexBox>
);

const NoMatch = () => (<div>No Match</div>);

export default App;
