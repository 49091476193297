import React, {InputHTMLAttributes} from "react";
import type {FlexType} from "@components/FlexBox";
import FlexBox, {flexProps} from "@components/FlexBox";
import _ from "lodash";
import "react-day-picker/lib/style.css";
import DateTimePicker from "react-day-picker/DayPickerInput";
import MaskedInput from "react-maskedinput";
import {observer} from "mobx-react";
import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {If} from "react-if";
import {
  BaseInput,
  BaseTextArea,
  CheckboxContainer,
  InputLabel,
  RadioContainer,
  StyledError,
  StyledErrorBlock,
  StyledHeader,
  StyledIcon
} from "./styled";

type EdgeType =
  string | number | {
  top: string | number,
  bottom: string | number,
  right: string | number,
  left: string | number,
  horizontal: string | number,
  vertical: string | number,
  mobile: {
    top: string | number,
    bottom: string | number,
    right: string | number,
    left: string | number,
    horizontal: string | number,
    vertical: string | number,
  }
}

type InputProps = {
  type: "text" | "password" | "email" | "date" | "textarea" | "checkbox" | "radio",
  placeholder?: ?string,
  label?: ?string,
  lightLabel?: boolean,
  containerProps?: FlexType,
  withMargin?: boolean,
  margin?: EdgeType,
  pad?: EdgeType,
  onChange?: Function,
  error?: string,
  labelWidth?: number
} & InputHTMLAttributes;

type InputState = { isChecked: boolean, textValue: string };

class Input extends React.Component<InputProps, InputState> {

  state: InputState = {
    isChecked: false,
    textValue: ""
  };


  /** Get input value based on type */
  get value(): ?string | ?boolean {
    const {textValue, isChecked} = this.state;
    const {type} = this.props;
    switch (type) {
      case "text":
      case "password":
      case "email":
      case "date":
      case "datetime":
      case "number":
      case "textarea":
      case "phone":
        return textValue;


      case "checkbox":
        return isChecked;
      default:
        return null;
    }
  }


  _switchCheckbox = () => {
    const {isChecked} = this.state;
    const {onChange} = this.props;
    this.setState({
      isChecked: !isChecked
    });

    onChange();
  };

  render() {
    const {type, label, lightLabel, containerProps, withMargin, labelWidth, fill, onChange, margin, error, ...props} = this.props;
    const inputProps = _.omit(this.props, ["containerProps", "light", "margin"]);
    if (["text", "password", "email","number","datetime-local"].indexOf(type) > -1) {
      return (
        <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}
                 direction="row-responsive" {...containerProps}>
          {label ?
            <InputLabel {...props} labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
          <FlexBox full={fill} margin={margin} >
            <BaseInput {...inputProps} showError={error}/>
            <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
            <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
          </FlexBox>
        </FlexBox>

      );
    }

    if (type === "phone") {
      return (
        <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                 direction="row-responsive">
          {label ?
            <InputLabel {...props} labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
          <FlexBox full={fill} margin={margin}>
            <BaseInput {...inputProps} showError={error}/>
            <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
            <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
          </FlexBox>
        </FlexBox>

      );
    }
    if (type === "date") {
      const {type, ...iProps} = inputProps;
      return (
        <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                 direction="row-responsive">
          {label ?
            <InputLabel {...props} labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
          <FlexBox full={fill} margin={margin}>
            <BaseInput  {...inputProps} as={MaskedInput} showError={error} type="text" mask="11/11/1111"/>
            <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
            <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
          </FlexBox>
        </FlexBox>

      );
    }
    if (type === "dateTimePicker") {
      const {type, ...iProps} = inputProps;
      return (
        <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                 direction="row-responsive">
          {label ?
            <InputLabel {...props} labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
          <FlexBox full={fill} margin={margin}>
            <DatePicker className="date-picker"  {...inputProps}/>
            <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
            <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
          </FlexBox>
        </FlexBox>

      );
    }

      if (type === "time") {
        const {type, ...iProps} = inputProps;
        return (
            <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                     direction="row-responsive">
              {label ?
                  <InputLabel {...props} labelWidth={labelWidth} type={type}
                              light={lightLabel}>{label}</InputLabel> : null}
              <FlexBox full={fill} margin={margin}>
                <BaseInput  {...inputProps} as={MaskedInput} showError={error} type="text" mask="11:11 AM"/>
                <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
                <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
              </FlexBox>
            </FlexBox>

        );
      }

    if (type === "t") {
      const {type, ...iProps} = inputProps;
      return (
        <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                 direction="row-responsive">
          {label ? <InputLabel labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
          <FlexBox full={fill} margin={margin}>
            <DateTimePicker component={p => <BaseInput {...iProps} {...p} showError={error} autoComplete="off"/>}
                            onDayChange={onChange}/>
            <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
            <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
          </FlexBox>
        </FlexBox>
      );
    }

    if (type === "select") {
      const {options, children, ...elseProps} = inputProps;
      return (
        <FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                 direction="row-responsive">
          {label ?
            <InputLabel {...props} labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
          <FlexBox full={fill} margin={margin}>
            <BaseInput {...elseProps} as="select" showError={error}>
              {options && options.map(o => <option key={o.value} value={o.value}>{o.name}</option>)}
              {children}
            </BaseInput>
            <StyledIcon show={error} type="alert-circle" size="small" color="darkred"/>
            <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
          </FlexBox>
        </FlexBox>
      );
    }
    if (type === "checkbox") {
      const {checked, onClick, link} = this.props;
      return (
        <FlexBox {...containerProps} margin={{top: withMargin ? 40 : 0}} align={flexProps.center}>
          <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
          <CheckboxContainer onClick={onClick}>
            <input {...inputProps} checked={checked}/>
            <span className="checkmark"/>
          </CheckboxContainer>
          {label ? <InputLabel light={lightLabel} type={type}>{label}{link}</InputLabel> : null}
        </FlexBox>
      );
    }
    if (type === "radio") {
      const {checked, onClick} = this.props;
      return (
        <FlexBox {...containerProps} margin={{right: withMargin ? 40 : 0, bottom: 10}} align={flexProps.center}>
          <RadioContainer onClick={onClick}>
            <input {...inputProps} checked={checked}/>
            <span className="checkmark"></span>
          </RadioContainer>
          {label ? <InputLabel light={lightLabel} type={type}>{label}</InputLabel> : null}
        </FlexBox>
      );
    }
    if (type === "textarea") {
      return (<FlexBox margin={{bottom: withMargin ? 20 : 0}} align={flexProps.center}  {...containerProps}
                       full={fill}
                       direction="row-responsive">
        {label ? <InputLabel labelWidth={labelWidth} type={type} light={lightLabel}>{label}</InputLabel> : null}
        <BaseTextArea {...inputProps} />
      </FlexBox>);
    }
    return null;
  }
}

Input.RadioGroup = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedRadio: -1};
  }

  render(): React.ReactNode {
    const {error, children, title, ...props} = this.props;
    return <FlexBox direction="row-responsive" wrap margin={{top: 20}} {...props}>
      <If condition={title}>
        <InputLabel labelWidth={120}>{title}</InputLabel>
      </If>
      <StyledErrorBlock show={error}>{error}</StyledErrorBlock>
      {children}
    </FlexBox>;
  }
};

Input.Header = ({text, error}) => {
  return (
    <StyledHeader>{text} <StyledError>{error}</StyledError></StyledHeader>
  );
};

Input.defaultProps = {
  placeholder: null,
  label: null,
  lightLabel: false,
  containerProps: null,
  withMargin: true,
  margin: null,
  pad: null,
  error: null,
  labelWidth:120,
  onChange: () => {
  }
};


export const ObservedInput = observer(({field, error, ...props}) => {
  return <Input error={(field ? field.error : null) || (error)} {...field ? field.bind() : {}} {...props}  />;
});

export default Input;
