import api from "@config/api";
import {BaseModel, BaseStore} from "@stores/domain/BaseStore";
import moment from "moment";
import {action, computed, observable} from "mobx";
import colors from "@constants/colors";
import _ from "lodash";
import studentLanguageStore from "./student/studentLanguageStore";
import balanceStore from "./student/balanceStore";
import cpStudentLanguageStore from "./cp/studentLanguageStore";
import authStore from "./authStore";

const service = {
  getSession: (id): Promise => api.get(`session/${id}`),
  getSessions: (): Promise => api.get("sessions"),
  deleteSession: (id): Promise => api.get(`session/${id}`),
  cancelSession: (id): Promise => api.post(`sessions/${id}/cancel`),
  updateSession: ({id, data}): Promise => api.patch(`session/${id}`, data)
};

export class SessionModel extends BaseModel {
  loader = service.getSession;

  updater = service.updateSession;

  deleter = service.deleteSession;

  parentStore = store;


  endPoint = "sessions";

  @observable
  cpId: number;

  @observable
  studentLanguageId: number;

  @observable
  studentUrl = null;

  @observable
  cpUrl = null;

  @observable
  code = null;

  @observable
  isCompleted = false;

  @observable
  scheduledAt = null;

  @observable
  type = null;


  @computed
  get studentLanguageStore() {
    if (authStore.role === "student") {
      return studentLanguageStore;
    }
    if (authStore.role === "cp") {
      return cpStudentLanguageStore;
    }
    return null;
  }

  @computed
  get studentLanguage() {
    return this.studentLanguageStore.find(this.studentLanguageId);
  }

  @computed
  get color() {
    return this.studentLanguage.color;
  }

  @computed
  get cp() {
    return this.studentLanguage.cp;
  }

  @computed
  get student() {
    return this.studentLanguage.student;
  }

  @computed
  get scheduledAtMoment() {
    return moment(this.scheduledAt);
  }

  @computed
  get endMoment() {
    return moment(this.scheduledAt).add(30, "m");
  }

  @computed
  get startTime() {
    return this.scheduledAtMoment.format("LT");
  }

  @computed
  get fullStartTime(){
    return this.scheduledAtMoment.format("hh:mm a");
  }

  @computed
  get startDate(){
    return this.scheduledAtMoment.format("DD/MM/YYYY")
  }

  @computed
  get endTime() {
    return moment(this.scheduledAt).add(this.duration, "m").format("LT");
  }

  @computed
  get canAccessSession() {
    return this.scheduledAtMoment.diff(moment(), "m") < 5;
  }

  @computed
  get canCancel() {
    return this.scheduledAtMoment.diff(moment(), "m") > 30;
  }

  @computed
  get timeLeft() {
    return moment(this.scheduledAt).fromNow();
  }

  @computed
  get language(){
    return this.studentLanguage.language;
  }


  @computed
  get leftTimeLeadUnit() {
    if (this.timeLeft.match(/second/g))
      return "s";
    if (this.timeLeft.match(/minute/g))
      return "m";
    if (this.timeLeft.match(/hour/g))
      return "h";
    if (this.timeLeft.match(/day/g))
      return "d";
    return null;

  }

  @computed
  get moreThan24() {
    const now = moment();
    const diff = this.scheduledAtMoment.diff(now);
    const duration = moment.duration(diff);
    return duration.asHours() > 24;
  }

  @action
  cancel = () => {
    this.studentLanguage.subPendingSessionHours(this.duration);
    if (authStore.role === "student") {
      const {cost} = this.studentLanguage.defaultType;
      if(this.moreThan24)
        balanceStore.add(cost);
      this.studentLanguage.cp.availabilities.availabilityBlocks.find(d=>d.start === this.scheduledAt).isAvailable = true;
      this.studentLanguage.subCreditUsed(cost);
    }
    this.parentStore.remove(this);
    service.cancelSession(this.id);
  };

  static get tableSchema() {
    return [
      "code",
      "isCompleted"
    ];
  }

  serializeData(data) {
    const consumedData = _.omit(data, ["student"]);
    super.serializeData(consumedData);
  }

}

export class SessionStore extends BaseStore {


  @computed
  get completedSessions(): Array<SessionModel> {
    return this.data.filter(s => s.isCompleted === true);
  }

  @computed
  get activeSessions(): Array<SessionModel> {
    return this.data.filter(s => s.isCompleted === false);
  }

  @computed
  get sectionedData(): SectionedType {
    const months: SectionedType = [];

    const sessions = this.activeSessions.slice().sort((a, b) => (a.scheduledAt >= b.scheduledAt) ? 1 : -1);
    sessions.forEach(session => {
      const scheduledAtMoment = moment(session.scheduledAt);
      const month = scheduledAtMoment.format("MMMM YYYY");
      const day = scheduledAtMoment.format("dddd Do");
      const targetMonth = months.find(m => m.month === month);
      if (!targetMonth) {
        months.push({month, days: [{name: day, sessions: [session]}]});

      } else {
        const targetDay = targetMonth.days.find(d => d.name === day);
        if (!targetDay) {
          targetMonth.days.push({name: day, sessions: [session]});
        } else {
          targetDay.sessions.push(session);
        }
      }
    });

    return months;
  }

  @action
  consume(data: [Object], languageStore) {
    const sortedData = data.slice().sort((a, b) => (a.scheduledAt >= b.scheduledAt) ? 1 : -1);
    // super.consume(sortedData);
    console.log(data);
    sortedData.map(s => this.data.push(new SessionModel({...s, studentLanguageId: languageStore})));
    this.data = this.data.replace(this.data.slice().sort((a, b) => (a.scheduledAt >= b.scheduledAt) ? 1 : -1));
  }

  @computed
  get dataForCpGraph() {
    return [{
      id: 0,
      angle: Math.floor(this.hoursCompleted* 100) / 100,
      label: `${Math.floor(this.hoursCompleted* 100) / 100} Hours Completed`,
      color: colors.secondary
    }, {
      id: 1,
      angle: Math.floor(this.hoursNotCompleted* 100) / 100,
      label: `${Math.floor(this.hoursNotCompleted* 100) / 100} Hours Pending`,
      color: colors.primary
    },];
  }

  @computed
  get dataForCpGraphLegend() {
    return [...this.dataForCpGraph, {
      id: 2,
      angle: Math.floor(this.totalHours* 100) / 100,
      label: `${Math.floor(this.totalHours* 100) / 100} Total Hours`,
      color: colors.grey
    }];
  }

  @computed
  get hoursCompleted() {
    return _.sumBy(cpStudentLanguageStore.data, d => {
      return d.completedSessionHours;
    });
  }

  @computed
  get hoursNotCompleted() {
    return _.sumBy(cpStudentLanguageStore.data, d => {
      return d.pendingSessionHours;
    });
  }

  @computed
  get totalHours() {
    return Math.floor(_.sumBy(cpStudentLanguageStore.data, d => {
      return d.totalSessionHours;
    })* 100) / 100;
  }

  @computed
  get totalNumberOfSessions() {
    return this.completedSessions.length + this.activeSessions.length;
  }


  @computed
  get sectionedOnlyTwoData(): SectionedType {
    const months: SectionedType = [];

    this.activeSessions.slice(0, 2).forEach(session => {
      const mMoment = session.scheduledAtMoment;
      const month = mMoment.format("MMMM YYYY");
      const day = mMoment.format("dddd [the] do");


      const targetMonth = months.find(m => m.month === month);
      if (!targetMonth) {
        months.push({month, days: [{name: day, sessions: [session]}]});

      } else {
        const targetDay = targetMonth.days.find(d => d.name === day);
        if (!targetDay) {
          targetMonth.push({name: day, sessions: [session]});
        } else {
          targetDay.sessions.push(session);
        }
      }
    });

    return months;
  }

  loader = service.getSessions;

  DataModel = SessionModel;
}

type SectionedType = Array<{
  month: string,
  days: [{
    name: string,
    sessions: SessionStore
  }]
}>

const store = new SessionStore();

window.sessionStore = store;

export default store;
