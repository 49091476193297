import React from "react";
import {observer} from "mobx-react";
import TopNavbar from "@components/TopNavbar";
import {BaseStore} from "../stores/domain/BaseStore";
import FlexBox from "../components/FlexBox";

/**
 * Higher order component - identify that this component shows a loader while loading store
 * @param storeToWatch
 * @param authStore
 * @return {React.Component}
 */
export default (storeToWatch: BaseStore,authStore: boolean) => WrappedComponent => {
  return observer((props) => {
    return authStore.renderForAdmin?
      <WrappedComponent {...props}/>
    : !storeToWatch.loaded ?
        <FullScreenLoader prop={props} store={storeToWatch}/> :
    <WrappedComponent {...props}/>
  });
}

const Wrapper = observer(({storeToWatch,Component})=>{

  console.log("Loaded",storeToWatch.loaded);
  if (storeToWatch.loaded){
    return (<Component/>)
  }
  return(<FullScreenLoader/>);
});

/**
 * Full screen loader
 * @type {(<T extends IReactComponent>(clazz: function({store: *, prop: *}): *) => void) | IReactComponent | (function({store: *, prop: *}): *)}
 */
const FullScreenLoader = observer(({store,prop})=>{
  // if(!store.loaded && !store.loading){
  //   store.load();
  // }
  return(
    <FlexBox full="both" direction="column">
      <TopNavbar/>
    </FlexBox>
  )
});
