import apisauce, {CANCEL_ERROR, CONNECTION_ERROR, NETWORK_ERROR} from "apisauce";
import {toast} from "@components/Toast";
import API_CODES from "../constants/api";

/**
 * API instance
 * @type {ApisauceInstance}
 */
const api = apisauce.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {"X-AUTH-TOKEN": ""},
    timeout: 50000
});

/**
 * S3 URL
 * @type {string}
 */
const s3Url = "https://s3.amazonaws.com/natakallam-assets";


/**
 * Function to assert url
 * @type {Function}
 * @return {string}
 */
api.assetUrl = ({filename, folder}: { filename: string, folder: string }) => {
    return (`${s3Url}/${folder}/${filename}`);
};


/**
 * Api errors handler
 * @type {Function}
 * @param response
 */
const handleApiErrors = response => {
    switch (response.problem) {
        case apisauce.NETWORK_ERROR:
            toast.error("Please check your internet connection");
            break;
        default:
            break;
    }


};

/**
 * Adding a monitor to monitor responses
 */
// api.addMonitor(response => console.log(response));



/**
 * Function to add monitors
 * @param {Array<Function>} monitors
 */
const addMonitors = (monitors: Array<Function>): void => {

    const allMonitors = [
        handleApiErrors,
    ];

    allMonitors.map(m => api.addMonitor(m));
};

addMonitors();
window.testapi = api;
export default api;

/**
 * Graphql api endpoint
 * @return {Promise<ApiResponse<any>>}
 * @example
 * graphql('{user:{id}}')
 *    .then(resp=>console.log(resp))
 *    .catch(e=>console.log(e))
 */
export const graphql = (query): Promise => api.post("graphql", {query});



