import {action, computed, observable} from "mobx";
import api from "@config/api";
import _ from "lodash";
import {BaseModel, BaseStore} from "../BaseStore";
import {toast} from "@components/Toast";
import CPModal from "../../models/cpModel";
import languageStore from "../languageStore";
import type {LanguageDialect} from "./language";
import studentLanguageStore from "./studentLanguage";
import studentStore from "./student";
import sessionStore from "./sessions";
import moment from "moment";
import CpLanguageModel from "@stores/models/cpLanguageModel";

const service = {
    loadCps: (): Promise => api.get("conversation_partners"),
    add: data => api.post("register", {type: "cp", ...data}),
};

/**
 * CP Model
 */
export class Model extends CPModal {

    /**
     * @override
     */
    parentStore: BaseStore = store;

    adder = service.add;

    @observable
    loading = false;

    @observable
    partnership = "";

    @observable
    training = "";

    @observable
    city = "";

    @observable
    firstName = "";

    @observable
    lastName = "";

    @observable
    password = "";

    @observable
    email = "";

    @observable
    skypeId = "";

    @observable
    country = "";

    @observable
    profession = "";

    @observable
    phoneNumber = "";

    @observable
    phone = "";

    @observable
    dob = "";

    @observable
    earnings = 0;

    @observable
    earningsPaid = 0;

    @observable
    bio = "";

    @observable
    gender = "";

    @observable
    heardAboutUsFrom = "";

    @observable
    isFirstTime = true;

    @observable
    title = "";

    @observable
    status = true;

    @observable
    isActive = true;

    @observable
    createdAt = "2019-03-29T15:42:28+00:00";

    updateSchema: * = [
        "firstName",
        "lastName",
        "email",
        "dob",
        "skypeId",
        "profession",
        "gender",
        "phoneNumber",
        "birthDay",
        "isFirstTime",
        "earnings",
        "earningsPaid",
        "password",
        "bio",
        "phone",
        "country",
        "heardAboutUsFrom",
        "status",
        "city",
        "training",
        "hobbies",
        "partnership",
        "isActive",
        "avatarFileName"
    ];

    /**
     * CP Dialects
     */
    @observable
    cpDialects: Array<LanguageDialect> = [];
    /**
     * Hobbies
     */

    @observable
    hobbyInputValue = "";

    /**
     * CP Dialects
     */
    @computed
    get dialects(): Array<LanguageDialect> {
        return this.cpDialects.map(d => languageStore.findDialect(d.dialectId));
    }

    @computed
    get students() {
        const studentLanguages = studentLanguageStore.data.filter(lang => lang.cpId === this.id);
        return studentLanguages.map(l => studentStore.find(l.studentId));
    }

    @computed
    get sessions() {
        console.log(sessionStore);
        return sessionStore.data.filter(s => s.CP.id === this.id);
    }

    @computed
    get leveledDialects() {
        return this.cpDialects.map(d => _.extend(languageStore.findDialect(d.dialectId), {level: d.levelName}));
    }

    /**
     * Dialect Level
     */
    @computed
    get currentDialectLevel(): string {
        return (id) => {
            return this.cpDialects.find(d => d.dialectId === id).levelName;
        };
    }

    @computed
    get createdAtFormatted() {
        return moment(this.createdAt, "hh:mm DD/MM/YYYY");
    }

    @action.bound
    setHobbyInputValue(value) {
        this.hobbyInputValue = value;
    }

    @action.bound
    addHobby() {
        if (this.hobbyInputValue.trim()) {
            this.hobbies.push(this.hobbyInputValue.trim());
            this.hobbyInputValue = "";
        }
    }

    @action.bound
    removeHobby(hobby) {
        this.hobbies = this.hobbies.filter(h => h !== hobby);
    }


    /**
     * @override
     * @param data
     * @param setLoadedOnDone
     */
    serializeData(data: Object, setLoadedOnDone: boolean = true): void {
        if (data.languages) {
            data.languages.map(language => this.cpDialects.push(new CpLanguageModel(language,this)));
        }
        super.serializeData(data, setLoadedOnDone);
    }

    @action.bound
    addDialect({dialect, onDone = () => {
        }}) {
        const existingDialect = this.cpDialects.find(d => d.dialectId === dialect.dialectId);
        if (existingDialect) {
            console.log(existingDialect);
            toast.error(`This CP already have ${languageStore.findDialect(dialect.dialectId).name} assigned to him`);
        } else {
            this.loading = true;
            dialect.cpId = this.id;
            this.cpDialects.push(dialect);
            dialect.add()
                .then(() => {
                    onDone();
                    this.loading = false;
                }).catch(e => {
                toast.error("This wrong happened, please try again.");
                this.loading = false;
            })
            //     this.loading = true;
            //     const cp = `api/conversation_partners/${this.id}`;
            //     const language = `api/language_dialects/${dialect}`;
            //     const languageLevel = `api/language_levels/${level}`;
            //     const cpLanguageDialect = {
            //         dialectId: dialect,
            //         id: 9999999,
            //         levelName: languageLevelStore.find(level).name,
            //         sessionNumber: 0
            //     };
            //     this.cpDialects.push(cpLanguageDialect);
            //     service.addDialect({cp, language, level: languageLevel})
            //         .then(resp => {
            //             cpLanguageDialect.id = resp.id;
            //             onDone();
            //             this.loading = false;
            //         })
            //         .catch(e => {
            //             toast.error("This wrong happened, please try again.");
            //             this.loading = false;
            //         });
        }
    }


    @action.bound
    remove(model: BaseModel) {
        this.cpDialects = this.cpDialects.filter(s => s !== model && s.id !== model.id);
    }

}

export class CpStore extends BaseStore {
    /**
     * Data Model Class
     * @override
     */
    DataModel: BaseModel = Model;


    loader: Function<Promise> = service.loadCps;
}

const store = new CpStore();
window.admincp = store;

export default store;
