import React from "react";
import ProfileImage from "@components/ProfileImage";
import styled from "styled-components";
import {Icon} from "rsuite";
import {observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import FlexBox from "@components/FlexBox";

@observer
export default class ImageUploader extends React.Component {


    @computed
    get preview() {
        if (this.props.controller.file) {
            return URL.createObjectURL(this.props.controller.file);
        }

        return "";
    }

    @action
    onFileChange = (event) => {
        this.props.controller.file = event.target.files[0];
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {defaultImage} = this.props;
        return <FlexBox direction="column" margin={{right:40,mobile:{right:"0"}}}>
            <ImageContainer>
                <ProfileImage src={this.props.controller.file?this.preview:defaultImage}/>
                <UploadIcon for="input"><Icon style={{color: "white"}} icon="camera" size="2x"/></UploadIcon>
                <FileInput id="input" type="file" accept=".svg,.SVG,.png,.PNG" onChange={this.onFileChange}/>
            </ImageContainer>
            <FileName>{this.props.controller.file ? this.props.controller.file.name : ""}</FileName>
        </FlexBox>;
    }
}

const UploadIcon = styled.label`
    width:50px;
    height:50px;
    border-radius: 50px;
    background-color: #bbbbbb;
    position: absolute;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 200ms ease-in-out, width 200ms, height 200ms;
    &:hover{
      width:60px;
      height:60px;
    }
    cursor: pointer;
`;

const ImageContainer = styled.div`
    width:140px;
    height:140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover ${UploadIcon}{
      opacity: 1 !important;
    }
`;

const FileInput = styled.input`
    display: none;
`;


const FileName = styled.div`
    text-align: center;
    font-weight: bold;
`

