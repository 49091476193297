import {action, computed, observable} from "mobx";
import moment, {Moment} from "moment";
import {BaseModel} from "../domain/BaseStore";
import languageStore from "../domain/languageStore";
import Availabilities from "../domain/subStores/availabilitySubstore";
import api from "@config/api";
import {serverTimeFormat} from "@utils/date";
import React from "react";

export default class StudentModel extends BaseModel {

  endPoint = "students";

  @observable
  dialectId;

  @observable
  avatarFileName;

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get birthday() {
    return moment(this.dob).format("DD/MM/YYYY");
  }

  @computed
  get birthdayMoment(): Moment {
    return moment(this.dob);
  }

  @action.bound
  setBirthday(moment: Moment){
    this.dob = moment.format(serverTimeFormat);
  }


  @computed
  get dialect() {
    return languageStore.findDialect(this.dialectId);
  }

  @computed
  get language() {
    return languageStore.findByDialect(this.dialectId);
  }

  @observable
  hobbies: [string] = [];

  @observable
  availabilities: [Availabilities] = [];

  @computed
  get profilePicture() {
    if (this.avatarFileName) {
      return api.assetUrl({filename: this.avatarFileName, folder: "profile"});
    }
    return require("@assets/male-placeholder.jpg");
  }

  @computed
  get creditExpiresAt(){
    return moment(this.creditExpiry).format("DD/MM/YYYY");
  }


  @computed
  get age(){
    return moment().diff(moment(this.dob),'y');
  }

  static get tableSchema(){
    return[
      "profilePicture",
      "firstName",
      "lastName",
      "email",
      "isFirstTime",
      "age",
      "phoneNumber",
      "profession",
      "skypeId",
      "creditBalance",
      "creditExpiresAt"
    ]
  }

  serializeData(data, setLoadedOnDone = true) {
    super.serializeData(data, setLoadedOnDone);
    this.hobbies = data.hobbies;
    if (data.availabilities)
      this.availabilities = new Availabilities(data.availabilities);
  }
}
