import React from "react";
import {observer} from "mobx-react";
import Icon from "@oovui/react-feather-icons";
import FlexBox from "../FlexBox";
import {StyledStep} from "./styled";


type StepperProps = {
  length: number,
  current: number,
  color?: string
}


const Stepper = observer(({length, current, color}: StepperProps) => {
  const steppers = [];

  for (let i = 1; i <= length; i++) {
    steppers.push(<StyledStep color={color} active={i <= current}>{
      i <= current ? i === current ? i : <Icon color="white" type="check" size={20}/> : null
    }</StyledStep>);
  }
  return (
    <FlexBox>
      {steppers}
    </FlexBox>
  );
});

Stepper.defaultProps = {
  color: null
};


export default Stepper;