import React from "react";
import Icon from "@oovui/react-feather-icons";
import {StyledPlainButton} from "./styled";
import FlexBox from "../FlexBox";

type ButtonProps = {
  title?: string,
  children?: [React.ReactNode],
  onClick?: Function,
  light?: boolean,
  icon?:string,
  unClickable?:boolean
}

const PlainButton = (props: ButtonProps) => {
  const {title,children,color,icon} = props;
  return (
    <StyledPlainButton {...props}><FlexBox direction="row" align="center">{title || children}<Icon color={color} size={16} type={icon} style={{marginLeft:5}}/></FlexBox></StyledPlainButton>
  );
};

PlainButton.defaultProps = {
  title: null,
  children: null,
  onClick: undefined,
  light: false,
  icon:null,
  unClickable: false
};

export default PlainButton;
