import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import FormInput, {ObservedInput} from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import Input from "@containers/Admin/Input";
import ImageUploader from "@components/ImageUploader";
import {uploadFile} from "@config/s3";
import {serverTimeFormat} from "@utils/date";


@inject("adminStudentStore")
@observer
class Edit extends React.Component {

    @observable
    _entity;

    @observable
    fileController = {file: ""};

    componentDidMount(): void {
        const {adminStudentStore, match} = this.props;
        const id = ~~match.params.id;
        const student = adminStudentStore.data.find(c => c.id === id);

        if (student) {
            this._entity = cloneStore(student, true);
        }
    }

    @action
    _submit = e => {
        e.preventDefault();
        if (this.fileController.file.name) {
            this._entity.loading = true;
            uploadFile(this.fileController.file, "profile", this._entity.email+(new Date().getTime())).then(resp => {
                    this._entity.avatarFileName = resp.key.split("/")[1];
                    this._entity.serializeAndUpdate().then(() => this._goBack())
                }
            ).catch(error => {
                this._entity.loading = true;
                console.log(error);
            })
        } else
            this._entity.serializeAndUpdate().then(this._goBack);
    };

    _goBackPrevention = e => {
        e.preventDefault();
        this._goBack()
    };

    _goBack = () => {
        const {history} = this.props;
        history.goBack();
    };

    render() {
        window.test = this;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock as="form" onSubmit={this._submit}
                                    title={`Editing ${this._entity.original.firstName} ${this._entity.original.lastName}`}>
                        <FlexBox direction="row-responsive" loading={this._entity.loading}>
                            <FlexBox>
                                <ImageUploader controller={this.fileController}
                                               defaultImage={this._entity.original.profilePicture}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                                <Input type="text" fill label="First Name" validationRules="required|min:2"
                                       propName="firstName"
                                       store={this._entity}/>
                                <Input type="text" fill label="Last Name" validationRules="required|min:2"
                                       propName="lastName"
                                       store={this._entity}/>
                                <Input type="email" fill label="Email" validationRules="required|email" propName="email"
                                       store={this._entity}/>
                                <Input type="text" fill label="Skype Id" propName="skypeId" store={this._entity}/>
                                <Input type="text" fill label="Profession" propName="profession" store={this._entity}/>
                                <Input type="text" fill label="Phone Number" propName="phoneNumber"
                                       store={this._entity}/>
                                <FormInput label="Birthday" type="dateTimePicker" value={this._entity.birthdayMoment} onChange={this._entity.setBirthday}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1}>
                                <Input type="number" label="Credit Balance" propName="creditBalance"
                                       store={this._entity}/>
                                <FormInput label="Credit Expiry"  type="dateTimePicker" value={this._entity.creditExpiryMoment} onChange={v=>{this._entity.creditExpiry = v.format(serverTimeFormat)}}/>
                                <Input type="textarea" label="Bio" propName="bio" store={this._entity} rows={4}/>
                                <FormInput.RadioGroup margin={{top: "0", bottom: 20}} title="Gender">
                                    <Input type="radio" label="Male" value="male" propName="gender"
                                           store={this._entity}/>
                                    <Input type="radio" label="Female" value="female" propName="gender"
                                           store={this._entity}/>
                                </FormInput.RadioGroup>
                                <Input type="checkbox" label="First Time" propName="isFirstTime" store={this._entity}
                                       withMargin={false}/>
                                <Input type="checkbox" label="Active" propName="isActive" store={this._entity} withMargin/>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                                 justify={flexProps.spaceBetween}>
                            <Button text="Cancel" onClick={this._goBackPrevention} withArrowLeft/>
                            <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );
        return (
            <div>loading</div>
        );
    }

}


export default Edit;
