import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import sessionTypeStore, {SessionTypeModel} from "@stores/domain/sessionTypeStore";
import Input from "@containers/Admin/Input";

@inject("sessionTypeStore")
@observer
class AddCP extends React.Component {

    @observable
    loading = false;

    @observable
    _entity = cloneStore(new SessionTypeModel({}), true);


    _submit = e => {
        e.preventDefault();
        const {history} = this.props;
        this._entity.serializeAndAdd().then(resp => {
            history.goBack();
        });
    };

    _goBack = e => {
        const {history} = this.props;
        e.preventDefault();
        history.goBack();
    };

    render() {
        window.test = this._entity;
        return (
            <Screen.Column last basis="full">
                <DashboardBlock as="form" onSubmit={this._submit} title="Add a new Conversation Partner">
                    <FlexBox direction="row-responsive" loading={this.loading}>
                        <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                            <Input type="text" fill label="Name" validationRules="required|min:2" propName="name"
                                   store={this._entity}/>
                            <Input type="number" fill label="Session Cost" validationRules="required"
                                   propName="cost"
                                   store={this._entity}/>
                        </FlexBox>
                        <FlexBox direction="column" f={1}>
                            <Input type="number" fill label="CP Earning" validationRules="required"
                                   propName="earning"
                                   store={this._entity}/>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                             justify={flexProps.spaceBetween}>
                        <Button text="Cancel" type="button" onClick={this._goBack} withArrowLeft/>
                        <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                    </FlexBox>
                </DashboardBlock>
            </Screen.Column>
        );
    }

}

export default AddCP;
