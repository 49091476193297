import React from "react";
import {observer,inject} from "mobx-react";
import DashboardBlock from "../../components/DashboardBlock";


const BioBlock = inject("routerStore")(observer(({userStore, type,routerStore}) => (
  <DashboardBlock title={type === "cp" ? "About Me" : "Your Personal Goals"} action={type==="cp"?null:{
    icon: "edit", text: "Edit", onClick: () => {
      routerStore.push("/profile/edit")
    }
  }}>
    {userStore.bio}
  </DashboardBlock>
)));


export default BioBlock;