export default {
  primary: "#6396D8",
  secondary: "#8D1E27",
  blue: "#48B1E2",
  white: "#FFFFFF",
  whiteAccent: "#F8F8F8",
  whiteDarkAccent: "#F0F0F0",
  grey:"#D8D8D8",
  black: "#000000",
  disabled:"#E2E2E2",
  orange: "#E46530",
  navy: "#232C74",
  teal: "#4DA1A9",
  yellow: "#FFD25A",
  accent: "#FFF7F3",
  lightAccent: "#FBF9F7",
  darkAccent: "#F0F0F0",
  borderGrey: "#979797",
  textLight:"#9B9B9B",
  transparent: "transparent",
  whiteOpace: (opacity)=> `rgba(255,255,255,${opacity})`,
  gradient: ["#48B1E2","#8576BF","#9C5494","#9F325F","#6396D8"]
};
