import React from "react";
import {inject, observer} from "mobx-react";
import moment from "moment";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import FlexBox, {flexDirection} from "@components/FlexBox";
import colors from "@constants/colors";
import PlainButton from "@components/PlainButton";
import {SessionModel} from "@stores/domain/sessionStore";
import Icon from "@oovui/react-feather-icons";
import Loading from "react-loading";
import routerStore from "@stores/app/routerStore";
import {computed} from "mobx";
import Modal from "../../components/Modal";


const SessionBlock = inject("uiConfig")(observer(({uiConfig}) => {
  return (
    <DashboardBlock title="Upcoming sessions" action={uiConfig.renderForCp ? null : {
      text: "Book a session", icon: "plus", onClick: () => {
        routerStore.push("/sessions");
      }
    }}>
      <SessionFeeds uiConfig={uiConfig}/>
    </DashboardBlock>
  );
}));


const SessionFeeds = inject("sessionStore", "sessionCancelStore")(observer(({sessionStore, sessionCancelStore, uiConfig}) => {
  if (sessionStore.isEmpty)
    return <EmptyPlaceholder/>;
  return (<React.Fragment>
      {sessionStore.sectionedData.map(section => <>
        <DashboardHeader key={section.month}>{section.month}</DashboardHeader>
        {section.days.map(day => (
          <>
            <DashboardHeader subHeader key={`${section.month}${day.name}`}>{day.name}</DashboardHeader>
            {!uiConfig.renderForCp ?
              day.sessions.map(session => <SessionCard key={session.id} session={session}
                                                       cancelStore={sessionCancelStore}/>) :
              day.sessions.map(session => <CpSessionCard key={session.id} session={session}
                                                         cancelStore={sessionCancelStore}/>)
            }
          </>
        ))}
      </>)}
    </React.Fragment>
  );
}));


const EmptyPlaceholder = () => (
  <FlexBox full="horizontal" direction="row" height={300}>
    <Icon type="book" size={24}/>
    <FlexBox direction="column" margin={{left: 10}}>
      <DashboardBlock.Value>You have no upcoming sessions.</DashboardBlock.Value>
    </FlexBox>
  </FlexBox>
);


@observer
class SessionCard extends React.Component<{ session: SessionModel }> {


  componentDidMount(): void {
    this.startTimer();
  }

  componentDidUpdate(): void {
    this.startTimer();
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  get timeLeft(){
    const {session} = this.props;
    return moment(session.scheduledAt).fromNow()
  }

  startTimer(){
    const {session} = this.props;
    if (!session.canAccessSession)
      switch (session.leftTimeLeadUnit) {
        case "s":
          this.timer = setTimeout(() => {this.forceUpdate()}, 1000);
          break;
        case "m":
          this.timer = setTimeout(() => {this.forceUpdate()}, 1000 * 60);
          break;
        case "h":
          this.timer = setTimeout(() => {this.forceUpdate()}, 1000 * 60 * 60);
          break;
        case "d":
          this.timer = setTimeout(() => {this.forceUpdate()}, 1000 * 60 * 60 * 24);
          break;
        default:
          break;
      }
  }

  render() {
    const {session, cancelStore} = this.props;
    return (
      <FlexBox margin={{bottom: 20}} direction="column" full="horizontal">
        <FlexBox backgroundColor={session.color} pad={{horizontal: 20, vertical: 15}} direction="column">
          <DashboardHeader subHeader light>{`Conversation with ${session.cp.firstName}`}</DashboardHeader>
          <FlexBox direction="row" justify="space-between">
            <DashboardHeader subHeader light bold marginBottom={false}>
              {`${session.startTime} - ${session.endTime}`}
            </DashboardHeader>
            <FlexBox direction="row-responsive">
              <FlexBox direction={flexDirection.rowResponsive}>
                {session.canAccessSession ?
                  <PlainButton title="Skype Session" light onClick={() => {
                    window.open(session.studentUrl, "_blank");
                  }}/> : <PlainButton unClickable title={this.timeLeft} light/>}
                {session.canCancel ?
                  <PlainButton title="Cancel" light onClick={() => cancelStore.selectSession(session)}/> : null}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    );
  }
};

const CpSessionCard = observer(({session, cancelStore}: { session: SessionModel }) => {
  return (
    <FlexBox margin={{bottom: 20}} direction="column" full="horizontal">
      <FlexBox backgroundColor={session.color} pad={{horizontal: 20, vertical: 15}} direction="column">
        <DashboardHeader subHeader light>{`Conversation with ${session.student.firstName}`}</DashboardHeader>
        <div style={{position: "absolute", top: -10, right: 10}}>
          {!session.cpUrl ?
            <Loading type="cylon" color="white" height={10} width={40}/>
            : null}
        </div>
        <FlexBox direction="row" justify="space-between">
          <DashboardHeader subHeader light bold marginBottom={false}>
            {`${session.startTime} - ${session.endTime}`}
          </DashboardHeader>
          <FlexBox direction="row-responsive">
            <FlexBox direction={flexDirection.rowResponsive}>
              {session.cpUrl ?
                <PlainButton title="Skype Session" light onClick={() => {
                  window.open(session.cpUrl, "_blank");
                }}/> : null}
              <PlainButton title="Cancel" light onClick={() => cancelStore.selectSession(session)}/>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
});


export default SessionBlock;

export const UnobservedSessionBlock = () => (
  <DashboardBlock title="Upcoming sessions" action={{text: "Book a session", icon: "plus"}}>
    <DashboardHeader>{moment().format("MMMM YYYY")}</DashboardHeader>
    <UnobservedSessionCard cp="TestCp"/>
    <UnobservedSessionCard cp="TestCp"/>
  </DashboardBlock>
);

const UnobservedSessionCard = ({cp, time}) => (
  <FlexBox margin={{bottom: 20}} direction="column" full="horizontal">
    <DashboardHeader subHeader>{moment(time).format("dddd Mo")}</DashboardHeader>
    <FlexBox backgroundColor={colors.blue} pad={{horizontal: 20, vertical: 15}} direction="column">
      <DashboardHeader subHeader light>{`Conversation with ${cp}`}</DashboardHeader>
      <FlexBox direction="row" justify="space-between">
        <DashboardHeader subHeader light bold marginBottom={false}>
          {`${moment(time).format("hh:mm a")} - ${moment(time).add(1, "h").format("hh:mm a")}`}
        </DashboardHeader>
        <PlainButton title="Cancel" light/>
      </FlexBox>
    </FlexBox>
  </FlexBox>
);
