import React from "react";
import {inject, observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import Screen from "@components/Screen";
import Table from "@components/Table";
import {Button} from "rsuite";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import type {StudentModel} from "@stores/domain/cp/studentStore";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import type {LanguageDialect} from "@stores/domain/admin/language";
import {skills, structures} from "@constants/dialect";
import {ObservedInput} from "@components/Input";
import _ from "lodash";

/**
 * Admin Matching Screen for CP and Students
 */
@inject("adminStudentLanguageStore", "adminStudentStore", "adminCpStore", "routerStore")
@observer
class CpMatchingEntityScreen extends React.Component {

    @observable
    skills = [];
    @observable
    structure = [];
    @observable
    levels = [];

    @computed
    get store() {
        const {match, adminStudentLanguageStore} = this.props;
        const {id} = match.params;
        return adminStudentLanguageStore.find(~~id);
    }

    /**
     * Student that requested a match
     */
    @computed
    get student(): StudentModel {
        return this.store ? this.store.student : null;
    }

    /**
     * Dialect specified for the match
     */
    @computed
    get dialect(): LanguageDialect {
        return this.store ? this.store.dialect : null;
    }

    /**
     * Loading identifier
     */
    @computed
    get loading(): boolean {
        const {adminStudentLanguageStore, adminStudentStore, adminCpStore} = this.props;
        return adminStudentLanguageStore.loading || adminStudentStore.loading || adminCpStore.loading;
    }

    /**
     * @override
     */
    componentDidMount(): void {
        const {adminStudentLanguageStore, adminStudentStore, adminCpStore} = this.props;
        if (!adminStudentStore.loaded && !adminStudentStore.loading)
            adminStudentStore.load();
        if (!adminCpStore.loaded && !adminCpStore.loading)
            adminCpStore.load();
        if (!adminStudentLanguageStore.loaded && !adminStudentLanguageStore.loading)
            adminStudentLanguageStore.load();


        const {match} = this.props;
        const {id} = match.params;
        const studentLanguage = adminStudentLanguageStore.find(~~id);
        this.skills.push(studentLanguage.focusOn);
        studentLanguage.lookingFor === "Structured sessions"|| studentLanguage.lookingFor=== "Free-flowing conversation"?this.structure.push(studentLanguage.lookingFor):null;
        if(studentLanguage.lookingFor === "Something more structured"){
            this.structure.push("Structured sessions")
        }
        this.levels.push(languageLevelStore.data.find(l => l.name === studentLanguage.level).id);
    }

    @action.bound
    addOrRemove(array, item) {
        if (this[array].includes(item)) {
            this[array] = this[array].filter(l => l !== item);
        } else {
            this[array].push(item);
        }
    }

    @observable
    minSharedBlocks = 1;

    render(): React.ReactNode {
        const {routerStore} = this.props;
        return (
            <Screen full="horizontal">
                <Screen.Column last basis="full">
                    <DashboardBlock title=" " full="horizontal" direction="column" justify={flexProps.stretch}>
                        <StudentDetails student={this.student} dialect={this.dialect} studentLanguage={this.store}/>
                        <FlexBox direction={flexDirection.column}>
                            <DashboardHeader italic>Filters</DashboardHeader>
                            <FlexBox direction={flexDirection.rowResponsive}>
                                <FlexBox direction={flexDirection.column}>
                                    {languageLevelStore.data.map(level => <ObservedInput type="checkbox"
                                                                                         label={level.name}
                                                                                         checked={this.levels.includes(level.id)}
                                                                                         onClick={() => {
                                                                                             this.addOrRemove('levels', level.id)
                                                                                         }}
                                                                                         containerProps={checkboxStyle}/>)}
                                </FlexBox>
                                <FlexBox direction={flexDirection.column}>
                                    {_.keys(structures).map(key => <ObservedInput type="checkbox"
                                                                                  checked={this.structure.includes(structures[key])}
                                                                                  onClick={() => {
                                                                                      this.addOrRemove('structure', structures[key])
                                                                                  }} label={structures[key]}
                                                                                  containerProps={checkboxStyle}/>)}
                                </FlexBox>
                                <FlexBox direction={flexDirection.column}>
                                    {_.keys(skills).map(key => <ObservedInput type="checkbox"
                                                                              checked={this.skills.includes(skills[key])}
                                                                              onClick={() => {
                                                                                  this.addOrRemove('skills', skills[key])
                                                                              }} label={skills[key]}
                                                                              containerProps={checkboxStyle}/>)}
                                </FlexBox>
                              <FlexBox direction={flexDirection.column}>
                                    <ObservedInput label="# of Shared Availability" type="number" defaultValue={1} containerProps={{direction:"column",align:flexProps.stretch}} style={{width:"100%"}} onChange={(l) => {
                                      this.minSharedBlocks = (~~l.nativeEvent.target.value || 0)
                                    }}/>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                        <DataTable store={this.dialect} studentLanguage={this.store} loading={this.loading}
                                   router={routerStore} skills={this.skills} structure={this.structure}
                                   levels={this.levels} minBlocks={this.minSharedBlocks}/>
                    </DashboardBlock>
                </Screen.Column>
            </Screen>);
    }
}

const StudentDetails = observer(({student, dialect, studentLanguage}) => {
    return (
        <FlexBox direction="row-responsive" full="horizontal">
            <FlexBox direction="column" basis="1/2">
                <DashboardHeader italic>Student</DashboardHeader>
                <br/>
                <DashboardHeader>Name: <span
                    style={{fontWeight: "300"}}>{student ? student.name : "Loading"}</span></DashboardHeader>
                <DashboardHeader>DOB: <span
                    style={{fontWeight: "300"}}>{student ? `${student.birthday} (${student.age} years old)` : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Email: <span
                    style={{fontWeight: "300"}}>{student ? student.email : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Country: <span
                    style={{fontWeight: "300"}}>{student ? student.country : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Occupation: <span
                    style={{fontWeight: "300"}}>{student ? student.profession : "Loading"}</span></DashboardHeader>
            </FlexBox>
            <FlexBox direction="column" basis="1/2">
                <DashboardHeader italic>Language Dialect</DashboardHeader>
                <br/>
                <DashboardHeader>Name: <span
                    style={{fontWeight: "300"}}>{dialect ? `${dialect.name} (${dialect.parentStore ? dialect.parentStore.name : "Loading"})` : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Level: <span
                    style={{fontWeight: "300"}}>{studentLanguage ? `${studentLanguage.level}` : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Focus: <span
                    style={{fontWeight: "300"}}>{studentLanguage ? studentLanguage.focusOn : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Topics: <span
                    style={{fontWeight: "300"}}>{studentLanguage ? studentLanguage.whatToCover : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Looking For: <span
                    style={{fontWeight: "300"}}>{studentLanguage ? studentLanguage.lookingFor : "Loading"}</span></DashboardHeader>
                <DashboardHeader>Comments: <span
                    style={{fontWeight: "300"}}>{studentLanguage ? studentLanguage.additionalRequests : "Loading"}</span></DashboardHeader>
            </FlexBox>
        </FlexBox>
    );
});

/**
 * Data table that represents the matches
 */
@observer
class DataTable extends React.Component{

    @observable
    sortParam = "";

    @observable
    sortType = "asc";

    @action
    sortBy = (param: string, type: string = "asc") => {
        this.sortParam = param;
        this.sortType = type;
    };

    @computed
    get filteredCps() {
        const {store, router, minBlocks,  studentLanguage, levels = [], skills = [], structure = []} = this.props;

        if (store) {
            store.cps.forEach((c, index) => {
                store.cps[index].otherDialects = c.dialects.filter(d => d.id !== store.id);
                store.cps[index].dialectLevel = c.currentDialectLevel(store.id);
            });
        }
        const availabilities = studentLanguage.student.availabilities.comparableAvailabilities;

        const filteredCPs = store.cps.filter(cp => {
            const cpDialect = cp.cpDialects.find(c => c.dialectId === store.id);
            const dialectLevels = cpDialect.levels.map(d => d.id);
            const common = cp.availabilities.comparableAvailabilities.filter(a => availabilities.find(block => block.day === a.day && block.date === a.date));
            const sharedBlockNumber = common.length;
            cp.structure = cpDialect.structure;
            return (skills.length === 0 || isIn(cpDialect.skills, skills)) &&
                (structure.length === 0 || isIn(cpDialect.structure, structure)) &&
                (levels.length === 0 || isIn(dialectLevels, levels)) && (sharedBlockNumber >= minBlocks)
        });

        if (this.sortParam) {
            const sortedData = _.sortBy(filteredCPs, d => d[this.sortParam]);
            return this.sortType === "desc" ?
                sortedData.reverse() : sortedData;
        }
        return filteredCPs
    }

    render() {
        const {studentLanguage,store,loading} = this.props;
        const availabilities = studentLanguage.student.availabilities.comparableAvailabilities;
        return (
            <div style={{width: "100%"}}>
                <Table rowHeight={50}
                       height={800}
                       data={store ? this.filteredCps : []}
                       loading={loading}
                       sortType={this.sortType}
                       sortColumn={this.sortParam}
                       onSortColumn={this.sortBy}
                >
                    <Table.Column resizable sortable width={120}>
                        <Table.HeaderCell>CP Name</Table.HeaderCell>
                        <Table.Cell dataKey="name"/>
                    </Table.Column>
                    <Table.Column resizable sortable width={50}>
                        <Table.HeaderCell>Age</Table.HeaderCell>
                        <Table.Cell dataKey="age"/>
                    </Table.Column>
                    <Table.Column resizable sortable width={120}>
                        <Table.HeaderCell>Gender</Table.HeaderCell>
                        <Table.Cell dataKey="gender"/>
                    </Table.Column>
                    <Table.Column resizable sortable width={200}>
                        <Table.HeaderCell>Session Type</Table.HeaderCell>
                        <Table.ArrayCell dataKey="structure"/>
                    </Table.Column>
                    <Table.Column resizable>
                        <Table.HeaderCell>Dialects</Table.HeaderCell>
                        <Table.AdvanceArrayCell dataKey="cpDialects" dataSubKey="dialect.name"/>
                    </Table.Column>
                    <Table.Column resizable>
                        <Table.HeaderCell># of Students</Table.HeaderCell>
                        <Table.Cell dataKey="studentsCount"/>
                    </Table.Column>
                    <Table.Column resizable>
                        <Table.HeaderCell># of Completed Sessions</Table.HeaderCell>
                        <Table.Cell dataKey="sessionsCount"/>
                    </Table.Column>
                    <Table.Column resizable>
                        <Table.HeaderCell># of Common Availabilities</Table.HeaderCell>
                        <Table.FunctionalCell dataKey="availabilities.comparableAvailabilities"
                                              data={(availability) => {
                                                  const common = availability.filter(a => availabilities.find(block => block.day === a.day && block.date === a.date));
                                                  return common.length;
                                              }}/>
                    </Table.Column>
                    <Table.Column resizable fixed="right">
                        <Table.HeaderCell>Assign</Table.HeaderCell>
                        <ActionCell dataKey="id" compareTo={studentLanguage.cpId}
                                    onClickFunction={studentLanguage ? studentLanguage.assignCp : () => {
                                    }}/>
                    </Table.Column>
                </Table>
            </div>
        );
    }
}

/**
 * Extension for the action cells
 * @type {(<T extends IReactComponent>(clazz: function({rowData: *, dataKey: *, onClickFunction: *, compareTo: *, [p: string]: *}): *) => void) | IReactComponent | (function({rowData: *, dataKey: *, onClickFunction: *, compareTo: *, [p: string]: *}): *)}
 */
const ActionCell = observer(({rowData, dataKey, onClickFunction, compareTo, ...props}) => {
    return (
        <Table.Cell {...props}>
            <Button appearance={rowData.id === compareTo ? "ghost" : "primary"} disabled={rowData.id === compareTo}
                    size="xs"
                    onClick={() => {
                        onClickFunction(rowData.id);
                    }}>Assign</Button>
        </Table.Cell>
    );
});


const checkboxStyle = {style: {margin: 0, marginRight: 20}};

const isIn = (arr1, arr2) => {
    let exist = false;
    arr1.forEach(r1 => {
        if (arr2.includes(r1))
            exist = true;
    });

    return exist;
};

export default CpMatchingEntityScreen;
