import {inject} from "mobx-react";
import React from "react";
import StoreDataScreen from "../../../containers/Admin/StoreDataScreen";
import {tableSchema} from "@schemas/Admin";

const dataScreen = (data) => (Component) =>{
  return ()=><Component {...data}/>
};

/**
 * Data screens with dynamically injected stores
 * @type {string & IWrappedComponent<any>}
 */
const ConversationPartners = dataScreen({schema:tableSchema.cp,noDelete:true,exportUrl:"export/cps"})(inject("adminCpStore")(StoreDataScreen));
const Students =  dataScreen({schema:tableSchema.student,noDelete:true,exportUrl:"export/students"})(inject("adminStudentStore")(StoreDataScreen));
const Languages = dataScreen({schema:tableSchema.languages,noDelete:true})(inject("languageStore")(StoreDataScreen));
const Sessions = dataScreen({schema:tableSchema.session,exportUrl:"export/sessions"})(inject("adminSessionStore")(StoreDataScreen));
const Bundles =dataScreen({schema:tableSchema.bundle,noDelete:true})(inject("adminBundleStore")(StoreDataScreen));
const SessionTypes = dataScreen({schema:tableSchema.session_type,noView:true})(inject("sessionTypeStore")(StoreDataScreen));
const Admins = dataScreen({schema: tableSchema.admin})(inject("adminAdminStore")(StoreDataScreen));

export default {
  ConversationPartners,
  Students,
  Languages,
  Sessions,
  Bundles,
  SessionTypes,
  Admins
}
