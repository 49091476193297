import React from "react";
import type {EdgeType} from "@utils/styles";
import {StyledContainer, StyledProgressBar, StyledTotalBar} from "./styled";


type ProgressBarProps = {
  total: number,
  progress: number,
  color?: string,
  margin?:  EdgeType,
}

const ProgressBar = ({total, progress, color,margin}: ProgressBarProps) => (
  <StyledContainer margin={margin}>
    <StyledTotalBar>
      <StyledProgressBar color={color} progress={(progress * 100) / total}/>
    </StyledTotalBar>
  </StyledContainer>
);

ProgressBar.defaultProps = {
  color: null,
  margin: null
};

export default ProgressBar;