import React from "react";
import Loader from "react-loading";
import {inject, observer} from "mobx-react";
import LanguageSelectStep from "../LanguageAdditorScreen/Steps/LanguageSelectStep";
import DialectSelectStep from "../LanguageAdditorScreen/Steps/DialectSelectStep";
import QuestionaireSelectStep from "../LanguageAdditorScreen/Steps/QuestionaireSelectStep";
import {flexDirection, flexProps} from "../../../components/FlexBox";
import {StyledHeader} from "../LanguageAdditorScreen/Steps/styled";
import colors from "../../../constants/colors";
import Screen from "../../../components/Screen";
import FlexBox from "../../../components/FlexBox";
import Stepper from "../../../components/Stepper";
import DashboardBlock from "../../../components/DashboardBlock";
import  AvailabilityStepSelector from "./Steps/AvailabilityStepSelector";


@inject("languageAdditorStore")
@observer
class FirstTimeWalkthroughScreen extends React.Component {
  steps = [
    <LanguageSelectStep/>,
    <DialectSelectStep/>,
    <QuestionaireSelectStep isWalkThrough/>,
    <AvailabilityStepSelector/>

  ];

  render(): React.ReactNode {
    const {languageAdditorStore} = this.props;
    const {currentStep, loading} = languageAdditorStore;
    return (
      <Screen>
        <FlexBox direction={flexDirection.row} justify={flexProps.center} f={1}>
          <Screen.Column last full="horizontal" solo>
            <StyledHeader>
            <span>Hi! Please fill out short questionnaire so we can match you with a conversation
            partner!</span></StyledHeader>
            <FlexBox direction={flexDirection.row} justify={flexProps.center} margin={{top: 40}}>
              <Stepper length={this.steps.length} current={currentStep}/>
            </FlexBox>
            <DashboardBlock title=" ">
              {loading ? <SubmitionStep/> : this.steps[currentStep - 1]}
            </DashboardBlock>
          </Screen.Column>
        </FlexBox>
      </Screen>
    );
  }
}

const SubmitionStep = () => (
  <FlexBox full="horizontal" align={flexProps.center}
           justify={flexProps.center} direction={flexDirection.column}
           pad={{horizontal: 80, mobile: {horizontal: "0"}}}>
    <DashboardBlock.Header>
      Please wait we are submitting your request.
    </DashboardBlock.Header>
    <FlexBox pad={{vertical: 100}}>
      <Loader color={colors.secondary} type="bars"/>
    </FlexBox>
  </FlexBox>
);


export default FirstTimeWalkthroughScreen;