import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock from "@components/DashboardBlock";
import FlexBox from "@components/FlexBox";
import ProfileImage from "@components/ProfileImage";
import Button from "@components/Button";
import {viewSchema} from "@schemas/Admin";
import ViewDetails from "@containers/Admin/ViewDetails";


@inject("adminStudentStore")
@observer
class ViewCp extends React.Component {

  @computed
  get _entity() {
    const {adminStudentStore, match} = this.props;
    const id = ~~match.params.id;
    const cp = adminStudentStore.data.find(c => c.id === id);

    return cp || null;
  }

  _onEdit = () => {
    const {history, match} = this.props;
    history.push(`${match.url}/edit`);
  };


  _onCpClick = () =>{
    const {history,match} = this.props;
    history.push(`${match.url}/cps`)
  };

  _onSessionsClick = () =>{
    const {history,match} = this.props;
    history.push(`${match.url}/sessions`)
  };

  _onLanguagesClick = () =>{
    const {history,match} = this.props;
    history.push(`${match.url}/languages`)
  };

  render() {
    window.test = this;
    if (this._entity)
      return (
        <Screen.Column last basis="full">
          <DashboardBlock title={this._entity.fullName} action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
            <FlexBox direction="row-responsive" full>
              <FlexBox direction="column" margin={{right: 20, mobile: {right: "0", bottom: 20}}}>
                <ProfileImage src={this._entity.profilePicture}/>
              </FlexBox>
              <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                <ViewDetails entity={this._entity} schema={viewSchema.student}/>
              </FlexBox>
              <FlexBox direction="column" childrenSpace={10}>
                <Button text="Cps" onClick={this._onCpClick}/>
                <Button text="Sessions" onClick={this._onSessionsClick}/>
                <Button text="Languages" onClick={this._onLanguagesClick}/>
              </FlexBox>
            </FlexBox>
          </DashboardBlock>
          <FlexBox direction="row-responsive">
          </FlexBox>
        </Screen.Column>
      );

    return (
      <div>loading</div>
    );
  }
}


export default ViewCp;
