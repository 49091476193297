import styled from "styled-components";
import FlexBox, {flexProps} from "../FlexBox";


/**
 * @component
 */
export const StyledContainer = styled(FlexBox).attrs({
  align: flexProps.center,
  justify: flexProps.center
})`
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    position: absolute;
    top:0;
    left:0;
    transition: 300ms all ease-in-out;
`;


/**
 * @component
 */
export const StyledInnerContainer = styled(FlexBox).attrs((props) => ({...props}))`
    background-color: white;
    border-radius: 2px;
    padding: 20px;
    min-width: 400px;
`;
