import styled,{css} from "styled-components";
import colors from "@constants/colors";
import FlexBox, {flexProps} from "../FlexBox";

const colorStyle = css`
    background-color: ${props=>props.color || colors.primary};
`;

const opacityStyle = css`
    opacity: ${props=>props.active?1:0.5};
`;

/**
 * @component
 */
export const StyledStep = styled(FlexBox).attrs({align:flexProps.center,justify:flexProps.center})`
    width:40px;
    height:40px;
    border-radius: 40px;
    color: white;
    font-size:16px;
    ${opacityStyle}
    ${colorStyle}
    
    :not(:last-of-type){
      margin-right:20px;
    }
`
