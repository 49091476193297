import {
  extendObservable,
  isAction,
  isComputed,
  isObservableArray, isObservableMap,
  isObservableObject,
  set as setObservable,
  toJS
} from "mobx";

export const cloneStore = (store, withValidator) => {

  const cStore = Object.assign(Object.create(Object.getPrototypeOf(store)), store);
  const decorators = {};
  Object.keys(cStore).forEach(key => {
    if (key !== "validationError")
      if (!isComputed(cStore[key]) && !isAction(cStore[key]) && !isObservableArray(cStore[key]) && !isObservableObject(cStore[key])) {
        decorators[key] = cStore[key];
      }
  });

  decorators.original = store;

  if (withValidator) {
    decorators.vErrors = {};
  }

  setObservable(cStore, decorators);

  try {
    extendObservable(cStore, {
      get hasVError() {
        return Object.keys(this.vErrors).length > 0;
      },
      submit: () => {
        Object.keys(cStore).forEach(key => {
          if (key !== "original" && key !== "submit" && key !== "serializeAndUpdate" && key !== "serializeAndAdd" && key !== "hasVError" && key !== "vErrors") {
            const source = cStore[key];
            const destination = store[key];
            if (isObservableArray(destination)) {
              destination.replace(source);
            } else if (isObservableMap(destination)) {
              destination.clear();
              destination.merge(source);
            } else if (isComputed(destination) || isAction(destination)) {
            } else {
              store[key] = source;
            }
          }
        });
      },
      serializeAndAdd(updateMap,parent,customData){
        return new Promise((resolve, reject) => {

          const ob = toJS(cStore);
          let data = {};

          const schema = store.updateSchema;

          Object.keys(ob).forEach(key => {
            if (schema.indexOf(key) > -1 ) {
              data[key] = ob[key];
            }
          });
          if(updateMap){
            Object.keys(updateMap).forEach(key => {
              data[key] = cStore[updateMap[key]];
            })
          }
          if(customData){
            data = {...data,...customData}
          }
          cStore.loading = true;
          store.adder(data)
            .then(resp => {
              cStore.loading = false;
              if (resp.ok) {
                this.id = resp.data.id;
                this.submit();
                if(parent)
                  parent.push(store);
                else
                store.parentStore.add(store);
                resolve(resp);
              }
            }).catch(e => {
            cStore.loading = false;
            reject(e)
          });


        });
      },
      serializeAndUpdate(updateMap,customData) {
        return new Promise((resolve, reject) => {

          const ob = toJS(cStore);
          let data = {};

          const schema = store.updateSchema;

          Object.keys(ob).forEach(key => {
            if (schema.indexOf(key) > -1) {
              if(cStore[key] !== store[key])
              data[key] = ob[key];
              else
                if(Array.isArray(cStore[key]))
                    data[key] = ob[key];
            }
            if(key === "hobbies"){
              data["hobbies"] = ob["hobbies"];
            }
          });
          if(updateMap){
            Object.keys(updateMap).forEach(key => {
              data[key] = cStore[updateMap[key]];
            })
          }
          if(customData){
            data = {...data,...customData}
          }
          console.log(data);
          cStore.loading = true;
          store.updatePromise(data)
            .then(resp => {
              cStore.loading = false;
              if (resp.ok) {
                this.submit();
                resolve(resp);
              }

              console.log("Promise Resolve:",resp);
            }).catch(e => {
            cStore.loading = false;
            reject(e);
            console.log("Promise Rejection:",e);
          });


        });
      }
    });
  } catch (e) {
    console.log(e);
  }

  return cStore;
};

export const serializeAndUpdate = (thisStore, store) => {

};
