import React from "react";
import FlexBox, {flexDirection} from "@components/FlexBox";
import {
  DayColumn,
  HeaderContainerStyle,
  HeaderStyle,
  LeftBlockStyle,
  TimeBlockSessionStyle,
  TimeBlockStyle
} from "@components/Calendar/styled";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import Icon from "@oovui/react-feather-icons";
import {computed} from "mobx";
import LazyInViewRederer from "@components/LazyInViewRenderer";

const SessionCalendar = inject("sessionCalendarStore")(observer(({sessionCalendarStore}) => {
  const {currentWeek} = sessionCalendarStore;
  return (
    <FlexBox preventSelection direction={flexDirection.column}>
      <HeaderContainerStyle>
        <DashboardBlock.Header>
          {sessionCalendarStore.currentDateForCalendar}
        </DashboardBlock.Header>
        <DashboardBlock.Header>
          Your Calendar
        </DashboardBlock.Header>
        <FlexBox>
          <IconButton color="black" type="chevron-left" size={24} onClick={sessionCalendarStore.prevWeek}/>
          <DashboardBlock.Header style={{lineHeight: 1.5}}>
            {`${currentWeek[0].date} ${currentWeek[0].month}-${currentWeek[currentWeek.length - 1].date} ${currentWeek[currentWeek.length - 1].month}`}
          </DashboardBlock.Header>
          <IconButton color="black" type="chevron-right" size={24} onClick={sessionCalendarStore.nextWeek}/>
        </FlexBox>
      </HeaderContainerStyle>
      <FlexBox direction="row" mobileHidden>
        <DayColumn static style={{width: "63px"}}>
          <LeftBlockStyle/>
        </DayColumn>
        {
          currentWeek.map(day =>
            <HeaderStyle direction="column">
              <span>{day.name}</span>
              <DashboardHeader>{day.date}</DashboardHeader>
            </HeaderStyle>)
        }
      </FlexBox>
      <FlexBox direction="row-responsive" height={500} overflow="scroll-y">
        <DayColumn static mobileHidden>
          <LeftBlockStyle/>
          {sessionCalendarStore.allHours.map((t, index) =>
            index === 0 ? null : <LeftBlockStyle>
              {t.viewFormat}
            </LeftBlockStyle>
          )}
        </DayColumn>
        {
          currentWeek.map(day => <AvailabilitiesColumn key={day.date} day={day}
                                                       hours={sessionCalendarStore.allHours}/>)
        }
      </FlexBox>
    </FlexBox>
  );
}));

const IconButton = styled(Icon)`
    cursor: pointer;
    background-color: transparent;
    border-radius: 100%;
    transition: 200ms all;
    margin: 0 10px;
    :hover{
        opacity: .6;
        background-color: lightgray;
        transition: 200ms all;
    }
`;


const AvailabilitiesColumn = observer(({day, hours}) => {
  return (
    <DayColumn noBorder key={day.date}>
      {hours.map(a => <LazyInViewRederer style={{height: 50, minHeight: 50}}>
        {isVisible => isVisible ?
          <TimeBlock key={day.date + a.format} date={day.fullDate} time={a.viewFormat}>test</TimeBlock> : null}
      </LazyInViewRederer>)}
    </DayColumn>
  );
});


@inject("sessionBookingStore", "sessionStore")
@observer
class TimeBlock extends React.Component {
  @computed
  get session() {
    const {date, time, sessionStore} = this.props;
    return sessionStore.data.find(s =>
      date === s.startDate && time === s.fullStartTime
    );
  }

  render() {
    return (
      <TimeBlockStyle bordered>
        {this.session ? <TimeBlockSessionStyle color={this.session.color}>
          <TimeBlockSessionStyle.Content active={this.isActive} direction="column" align="start">
            <strong>{this.session.cp.name}</strong>
            <FlexBox direction="row" justify="start" align="center" full>
              <Icon type="clock" size={10}
                    color={this.isActive ? "white" : "black"}/>
              <span>{`${this.session.startTime} - ${this.session.endTime}`}</span>
            </FlexBox>
          </TimeBlockSessionStyle.Content>
        </TimeBlockSessionStyle> : null}
      </TimeBlockStyle>
    );
  }
};


export default SessionCalendar;
