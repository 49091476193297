import React from "react";
import AvailabilityCalendar from "@containers/AvailabilityCalendar";
import {observer, inject} from "mobx-react";
import Screen from "../../../components/Screen";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import DashboardBlock, {DashboardHeader} from "../../../components/DashboardBlock";
import StepContinueButton from "../LanguageAdditorScreen/Steps/components/StepContinueButton";


@inject("availabilitySelectionStore", "routerStore", "userStore","authStore")
@observer
class AvailabilityScreen extends React.Component {
  onClick = () => {
    const {availabilitySelectionStore, routerStore, authStore, userStore} = this.props;
    availabilitySelectionStore.submitAvailabilities().catch(e => console.log(e));
    if(authStore.role === "student" && userStore.isFirstTime){
      routerStore.push("/purchase");
    }else{
      routerStore.push("/");
    }
  };

  render(): React.ReactNode {
    return <Screen>
      <FlexBox direction={flexDirection.row} justify={flexProps.center} f={1}>
        <Screen.Column last full="horizontal" solo>
          <DashboardBlock title="Tell us about your availability. Click & drag to select all that applies.">
            <FlexBox full="horizontal" align={flexProps.center}
                     justify={flexProps.center} direction={flexDirection.column}
            >
              <AvailabilityCalendar/>
              <FlexBox margin={{top:60}}>
              <DashboardHeader centerText subHeader>Don’t forget to set your evening and night availability too!</DashboardHeader>
              </FlexBox>
              <StepContinueButton submit disabled={false} onClick={this.onClick}
              />
            </FlexBox>
          </DashboardBlock>
        </Screen.Column>
      </FlexBox>
    </Screen>;
  }
}

export default AvailabilityScreen;
