import {computed, action, observable} from "mobx";
import {Form} from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import moment from "moment";
import api from "../../config/api";
import routerStore from "../app/routerStore";
import userStore from "./userStore";
import authStore from "./authStore";
import {selectorCountries} from "@constants/countries";
import {serverTimeFormat} from "../../utils/date";

const service = {
  checkEmail: (email): Promise => api.post("check_email", {email}),
  register: (data): Promise => api.post("register", data),
};

class RegistrationStore {

  @observable
  loading: boolean = false;

  @observable
  showSuccess: boolean = false;

  @observable
  error: ?string;

  @observable
  firstName: ?string;

  @observable
  lastName: ?string;

  @observable
  email: ?string;

  @observable
  password: ?string;

  @observable
  passwordConfirmation: ?string;

  @observable
  heardOfUs: ?string;

  @observable
  profession: ?string;

  @observable
  country: ?string;

  @observable
  dob: ?string;

  @observable
  whatsappNumber: ?string;

  @observable
  gender: ?string;

  @observable
  form = form;


  @computed
  get countriesNames() {
    return selectorCountries;
  }

  @action
  register = (d) => {
    this.loading = true;
    const {dob, ...args} = d;

    service.register({
      dob: dob ? dob.format(serverTimeFormat) : "",
      ...args
    })
      .then(resp => {
        this.loading = false;
        if (resp.ok) {
          const {data} = resp;
          authStore.id = data.id;
          authStore.type = data.type;
          authStore.setToken(data.token);
          authStore.role = "student";
          routerStore.replace("/");
          authStore.setCurrentUser(data);
          userStore.load();
        }
      });
  };
}


const store = new RegistrationStore();

class FormValidator extends Form {
  plugins() {
    return {
      dvr: dvr({
        package: validatorjs,
        extend: ({validator}) => {
          Object.keys(rules).forEach(key =>
            validator.register(key, rules[key].function, rules[key].message));
          Object.keys(asyncRules).forEach((key) =>
            validator.registerAsyncImplicit(key, asyncRules[key]));
        }
      }),
    };
  }

  setup() {
    return {
      fields: {
        firstName: {
          name: "firstName",
          label: "Name*",
          type: "text",
          placeholder: "First Name",
          rules: ["required", "string", "regex:/^[a-z ,.'-]+$/i"]
        }, lastName: {
          name: "lastName",
          type: "text",
          placeholder: "Last Name",
          rules: ["required", "string", "regex:/^[a-z ,.'-]+$/i"]
        }, email: {
          name: "email",
          label: "Email*",
          type: "email",
          placeholder: "Your Email Address",
          rules: ["required", "email", "min:5"]
        }, password: {
          name: "password",
          label: "Password*",
          type: "password",
          placeholder: "Password should be between 5-17 characters",
          rules: ["required", "string", "between:5,17", "passwords"]
        }, confirmPassword: {
          name: "confirmPassword",
          label: "Confirm Password*",
          type: "password",
          placeholder: "Same as password",
          rules: "required|string|same:password"
        }, heardAboutUsFrom: {
          name: "heardAboutUsFrom",
          type: "hidden",
          value: "",
          rules: ["required"]
        },
        profession: {
          name: "profession",
          label: "General field of work/study",
          type: "text",
          placeholder: "Your job",
          rules: ["string", "regex:/^[a-z ,.'-]+$/i"]
        },
        country: {
          name: "country",
          label: "Country of residence",
          type: "text",
          placeholder: "Where you live right now",
          rules: ["string", "regex:/^[a-z ,.'-]+$/i"]
        },
        dob: {
          name: "dob",
          label: "Date of birth",
          type: "date",
          placeholder: "DD/MM/YYYY",
        },
        phone: {
          name: "phone",
          label: "Whatsapp Number",
          type: "phone",
          placeholder: "Your Whatsapp Number",
          rules: ["string", "telephone"]
        },
        gender: {
          name: "gender",
          label: "Gender",
          type: "hidden",
          rules: ["string"]
        },
        terms: {
          name: "terms",
          label: "I agree to the",
          type: "checkbox",
          rules: ["required", "boolean", "terms"]
        }
      },
    };
  }

  hooks() {
    return {
      /*
        Success Validation Hook
      */
      onSuccess(form) {
        const values = form.values();
        store.register(values);
      },
      /*
        Error Validation Hook
      */
      onError(form) {
        alert("Form has errors!");
        // get all form errors
        console.log("All form errors", form.errors());
      }
    };
  }

  options() {
    return {
      validateOnChange: true
    };
  }
}

const asyncRules = {
  checkEmail: (value, attr, key, passes) => {
    const msg = `Hey! The email ${value} is already registered.`;
    service.checkEmail(value)
      .then(resp => resp.data.exist ? passes(false, msg) : passes).catch(e => console.log(e));
  }
};

export const checkEmail = (value, passCallback) => {
  const msg = `Hey! The email ${value} is already registered.`;
  service.checkEmail(value)
    .then(resp => resp.data.exist ? passCallback(false, msg) : passCallback(true)).catch(e => console.log(e));
};

const rules: { [string]: { function: Function, message: string } } = {
  telephone: {
    function: value => value.match(/^(\+\d{0,9})?\(?\d{3}\)?\d{3}$/),
    message: "The :attribute should start with '+' and contain 6-15 digits."
  },
  radioGroup: {
    function: value => value.toString().length > 0,
    message: "You need to choose one of the given options."
  },
  passwords: {
    function: value => value.match(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g),
    message: "Password should contain at least one capital letter, one small letter and one number."
  }, terms: {
    function: value => value === true,
    message: "You need to accept our terms in order to proceed."
  }
};


export const form = new FormValidator();

window.validStore = form;
// store.form = form;

export default store;
