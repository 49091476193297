import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import FlexBox from "@components/FlexBox";
import ProfileImage from "@components/ProfileImage";
import {tableSchema, viewSchema} from "@schemas/Admin";
import {SubDataTable} from "@containers/Admin/StoreDataTable";
import ViewDetails from "@containers/Admin/ViewDetails";

const dataScreen = (data) => (Component) => {
    return () => <Component {...data}/>
};

@inject("languageStore")
@observer
class ViewCp extends React.Component {

    @computed
    get _entity() {
        const {languageStore, match} = this.props;
        const id = ~~match.params.id;
        const entity = languageStore.data.find(c => c.id === id);

        return entity || null;
    }

    @computed
    get DialectsTable() {
        return dataScreen({
            schema: tableSchema.dialect,
            data: this._entity.dialects,
            baseRoute: "languages/dialects",
            noDelete: true
        })(SubDataTable);
    }

    _onEdit = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/edit`);
    };

    _onAdd=()=>{
        const {history,match} = this.props;
        history.push(`${match.url}/dialects/add`);
    }

    render() {
        window.test = this;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock title={this._entity.name}
                                    action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
                        <FlexBox direction="row-responsive" full>
                            <FlexBox direction="column" margin={{right: 20, mobile: {right: "0", bottom: 20}}}>
                                <ProfileImage src={this._entity.image}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                                <DashboardHeader># Dialects: <span
                                    style={{fontWeight: "300"}}>{this._entity.dialects.length}</span></DashboardHeader>
                                <ViewDetails schema={viewSchema.language} entity={this._entity}/>
                            </FlexBox>
                        </FlexBox>
                    </DashboardBlock>
                    <FlexBox direction="row-responsive">
                        <DashboardBlock title="Dialects" basis="full" action={{
                            icon: "plus", onClick: this._onAdd, text: "Add"
                        }}>
                            <this.DialectsTable/>
                        </DashboardBlock>
                    </FlexBox>
                </Screen.Column>
            );

        return (
            <div>loading</div>
        );
    }
}


export default ViewCp;
