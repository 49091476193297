export const viewActionType = {
  filter: "filter",
  link: "link",
  email: "email",
  array:"array",
  image: "image",
  phone: "phone",
  boolean: "boolean",
  currency: "currency"
};
