import React from "react";
import TopNavbar from "@components/TopNavbar";
import Button from "@components/Button";
import {observer} from "mobx-react";
import {observable} from "mobx";
import Icon from "@oovui/react-feather-icons";
import colors from "@constants/colors";
import FlexBox, {flexDirection, flexProps} from "../../components/FlexBox";
import DashboardBlock, {DashboardHeader} from "../../components/DashboardBlock";
import {ObservedInput} from "../../components/Input";
import api from "../../config/api";
import {toast} from "../../components/Toast";

@observer
class ForgotPassword extends React.Component {
  @observable
  _loading = false;

  @observable
  _showSuccess = false;

  @observable
  _email = "";

  @observable
  _error = null;


  _inputOnClick = (e) => {
    this._email = e.nativeEvent.target.value;
    if (!this._email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/igm)) {
      this._error = "This is not a valid email";
    } else {
      this._error = null;
    }
  };

  _onSubmit = (e) => {
    e.preventDefault();
    this._loading = true;
    api.post("reset_password",{email:this._email})
      .then(resp=>{
        this._loading = false;
        if(resp.ok){
          this._showSuccess = true;
        }else{
          toast.error(resp.data.error);
        }
      })
  };

  renderSuccess(){
    return(
      <FlexBox align={flexProps.center} direction={flexDirection.column} pad={{bottom:20}}>
        <Icon type="mail" size={56} color={colors.secondary} />
        <br/>
        <br/>
        <DashboardHeader centerText style={{maxWidth: 400}} marginBottom={40}>We sent you an email containing instructions on how to reset your password.</DashboardHeader>
        <Button withArrow onClick={()=>this.props.history.push("/")} text="Go To Login"/>
      </FlexBox>
    )
  }

  renderForm(){
    return(
      <FlexBox as="form" onSubmit={this._onSubmit} pad={{vertical: 40, horizontal: 20}}
               direction={flexDirection.column}>
        <DashboardHeader subHeader style={{maxWidth: 400}} marginBottom={40}>Please enter your email address to send
          you an email containing information on how to reset
          your password.</DashboardHeader>
        <ObservedInput error={this._error} type="email" fill placeholder="Your Email Address"
                       onChange={this._inputOnClick}/>
        <FlexBox align={flexProps.center} justify={flexProps.center} direction={flexDirection.column}
                 pad={{top: 28}}>
          <Button disabled={this._error || this._email.length < 1} text="Forgot Password"/>
        </FlexBox>
      </FlexBox>
    )
  }

  render(): React.ReactNode {
    return <FlexBox full="both" direction={flexDirection.column}>
      <TopNavbar/>
      <FlexBox
        direction={flexDirection.column}
        align={flexProps.center} justify={flexProps.center}
        full="both"
        pad={{mobile:{horizontal:10}}}
      >
        <DashboardBlock  margin={{top:-80}} loading={this._loading}  center title="Forgot Password" direction="column" align="center">
          {this._showSuccess?this.renderSuccess():this.renderForm()}
        </DashboardBlock>
      </FlexBox>
    </FlexBox>;
  }
}

export default ForgotPassword;