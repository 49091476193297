import api from "@config/api";
import {computed, observable} from "mobx";
import BaseStore from "./baseStore";
import StudentModel from "../../models/studentModel";
import moment from "moment";
import {serverTimeFormat} from "@utils/date";
import studentLanguageStore from "@stores/domain/admin/studentLanguage";
import cpStore from "@stores/domain/admin/cp";
import sessionStore from "@stores/domain/admin/sessions";

const service = {
    load: (): Promise => api.get("students"),
    pageLoad: (page) => (): Promise => api.get(`students?page=${page}`),
    update: id => data => api.put(`students/${id}`, data),
    add: data => api.post("register", {type: "student", ...data})
};

export class Model extends StudentModel {
    parentStore = store;

    updater = service.update(this.id);

    adder = service.add;


    @observable
    firstName = "";

    @observable
    lastName = "";

    @observable
    password = "";

    @observable
    email = "";

    @observable
    skypeId = "";

    @observable
    country = "";

    @observable
    profession = "";

    @observable
    phoneNumber = "";

    @observable
    phone = "";

    @observable
    dob = "";

    @observable
    creditBalance = 0;

    @observable
    creditExpiry = moment().format(serverTimeFormat);

    @observable
    bio = "";

    @observable
    gender = "";

    @observable
    heardAboutUsFrom = "";

    @observable
    isFirstTime = true;
    updateSchema: * = [
        "firstName",
        "lastName",
        "email",
        "password",
        "country",
        "dob",
        "skypeId",
        "profession",
        "gender",
        "phoneNumber",
        "birthDay",
        "isFirstTime",
        "creditBalance",
        "creditExpiry",
        "bio",
        "phone",
        "heardAboutUsFrom",
        "avatarFileName",
        "isActive"
    ];

    @computed
    get cps() {
        const studentLanguages = studentLanguageStore.data.filter(lang => lang.studentId === this.id && lang.cpId);
        return studentLanguages.map(l => cpStore.find(l.cpId));
    }

    @computed
    get sessions() {
        console.log(sessionStore);
        return sessionStore.data.filter(s => s.Student.id === this.id);
    }

    @computed
    get creditExpiryMoment(){
        return moment(this.creditExpiry);
    }

    @computed
    get studentLanguages(){
        return studentLanguageStore.data.filter(lang => lang.studentId === this.id && lang.cpId);
    }

    @computed
    get dialects(){
        return this.studentLanguages.map(l=>l.dialect);
    }

    @computed
    get profilePicture() {
        if (this.avatarFileName) {
            return api.assetUrl({filename: this.avatarFileName, folder: "profile"});
        }
        return require("@assets/male-placeholder.jpg");
    }


}

export class StudentStore extends BaseStore {
    DataModel = Model;

    loader = service.load;

    pageLoader = service.pageLoad;
}

const store = new StudentStore();
window.adminstudent = store;

export default store;
