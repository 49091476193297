import { observable, computed, action } from "mobx";
import moment from "moment";
import userStore from "@stores/domain/userStore";
import api from "@config/api";
import { AvailabilityBlock, AvailabilityDay } from "../domain/subStores/availabilitySubstore";

const service = {
  updateAvailabilities: (data) => api.post("user_availabilities/post_changes", data)
};

const weekDays = moment.weekdaysShort();

export class AvailabilitySelectionStore {

  @observable
  selectedHourRange = AvailabilitySelectionStore.HOURS_RANGE.day;

  static HOURS_RANGE = {
    day: "day",
    evening: "evening",
    night: "night"
  };

  @observable
  tempHolder = [];

  slotsAdded = [];

  slotsRemoved = [];

  @observable
  isMouseDown = false;

  @observable
  isAdd = false;


  @computed
  get availabilities() {
    const data = [];
    weekDays.forEach(
      day => {
        const entry = { name: day, data: [] };
        const mAvailability = userStore.availabilities.data.find(availability => availability.name === day);

        if (mAvailability) {
          mAvailability.data.forEach(
            timeSlot => {
              entry.data.push(timeSlot);
            }
          );
        }

        data.push(entry);
      }
    );
    return data;
  }

  @action.bound
  startSelecting() {
    this.isMouseDown = true;
  }

  @action.bound
  stopSelecting() {
    this.isMouseDown = false;
  }

  @action.bound
  startChoosing(added, params) {
    this.isAdd = !added;
    this.selectTimeSlot(params, true);
  };


  @action
  selectTimeSlot({ day, hour }, directTrigger) {
    if (this.isMouseDown || directTrigger) {

      const mAvailability = userStore.availabilities.data.find(availability => availability.name === day.name);

      // const timeNow = moment().format("YYYY-MM-DD");
      const startTime = moment(hour.time,"YYYY-MM-DD HH:mm:ss");
      const endTime = moment(hour.time,"YYYY-MM-DD HH:mm:ss").add(30, "m");
      const format = "1970-01-01THH:mm:ssZ";
      const startFormat = startTime.format(format);
      const endFormat = endTime.format(format);


      console.log(startFormat);
      const m = moment(hour.time,"YYYY-MM-DD HH:mm:ss");
      const today = moment();
      m.month(today.month());
      m.year(today.year());
      const m2 = moment(hour.time,"YYYY-MM-DD HH:mm:ss");
      m2.month(today.month());
      m2.year(today.year());

      const startUtc = m.utc().format(format);
      const endUtc = m2.add(30, "m").utc().format(format);

      if (this.isAdd) {
        if (mAvailability) {
          const lt = this.slotsRemoved.find(c => c.start === startUtc && c.userAvailability === mAvailability.id);
          const entry = new AvailabilityBlock({
            start: startFormat,
            end: endFormat,
            id: lt ? lt.id : startFormat
          }, mAvailability);
          mAvailability.data.push(entry);
          this.indexChange({ id: startFormat, start: startUtc, end: endUtc, userAvailability: mAvailability.id }, "add");

        } else {
          const availability = new AvailabilityDay({ day: day.name }, userStore);
          const entry = new AvailabilityBlock({
            start: startFormat,
            end: endFormat,
            id: startFormat
          }, availability);
          availability.data.push(entry);
          userStore.availabilities.data.push(availability);
        }
      } else if (mAvailability) {
        const foundEntry = mAvailability.data.find(d => d.startComparison === startTime.format("HH:mm"));

        if (foundEntry) {
          mAvailability.remove(foundEntry);
          this.indexChange({
            id: foundEntry.id,
            start: startUtc,
            end: endUtc,
            userAvailability: mAvailability.id
          }, "remove");

        }
      }
    }
  }

  @action
  indexChange(changed: Object, action: "add" | "remove") {
    if (action === "add") {
      const foundEntry = this.slotsRemoved.find(c => c.start === changed.start && c.userAvailability === changed.userAvailability);
      if (foundEntry) {
        this.slotsRemoved = this.slotsRemoved.filter(c => c.start !== changed.start || c.userAvailability !== changed.userAvailability);
      } else {
        this.slotsAdded.push(changed);
      }
    } else if (action === "remove") {
      const foundEntry = this.slotsAdded.find(c => c.start === changed.start && c.userAvailability === changed.userAvailability);
      if (foundEntry) {
        console.log("Found");
        this.slotsAdded = this.slotsAdded.filter(c => c.start !== changed.start || c.userAvailability !== changed.userAvailability);
      } else {
        this.slotsRemoved.push(changed);
      }
    }
  }

  static generateHours(start, end) {
    if (!end) {
      const data = [];
      for (let i = 2; i < 18; i++) {
        let hour = start + Math.floor(i / 2) - 2;
        if (hour >= 24) {
          hour -= 24;
        }
        const minute = i % 2 ? 30 : 0;
        const m = moment();
        const time = `${m.format("YYYY-MM-DD")} ${hour}:${minute}:00`;
        const momentDate = moment(time, 'YYYY-MM-DD HH:mm:ss');
        momentDate.month(m.month());
        momentDate.year(m.year());
        momentDate.day(m.day());
        data.push({ time, format: momentDate.format("HH:mm"), viewFormat: momentDate.format("hh:mm a") });
      }
      return data;
    }

    const data = [];
    for (let i = 2; i < end; i++) {
      let hour = start + Math.floor(i / 2) - 2;
      if (hour >= 24) {
        hour -= 24;
      }
      const minute = i % 2 ? 30 : 0;
      const m = moment();
      const time = `${m.format("YYYY-MM-DD")} ${hour}:${minute}:00`;
      const momentDate = moment(time,"YYYY-MM-DD HH:mm:ss");
      momentDate.month(m.month());
      momentDate.year(m.year());
      momentDate.day(m.day());
      data.push({ time, format: momentDate.format("HH:mm"), viewFormat: momentDate.format("hh:mm a") });
    }
    return data;

  }

  @computed
  get nightHours() {
    return AvailabilitySelectionStore.generateHours(1);
  }

  @computed
  get dayHours() {
    return AvailabilitySelectionStore.generateHours((24 / 3) + 1);
  }

  @computed
  get eveningHours() {
    return AvailabilitySelectionStore.generateHours(((24 / 3) * 2) + 1);
  }

  @computed
  get selectedHours() {
    return this[`${this.selectedHourRange}Hours`];
  }

  @action
  selectActiveHours = (range) => () => {
    this.selectedHourRange = range;
    console.log(this.slotAdded)
  };

  @action.bound
  submitAvailabilities() {
    return new Promise((resolve, reject) => {
      const added = this.slotsAdded.filter( (ele, ind) => ind === this.slotsAdded.findIndex( elem => elem.userAvailability === ele.userAvailability && elem.start === ele.start))
      console.log(added)
      const removed = this.slotsRemoved.map(c => c.id);
      const data = { added, removed };
      service.updateAvailabilities(data).then(r => {
        this.slotsAdded = [];
        this.slotsRemoved = [];
        if (r.ok) {
          resolve(r);
        } else {
          reject(r);
        }
      })
        .catch(e => {
          reject(e);
        });
    });
  }

}

const store = new AvailabilitySelectionStore();
window.availabilitySelectionStore = store;

export default store;
