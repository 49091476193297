import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import {ObservedInput} from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import {AdminModel as Model} from "@stores/domain/admin/admin";
import Input from "@containers/Admin/Input";
import sessionTypeStore from "@stores/domain/sessionTypeStore";

@inject("adminAdminStore")
@observer
class Add extends React.Component {

    @observable
    loading = false;

    @observable
    _entity = cloneStore(new Model({}), true);

    componentDidMount(): void {
    }


    _submit = e => {
        e.preventDefault();
        const {history} = this.props;
        this._entity.serializeAndAdd({password:"basicPassword",type:"defaultType"}).then(resp => {
            history.push(`${resp.data.id}`);
        });
    };

    _goBack = e => {
        const {history} = this.props;
        e.preventDefault();
        history.goBack();
    };

    render() {
        window.test = this._entity;
        return (
            <Screen.Column last basis="full">
                <DashboardBlock as="form" onSubmit={this._submit} title="Add a new Conversation Partner">
                    <FlexBox direction="row-responsive" loading={this.loading}>
                        <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                            <FlexBox direction="row-responsive" loading={this._entity.loading}>
                                <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                                    <Input type="text" fill label="First Name" validationRules="required|min:2" propName="firstName"
                                           store={this._entity}/>
                                    <Input type="text" fill label="Last Name" validationRules="required"
                                           propName="lastName"
                                           store={this._entity}/>
                                    <Input type="text" fill label="Email Address" validationRules="required|email"
                                           propName="email"
                                           store={this._entity}/>
                                    <Input type="checkbox" fill label="Active"
                                           propName="isActive"
                                           store={this._entity}/>
                                </FlexBox>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                             justify={flexProps.spaceBetween}>
                        <Button text="Cancel" type="button" onClick={this._goBack} withArrowLeft/>
                        <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                    </FlexBox>
                </DashboardBlock>
            </Screen.Column>
        );
    }

}

export default Add;
