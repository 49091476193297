import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import FormInput, {ObservedInput} from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import Input from "@containers/Admin/Input";
import {Model} from "@stores/domain/admin/bundle";


@inject("adminAdminStore")
@observer
class Edit extends React.Component {
    @observable
    _entity = null;

    componentDidMount(): void {
        const {adminAdminStore, match} = this.props;
        const id = ~~match.params.id;
        const entity = adminAdminStore.data.find(c => c.id === id);

        if (entity) {
            this._entity = cloneStore(entity, true);
        }
    }

    @action
    _submit = e => {
        e.preventDefault();
        console.log(this._entity);
        this._entity.serializeAndUpdate().then(this._goBack);
    };

    _goBackPrevention = e => {
        e.preventDefault();
        this._goBack()
    };

    _goBack = () => {
        const {history} = this.props;
        history.goBack();
    };

    render() {
        window.test = this;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock as="form" onSubmit={this._submit}
                                    title={`Editing "${this._entity.original.firstName} ${this._entity.original.lastName}"`}>
                        <FlexBox direction="row-responsive" loading={this._entity.loading}>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                                <Input type="text" fill label="First Name" validationRules="required|min:2" propName="firstName"
                                       store={this._entity}/>
                                <Input type="text" fill label="Last Name" validationRules="required"
                                       propName="lastName"
                                       store={this._entity}/>
                                <Input type="text" fill label="Email Address" validationRules="required|email"
                                       propName="email"
                                       store={this._entity}/>
                                       <Input type="checkbox" fill label="Active"
                                       propName="isActive"
                                       store={this._entity}/>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                                 justify={flexProps.spaceBetween}>
                            <Button text="Cancel" onClick={this._goBackPrevention} withArrowLeft/>
                            <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );
        return (
            <div>loading</div>
        );
    }

}


export default Edit;
