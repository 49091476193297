import React from "react";
import TopNavbar from "@components/TopNavbar";
import {observable} from "mobx";
import {observer} from "mobx-react";
import Icon from "@oovui/react-feather-icons";
import {flexDirection, flexProps} from "../../components/FlexBox";
import FlexBox from "../../components/FlexBox";
import DashboardBlock, {DashboardHeader} from "../../components/DashboardBlock";
import {ObservedInput} from "../../components/Input";
import Button from "../../components/Button";
import api from "../../config/api";
import colors from "../../constants/colors";

@observer
class ResetPassword extends React.Component {

  @observable
  _showSuccess = false;

  @observable
  _isValid = false;

  @observable
  _validityError = null;

  @observable
  _loading = true;


  @observable
  _error = null;

  @observable
  _verifyError = null;

  @observable
  _password = "";

  @observable
  _verifyPassword = "";

  componentDidMount(): void {
    this._validateToken();
  }

  _passwordOnChange = e => {
    this._password = e.nativeEvent.target.value;
    if (this._password.match(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g)) {
      this._error = null;
    } else {
      this._error = "Password should contain at least one capital letter, one small letter and one number.";
    }
  };

  _verifyPasswordOnChange = e => {
    this._verifyPassword = e.nativeEvent.target.value;
    if (this._password === this._verifyPassword) {
      this._verifyError = null;
    } else {
      this._verifyError = "The Confirm Password* and Password* fields must match.";
    }
  };

  _onSubmit = e => {
    e.preventDefault();
    const {token} = this.props.match.params;
    this._loading = true;
    api.post("/reset_password/new_password",{token,password:this._password})
      .then(resp=>{
        if(resp.ok){
          this._showSuccess = true;
        }else{
          this._validityError = resp.data.error;
        }
        this._loading = false;
      })
  };

  _validateToken = ()=>{
    const {token} = this.props.match.params;
    api.post("validate_token",{token}).then(
      resp=>{
        if(resp.ok){
          this._isValid = true;
        }else{
          this._isValid = false;
          this._validityError = resp.data.error;
        }
        this._loading = false;
      }
    )
  };


  renderSuccess(){
    return(
      <FlexBox align={flexProps.center} direction={flexDirection.column} pad={{bottom:20}}>
        <Icon type="check-circle" size={56} color={colors.secondary} />
        <br/>
        <br/>
        <DashboardHeader centerText style={{maxWidth: 400}} marginBottom={40}>
          Your password have been reset.
          Please login to continue.
        </DashboardHeader>
        <Button withArrow onClick={()=>this.props.history.push("/")} text="Go To Login"/>
      </FlexBox>
    )
  }


  renderForm(){
    return(
      <FlexBox as="form" onSubmit={this._onSubmit} pad={{vertical: 40, horizontal: 20}} style={{position:"relative"}}
               direction={flexDirection.column}>
        <DashboardHeader centerText style={{maxWidth: 400}} marginBottom={40}>Please enter a new
          password</DashboardHeader>
        <ObservedInput error={this._error} type="password" fill placeholder="Your New Password"
                       onChange={this._passwordOnChange}/>
        <ObservedInput error={this._verifyError} type="password" fill placeholder="Confirm Your New Password"
                       onChange={this._verifyPasswordOnChange}/>
        <FlexBox align={flexProps.center} justify={flexProps.center} direction={flexDirection.column}
                 pad={{top: 28}}>
          <Button disabled={this._error || this._verifyError || this._password.length < 8 || this._verifyPassword.length < 1} text="Reset"/>
        </FlexBox>
      </FlexBox>
    )
  }

  render(): React.ReactNode {
    return (
      <FlexBox full="both" direction={flexDirection.column}>
        <TopNavbar/>
        <FlexBox
          direction={flexDirection.column}
          align={flexProps.center} justify={flexProps.center}
          full="both"
        >
          <DashboardBlock margin={{top: -80}} loading={this._loading} error={this._validityError} center title="Reset Password" direction="column"
                          align="center">
            {this._showSuccess?this.renderSuccess():this.renderForm()}
          </DashboardBlock>
        </FlexBox>
      </FlexBox>
    );
  }

}

export default ResetPassword;
