import React from "react";
import FlexBox from "../FlexBox";
import {LegendItem} from "./styled";
import {generateLegendData} from "../../utils/dataVisualization";

type LegendsType = {
  items:Array<{
    color:string,
    label:string
  }>
}

const Legends = ({items}:LegendsType) => {
  return(<FlexBox direction="column">
    {items.map((item) => <LegendItem key={item.id} color={item.color}>
      <i/>
      <span>{item.label}</span>
    </LegendItem>)}
  </FlexBox>)
};



export default Legends;