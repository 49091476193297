import cp from "./cp";
import student from "./student";
import session from "./session";
import bundle from "./bundle";
import languages from "./languages";
import dialect from "./dialect";
import session_type from "./sessionType";
import cp_dialects from "@schemas/Admin/tableSchemas/cp_dialects";
import admin from './admin';

export default {
  cp,
  student,
  session,
  bundle,
  languages,
  cp_dialects,
  dialect,
  session_type,
  admin
};
