import React from "react";
import DashboardBlock from "@components/DashboardBlock";
import {observer, inject} from "mobx-react";
import {AccountInformationRow} from "./styled";

const AccountInformationBlock = inject("routerStore")(observer(({userStore, routerStore, type}) => (
  <DashboardBlock pad={0} title="Account Information" action={type === "cp" ? null : {
    text: "Edit", icon: "edit", onClick: () => {
      routerStore.push("/profile/edit");
    }
  }}>
    <AccountInformationRow>
      <AccountInformationRow.Header>
        Name
      </AccountInformationRow.Header>
      <AccountInformationRow.Value>
        {userStore.fullName}
      </AccountInformationRow.Value>
    </AccountInformationRow>
    <AccountInformationRow>
      <AccountInformationRow.Header>
        Birthday
      </AccountInformationRow.Header>
      <AccountInformationRow.Value>
        {userStore.birthday}
      </AccountInformationRow.Value>
    </AccountInformationRow>
    <AccountInformationRow>
      <AccountInformationRow.Header>
        Field of work/studies
      </AccountInformationRow.Header>
      <AccountInformationRow.Value>
        {userStore.profession}
      </AccountInformationRow.Value>
    </AccountInformationRow>
    <AccountInformationRow>
      <AccountInformationRow.Header>
        skype user name
      </AccountInformationRow.Header>
      <AccountInformationRow.Value>
        {userStore.skypeId}
      </AccountInformationRow.Value>
    </AccountInformationRow>
    <AccountInformationRow>
      <AccountInformationRow.Header>
        Email Address
      </AccountInformationRow.Header>
      <AccountInformationRow.Value>
        {userStore.email}
      </AccountInformationRow.Value>
    </AccountInformationRow>
    {!type ?
      <AccountInformationRow>
        <AccountInformationRow.Header>
          Password
        </AccountInformationRow.Header>
        <AccountInformationRow.Value>
          ************
        </AccountInformationRow.Value>
      </AccountInformationRow> : null}
  </DashboardBlock>
)));

export default AccountInformationBlock;