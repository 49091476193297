import React from "react";
import FlexBox, {flexDirection} from "../FlexBox";


const Screen = ({children, header,full}) => (
  <FlexBox full={full || "horizontal"} overflow="scroll-y" direction={flexDirection.column}>{header}
    <FlexBox full={full || "horizontal"} pad={{vertical: 25, horizontal: 30, mobile: {horizontal: 10}}}
             direction={flexDirection.rowResponsive}>
      {children}
    </FlexBox>
  </FlexBox>
);

Screen.Column = ({last,solo,...props}) => {
  return (<FlexBox maxWidth={solo?970:false} direction={flexDirection.column} margin={{right: last ? 0 : 20, mobile: {right: "0"}}} {...props}/>
  );
};

export default Screen;