import React from "react";
import {StyledContainer, StyledDescription, StyledHeader, StyledPrice, StyledText} from "./styled";
import FlexBox from "../FlexBox";
import ReactTooltip from 'react-tooltip';


const PurchaseBlock = ({name, price, quantity, duration, isSelected, onSelect, description}) => (
    <>
        <StyledContainer onClick={onSelect} isSelected={isSelected} data-tip data-for={name}>
            <StyledHeader>{name}</StyledHeader>
            <FlexBox f={1} direction="column" align="center" height={60} pad={12}>
                <StyledText>{`${quantity} Credits`}</StyledText>
                <StyledDescription>{`(${duration} days validity)`}</StyledDescription>
            </FlexBox>
            <StyledPrice bold>{`${price} USD`}</StyledPrice>
        </StyledContainer>
        <ReactTooltip id={name}>
            <span>{description}</span>
        </ReactTooltip>
    </>
);


export default PurchaseBlock;
