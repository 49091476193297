import {Icon as RIcon, IconButton, Table, Checkbox, Button, Tag} from "rsuite";
import React from "react";
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import ProfileImage from "../ProfileImage";
import _ from "lodash";
import {_getAdministration} from "mobx";
import FlexBox, {flexDirection} from "@components/FlexBox";


Table.ImageCell = ({rowData, dataKey, round, ...props}) => {
  return (<Table.Cell {...props} style={{padding: 0, height: 60}}>
    <ProfileImage src={rowData[dataKey]} size="small" round={round}/>
  </Table.Cell>);
};


Table.ActionCell = withRouter(({rowData, dataKey, location, history,baseRoute,onEdit,onDelete,noDelete,noView,noEdit, ...props}) => {
  function handleEdit() {
      if(onEdit){
          onEdit(rowData[dataKey])();
      }else {
          const {pathname} = location;
          if (baseRoute)
              history.push(`/${baseRoute}/${rowData[dataKey]}/edit`);
          else
              history.push(`${pathname}/${rowData[dataKey]}/edit`);
      }
  }

  function handleView() {
    const {pathname} = location;
    if(baseRoute)
    history.push(`/${baseRoute}/${rowData[dataKey]}`);
    else
    history.push(`${pathname}/${rowData[dataKey]}`);
  }

  function handleDelete() {
      if(onDelete)
      {
          onDelete(rowData[dataKey])
      }else {
          rowData.delete()
      }
  }

  return (
    <Table.Cell {...props} style={{padding: 5}}>
        {noView?null:<IconButton
        appearance="subtle"
        onClick={handleView}
        icon={<RIcon icon="eye"/>}
      />}
        {noEdit ? null : <IconButton
            appearance="subtle"
            onClick={handleEdit}
            icon={<RIcon icon="edit2"/>}
        />
        }
      {noDelete?null:<IconButton
      appearance="subtle"
      onClick={handleDelete}
      icon={<RIcon icon="trash"/>}
    />}
    </Table.Cell>
  );
});

Table.BooleanCell = ({rowData, dataKey, ...props}) => {
  return (
    <Table.Cell {...props}>
      <Checkbox checked={rowData[dataKey]}/>
    </Table.Cell>
  );
};

Table.CustomCell = ({rowData, dataKey, data, ...props}) => {
  return (
    <Table.Cell {...props}>
      {data}
    </Table.Cell>
  );
};

Table.FunctionalCell = ({rowData, dataKey, data, ...props}) => {
    const selectedData = _.get(rowData,dataKey);
    const processedData = data(selectedData);
  return (
    <Table.Cell {...props}>
      {processedData}
    </Table.Cell>
  );
};

Table.ArrayCell = ({rowData, dataKey, data, dataSubKey, ...props}) => {
  const chosenData = data || rowData[dataKey];
  return chosenData ? (
    <Table.Cell {...props}>
      <FlexBox direction={flexDirection.row} wrap>
      {chosenData.map(d => <Tag key={`${chosenData[dataKey]}${d[dataSubKey]}`}>{d[dataSubKey]||d}</Tag>)}
      </FlexBox>
    </Table.Cell>
  ) : null;
};

Table.AdvanceArrayCell = ({rowData, dataKey, data, dataSubKey, ...props}) => {
  const chosenData = _.get(rowData,dataKey);
  return chosenData ? (
    <Table.Cell {...props}>
      <FlexBox direction={flexDirection.row} wrap>
      {chosenData.map(d => <Tag key={`${d.id}`}>{_.get(d,dataSubKey)}</Tag>)}
      </FlexBox>
    </Table.Cell>
  ) : null;
};


Table.OCell = observer(({rowData, dataKey, ...props}) => {
  const data = _.get(rowData,dataKey);
  return (
    <Table.Cell {...props}>
      <div>{data}</div>
    </Table.Cell>
  );
});

Table.NavCell = ({rowData, dataKey, baseRoute, subDataKey, subDataString, router, ...props}) => {
  if (rowData[dataKey])
    return (
      <Table.Cell {...props}>
        <Button size="xs" onClick={() => {
          if (router) router.push("/");
        }}>{rowData[dataKey][subDataString]}</Button>
      </Table.Cell>
    );
  return null;
};

Table.ButtonCell = withRouter(({rowData, dataKey, baseRoute,routeKey,visualKey,history, ...props}) => {
    return (
      <Table.Cell {...props}>
        <Button size="xs" onClick={() => {
          history.push(`${baseRoute}/${_.get(rowData,routeKey)}`)
        }}>
          {_.get(rowData,dataKey)}</Button>
      </Table.Cell>);
});



export default Table;
