import {viewActionType as types} from "@constants/admin";

export default {
  isActive:{
    title:"Active",
    type:types.boolean
  },
  "profilePicture": {
    title: "Profile Picture",
    type: types.image
  },
  "firstName": {
    title: "First Name",
  },
  "lastName": {
    title: "Last Name"
  },
  "email": {
    title: "Email",
    type: types.email
  },
  "age": {
    title: "Age"
  },
  isFirstTime: {
    title: "First Time",
    type: types.boolean
  },
  "phoneNumber": {
    title: "Phone Number",
    type: types.phone
  },
  "profession": {
    title: "Field of Work"
  },
  "gender": {
    title: "Gender"
  },
  "creditBalance": {
    title: "Balance"
  },
  "creditExpiresAt": {
    title: "Balance Expiry"
  }
};
