import React from "react";
import ReactDOM from "react-dom";
import "@config/mobx";
import "./index.css";
import {AppContainer} from "react-hot-loader";
import Devtools from "mobx-react-devtools";
import "@globals/extensions.js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import 'react-toastify/dist/ReactToastify.css';
import StripeService from "@config/stripe";


if (process.env.NODE_ENV === "production")
  Sentry.init({dsn: "https://c7c69de33ba740a59154eb36102ad5ab@sentry.io/1468901"});

StripeService.setUp();

const render = Component =>
  ReactDOM.render(
    <AppContainer>
      <Component/>
    </AppContainer>,
    document.getElementById("root")
  );

const Container = () => (
  <React.Fragment>
    <App/>
    {/*<Devtools/>*/}
  </React.Fragment>
);

render(Container);

if (module.hot) {
  module.hot.accept("./App.js", () => {
    const NextRootContainer = require("./App").default;
    render(NextRootContainer);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
