import {observer} from "mobx-react";
import Validator from "validatorjs";
import {ObservedInput} from "@components/Input";
import {getValue} from "@utils/input";
import {serverTimeFormat} from "@utils/date";
import React from "react";

const Input = observer(({store, propName, validationRules, ...props}) => {
  let error = null;
  if (validationRules) {
    const data = {};
    data[props.label] = store[propName];
    const rules = {};
    rules[props.label] = validationRules;
    const validator = new Validator(data, rules);
    if (validator.fails()) {
      error = validator.errors.first(props.label);
      store.vErrors[propName] = error;
    } else {
      error = null;
      if (store.vErrors)
        delete store.vErrors[propName];
    }
  }

  return (
    <ObservedInput fill
                   checked={props.type !== "radio" ? store[propName] : (store[propName] === props.value)}
                   defaultValue={store[propName]}
                   onClick={props.type === "checkbox" ? () => {
                     store[propName] = !store[propName];
                   } : props.type === "radio" ? () => {
                     store[propName] = props.value;
                   } : null}
                   onChange={e => {
                     store[propName] = props.type === "number"?~~getValue(e): getValue(e);
                   }}
                   error={error}
                   {...props}
    />
  );
});

Input.DateInput = observer(({store, propName, ...props}) => {
  const moment = require("moment");
  const date = store[propName];
  const formattedDate = store[propName] ? moment(date).format("DD/MM/YYYY") : "";
  return (
    <Input {...props} store={store} propName={propName} type="date"
           value={formattedDate}
           placeholder="DD/MM/YYYY"
           onChange={e => {
             const d = moment(getValue(e),"DD/MM/YYYY");
             console.log(d);
             store[propName] = d.isValid() && d.year() < 2016 && d.year() > 1900  ? moment(getValue(e), "DD/MM/YYYY").format(serverTimeFormat) : store[propName];
           }}/>
  );
});

export default Input;
