import api from "@config/api";
import {BaseModel, BaseStore} from "@stores/domain/BaseStore";
import {action, computed, observable} from "mobx";
import studentLanguageStore from "./student/studentLanguageStore";
import adminCpStore from "./admin/cp";
import sessionTypeStore from "@stores/domain/sessionTypeStore";

const service = {
  getLanguage: (id): Promise => api.get(`languages/${id}`),
  getLanguages: (): Promise => api.get("languages"),
  postLanguage: (data): Promise => api.post("languages",data),
  deleteLanguage: (id): Promise => api.get(`languages/${id}`),
  updateLanguage: (id)=>(data): Promise => api.put(`languages/${id}`, data),
  getLanguageDialect: (id): Promise => api.get(`language_dialects/${id}`),
  getLanguageDialects: (): Promise => api.get("language_dialects"),
  deleteLanguageDialect: (id): Promise => api.get(`language_dialects/${id}`),
  postLanguageDialect: (data): Promise => api.post(`language_dialects`,data),
  updateLanguageDialect: (id)=>(data): Promise => api.put(`language_dialects/${id}`, data),
};

export class LanguageDialect extends BaseModel {

  loader = service.getLanguageDialect;
  adder = service.postLanguageDialect;

  updater = service.updateLanguageDialect(this.id);

  deleter = service.deleteLanguageDialect;


  endPoint = "language_dialects";

  @observable
  parentStore;

  @observable
  name;

  @observable
  origin;

  @observable
  isAvailable:boolean;

  @observable
  enabledTypes=[];

  @computed
  get enabledTypesObject(){
    return this.enabledTypes.map(f=>sessionTypeStore.find(f.id));
  }

  @computed
  get enabledTypesUris(){
    return this.enabledTypes.map(e=>`api/session_types/${e.id}`)
  }


  @action.bound
  addOrRemoveType(typeId) {
    const levelExist = this.enabledTypes.find(l => l.id === typeId);
    if (levelExist) {
      this.enabledTypes = this.enabledTypes.filter(l => l.id !== typeId);
    } else {
      this.enabledTypes.push({id:typeId});
    }
    console.log(this.enabledTypes);
  }

  constructor(props, parent) {
    super(props);
    this.parentStore = parent;
  }

  @computed
  get isAssociatedWithUser() {
    return !!studentLanguageStore.findByDialect(this.id);
  }

  @computed
  get cps() {
    if (!adminCpStore.loaded && !adminCpStore.loading)
      adminCpStore.load();
    return adminCpStore.data.filter(d => d.dialects.some(f => {
      return f.id === this.id;
    }));
  }

  @action
  delete = () => {
    service.deleteLanguageDialect(this.id)
        .then(resp=>{
          if(resp.ok){
            this.parentStore.remove(this);
          }
        })
  };

  serializeData(data, setLoadedOnDone = true) {
    super.serializeData(data, setLoadedOnDone);
    if(data.enabledTypes)
      this.enabledTypes = data.enabledTypes;

  }

  updateSchema: * = [
    "name",
    "origin",
    "isAvailable",
  ];
}

export class LanguageModel extends BaseModel {


  adder=service.postLanguage;

  loader = service.getLanguage;

  updater = service.updateLanguage(this.id);

  deleter = service.deleteLanguage;

  parentStore = store;

  endPoint = "languages";


  @observable
  name = null;

  @observable
  description = null;

  @observable
  icon = null;

  @observable
  code = null;

  @observable
  dialects = [];


  @computed
  get availableDialects() {
    const aDialects = this.dialects.filter(d => d.isAvailable);
    return aDialects.filter(d => !d.isAssociatedWithUser);
  }

  @computed
  get isAvailable() {
    return this.availableDialects.length > 0;
  }

  @computed
  get image() {
    if (this.icon) {
      return api.assetUrl({filename: this.icon, folder: "language"});
    }
    return require("@assets/svg/us.svg");
  }

  @action.bound
  remove(model: BaseModel) {
    this.dialects = this.dialects.filter(s => s !== model && s.id !== model.id);
    // delete this.data.find(d=>d === model || d.id === model.id);
  }

  static get tableSchema() {
    return [
      "image",
      "name",
      "description",
      "code",
        "icon"
    ];
  }

  constructor(props) {
    super(props);
    this.consume(props.dialects);
  }

  @action.bound
  consume(data) {
    data.map(d => this.dialects.push(new LanguageDialect(d, this)));
  }

  @action.bound
  add(store){
    this.dialects.push(store);
  }


  updateSchema: * = [
      "name",
      "icon",
      "description",
      "code"
  ];
}

export class LanguageStore extends BaseStore {

  loader = service.getLanguages;

  DataModel = LanguageModel;

  shouldClearOnLogout = false;


  @computed
  get availableLanguages() {
    return this.data.filter(d => d.isAvailable);
  }


  @computed
  get findByDialect(): ?LanguageModel {
    return (ob: BaseModel | number) => {
      const o = this.data.find(l => l.dialects.find(d => d.id === ob || d.id === ob.id));

      return o || null;
    };
  }

  @computed
  get findDialect(): ?LanguageDialect {
    return (ob: BaseModel | number) => {
      const lang = this.findByDialect(ob);
      if (!lang) {
        return null;
      }

      const o = lang.dialects.find(d => d.id === ob || d.id === ob.id);
      return o || null;
    };
  }
}

const store = new LanguageStore();
window.languageStore = store;

export default store;
