import api from "@config/api";
import {BaseModel, BaseStore} from "./BaseStore";

const service = {
  fetch: ()=> api.get("language_levels"),
};

class LanguageLevel extends BaseModel {
  parentStore = languageLevelStore;


  endPoint="language_levels";
};


class LanguageLevelStore extends BaseStore {
  DataModel = LanguageLevel;


  shouldClearOnLogout = false;

  loader = service.fetch;
}


const languageLevelStore = new LanguageLevelStore();
window.languageLevelStore = languageLevelStore;


export default languageLevelStore;
