import styled,{css} from "styled-components"
import colors from "../../constants/colors";


const colorsStyle = css`
    color:${props=>props.color || colors.black}
`;

const borderColorStyle = css`
    border-bottom: 1px solid ${props=>props.borderColor || colors.secondary}
`;

/**
 * @component
 */
export const StyledTitle = styled.h4`
    font-size: 20px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    line-height: 20px;
    padding: 0 0 9px 0;
    margin-top:40px;
    margin-bottom: 12px;
    ${colorsStyle}
    ${borderColorStyle}
`;
