import React from "react";
import {inject, observer} from "mobx-react";
import Screen from "@components/Screen";
import DashboardBlock from "@components/DashboardBlock";
import {flexProps} from "@components/FlexBox";
import StoreDataTable from "@containers/Admin/StoreDataTable";
import {computed, observable} from "mobx";
import {Switch, Route, withRouter} from "react-router-dom";
import modalRoutes from "@config/adminModalRoutes";
import Modal from "../../../components/Modal";
import {Button} from "rsuite";
import api from "@config/api";

@observer
class StoreDataScreen extends React.Component {

  @observable
  showModal = false;

  componentDidMount(): void {
    if(!this.store.loaded && !this.store.loading)
      this.store.load();
  }

  @computed
  get store(){
    const propKeys =  Object.keys(this.props);
    const filteredKeys = propKeys.filter(key=>key.toLowerCase().includes("store"));
    return this.props[filteredKeys[0]];
  }


  _switchModal = () =>{
    this.showModal = !this.showModal;
  };

  _goToAddition = () =>{
    const {match,history} = this.props;
    history.push(`${match.path}/add`)
  };

  _onExport = () =>{
    const {exportUrl} = this.props;

    window.open(`${process.env.REACT_APP_API_BASE_URL}/${exportUrl}`);
  };

  renderModal(){
    return(

      <Modal show={this.showModal}>
        <Switch>
          {modalRoutes.additionFormRoutes.map(route=>
          <Route exact {...route}/>
          )}
        </Switch>
      </Modal>
    )
  }

  render(): React.ReactNode {
    const {schema,noDelete,noView,exportUrl} = this.props;
    return (
      <React.Fragment>
        <Screen.Column last basis="full" ref={this.container}>
          <DashboardBlock title=" " direction="column" justify={flexProps.stretch} action={{text:"Add",icon:"plus",onClick: this._goToAddition}}>
            {exportUrl?<Button onClick={this._onExport} size="sm">Export Excel</Button>:null}
            <StoreDataTable store={this.store} schema={schema} noDelete={noDelete} noView={noView}/>
          </DashboardBlock>
        </Screen.Column>
        {this.renderModal()}
      </React.Fragment>
        );
  }
}

export default withRouter(StoreDataScreen);
