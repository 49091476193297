import React from "react";
import {CpModel} from "@stores/domain/student/cpStore";
import {computed} from "mobx";
import {inject, observer} from "mobx-react";
import ProfileImage from "@components/ProfileImage";
import Index from "../../../components/Screen";
import CpInformationBlock from "../../../containers/dashboardBlocks/CpInformationBlock";
import FlexBox, {flexProps} from "../../../components/FlexBox";
import UserInterestsBlock from "../../../containers/dashboardBlocks/UserInterestsBlock";
import BioBlock from "../../../containers/dashboardBlocks/BioBlock";
import TimeBlock from "../../../containers/dashboardBlocks/TimeBlock";

@inject("cpStore")
@observer
class StudentCpScreen extends React.Component {

  @computed
  get cp(): CpModel {
    const {match, cpStore} = this.props;
    const {id} = match.params;
    return cpStore.find(~~id);
  }


  render() {
    const {profilePicture} = this.cp;

    return (
      <Index>
        <Index.Column basis="1/3">
          <FlexBox align={flexProps.center} pad={{vertical: 26}} justify={flexProps.center}>
            <ProfileImage src={profilePicture} size="big" round/>
          </FlexBox>
          <TimeBlock timeZoneType={this.cp.timezoneName} timezone={this.cp.timezone} />
        </Index.Column>
        <Index.Column last basis="2/3">
          <CpInformationBlock cp={this.cp}/>
          <UserInterestsBlock userStore={this.cp} type="cp"/>
          <BioBlock userStore={this.cp} type="cp"/>
        </Index.Column>
      </Index>
    );
  }
}


export default StudentCpScreen;