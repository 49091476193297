import styled from "styled-components";
import colors from "@constants/colors";
import StyledFlex from "@components/FlexBox/styled";

export const StyledButton = styled(StyledFlex).attrs((props) => ({
    direction: "row",
    pad: {horizontal: props.block ? 20 : 15},
    align: "center",
    ...props
}))`
    color: ${({plain})=>plain?colors.secondary:colors.black };
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    padding: 16px;
    margin-bottom: 10px;
    background: ${({plain})=>plain?"rgba(158,45,30,0.2)":"rgba(0,0,0,0.2)"};
`;
