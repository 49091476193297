import {viewActionType as types} from "@constants/admin";


export default {
    "isActive":{
        title: "Active",
        type: types.boolean
    },
    "profilePicture": {
        title: "Profile Picture",
        type: types.image
    },
    "firstName":{
        title: "First Name",
    },
    "lastName":{
        title: "Last Name"
    },
    "email":{
        title: "Email",
        type: types.email
    },
    "age":{
        title: "Age"
    },
    "phoneNumber":{
        title: "Phone Number",
        type: types.phone
    },
    "gender":{
        title: "Gender"
    },
    "earnings":{
        title: "Earnings"
    },
    "profession":{
        title: "Field of Work"
    }
};
