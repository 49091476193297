export const structures = {
    structuredSession: "Structured sessions",
    flowSession: "Free-flowing conversation"
};

export const skills = {
    reading: "Reading",
    writing: "Writing",
    speaking: "Speaking",
    listening: "Listening"
};
