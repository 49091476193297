import styled,{css} from "styled-components";
import FlexBox,{flexDirection, flexProps} from "@components/FlexBox";
import {breakpointStyle} from "@utils/styles";
import colors from "@constants/colors";

const noFilterStyle = css`
    filter: none;
`;

export const StyledLanguageBlock = styled(FlexBox).attrs({
  direction: flexDirection.column,
  justify: flexProps.center
})`
    cursor: pointer;
    :not(:last-of-type){
      margin-right: 30px;
    }
    
    ${breakpointStyle({
  content: css`
      
      :not(:last-of-type){
      margin-right: 0;
      margin-bottom: 30px;
    }
`
})}
`;

StyledLanguageBlock.Image = styled.img`
    height:180px;
    width: 170px;
    background-color: ${colors.whiteDarkAccent};
    border:none;
    padding:35px;
    filter: grayscale(100%);
    ${props => props.active && noFilterStyle}
`;

StyledLanguageBlock.Name = styled.div`
    padding: 10px;
    text-align: center;
    font-size: 16px;
`;



export const StyledMapPlaceHolder = styled.div`
    width: 100%;
    height:280px;
    margin-right: 30px;
    border: 1px solid black;
`;


export const StyledHeader = styled(FlexBox).attrs({
  align: flexProps.center,
  justify: flexProps.center,
  direction: flexDirection.row
})`
    font-size: 28px;
    font-family: Lato, sans-serif;
    text-align: center;
    padding: 20px 0 0 0;
    color: ${colors.textLight};
    
    > span{
    
    max-width: 570px;
    }
`;

export const StyledParagraph = styled.p`
    text-align: center;
    margin: 20px 0;
`
