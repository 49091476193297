import React from "react";
import {Route, Switch} from "react-router-dom";
import Screen from "@components/Screen";
import DataScreens from "../DataScreens";
import AddScreen from "./Add";
import EditScreen from "./Edit";
import ViewScreen from "./View";
import ViewDialectScreen from "./Dialect/View";
import AddDialectScreen from "./Dialect/Add";
import EditDialectScreen from "./Dialect/Edit";


export default ({match}) => {
  const {path} = match;
  console.log(path);
  return (
    <Screen>
      <Switch>
        <Route path={path} exact component={DataScreens.Languages}/>
        <Route path={`${path}/add`} exact component={AddScreen}/>
        <Route path={`${path}/:id`} exact component={ViewScreen}/>
        <Route path={`${path}/:id/edit`} exact component={EditScreen}/>
        <Route path={`${path}/:langId/dialects/add`} exact component={AddDialectScreen}/>
        <Route path={`${path}/dialects/:id`} exact component={ViewDialectScreen}/>
        <Route path={`${path}/dialects/:id/edit`} exact component={EditDialectScreen}/>
      </Switch>
    </Screen>
  );
}
