import styled, {css} from "styled-components";
import Icon from "@oovui/react-feather-icons";
import colors from "../../constants/colors";
import {edgeStyle, parseStyleValue, reverseBreakpointStyle} from "../../utils/styles";


const fillStyle = css`
    width: 100%;
`;

const textAreaFill = (props) => {
  const styles = [];
  const {fill} = props;
  if (fill === true || fill === "horizontal" || fill === "both") {
    styles.push(css`
      width: 100%;`);
  }
  if (fill === true || fill === "vertical" || fill === "both") {
    styles.push(css`
      height: 100%;`);
  }
  return styles;
};

const marginStyle = css`
    margin-bottom: 20px;
`;

const errorStyle = css`
    border: 1px solid darkred !important;
`;


export const StyledHeader = styled.h3`
    font-family: Lato,sans-serif;
    font-size: 16px;
    margin-top: 0;
    color: #000;
    font-weight: normal;
    margin-bottom: 10px;
`;

export const StyledError = styled.span`
    color: darkred;
    font-weight: bold;
`;

/**
 * @component
 */
export const StyledIcon = styled(Icon)`
    position:absolute;
    opacity:0;
    top:9px;
    left:4px;
    height: 18px;
    width: 18px;
    transition: 400ms all;
    ${props => props.show && css`opacity: 1;`}
`;

const selectStyle = css`
    padding: 6px 6px 6px 24px;
    border-radius: 0;
`;


const errorShowStyle = css`
    display: flex;
    opacity: 1;
    bottom: 43px;
`;


/**
 * @component
 */
export const StyledErrorBlock = styled.div`
    position: absolute;
    opacity: 0;
    padding: 8px;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: white;
    background-color: darkred;
    transition: 200ms all;
    display: none;
    
    ::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 20%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: darkred transparent transparent transparent;
    }
`;

/**
 * @component
 */
export const BaseInput = styled.input`
    position:relative;
    background-color: ${colors.darkAccent};
    border: 2px solid transparent;
    color: #767983;
    font-size: 16px;
    font-family: Lato, sans-serif;
    height:36px;
    line-height: 20px;
    padding: 8px 8px 8px 24px;
    transition: 200ms all;
    ${props => props.fill && fillStyle}
    ${props => props.margin && edgeStyle("margin", props.margin, props.margin.mobile)}
    ${props => props.pad && edgeStyle("padding", props.pad, props.pad.mobile)}
    ${props => props.error && errorStyle}
    ${props => props.type === "select" && selectStyle}
  
  
    
    :focus,:hover{
      border: 1px solid ${colors.primary};
      outline:none;
      
      &~${StyledErrorBlock}{
          ${props => props.showError && errorShowStyle}
      }
    }
    
    
    ::placeholder{
    color: #BFBFBF;
    }
    
    > option{
      padding: 8px;
      line-height: 50px;
      :hover{
          background-color:red;
      }
      :active{
          background-color: red;
      }
    }
`;


/**
 * @component
 */
export const BaseTextArea = styled.textarea`
    background-color: ${colors.darkAccent};
    border: 2px solid transparent;
    color: #767983;
    font-size: 16px;
    font-family: Lato, sans-serif;
    line-height: 20px;
    padding: 8px 60px 8px 24px;
    transition: 200ms all;
    ${props => props.fill && textAreaFill(props)}
    
    :focus{
      border: 1px solid ${colors.primary};
      outline:none;
    }
    ::placeholder{
    color: #BFBFBF;
    }
`;

const labelWidthStyle = () => reverseBreakpointStyle({
  content: css`
    width: ${props => parseStyleValue(props.labelWidth)};
    min-width: ${props => parseStyleValue(props.labelWidth)};
`
});


/**
 * @component
 */
export const InputLabel = styled.div`
    color: ${({light}) => light ? colors.textLight : colors.black};	
    font-size: 16px;
    font-family: Lato, sans-serif;
    line-height: 26px;
    box-sizing: border-box;
    ${({type}) => ["text", "password", "email", "date", "phone", "select"].indexOf(type) > -1 ? reverseBreakpointStyle({
  content: css`
        
        width:120px;
        min-width: 120px;
      `
}) : null}
    ${({labelWidth}) => labelWidth && labelWidthStyle}
`;


/**
 * @component
 */
export const BaseBoxContainer = styled.div`
    cursor: pointer;
    display: block;
    font-size: 22px;
    position: relative;
    width:25px;
    height:25px;
    user-select: none;
    margin-right: 10px;
    
    & input{
      cursor: pointer;
      width:0;
      height:0;
      opacity: 0;
      position: absolute;
    }
    
    & .checkmark{
      height: 25px;
      left: 0;
      position: absolute;
      top: 0;
      width: 25px;
      border:1px solid ${colors.borderGrey};
      background: white;
    }
    
    :hover input ~ .checkmark{
      background-color: ${colors.white};
    }
    
    
    :hover .checkmark:after{
      opacity: .6;
    }
    
    
    & input:checked ~ .checkmark:after{
      opacity:1;
      transition: 200ms all;
    }
`;

/**
 * @component
 */
export const CheckboxContainer = styled(BaseBoxContainer)`
    
    
    & .checkmark:after {
      content: "";
      position: absolute;
      display: block;
      opacity: 0;
      left: 10px;
      bottom: 3.68px;
      width: 10px;
      height: 23px;
      border: solid ${colors.primary};
      border-width: 0 3px 3px 0;
      border-radius: 1px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(41deg);
      transition: 200ms opacity;
    }
    
`;

/**
 * @component
 */
export const RadioContainer = styled(BaseBoxContainer)`
    width:21px;
    height:21px;
    
    & .checkmark{
      width:21px;
      height:21px;
      padding: 2px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    
    & .checkmark:after{
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: ${colors.primary};
      opacity: 0;
      transition: 200ms opacity;
    }
`;
