import S3 from "aws-s3";
import {uploadFile as S3Upload} from "react-s3";

const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
const languagesDirName = "lang";
const region = process.env.REACT_APP_S3_REGION;
const accessKeyId = process.env.REACT_APP_S3_KEY_ID;
const secretAccessKey = process.env.REACT_APP_S3_KEY_SECRET;

export const uploadFile = (file, directory, fileName) => {
    const config = {
        bucketName,
        region,
        accessKeyId,
        secretAccessKey,
        dirName: directory,
        s3Url:"https://natakallam-assets.s3.amazonaws.com/"
    };
    const S3Client = new S3(config);
    if (fileName)
        return S3Client.uploadFile(file, fileName);
    return S3Upload(file,config);
};

