import styled from "styled-components";
import FlexBox from "../FlexBox";


/**
 * @component
 */
export const LegendItem = styled(FlexBox).attrs({
  direction: "row",
  margin: {bottom: 10},
})`
    > i {
      width:20px;
      height:20px;
      background-color:${props => props.color};
      margin-right:10px;
    }
    
    >span {
      font-size: 16px;
    }
    
`;
