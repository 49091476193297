import {BaseModel, BaseStore} from "@stores/domain/BaseStore";
import api from "@config/api";
import {computed, observable, toJS} from "mobx";
import _ from "lodash";

const service = {
    getType: (id): Promise => api.get(`session_types/${id}`),
    getTypes: (): Promise => api.get("session_types"),
    deleteType: (id): Promise => api.delete(`session_types/${id}`),
    updateType: (id)=>(data): Promise => api.put(`session_types/${id}`, data),
    addType: ( data): Promise => api.post(`session_types`, data),
};

class SessionTypeStore extends BaseStore {
    loader = service.getTypes;

    DataModel = SessionTypeModel;

    shouldClearOnLogout = false;

    @computed
    get enabledTypes(){
        let types = [];
        studentLanguageStore.allData.forEach(d =>toJS(d.plainDialect.enabledTypes)?types = [...types, ...toJS(d.plainDialect.enabledTypes)]:null);
            const merged = _.uniqBy(types, t => t.id);
        return merged.map(t => this.find(t.id)).filter(t => t);
    }

    @computed
    get selectMap(){
        return this.data.map(d=>({name:d.name,value:d.id}));
    }
}

export class SessionTypeModel extends BaseModel{
    updater = service.updateType(this.id);
    deleter = service.deleteType;
    loader = service.getType;
    adder = service.addType;

    parentStore=sessionTypeStore;

    @observable
    name;

    @observable
    cost;

    @observable
    earning;


    updateSchema: * =[
        "name",
        "cost",
        "earning"
    ]

}

const sessionTypeStore = new SessionTypeStore();
window.sessionTypes = sessionTypeStore;

export default sessionTypeStore;
