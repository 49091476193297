import React from "react";
import {observer} from "mobx-react";
import type {BaseStore} from "@stores/domain/BaseStore";
import Table from "@components/Table";
import {computed, observable} from "mobx";
import _ from "lodash";
import {viewActionType as types} from "@constants/admin";


/**
 * TODO: Make height dynamic
 */
@observer
class StoreDataTable extends React.Component {

    @observable
    shouldRender = false;
    @observable
    calculatedHeight = 740;
    @observable
    container;

    @computed
    get height() {
        return this.container ? this.container.offsetHeight : 740;
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.shouldRender = true;
        }, 100)
    }

    render() {
        const {store, schema,noEdit,noView, noDelete}: { store: BaseStore } = this.props;
        // const schemaKeys = Object.keys(tableSchema);

        const schemaKeys = schema ? _.keys(schema) : [];


        return this.shouldRender ? (
            <div ref={c => {
                this.container = c;
            }} style={{width: "100%", height: "100%"}}>
                <div style={{display: "none"}}>{store.processedData.map(d => d.id)}</div>
                <Table  rowHeight={50} height={680} sortType={store.sortType}
                       sortColumn={store.sortParam}
                       onSortColumn={store.sortBy}
                       data={store.processedData}
                       loading={store.loading}
                       virtualized
                >
                    {
                        schemaKeys.map(schemaKey => DataTableColumn({entry: schema[schemaKey], dataKey: schemaKey}))
                    }
                    <Table.Column width={noDelete || noEdit || noView ? 36 * 2.5 : 36 * 3.5} fixed="right">
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                        <Table.ActionCell dataKey="id" noDelete={noDelete} noView={noView} noEdit={noEdit}/>
                    </Table.Column>
                </Table>
            </div>
        ) : null;
    }
}

/**
 * TODO: Make height dynamic
 */
@observer
export class SubDataTable extends React.Component {

    @observable
    shouldRender = false;
    @observable
    calculatedHeight = 740;
    @observable
    container;

    @computed
    get height() {
        return this.container ? this.container.offsetHeight : 740;
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.shouldRender = true;
        }, 100)
    }

    render() {
        const {data, schema, baseRoute, onEdit,noEdit,noView,noDelete, ...props} = this.props;
        // const schemaKeys = Object.keys(tableSchema);

        const schemaKeys = schema ? _.keys(schema) : [];

        return this.shouldRender ? (
            <div ref={c => {
                this.container = c;
            }} style={{width: "100%", height: "100%"}}>
                <div style={{display: "none"}}>{data.map(d => d.id)}</div>
                <Table rowHeight={50} height={680}
                       data={data}
                       virtualized
                >
                    {
                        schemaKeys.map(schemaKey => DataTableColumn({entry: schema[schemaKey], dataKey: schemaKey}))
                    }
                    <Table.Column width={noDelete || noEdit || noView ? 36 * 2.5 : 36 * 3.5} fixed="right">
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                        <Table.ActionCell dataKey="id" baseRoute={baseRoute} onEdit={onEdit} noDelete={noDelete} noView={noView} noEdit={noEdit} {...props}/>
                    </Table.Column>
                </Table>
            </div>
        ) : null;
    }
}

const DataTableColumn = ({entry, dataKey}) => {
    const {type} = entry;
    let element;
    switch (type) {
        case types.image:
            element = <Table.Column resizable>
                <Table.HeaderCell>{entry.title}</Table.HeaderCell>
                <Table.ImageCell round dataKey={dataKey}/>
            </Table.Column>;
            break;
        case types.link:
            element = <Table.Column resizable>
                <Table.HeaderCell>{entry.title}</Table.HeaderCell>
                <Table.ButtonCell baseRoute={entry.baseRoute} routeKey={entry.routeKey} dataKey={dataKey}/>
            </Table.Column>;
            break;
        case types.boolean:
            element = <Table.Column resizable sortable>
                <Table.HeaderCell>{entry.title}</Table.HeaderCell>
                <Table.BooleanCell dataKey={dataKey}/>
            </Table.Column>;
            break;
        case types.array:
            element = <Table.Column resizable>
                <Table.HeaderCell>{entry.title}</Table.HeaderCell>
                <Table.ArrayCell dataKey={dataKey} dataSubKey={entry.subkey}/>
            </Table.Column>;
            break;
        default:
            element = <Table.Column sortable resizable width={120}>
                <Table.HeaderCell>{entry.title}</Table.HeaderCell>
                <Table.Cell dataKey={dataKey}/>
            </Table.Column>;
            break;
    }
    return (element);
};


export default StoreDataTable;
