import AdditionForms from "@containers/Admin/AdditionForms";

/**
 * Admin Form Routes
 * @type {Array<Object>}
 */
const additionFormRoutes = [{
  path: "/conversationpartners",
  component: AdditionForms.CP
}];

export default {
  additionFormRoutes
}
