import _ from "lodash";
import colors from "../constants/colors";

export const generateChartData = ({total, values}: { total: number, values: [number] }) => {
  const angleValues = [];
  values.map(value => angleValues.push({
    angle: value.angle,
    color: value.color,
    label: value.label,
    subLabel: value.subLabel
  }));

  angleValues.push({
    angle: total - _.sumBy(values, v => v.angle),
    label: `Remaining Hours`,
    color: colors.grey
  });

  return angleValues;
};

export const generateLegendData = (values) => {
  return [{label:"Remaining Hours",color:colors.grey},...values];
};

export const generateChartValue = ({total,values})=>{
  const usedValue = _.sumBy(values,v=>v.angle);
  return `${usedValue}/${total}`;
};

export const generateChartValues = ({total,values})=>{
  const vs = values.filter((v,i)=>i !== values.length -1);
  const usedValue = _.sumBy(vs,v=>v.angle);

  return `${usedValue}/${total}`;
};