import styled, {css} from "styled-components";
import colors from "../../constants/colors";


const underlinedStyle = css`
    border-bottom: solid 3px ${colors.orange};
`;

const boldStyle = css`
    font-weight: ${props => props.bold ? 900 : 500};
`;

const leadStyle = css`
     font-size: 50px;
`;

const alignStyle = css`
     text-align: center;
`;


/**
 * @component
 */
export const StyledHeader = styled.h1`
    font-size: 28px;
    font-family: Lato, sans-serif;
    line-height:55px;
    letter-spacing: 0.42px;
    color: #A0A096;
    margin:0;
   ${props => props.center && alignStyle}
   ${props => props.lead && leadStyle}
   ${boldStyle}
   ${props => props.underlined && underlinedStyle}
`;



/**
 * @component
 */
export const TempHeader = styled.h1`
    font-size: 36px;
    font-weight:600;
`;
