
export default {
  date:{
    title: "Scheduled At"
  },
  startTime:{
    title: "Starts At"
  },
  endTime:{
    title: "Ends At"
  },
  status:{
    title: "Status"
  },
  code:{
    title: "Skype Code"
  }
}
