import {observable, action, computed} from "mobx";
import moment from "moment";
import _ from "lodash";
import {currentWeeklyAvailabilityTime} from "../../../utils/date";
import {BaseStore, BaseModel} from "../BaseStore";
import {serverTimeFormat} from "@utils/date";


const today = moment();
const y1970 = moment("1970-1-1","YYYY-MM-DD");
const currentTimeZone = ~~today.format("ZZ");
const ancientTimeZone = ~~y1970.format("ZZ");
const timeDifference = (currentTimeZone - ancientTimeZone)/100;
export class AvailabilityBlock extends BaseModel {
  @observable
  id;

  @observable
  start = "";

  @observable
  end = "";

  @computed
  get startMoment() {
    return moment(this.start,serverTimeFormat);
  }

  @computed
  get processedStartMoment(){
    return 0;
  }

  @computed
  get endMoment() {
    return moment(this.end,serverTimeFormat);
  }

  @computed
  get startString() {
    return this.startMoment.format("h:mm a");
  }

  @computed
  get endString() {
    return this.endMoment.format("h:mm a");
  }

  @computed
  get startHour() {
    return this.startMoment.hour();
  }

  @computed
  get startMinute() {
    return this.startMoment.minutes();
  }

  @computed
  get day(){
    return this.startMoment.format("ddd")
  }

  @computed
  get date(){
    return this.startMoment.format("DD/MM/YYYY")
  }

  @computed
  get startComparison() {
    const m = moment(this.start,serverTimeFormat);
    m.year(moment().year());
    m.month(moment().month());
    m.day(moment().day());
    m.add({hour:timeDifference});
    return m.format("HH:mm");
  }

  @computed
  get durationInMinutes() {
    return this.endMoment.diff(this.startMoment, "minutes");
  }

  // @computed
  // get currentDateStartTime() {
  //   return currentWeeklyAvailabilityTime(this);
  // }

  // @computed
  // get currentDateStartTimeMoment() {
  //   return moment(this.currentDateStartTime);
  // }

  constructor(props, parent) {
    super(props);
    this.parentStore = parent;
  }


}

const weekDays = moment.weekdaysShort();

export class AvailabilityDay extends BaseModel {

  endPoint = "user_availabilities";

  @observable
  name = "";

  @observable
  date = "";

  @observable
  data = [];


  constructor(props, parent) {
    super();
    this.id = props.id;
    this.name = props.day;
    this.date = props.date;
    this.consume(props.timeSlots);
    this.parentStore = parent;
  }

  @action
  add(availability:AvailabilityBlock){
    this.data.push(availability);
    this.data = this.data.sort((a, b) => a.startHour - b.startHour || a.startMinute - b.startMinute).slice();
  }

  @action
  consume = (data: [Object]) => {
    if (data)
      {data.map(d => this.data.push(new AvailabilityBlock(d, this)));
        this.data = _.sortBy(this.data,d=>d.startHour);
      }
  };


  @action.bound
  remove(model: BaseModel) {
    this.data = this.data.filter(s => s !== model && s.id !== model.id);
    // delete this.data.find(d=>d === model || d.id === model.id);
  }

}

class Availabilities extends BaseStore {
  DataModel = AvailabilityDay;

  constructor(data) {
    super();
    this.consume(data);
  }

  @computed
  get availabilityBlocks() {
    const t = [];
    this.data.forEach(d => {
      t.push(...d.data);
    });
    return t;
  }

  @computed
  get comparableAvailabilities(){
    return this.availabilityBlocks.map(a=>({day:a.day,date:a.start}))
  }

  @action
  consume(data: [Object]) {
    data.map(d => this.data.push(new AvailabilityDay(d, this)));
    // weekDays.map(d => {
    //   const day = data.find(f => f.day === d);
    //   if (day)
    //     {this.data.push(new AvailabilityDay(day, this));}
    //   else
    //     this.data.push(new AvailabilityDay({day: d}, this));
    // });
  }
}

export default Availabilities;
