import {inject, observer} from "mobx-react";
import FlexBox,{flexDirection, flexProps} from "@components/FlexBox";
import DashboardBlock from "@components/DashboardBlock"
import React from "react";
import {StyledMapPlaceHolder} from "./styled";
import StepContinueButton from "./components/StepContinueButton";
import Input from "../../../../components/Input";

const DialectSelectStep = inject("languageAdditorStore")(observer(({languageAdditorStore}) => {
    const {selectedLanguage} = languageAdditorStore;
    return (
      <FlexBox full="horizontal" align={flexProps.center}
               justify={flexProps.center} direction={flexDirection.column}
               pad={{horizontal: 80, mobile: {horizontal: "0"}}}>
        <DashboardBlock.Header>
            Please select from the following
        </DashboardBlock.Header>
        <FlexBox full="horizontal" direction="row-responsive" margin={{top: 50}}>
          {/*<FlexBox basis="1/2">*/}
          {/*  <StyledMapPlaceHolder/>*/}
          {/*</FlexBox>*/}
         <DialectBlocks language={selectedLanguage} store={languageAdditorStore}/>
        </FlexBox>
        <LevelsBlocks store={languageAdditorStore}/>
        <StepContinueButton disabled={!languageAdditorStore.canSelectQuestions} onClick={languageAdditorStore.stepUp}
                            goBack/>
      </FlexBox>
    );
  })
);

const DialectBlocks = observer(({language,store})=>{
  return(
    <FlexBox direction="column" basis="full">
      {language.name.toLowerCase() ==="arabic"?`If you are interested in both MSA and dialect, check out our fully structured Integrated Curriculum.`:null}
      <FlexBox margin={{top:20}} direction="column" >
        {language.availableDialects.map(d => <Input key={d.id} checked={d.id === store.selectedDialectId} onClick={()=>store.selectDialect(d.id)} type="radio" title={d.name} label={`${d.name} | ${d.origin}`}/>)}
      </FlexBox>
    </FlexBox>
  )
});

const LevelsBlocks = inject("languageLevelStore")(observer(({languageLevelStore,store})=>store.renderLevels?(
  <FlexBox direction="column" full="horizontal" margin={{top:40}}>
    <DashboardBlock.Header>
      I consider my level as :
    </DashboardBlock.Header>
    <FlexBox direction="column" margin={{top:20}}>
      {languageLevelStore.data.map(d=><Input key={d.id} checked={d.id === store.selectedLevelId} onClick={()=>store.selectLevel(d.id)} type="radio" label={d.name}/>)}
    </FlexBox>
  </FlexBox>
):null));

export default DialectSelectStep;
