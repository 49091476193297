import React from "react";
import styled from "styled-components";
import {RadialChart} from "react-vis";
import {inject, observer} from "mobx-react";
import DashboardBlock, {DashboardHeader} from "../../components/DashboardBlock";
import colors from "../../constants/colors";
import FlexBox from "../../components/FlexBox";
import Legends from "../../components/Legend";
import {generateChartData, generateLegendData} from "../../utils/dataVisualization";
import RadialBlock from "../../components/RadialBlock";
import {BalanceStore} from "../../stores/domain/student/balanceStore";
import {getExpiredText} from "../../utils/date";
import Hint from "@components/Hint";
import {withRouter} from "react-router-dom";

const BalanceBlock = withRouter(inject("balanceStore", "uiConfig", "sessionTypeStore")(observer(({balanceStore, uiConfig, sessionTypeStore, vertical, history}: { balanceStore: BalanceStore, vertical: boolean }) => {
    return (
        <DashboardBlock title="Balance" action={uiConfig.renderForCp ? null : {
            text: "Buy Credit", icon: "plus", onClick: () => {
                history.push('/purchase')
            }
        }}>
            <DashboardHeader>{balanceStore.creditBalance} Credit
                <Hint data="At NaTakallam, we use a credit system. A 1h conversation session costs 2 credits."/>
            </DashboardHeader>
            {balanceStore.creditBalance > 0 ?
                <DashboardHeader
                    subHeader>{`${getExpiredText(balanceStore.creditExpiryDate)} ${balanceStore.expiryFormat}`}
                    <Hint>Please note: to manage work flow and conversation partner’s expectations,<br/> bundles have an expiration date.<br/> But don’t worry! Expired credits can be reactivated with the purchase of a new bundle.</Hint>
                </DashboardHeader> : null}
            {
                sessionTypeStore.enabledTypes.map(type => (
                    <DashboardHeader key={type.name + type.id}
                                     subHeader>{`${Math.floor(balanceStore.creditBalance / type.cost) / 2} hours of ${type.name.toLowerCase()} sessions`}</DashboardHeader>
                ))
            }
            <FlexBox direction={vertical ? "column" : "row-responsive"} align="center" full="horizontal"
                     margin={{top: 15}}>
                <Legends items={balanceStore.dataForGraphLegends}/>
                <RadialBlock data={balanceStore.dataForRadialGraph} vertical={vertical} label="NaTakallam Credit"
                             value={balanceStore.creditBalance}/>
            </FlexBox>
        </DashboardBlock>);
})));

const myData = [{angle: 30, color: colors.primary, title: "Test1"}, {
    angle: 12,
    color: colors.secondary,
    title: "Test2"
}, {angle: 22, label: "nonused", subLabel: "sublabel", title: "Test1"}];
const generatedData = generateChartData({total: 100, values: myData});
const generatedLegends = generateLegendData(myData);

export const UnobservedBalanceBlock = observer(({vertical}) => {
    return (
        <DashboardBlock title="Balance" action={{text: "Buy Credit", icon: "plus"}}>
            <DashboardHeader>4 hours remaining</DashboardHeader>
            <DashboardHeader subHeader>Expires on 4th of March</DashboardHeader>

            <FlexBox direction={vertical ? "column" : "row"} align="center" width="100%" margin={{top: 15}}>
                <Legends
                    items={generatedLegends}/>

                <FlexBox style={{position: "relative"}} align="center" justify="center"
                         margin={{left: vertical ? 0 : 70, mobile: {left: "0"}}}>
                    <RadialChart
                        width={210}
                        height={210}
                        data={generatedData}
                        innerRadius={92}
                        radius={103}
                        colorType="literal"
                        // colorRange={[colors.primary, colors.secondary, colors.orange, colors.navy]}
                        animation
                    >
                    </RadialChart>
                    <Value value="30/45" label="Hours Completed"/>
                </FlexBox>

            </FlexBox>

        </DashboardBlock>
    );
});


const Value = ({value, label}) => (
    <FlexBox align="center" style={{position: "absolute"}} direction="column">
        <StyledValue>{value}</StyledValue>
        <StyledLabel>{label}</StyledLabel>
    </FlexBox>
);

const StyledValue = styled.span`
    font-size: 40px;
`;
const StyledLabel = styled.span`
    font-size: 12px;
`;


export default BalanceBlock;
