import React from "react";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import {inject, observer} from "mobx-react";
import DashboardBlock from "@components/DashboardBlock";
import {LanguageAdditorStore} from "@stores/ui/languageAdditorStore";
import StepContinueButton from "./components/StepContinueButton";
import Input from "../../../../components/Input";


const QuestionaireSelectStep = inject("languageAdditorStore")(observer(({languageAdditorStore,isWalkThrough}) => (
  <FlexBox full="horizontal" align={flexProps.center}
           justify={flexProps.center} direction={flexDirection.column}
           pad={{horizontal: 80, mobile: {horizontal: "0"}}}>
    <DashboardBlock.Header>
        On the session content and format
    </DashboardBlock.Header>
    <FlexBox full="horizontal" direction="column" margin={{top: 50}}>
      <FlexBox full="horizontal" direction="row-responsive">
        <SelectorBlock store={languageAdditorStore} property="lookingFor" selector="selectedLookingFor"
                       title="Ideally, I’m looking for"/>
        <SelectorBlock store={languageAdditorStore} property="focusOn" selector="selectedFocusOn"
                       title="I’d like to focus on (select all that apply)"/>
      </FlexBox>
      <FlexBox full="horizontal" margin={{top: 40}} direction="row-responsive">
        <SelectorBlock store={languageAdditorStore} property="whatToCover" selector="selectedWhatToCover"
                       title="I'd be interested in covering"/>
        <CommentBlock store={languageAdditorStore} property="sessionsPerWeek" selector="selectedSessionsPerWeek"
                      title="Additional information"/>
      </FlexBox>
    </FlexBox>
    <StepContinueButton disabled={!languageAdditorStore.canSubmit}
                        onClick={languageAdditorStore.submitRequest(isWalkThrough)}
                        goBack/>
  </FlexBox>

)));

const CommentBlock = observer(({title,store}) => (
  <FlexBox basis="1/2" margin={{mobile: {top: 40}}} direction={flexDirection.column}>
    {title}
    <FlexBox direction={flexDirection.column} margin={{top: 20}} full="both">
      <Input type="textarea" value={store.additionalComments} withMargin={false} fill="both" onChange={(v)=>store.selectQuestionAnswer("additionalComments",v.target.value)} placeholder="Please use this space to add any additional information pertaining to your language background, interest, your future conversation partner/session format needs, or other."/>
    </FlexBox>
  </FlexBox>
));

const SelectorBlock = observer(({store, property, selector, title}: { store: LanguageAdditorStore }) => (
  <FlexBox basis="1/2" margin={{mobile: {top: 40}}} direction={flexDirection.column}>
    {title}
    <FlexBox direction={flexDirection.column} margin={{top: 20}}>
      {store[property].map(l => <Input key={l} type="radio" label={l} checked={l === store[selector]}
                                       onClick={() => store.selectQuestionAnswer(selector, l)}/>)}
    </FlexBox>
  </FlexBox>
));


export default QuestionaireSelectStep;
