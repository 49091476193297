import React from "react";
import {inject, observer} from "mobx-react";
import Icon from "@oovui/react-feather-icons";
import Screen from "../../../components/Screen";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import DashboardBlock, {DashboardHeader} from "../../../components/DashboardBlock";
import PurchaseBlock from "../../../components/PurchaseBlock";
import Button from "../../../components/Button";

@inject("bundleStore", "purchaseStore","userStore")
@observer
class PurchaseCreditScreen extends React.Component {

  renderSuccess() {
    const {purchaseStore,userStore} = this.props;
    return (
      <FlexBox direction={flexDirection.column}>
        <FlexBox direction={flexDirection.rowResponsive} pad={{horizontal: 50, bottom: 20, mobile: {horizontal: "0"}}}>
          <Icon type="check" size={20}/>
          <FlexBox f={1} margin={{left: 10, mobile: {left: 0}}} direction={flexDirection.column}>
              {userStore.isFirstTime? " Expect an email introcution to your partner within the next 48 hours with access to their calendar. From there, book your first session and start your conversation.":"Have fun"}
          </FlexBox>
        </FlexBox>
        <FlexBox direction={flexDirection.rowResponsive} justify={flexProps.center}>
          <Button onClick={purchaseStore.goToDashboard} text="Go To Dashboard" iconLeft="layout"/>
        </FlexBox>
      </FlexBox>
    );
  }

  renderBundles() {
    const {bundleStore, purchaseStore} = this.props;
    return (
      <React.Fragment>
        <DashboardHeader bold subHeader centerText>
            At NaTakallam, we convert your dollar purchase into credits, so you can easily use them across our platform. Purchase your preferred bundle of sessions below & track your balance on your dashboard.
        </DashboardHeader>
          {bundleStore.enabledBundles.length?<DashboardHeader subHeader centerText>
              Please note: to manage work flow and conversation partner’s expectations, bundles have an expiration date. But don’t worry! Expired credits can be reactivated with the purchase of a new bundle.
          </DashboardHeader>:<DashboardHeader subHeader centerText>
              Thank you for signing up to NaTakallam. Please be sure to pick a language before purchasing your sessions
          </DashboardHeader> }
        <FlexBox direction={flexDirection.rowResponsive} margin={{top: 20, bottom: 40}}
                 justify={flexProps.center} wrap="wrap"  align={flexProps.center}>
          {bundleStore.enabledBundles.map(b => <PurchaseBlock key={b.id} name={b.name}
                                                    price={b.price}
                                                    quantity={b.creditAmount}
                                                    description={b.description}
                                                    duration={b.duration}
                                                    isSelected={purchaseStore.selectedBundleId === b.id}
                                                    onSelect={() => purchaseStore.selectBundle(b.id)}

          />)}
        </FlexBox>
        <FlexBox direction={flexDirection.row} justify={flexProps.center}>
          <Button text="Continue" withArrow disabled={!purchaseStore.selectedBundle}
                  onClick={purchaseStore.purchaseBundle}/>

        </FlexBox>
        <br/><br/>
      </React.Fragment>
    );
  }

  render(): React.ReactNode {
    const {purchaseStore} = this.props;
    return (<Screen>
      <FlexBox direction="row" f={1} justify={flexProps.center}>
        <Screen.Column last full="horizontal" solo>
          <DashboardBlock title="Buy Credit" align={flexProps.center}>
            <FlexBox direction="column">
              {purchaseStore.showSuccess ? this.renderSuccess() : this.renderBundles()}
            </FlexBox>
          </DashboardBlock>
        </Screen.Column>
      </FlexBox>

    </Screen>);
  }

}

export default PurchaseCreditScreen;
