import React from "react";
import {inject,observer} from "mobx-react";
import DashboardBlock, {DashboardHeader} from "../../../components/DashboardBlock";

import FlexBox from "../../../components/FlexBox";
import RadialBlock from "../../../components/RadialBlock";
import Legends from "../../../components/Legend";
import {generateChartValues} from "../../../utils/dataVisualization";

const CpHoursBlock = inject("sessionStore")(observer(({sessionStore,vertical})=>(
  <DashboardBlock title="Sessions Statistics">
    <DashboardHeader>{`${sessionStore.totalNumberOfSessions} sessions booked`}</DashboardHeader>
    <FlexBox direction={vertical ? "column" : "row-responsive"} align="center" full="horizontal" margin={{top: 15}}>
      <Legends items={sessionStore.dataForCpGraphLegend}/>
      <RadialBlock data={sessionStore.dataForCpGraph} vertical={vertical} value={generateChartValues({total:sessionStore.totalHours, values: sessionStore.dataForCpGraph})} label="Hours Completed"/>
    </FlexBox>
  </DashboardBlock>
)));


export default CpHoursBlock;