import styled, {css} from "styled-components";
import FlexBox, {flexDirection, flexProps} from "../FlexBox";
import colors from "../../constants/colors";

const flexStyle = css`
    flex:1;
`;

const dayBorderStyle = css`
    border-width: 0 0 0 1px;;
`;

const dayNoBorderStyle = css`
    border-width: 0;
`;

/**
 * @component
 */
export const DayColumn = styled(FlexBox).attrs({direction: flexDirection.column})`
    border: solid rgb(0,0,0,0.05);
    ${props => !props.static && flexStyle}
    ${props => !props.static && dayBorderStyle || dayNoBorderStyle}
`;


/**
 * @component
 */
export const TimeRow = styled(FlexBox).attrs({direction: flexDirection.row})`
    border: solid rgb(0,0,0,0.05);
    height: 50px;
    min-height: 50px;
    ${props => !props.static && flexStyle}
    ${props => !props.static && dayBorderStyle || dayNoBorderStyle}
`;


/**
 * @component
 */
export const LeftBlockStyle = styled(FlexBox).attrs({direction: flexDirection.column, justify: flexProps.end})`
    flex:1;
    height:50px;
    min-height: 50px;
    border: solid rgb(0,0,0,0.15);
    border-width: 0 1px 0 0;
    font-size: 12px;
    color:${colors.primary};
    text-align: end;
    text-justify: distribute-center-last;
    padding-right: 10px;
    width:50px;
`;

/**
 * @component
 */
export const TimeBlockStyle = styled(FlexBox).attrs({direction: flexDirection.column})`
    flex:1;
    border: solid rgb(0,0,0,0.05);
    height:50px;
    min-height: 50px;
    border-width: 0 0 1px 0;
`;

/**
 * @component
 */
export const HeaderStyle = styled(FlexBox).attrs({
  align: "center",
  justify: "center"
})`
    height:50px;
    min-height: 50px;
    flex:1;
    border: solid rgb(0,0,0,0.15);
    border-width: 0 0 1px 0;
    font-size: 12px;
    color:${colors.primary}
`;
