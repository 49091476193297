import {computed, observable} from "mobx";
import {BaseModel} from "../domain/BaseStore";
import api from "@config/api";
import sessionTypeStore from "@stores/domain/sessionTypeStore";
const service = {
  load: (): Promise => api.get("bundles"),
  update: id => data => api.put(`bundles/${id}`, data),
  add: data => api.post("bundles", data)
};

class BundleModel extends BaseModel {
  @observable
  active;

  @observable
  name;

  @observable
  description;

  @observable
  price;

  @observable
  creditAmount;

  @observable
  duration;

  @observable
  sessionTypeId;

  @observable
  isActive=false;

  @observable
  activationData;

  @computed
  get sessionTypeObject(){
    if(this.sessionTypeId)
    return sessionTypeStore.find(this.sessionTypeId);
    return null;
  }

  updater=service.update(this.id);
  adder=service.add;

  serializeData(data, setLoadedOnDone = true) {
    this.sessionTypeId = data.sessionType?data.sessionType.id:null;
    super.serializeData(data, setLoadedOnDone);
  }

  static get tableSchema(){
    return [
      "isActive",
      "name",
      "creditAmount",
      "price"
    ]
  }

  updateSchema: * = [
    "name",
    "description",
    "price",
    "creditAmount",
    "duration",
    "isActive"
  ];
}

export default BundleModel;
