import React from "react";
import {inject, observer} from "mobx-react";
import {computed, isObservableArray} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import {Icon, IconButton} from "rsuite";
import _ from "lodash";
import FlexBox from "@components/FlexBox";
import {viewSchema} from "@schemas/Admin";
import ViewDetails from "@containers/Admin/ViewDetails";



@inject("adminSessionStore")
@observer
class ViewCp extends React.Component {

  @computed
  get _entity() {
    const {adminSessionStore, match} = this.props;
    const id = ~~match.params.id;
    const entity = adminSessionStore.data.find(c => c.id === id);

    return entity || null;
  }

  _onEdit = () => {
    const {history, match} = this.props;
    history.push(`${match.url}/edit`);
  };

  render() {
    window.test = this._entity;
    if (this._entity)
      return (
        <Screen.Column last basis="full">
          <DashboardBlock title={`Session ${this._entity.id}`} action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
            <FlexBox direction="row-responsive" full>
              <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                <ViewDetails entity={this._entity} schema={viewSchema.session} />
                <DashboardHeader>Language: <span
                  style={{fontWeight: "300"}}>{this._entity.StudentLanguage.languageName} {this._entity.StudentLanguage.dialectName}</span>
                </DashboardHeader>
              </FlexBox>
              <FlexBox direction="column" f={1}
                       margin={{right: 20, mobile: {bottom: 20}}}>
                <DashboardHeader italic>Student <IconButton
                  onClick={() => {
                    this.props.history.push(`/students/${this._entity.Student.id}`);
                  }}
                  size="sm" icon={<Icon icon="eye"/>}/></DashboardHeader>
                <ViewDetails entity={this._entity.Student} schema={viewSchema.student} />
              </FlexBox>
              <FlexBox direction="column" f={1}
                       margin={{mobile: {bottom: 20}}}>
                <DashboardHeader italic>Conversation Partner <IconButton
                  onClick={() => {
                    this.props.history.push(`/cp/${this._entity.CP.id}`);
                  }}
                  size="sm" icon={<Icon icon="eye"/>}/></DashboardHeader>
                <ViewDetails entity={this._entity.CP} schema={viewSchema.cp} />
              </FlexBox>
            </FlexBox>
          </DashboardBlock>
          <FlexBox direction="row-responsive">
          </FlexBox>
        </Screen.Column>
      );

    return (
      <div>loading</div>
    );
  }
}


export default ViewCp;
