import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import FormInput from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import Input from "@containers/Admin/Input";
import Tag from "@components/Tag";
import {selectorCountries} from "@constants/countries";
import ImageUploader from "@components/ImageUploader";
import {uploadFile} from "@config/s3";


@inject("adminCpStore")
@observer
class EditCP extends React.Component {

    @observable
    _cp;


    @observable
    fileController = {file: ""};

    componentDidMount(): void {
        const {adminCpStore} = this.props;
        if (!adminCpStore.loaded && !adminCpStore.loading) {
            adminCpStore.load();
        }

        const {match} = this.props;
        const id = ~~match.params.id;
        const cp = adminCpStore.data.find(c => c.id === id);

        if (cp) {
            this._cp = cloneStore(cp, true);
        }
    }

    @action
    _submit = e => {
        e.preventDefault();
        if (this.fileController.file.name) {
            this._cp.loading = true;
            uploadFile(this.fileController.file, "profile", this._cp.email+(new Date().getTime())).then(resp => {
                    this._cp.avatarFileName = resp.key.split("/")[1];
                    this._cp.serializeAndUpdate().then(() => this._goBack())
                }
            ).catch(error => {
                this._cp.loading = true;
                console.log(error);
            })
        } else
            this._cp.serializeAndUpdate().then(this._goBack);
    };

    _goBackPrevention = e => {
        e.preventDefault();
        this._goBack()
    }

    _goBack = () => {
        const {history} = this.props;
        history.goBack();
    };

    render() {

        window.test = this;
        if (this._cp)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock as="form" onSubmit={this._submit}
                                    title={`Editing ${this._cp.original.firstName} ${this._cp.original.lastName}`}>
                        <FlexBox direction="row-responsive" loading={this._cp.loading}>
                            <FlexBox>
                                <ImageUploader controller={this.fileController}
                                               defaultImage={this._cp.original.profilePicture}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                                <Input type="text" fill label="First Name" validationRules="required|min:2"
                                       propName="firstName"
                                       store={this._cp}/>
                                <Input type="text" fill label="Last Name" validationRules="required|min:2"
                                       propName="lastName"
                                       store={this._cp}/>
                                <Input type="email" fill label="Email" validationRules="required|email" propName="email"
                                       store={this._cp}/>
                                <Input type="select" fill label="Country of Residence" propName="country"
                                       options={selectorCountries}
                                       store={this._cp}/>
                                <Input type="text" fill label="City" propName="city"
                                       store={this._cp}/>
                                <Input type="text" fill label="Skype Id" propName="skypeId" store={this._cp}/>
                                <Input type="text" fill label="Profession" propName="profession" store={this._cp}/>
                                <Input type="text" fill label="Phone Number" propName="phoneNumber" store={this._cp}/>
                                <FormInput label="Birthday" type="dateTimePicker" value={this._cp.birthdayMoment} onChange={this._cp.setBirthday}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1}>
                                <Input type="text" fill label="Partnership"
                                       placeholder="University, K-12, Guest Speaker,…"
                                       propName="partnership"
                                       store={this._cp}/>
                                <FlexBox direction={flexDirection.row} wrap>
                                    {this._cp.hobbies.map(h => <Tag key={h} text={h}
                                                                    onClose={() => {
                                                                        this._cp.removeHobby(h)
                                                                    }}
                                    />)}
                                </FlexBox>
                                <FlexBox direction={flexDirection.rowResponsive} full="horizontal">
                                    <FormInput type="text" label="Add Hobbies"
                                               placeholder="Add interests as much as you need"
                                               value={this._cp.hobbyInputValue}
                                               onChange={t => this._cp.setHobbyInputValue(t.nativeEvent.srcElement.value)}
                                    />
                                    <Button text="Add" width={80} type="button" iconLeft="plus"
                                            onClick={this._cp.addHobby}/>
                                </FlexBox>
                                <Input type="textarea" label="Bio" propName="bio" store={this._cp} rows={4}/>
                                <Input type="textarea" label="Training" propName="training" store={this._cp} rows={4}/>
                                <FormInput.RadioGroup margin={{top: "0", bottom: 20}} title="Gender">
                                    <Input type="radio" label="Male" value="male" propName="gender" store={this._cp}/>
                                    <Input type="radio" label="Female" value="female" propName="gender"
                                           store={this._cp}/>
                                </FormInput.RadioGroup>
                                <Input type="checkbox" label="First Time" propName="isFirstTime" store={this._cp}
                                       withMargin={false}/>
                                <Input type="checkbox" label="Active" propName="isActive" store={this._cp} withMargin/>

                            </FlexBox>
                        </FlexBox>
                        <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                                 justify={flexProps.spaceBetween}>
                            <Button text="Cancel" onClick={this._goBackPrevention} withArrowLeft/>
                            <Button text="Submit" iconRight="check" disabled={this._cp.hasVError} primary/>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );
        return (
            <div>loading</div>
        );
    }

}


export default EditCP;
