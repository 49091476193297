import {computed, observable} from "mobx";
import moment from "moment";
import {BaseModel, BaseStore} from "../BaseStore";
import authStore from "../authStore";
import languageStore from "../languageStore";
import Availabilities from "../subStores/availabilitySubstore";
import api from "@config/api";


export class StudentModel extends BaseModel{

  endPoint="students";

  @observable
  dialectId;

  @computed
  get fullName(){
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get birthday(){
    return moment(this.dob).format("DD/MM/YYYY");
  }

  @computed
  get name(){
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get dialect(){
    return languageStore.findDialect(this.dialectId);
  }

  @computed
  get language(){
    return languageStore.findByDialect(this.dialectId);
  }

  @observable
  hobbies:[string] = [];

  @observable
  availabilities:[Availabilities] = [];

  @computed
  get profilePicture() {
    if (this.avatarFileName) {
      return api.assetUrl({filename: this.avatarFileName, folder: "profile"});
    }
    return require("@assets/male-placeholder.jpg");
  }

  serializeData(data, setLoadedOnDone = true) {
    super.serializeData(data, setLoadedOnDone);
    this.hobbies = data.hobbies;
    this.availabilities = new Availabilities(data.availabilities)
  }
}

export class StudentStore extends BaseStore{

  shouldClearOnLogout = true;

  DataModel = StudentModel;
}

const studentStore = new StudentStore();
window.studentStore = studentStore;

export default studentStore;
