import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Button from "@components/Button";
import Input from "@containers/Admin/Input";
import {cloneStore} from "@utils/mobx";
import {LanguageModel} from "@stores/domain/languageStore";
import ImageUploader from "@screens/Admin/Languages/Components";
import {uploadFile} from "@config/s3";

@inject("languageStore")
@observer
class Edit extends React.Component {

    @observable
    loading = false;


    @observable
    fileController = {file: ""};

    @observable
    _entity = cloneStore(new LanguageModel({dialects: []}), true);

    componentDidMount(): void {
        const {match, languageStore} = this.props;
        const id = ~~match.params.id;
        const language = languageStore.data.find(c => c.id === id);
        console.log(language);

        if (language) {
            this._entity = cloneStore(language, true);
        }
    }

    _submit = e => {
        e.preventDefault();
        if (this.fileController.file.name) {
            uploadFile(this.fileController.file, "language",this._entity.name+(new Date().getTime())).then(resp =>
                {
                    this._entity.icon = resp.key.split("/")[1];
                    this._entity.serializeAndUpdate().then(() => this._goBack())}
            ).catch(error => console.log(error))
        }else
            this._entity.serializeAndUpdate().then(() => this._goBack())
    };

    _goBack = e => {
        const {history} = this.props;
        if (e)
            e.preventDefault();
        history.goBack();
    };

    render() {
        window.test = this._entity;
        return (
            <Screen.Column last basis="full">
                <DashboardBlock as="form" onSubmit={this._submit} title="Add a new language">
                    <FlexBox direction="row-responsive" loading={this.loading}>
                        <FlexBox direction="column">
                            <ImageUploader controller={this.fileController} defaultImage={this._entity.image}/>
                        </FlexBox>
                        <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                            <Input type="text" fill label="Name" propName="name"
                                   store={this._entity}/>
                            <Input type="text" fill label="Code" propName="code"
                                   store={this._entity}/>
                            <Input type="textarea" fill label="Description" propName="description"
                                   store={this._entity}/>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                             justify={flexProps.spaceBetween}>
                        <Button text="Cancel" type="button" onClick={this._goBack} withArrowLeft/>
                        <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                    </FlexBox>
                </DashboardBlock>
            </Screen.Column>
        );
    }

}

export default Edit;
