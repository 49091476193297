import styled,{css} from "styled-components";
import ProfileImage from "../ProfileImage";
import FlexBox from "../FlexBox";
import {breakpointStyle} from "../../utils/styles";



const grayedOutStyle = css`
    border-color: #F7F7F7;
    filter: grayscale(100%);
`


/**
 * @component
 */
export const BorderedProfileImage = styled(ProfileImage)`
    border-style: solid;
    border-width:2px;
    border-color: ${props => props.color};
    ${props=>props.grayedOut && grayedOutStyle}
`;


/**
 * @component
 */
export const SelectorContainer = styled(FlexBox)`
     cursor: pointer;
     :not(:last-of-type){
        margin-right:40px;
     }
     
     ${()=>breakpointStyle({content:css`
        :not(:last-of-type){
        margin-bottom:20px;
      }
  `})}
    
`;
