import React from "react";
import {StyledHeader} from "./styled";

type HeaderType = {
  lead?: boolean,
  children?: any,
  center?: boolean,
  bold?: boolean,
  underlined?: boolean
}
const Header = (props: HeaderType) => {
  const {children} = props;
  return (
    <StyledHeader {...props}
    >{children}</StyledHeader>
  );
};

Header.defaultProps = {
  lead: false,
  center: false,
  children: null,
  bold: false,
  underlined: false
};


export default Header;
// const batbout="Aya";
// if(batbout === "Tweety"){
//   return "I love you";
// }