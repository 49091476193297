import {
  ToastContainer,
  type ToastContainerProps,
  toast as originalToast,
  type ToastContent,
  type Toast as ToastType
} from "react-toastify";
import styled from "styled-components";
import React from "react";
import colors from "@constants/colors";
import Icon from "@oovui/react-feather-icons";
import FlexBox from "../FlexBox";


const Toast = (styled(ToastContainer)`
    border-radius: 0;   
    font-family: Lato, sans-serif;
    font-size:14px;
    font-weight: bold;
    
    .Toastify__toast--error{
        background-color: ${colors.secondary};
    }
    
    .Toastify__toast--success{
        background-color: ${colors.primary};
    }
`);

/**
 * @component
 */
export const toast:ToastType = {
  ...originalToast,
  success: (content:ToastContent)=> originalToast.success(<FlexBox>
    <Icon color={colors.white} type="check" size={25}/>
    <FlexBox margin={{left:10}}>{content}</FlexBox>
  </FlexBox>),
  // error:  (content:ToastContent)=> originalToast.error(<FlexBox>
  //   <Icon color={colors.white} type="x" size={25}/>
  //   <FlexBox margin={{left:10}}>{content}</FlexBox>
  // </FlexBox>)
};


export default (props: ToastContainerProps) => <Toast {...props}/>;
