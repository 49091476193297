import {extendObservable} from "mobx";
import _ from "lodash";
import stores from "../domain/admin";

const AdminStore = function(){
  extendObservable(this, {
    loaded: false,
    loading: true,
    loadAll: () => {
      _.mapKeys(stores, (value) => {
        value.load();
      });
    },
    get didLoad() {
      return stores.cp.loaded && stores.bundle.loaded && stores.sessions.loaded && stores.student.loaded && stores.studentLanguage.loaded;
    }
  });
};

const adminStore = new AdminStore();

window.adminStore = adminStore;


export default adminStore;
