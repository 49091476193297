import React from "react";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import {Else, If, Then} from "react-if";
import {Link, NavLink} from "react-router-dom";
import ProfileImage from "@components/ProfileImage";
import {Button} from "./components/MenuItem";
import {inject, observer} from "mobx-react";
import styled from "styled-components";

type Props = {
    menuRoutes?: [{
        name: string,
        path: string,
        active: boolean,
        icon: string
    }]
}


const NavMenu = inject("userStore", "authStore", "uiConfig")(observer(({menuRoutes, userStore, authStore, uiConfig}: Props) => {
    return (
        <FlexBox style={{overflow: "auto", position: "absolute", left: 0, top: 0,background: "white", display: uiConfig.showMenu?"flex":"none",zIndex:999999999}}
                 full="both">
        <FlexBox style={{minHeight:100,background: "white",overflow: "auto",}}
                 full="both"
                 direction={flexDirection.column}
                 desktopHidden
                     >
                <RouterSection routes={menuRoutes}/>
                <If condition={!uiConfig.renderForAdmin}>
                    <ActionsSection/>
                </If>
                <ControlSection logout={authStore.logout}/>
            </FlexBox>
        </FlexBox>);
}));


export const UnobservedNavMenu = () =>
    <FlexBox width={200} direction={flexDirection.column}
             backgroundColor="whiteDarkAccent" mobileHidden
             full="vertical">
        <ProfileSection user={{avatarFileName: "", fullName: "test"}}/>
        <RouterSection routes={[]}/>
        <ActionsSection/>
        <ControlSection logout={() => {
        }}/>
    </FlexBox>;


const ProfileSection = observer(({user, adminName, ignoreUser}) => {
    const {profilePicture, fullName} = user;
    return (
        <FlexBox direction={flexDirection.column} align={flexProps.center} pad={{vertical: 40}}>
            <If condition={ignoreUser}>
                <Then>
                    <ProfileImage
                        src={require('@assets/male-placeholder.jpg')}
                        round/>
                    <ProfileName>{adminName}</ProfileName>
                </Then>
                <Else>
                    <Link to="/profile">
                        <ProfileImage
                            src={profilePicture || require('@assets/male-placeholder.jpg')}
                            round/>
                    </Link>
                    <ProfileName>{fullName}</ProfileName>
                </Else>
            </If>
        </FlexBox>
    );
});

const RouterSection = inject("routerStore")(observer(({routes, routerStore}) => {
    const {pathname} = routerStore.location;
    return (
        <FlexBox direction="column" pad={{bottom: 25}} separator={{bottom: true}}>
            {routes.map(r => r.inMenu ?
                <Button block plain as={NavLink} iconLeft={r.icon} exact={r.exact} to={r.path} key={r.name}
                        text={r.name}/> : null)}
        </FlexBox>
    );
}));

const ActionsSection = inject("uiConfig")(observer(({uiConfig}) => (
    <FlexBox
        align={flexProps.start}
        justify={flexProps.center}
        direction="column"
        separator={{bottom: true}}
        pad={{vertical: 25}}
    >
        <If condition={() => uiConfig.renderForCp}>
            <Then>
                <Button pad={{horizontal: 10}}  iconLeft="calendar" round text="Edit Availability"
                        margin={{bottom: 10}} as={Link} to="/availability"/>
            </Then>
            <Else>
                <Button pad={{horizontal: 10}} margin={{bottom: 10}} block iconLeft="activity"
                        text="Select or Add a language" as={Link}
                        to="/language/add"/>
                <Button pad={{horizontal: 10}}  iconLeft="calendar" round text="Set Availability"
                        margin={{bottom: 10}} as={Link} to="/availability"/>
                <Button pad={{horizontal: 10}}  iconLeft="dollar-sign" round text="Buy Credit"
                        as={Link} to="/purchase"/>
            </Else>
        </If>
    </FlexBox>
)));

const ControlSection = observer(({logout}) => (
    <FlexBox
        direction="column"
        justify={flexProps.end}
        pad={{vertical: 25}}
    >
        <Button block iconLeft="settings" plain text="Privacy settings"
                onClick={() => window.open("https://natakallam.com/privacy")}/>
        <Button block iconLeft="log-out" plain text="Logout" onClick={logout}/>
    </FlexBox>
));

NavMenu.defaultProps = {
    menuRoutes: []
};

export default NavMenu;

const ProfileName = styled.p`
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top:15px;
`;
