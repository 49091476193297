import React from "react";
import TopNavbar from "@components/TopNavbar";
import Input, {ObservedInput} from "@components/Input";
import {Link} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import FlexBox, {flexDirection, flexProps} from "../../components/FlexBox";
import DashboardBlock, {DashboardHeader} from "../../components/DashboardBlock";
import Button from "../../components/Button";
import {AuthStore} from "../../stores/domain/authStore";
import {checkEmail} from "../../stores/domain/registrationStore";
import PlainButton from "../../components/PlainButton";


@inject("authStore", "registrationStore")
@observer
class Register extends React.Component {

    @observable
    emailError = null;


    _onSubmit = e => {
        e.preventDefault();
        // authStore.register();

        const {registrationStore}: { authStore: AuthStore } = this.props;
        const {form} = registrationStore;
        form.onSubmit();
    };


    setHeardOfUs = (v) => () => {
        const {registrationStore}: { authStore: AuthStore } = this.props;
        const {form} = registrationStore;
        const field = form.$("heardAboutUsFrom");
        field.value = v;
    };


    setGender = v => () => {
        const {registrationStore}: { authStore: AuthStore } = this.props;
        const {form} = registrationStore;
        const field = form.$("gender");
        field.value = v;

    };


    setTimeOut = (v) => {
        const {value} = v.nativeEvent.target;
        this.checker = setTimeout(() => {
            checkEmail(value, (pass, msg) => {
                if (!pass) {
                    this.emailError = msg;
                } else {
                    this.emailError = null;
                }
            });
        }, 1000);
    };

    _checkEmail = v => {
        if (this.checker)
            clearTimeout(this.checker);
        this.setTimeOut(v);
    };

    renderAuthRequiredSnippet() {

        const {registrationStore}: { authStore: AuthStore } = this.props;
        const {form} = registrationStore;
        return (
            <React.Fragment>
                <FlexBox direction={flexDirection.rowResponsive}>
                    <ObservedInput
                        fill field={form.$("firstName")}/>
                    <ObservedInput field={form.$("lastName")} fill
                                   margin={{left: 10, mobile: {left: "0"}}}/>
                </FlexBox>
                <ObservedInput required error={this.emailError} field={form.$("email")} fill onChange={(v) => {
                    this._checkEmail(v);
                    form.$("email").onChange(v);
                }}/>
                <ObservedInput required field={form.$("password")} fill/>
                <ObservedInput required field={form.$("confirmPassword")} fill/>
            </React.Fragment>
        );
    }

    renderHeardOfUsSnippet() {
        const {registrationStore}: { authStore: AuthStore } = this.props;
        const {form} = registrationStore;
        const field = form.$("heardAboutUsFrom");
        const values = ["Friend/Colleague", "Professor/Teacher", "Press coverage", "NaTakallam's social media/digital ads", "General social media", "Natakallam's newsletter", "Other"];
        return (
            <FlexBox direction={flexDirection.column} pad={{vertical: 20}}>

                <Input.Header text="How you heard of us?*"
                              error={field.errorSync ? "Help us reach more people like you" : null}/>
                <input {...field.bind()}/>
                <Input.RadioGroup margin={{bottom: 20, top: 5}}>
                    {values.map(f => <ObservedInput key={f} type="radio" error={null} label={f}
                                                    onClick={this.setHeardOfUs(f)}
                                                    checked={field.value === f}/>)}
                </Input.RadioGroup>
            </FlexBox>
        );
    }

    setDob = (value)=>{
        const {registrationStore} = this.props;
        const {form} = registrationStore;
        form.$("dob").set(value);
    };

    renderDetailSnippet() {
        const {registrationStore}: { authStore: AuthStore } = this.props;
        const {form} = registrationStore;
        const gender = form.$("gender");
        return (
            <React.Fragment>
                <DashboardHeader subHeader marginBottom={false} margin={{vertical: 40}}>At NaTakallam - it’s all about making the
                    perfect match - between you and your language partner that is</DashboardHeader>
                <Input.Header text="Tell us a bit more about yourself."/>
                <br/>
                <ObservedInput fill field={form.$("profession")}/>
                <ObservedInput fill field={form.$("country")} type="select" options={registrationStore.countriesNames}/>
                {/*<ObservedInput fill field={form.$("dob")}/>*/}
                <Input  type="dateTimePicker" label={form.$("dob").label} placeholder="Your Birthday" value={form.$("dob").value} onChange={this.setDob} />
                <ObservedInput fill field={form.$("phone")}/>
                <FlexBox direction="column" margin={{top: 20}}>
                    <input {...form.$("gender").bind()} />
                    <DashboardHeader subHeader>{form.$("gender").label}</DashboardHeader>
                    <Input.RadioGroup margin={{bottom: 20, top: 5}}>
                        <Input type="radio" label="Male" onClick={this.setGender("male")}
                               checked={gender.value === "male"}/>
                        <Input type="radio" label="Female" onClick={this.setGender("female")}
                               checked={gender.value === "female"}/>
                        <Input type="radio" label="Other" onClick={this.setGender("unknown")}
                               checked={gender.value === "unknown"}/>
                    </Input.RadioGroup>
                </FlexBox>
            </React.Fragment>
        );
    }

    render(): React.ReactNode {
        const {registrationStore} = this.props;
        const {form} = registrationStore;
        const terms = form.$("terms");
        return (
            <FlexBox full="both" direction={flexDirection.column}>
                <TopNavbar/>
                <FlexBox pad={{horizontal: "20%", vertical: 10, mobile: {horizontal: 10}}}
                         direction={flexDirection.column}>
                    <DashboardBlock title="Sign Up" direction={flexDirection.column} align={flexProps.center}
                                    loading={registrationStore.loading}>
                        <FlexBox as="form" onSubmit={form.onSubmit} full="vertical" mobileWidth="auto"
                                 margin={{top: 100}}
                                 direction={flexDirection.column}>
                            {this.renderAuthRequiredSnippet()}
                            {this.renderHeardOfUsSnippet()}
                            {this.renderDetailSnippet()}
                            <ObservedInput required field={terms}
                                           link={<PlainButton color="darkred" title="terms & conditions"
                                                              onClick={() => window.open("https://natakallam.com/terms-and-conditions")}/>}
                                           onClick={() => {
                                               terms.$value = !terms.$value
                                           }} checked={terms.$value}/>
                            <FlexBox align={flexProps.center} pad={{vertical: 40}} justify={flexProps.center}
                                     direction="column">
                                <Button text="Sign up" disabled={form.hasError} withArrow/>
                            </FlexBox>
                        </FlexBox>
                        <span style={{marginBottom: 100}}>Already have an account? <Link to="login">Log in</Link></span>
                    </DashboardBlock>
                </FlexBox>
            </FlexBox>);
    }

}


export default Register;
