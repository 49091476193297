import React from "react";
import {Route, Switch} from "react-router-dom";
import Screen from "@components/Screen";
import DataScreens from "../DataScreens";
import AddScreen from "./Add";
import EditScreen from "./Edit";
import ViewScreen from "./View";


export default ({match}) => {
    const {path} = match;
    return (
        <Screen>
            <Switch>
                <Route path={path} exact component={DataScreens.Admins}/>
                <Route path={`${path}/add`} exact component={AddScreen}/>
                <Route path={`${path}/:id`} exact component={ViewScreen}/>
                <Route path={`${path}/:id/edit`} exact component={EditScreen}/>
            </Switch>
        </Screen>
    );
}
