import styled from "styled-components";
import React from "react";
import colors from "../../constants/colors";
import FlexBox from "../../components/FlexBox";
import Button from "../../components/Button";

const NotFound = ({history}) => {
  return (<FlexBox align="center" direction="column" full="both" pad={{horizontal: 40}}>
    <T404>404</T404>
    <Message>It looks like you lost your way around</Message>
    <Button withArrow text="Go Home" onClick={() => history.push("/")}/>
  </FlexBox>);
};

const Message = styled.h4`
    font-size: 32px;
    color: ${colors.secondary}
`;

const T404 = styled(Message)`
    font-size: 120px;
`;

export default NotFound;