import React from "react";
import {observer,inject} from "mobx-react";
import {computed} from "mobx";
import {MultiColumnBlock} from "@components/DashboardBlock";
import FlexBox from "@components/FlexBox";
import styled from "styled-components";
import colors from "@constants/colors";
import {AccountInformationRow} from "../styled";


const CpInformationBlock = inject("routerStore","sessionBookingStore")(observer(({cp,routerStore,sessionBookingStore}) => {

  const dialect = computed(() => {
    return cp.dialect ? cp.dialect.name : "loading";
  });

  const language = computed(() => {
    return cp.language ? cp.language.name : "loading";
  });

  return (
    <MultiColumnBlock title={`Hi my name is ${cp.name}`} direction="row-responsive" action={{
      icon: "plus", text: "Book a session with me", onClick: () => {
        sessionBookingStore.selectLanguageByCp(cp.id);
        routerStore.push("/sessions");
      }
    }}>
      <MultiColumnBlock.Block basis="2/3" direction="column">
        <AccountInformationRow>
          <AccountInformationRow.Header>
            Language
          </AccountInformationRow.Header>
          <AccountInformationRow.Value>
            {`${language} | ${dialect}`}
          </AccountInformationRow.Value>
        </AccountInformationRow>
        <AccountInformationRow>
          <AccountInformationRow.Header>
            Origins
          </AccountInformationRow.Header>
          <AccountInformationRow.Value>
            {`${cp && cp.dialect && cp.dialect.origin}`}
          </AccountInformationRow.Value>
        </AccountInformationRow>
        <AccountInformationRow>
          <AccountInformationRow.Header>
            Skype
          </AccountInformationRow.Header>
          <AccountInformationRow.Value>
            {`${cp && cp.skypeId || "No Skype"}`}
          </AccountInformationRow.Value>
        </AccountInformationRow>
      </MultiColumnBlock.Block>
      <MultiColumnBlock.Block basis="1/3" direction="column" last>
        <AccountInformationRow>
          <StyledValue>{cp.sessionHours}</StyledValue>
          <FlexBox direction="column">
            <AccountInformationRow.Header>
              Hours
            </AccountInformationRow.Header>
            <AccountInformationRow.Value>
              Conversation Hours
            </AccountInformationRow.Value>
          </FlexBox>
        </AccountInformationRow>
        <AccountInformationRow>
          <StyledValue>{cp.completedSessions}</StyledValue>
          <FlexBox direction="column">
            <AccountInformationRow.Header>
              Sessions
            </AccountInformationRow.Header>
            <AccountInformationRow.Value>
              Completed Sessions
            </AccountInformationRow.Value>
          </FlexBox>
        </AccountInformationRow>
      </MultiColumnBlock.Block>
    </MultiColumnBlock>
  );
}));

const StyledValue = styled.div`
    font-family: Lato, sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: ${colors.black};
    margin-right: 15px;
    min-width: 70px;
`;

export default CpInformationBlock;