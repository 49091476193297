import {observable, action, computed} from "mobx";
import routerStore from "@stores/app/routerStore";
import api from "@config/api";
import {BaseModel} from "../domain/BaseStore";
import languageStore from "../domain/languageStore";
import languageLevelStore from "../domain/languageLevelStore";
import studentLanguageStore from "../domain/student/studentLanguageStore";
import userStore from "../domain/userStore";

const service = {
  addLanguage: (data) => api.post("student_languages", data)
};

export class LanguageAdditorStore extends BaseModel {

  lookingFor = [
    "Something more structured",
    "Free-flowing conversation",
    "A combination of both"
  ];

  focusOn = [
    "Reading",
    "Writing",
    "Speaking",
    "Listening"
  ];

  whatToCover = [
    "Politics/Current affairs",
    "Art & Culture",
    "Daily life",
    "Media, Cinemas and TV shows",
    "Sports"
  ];

  sessionsPerWeek = [
    "Less than 1",
    "1",
    "2",
    "3 or more"
  ];

  @observable
  currentStep = 1;

  @observable
  selectedLanguageId = null;

  @observable
  selectedDialectId = null;

  @observable
  selectedLevelId = null;

  @observable
  selectedLookingFor = null;

  @observable
  selectedFocusOn = null;

  @observable
  selectedWhatToCover = null;

  @observable
  additionalComments = null;

  @computed
  get selectedLanguage() {
    return languageStore.find(this.selectedLanguageId);
  }

  @computed
  get selectedDialect() {
    return languageStore.findDialect(this.selectedDialectId);
  }

  @computed
  get selectedLevel() {
    return languageLevelStore.find(this.selectedLevelId);
  }


  @computed
  get canSelectDialect() {
    return !!this.selectedLanguageId;
  }

  @computed
  get canSelectQuestions() {
    return !!(this.selectedDialectId && this.selectedLevelId);
  }

  @computed
  get canSubmit() {
    return !!(this.selectedFocusOn && this.selectedLookingFor && this.selectedWhatToCover);
  }

  @computed
  get renderLevels() {
    return !!this.selectedDialectId;
  }

  @action.bound
  selectLanguage(id) {
    this.selectedLanguageId = id;
    this.selectedDialectId = null;
    this.selectedLevelId = null;
  }

  @action.bound
  selectDialect(id) {
    this.selectedDialectId = id;
    this.selectedLevelId = null;
    this.selectedLookingFor = null;
    this.selectedFocusOn = null;
    this.selectedWhatToCover = null;
    this.additionalComments = null;
  }

  @action.bound
  selectLevel(id) {
    this.selectedLevelId = id;
  }

  @action.bound
  stepUp() {
    this.currentStep += 1;
  }

  @action.bound
  stepDown() {
    this.currentStep -= 1;
  }

  @action.bound
  selectQuestionAnswer(question, answer) {
    this[question] = answer;
  }

  @action
  submitRequest = (shouldStepForward) => () => {
    const data = {
      languageDialect: this.selectedDialect.iri,
      student: userStore.studentIri,
      level: this.selectedLevel.iri,
      lookingFor: this.selectedLookingFor,
      focusOn:this.selectedFocusOn,
      whatToCover: this.selectedWhatToCover,
      additionalRequests: this.additionalComments
    };

    studentLanguageStore.add({...data,activeSessions:[],languageDialect:this.selectedDialect,enabledTypes:[],completedSessionHours:0,totalSessionHours:0,pendingSessionHours:0});

    this.loading = true;
    service.addLanguage(data)
      .then(resp => {
        if (resp.ok && resp.status === 201) {
          this.loading = true;
          if (shouldStepForward)
           { this.stepUp();
           }else{
            this.showSuccess()
          }
          this.loading = false;

        }
      })
      .catch(e => console.log(e));
  };

  @action.bound
  showSuccess(){
    routerStore.replace("/language/add/success");
    this.clear();
  }

  @action
  clear() {
    this.currentStep = 1;
    this.selectedLanguageId = null;
    this.selectedDialectId = null;
    this.selectedLevelId = null;
    this.selectedLookingFor = null;
    this.selectedFocusOn = null;
    this.selectedWhatToCover = null;
    this.additionalComments = null;
  }
}


const languageAdditorStore = new LanguageAdditorStore();

window.languageAdditorStore = languageAdditorStore;

export default languageAdditorStore;
