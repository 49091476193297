import React from "react";
import logo from "@assets/img_logo.png";
import Flex, {flexDirection} from "@components/FlexBox";
import {Logo, LogoText} from "./styled";
// React Component
import Burger from '@animated-burgers/burger-squeeze'
// don't forget the styles
import '@animated-burgers/burger-squeeze/dist/styles.css'
import {BurgerContainer} from "@components/TopNavbar/styled";
import {inject, observer} from "mobx-react";

export default inject("uiConfig")(observer(({uiConfig}) => (
    <Flex direction={flexDirection.row} pad={{horizontal: 20, vertical: 10}} height={80} withShadow onTop
          backgroundColor="white">
        <Logo src={logo}/>
        <Flex f={1} margin={{horizontal: 12}} full align="center">
            <LogoText>NaTakallam</LogoText>
        </Flex>
        {uiConfig.showBurgerMenu ? <BurgerContainer align="center" desktopHidden>
            <Burger isOpen={uiConfig.showMenu} onClick={uiConfig.switchNavBar}/>
        </BurgerContainer> : null}
    </Flex>
)));


