import {viewActionType as types} from "@constants/admin";

export default {
  "date": {
    title: "Date"
  },
  "startTime": {
    title: "Start Time"
  },
  "endTime":{
    title: "End Time"
  },
  "Student.fullName": {
    title: "Student",
    type: types.link,
    baseRoute: "students",
    routeKey: "Student.id"
  },
  "CP.fullName": {
    title: "CP",
    type: types.link,
    baseRoute: "cp",
    routeKey: "CP.id"
  },
  "code": {
    title: "Skype Code"
  },
  "status": {
    title: "Status"
  },

};
