import {computed, observable,action, reaction} from "mobx";
import authStore from "../domain/authStore";

class GeneralConfigStore {

  @observable
  showMenu = false;

  @computed
  get renderForCp(){
    return authStore.role === "cp"
  }

  @computed
  get renderForAdmin(){
    return authStore.role === "admin";
  }

  @computed
  get showBurgerMenu(){
    return authStore.isLoggedIn
  }

  @action
  switchNavBar=()=>{
    this.showMenu = !this.showMenu;
  }
}

const generalUiStore = new GeneralConfigStore();

const importRSuiteStyleReaction = reaction(
  ()=>generalUiStore.renderForAdmin,
  (value,thisReaction)=>{
    if(value) {
      import("rsuite/styles/less/index.less");
      console.log("Reaction",value);
      thisReaction.dispose();
    }
  }
);

const routeChangeMenuReaction = reaction(
  ()=>routerStore.location,
  (value,thisReaction)=>{
    generalUiStore.showMenu = false;
  }
);

export default generalUiStore;
