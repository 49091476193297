import {inject, observer} from "mobx-react";
import FlexBox from "@components/FlexBox";
import React from "react";
import DashboardBlock from "@components/DashboardBlock";
import AvailabilityCalendar from "@containers/AvailabilityCalendar";
import {flexDirection, flexProps} from "../../../../components/FlexBox";
import {StyledMapPlaceHolder} from "../../LanguageAdditorScreen/Steps/styled";
import StepContinueButton from "../../LanguageAdditorScreen/Steps/components/StepContinueButton";


const AvailabilityStepSelector = inject("languageAdditorStore","availabilitySelectionStore")(observer(({languageAdditorStore,availabilitySelectionStore}) => {
  const onClick = ()=>{
    availabilitySelectionStore.submitAvailabilities();
    languageAdditorStore.showSuccess();
  };
    return (
      <FlexBox full="horizontal" align={flexProps.center}
               justify={flexProps.center} direction={flexDirection.column}
               >
        <DashboardBlock.Header>
          What is your general availability? (Select all that applies)
        </DashboardBlock.Header>
        <FlexBox full="horizontal" direction="row-responsive" margin={{top: 50}}>
            <AvailabilityCalendar/>
        </FlexBox>
        <StepContinueButton submit disabled={false} onClick={onClick}
                            />
      </FlexBox>
    );
  })
);

export default AvailabilityStepSelector;