import api from "@config/api";
import {BaseStore} from "../BaseStore";
import BundleModel from "../../models/bundleModel";
import {computed} from "mobx";

const service = {
  load: ():Promise=>api.get("bundles"),
};

export class Model extends BundleModel{
  parentStore = store;

  @computed
  get sessionTypeUri(){
    if(this.sessionTypeId){
      return `api/session_types/${this.sessionTypeId}`;
    }
    return null;
  }

}

class BundleStore extends BaseStore{
  DataModel = Model;

  loader = service.load;

  serializeData(data, setLoadedOnDone = true) {
    super.serializeData(data, setLoadedOnDone);
  }
}

const store = new BundleStore();
window.adminbundle = store;

export default store;
