import {viewActionType as types} from "@constants/admin";


export default {
    "name":{
        title: "Name",
    },
    "code":{
        title: "Code"
    },
    "description":{
        title: "Description"
    },
};
