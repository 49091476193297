import {createGlobalStyle} from "styled-components";
import colors from "../constants/colors";

/**
 * Global style injector
 * @type {GlobalStyleComponent<{}, DefaultTheme>}
 */
export default createGlobalStyle`
  *{
    box-sizing: border-box;
  }
  @import url('https://fonts.googleapis.com/css?family=Raleway');
  @import url('https://fonts.googleapis.com/css?family=Hind');
  @import url('https://fonts.googleapis.com/css?family=Lato');
  
  body,#root {
    font-family: Lato,sans-serif;
    background-color: ${colors.whiteAccent};
    height:100vh;
    width:100vw;
  }
  
 
  
  `;

/**
 * Global style injector for storybook
 * @type {GlobalStyleComponent<{}, DefaultTheme>}
 */
export const StorybookGlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  }
  @import url('https://fonts.googleapis.com/css?family=Raleway');
  @import url('https://fonts.googleapis.com/css?family=Hind');
  @import url('https://fonts.googleapis.com/css?family=Lato');
  
  body,#root {
    font-family: Lato,sans-serif;
  }
  
  `;
