import {RouterStore,syncHistoryWithStore} from "mobx-react-router";
import createBrowserHistory from "history/createBrowserHistory";

/**
 * New instance of browser history
 */
export const browserHistory = createBrowserHistory();

/**
 * Initiated a router store
 * @type {MobxReactRouter.RouterStore}
 */
const routingStore = new RouterStore();

window.router = routingStore;

/**
 * React router store synced history
 * @type {MobxReactRouter.SynchronizedHistory}
 */
export const history = syncHistoryWithStore(browserHistory,routingStore);

window.routerStore = routingStore;

export default routingStore;
