import React from "react";
import {StyledButton, StyledIcon} from "./styled";

type ButtonProps = {
  plain?: boolean,
  text: string,
  onClick?: Function,
  disabled?: boolean,
  iconLeft?: string,
  iconRight?: string,
  withArrow?: boolean,
  round?: boolean,
  block?: boolean,
  color?: string,
  background?: string,
  withArrowLeft?: boolean,
  primary?: boolean,
  small?:boolean
}

const Button = ({plain, text, onClick, disabled, href, withArrow, iconLeft, iconRight, round, as, withArrowLeft, primary, ...props}: ButtonProps) => {

  return (
    <StyledButton
      as={as || "button"}
      round={round}
      plain={plain}
      disabled={disabled}
      href={disabled ? null : href}
      primary={primary}
      withArrowLeft={withArrowLeft}
      withArrow={withArrow}
      iconLeft={iconLeft}
      iconRight={iconRight}
      {...props}
      onClick={disabled ? () => {
      } : onClick}
    >
      {withArrowLeft ? <StyledIcon color={plain ? "black" : "white"} size={16} type="arrow-left"/> : null}
      {iconLeft ? <StyledIcon color={plain ? "black" : "white"} size={16} type={iconLeft} position="left"/> : null}
      <span>{text}</span>
      {iconRight ? <StyledIcon color={plain ? "black" : "white"} size={16} type={iconRight} position="right"/> : null}
      {withArrow ? <StyledIcon color={plain ? "black" : "white"} size={16} type="arrow-right"/> : null}
    </StyledButton>
  );
};

Button.defaultProps = {
  plain: false,
  onClick: null,
  disabled: false,
  iconLeft: null,
  iconRight: null,
  withArrow: false,
  round: false,
  block: false,
  color: null,
  background: null,
  withArrowLeft: false,
  primary: false,
  small:false
};

export default Button;
