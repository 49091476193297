import React, {ReactNodeArray} from "react";
import {Transition} from "react-transition-group";
import {StyledContainer, StyledInnerContainer} from "./styled";


type ModalProps = {
  children: ReactNodeArray,
  show?: boolean
}

const transitionStyle = {
  entering: {opacity: 1, zIndex: 9},
  entered: {opacity: 1,zIndex: 9},
  exiting: {opacity: 0, zIndex: 9},
  exited: {opacity: 0, zIndex: -1}
};

const Modal = (props: ModalProps) => {
  const {show} = props;
  return (
    <Transition in={show}>
      {state =>
        (<StyledContainer style={{...transitionStyle[state]}}>
          <StyledInnerContainer {...props}/>
        </StyledContainer>)
      }
    </Transition>
  );
};


Modal.defaultProps = {
  show: false
};

export default Modal;