import React, {ReactComponentElement, ReactNodeArray} from "react";
import styled from "styled-components";
import colors from "@constants/colors";
import {observer, inject} from "mobx-react";
import TopNavbar from "@components/TopNavbar";
import {NavLink} from "react-router-dom";
import FlexBox, {flexDirection, flexProps} from "../../components/FlexBox";
import Input, {ObservedInput} from "../../components/Input";
import Button from "../../components/Button";
import type {FlexType} from "../../components/FlexBox";
import {AuthStore} from "../../stores/domain/authStore";
import DashboardBlock from "../../components/DashboardBlock";

@inject("authStore")
@observer
export default class LoginScreen extends React.Component {



  _onSubmit = e => {
    const {authStore}: { authStore: AuthStore } = this.props;
    e.preventDefault();
    authStore.login(0);
  };

  renderLogin = () => {
    const {authStore}: { authStore: AuthStore } = this.props;
    return (
      <FlexBox
        direction={flexDirection.column}
        align={flexProps.center}
        full="both"
        pad={{mobile:{horizontal:10}}}
      >
        <DashboardBlock title="Login" direction="column" justify="center">
          <FlexBox as="form" full="both" onSubmit={this._onSubmit} pad={{vertical: 100, horizontal: 40,mobile:{horizontal:"0",vertical:20}}} direction="column" loading={authStore.loading}>
            <LoginForm/>
            <FlexBox align={flexProps.center} justify={flexProps.center} direction={flexDirection.column}
                     pad={{top: 28, bottom: 80,mobile:{bottom:"0"}}}>
              <Button withArrow text="Login"/>
              <AccountFooter>{"Forgot your password? "}<NavLink to="forgot_password">Reset Password</NavLink>
                <br/>
                {"Don't have an account? "}<NavLink to="signup">Sign
                up</NavLink></AccountFooter>
              </FlexBox>
            </FlexBox>
        </DashboardBlock>
      </FlexBox>
    );
  };

  render() {
    return (
      <FlexBox full="both" direction={flexDirection.column}>
        <TopNavbar/>
        {this.renderLogin()}
      </FlexBox>
    );
  }

}

const LoginForm = inject("authStore")(observer(({authStore}: { authStore: AuthStore }) => (
  <>
    <ObservedInput fill type="email" error={authStore.emailPasswordError}
                   onChange={t => authStore.setEmail(t.nativeEvent.srcElement.value)} label="E-mail"
                   containerProps={{margin: {bottom: 37}}}/>
    <ObservedInput fill type="password" error={authStore.emailPasswordError}
                   onChange={t => authStore.setPassword(t.nativeEvent.srcElement.value)} label="Password"
                   containerProps={{margin: {bottom: 80}}}/>
  </>
)));

type FlipCardType = {
  children?: ReactNodeArray,
  flexProps?: FlexType,
  flipped?: boolean
}

const FlipCard = ({children, flexProps, flipped}: FlipCardType): ReactComponentElement => (
  <CardContainer flipped={flipped} {...flexProps}>
    {children}
  </CardContainer>
);

FlipCard.defaultProps = {
  children: null,
  flexProps: {},
  flipped: false
};

const CardContainer = styled(FlexBox)`
  perspective: 1000px;
  background-color: white;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: 0.5s;
    position: relative;
    ${({flipped}) => flipped ? `transform: rotateX(180deg);` : null}
  
  & > * {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top:0;
    left:0;
  }
  & >:last-child{
    transform: rotateX(180deg);
  }
`;

const Title = styled.h2`
  margin-bottom: 0;
`;

const AccountFooter = styled.p`
  margin-top: 23px;
  font-size: 15px;
  line-height:20px;
  text-align: center;
  color: #4A4A4A;
`;