import {action, computed, observable} from "mobx";
import moment from "moment";
import _ from "lodash";
import sessionStore, {SessionModel} from "@stores/domain/sessionStore";
import routerStore from "@stores/app/routerStore";
import api from "@config/api";
import studentLanguageStore from "../domain/student/studentLanguageStore";
import balanceStore from "../domain/student/balanceStore";
import {currentWeek} from "../../utils/date";


const service = {
    bookSession: (data) => api.post("sessions/book", data)
};


class SessionBookingStore {
    @observable
    selectedLanguageStore = null;

    @observable
    selectedWeek = 0;
    @observable
    popModal = false;
    @observable
    showConfirmationModal = false;
    @observable
    renderSuccess = false;
    @observable
    selectedTimeBlocks = [];

    @computed
    get languageStore() {
        return studentLanguageStore.data.length > 0 ? studentLanguageStore.data[0] : null;
    }

    @computed
    get currentWeek() {
        return currentWeek(this.selectedWeek);
    }

    @computed
    get shouldRenderSuccess() {
        return this.renderSuccess && this.selectedLanguageStore;
    }

    @computed
    get showModal() {
        return this.studentLanguage && this.popModal;
    }

    @computed
    get studentLanguage() {
        return this.selectedLanguageStore && studentLanguageStore.find(this.selectedLanguageStore);
    }

    @computed
    get currentDateForCalendar() {
        return moment().format("MMMM YYYY").toUpperCase();
    }

    @computed
    get isCreditExpired() {
        const expiryData = moment(balanceStore.creditExpiry);
        const now = moment();
        return expiryData.isBefore(now);
    }

    @computed
    get canBook() {
        return this.selectedTimeBlocks.length > 0;
    }

    @computed
    get cp() {
        return this.studentLanguage.cp;
    }

    @computed
    get color() {
        return this.studentLanguage.color;
    }

    @computed
    get shouldRenderBookingCalendar() {
        return !!this.selectedLanguageStore;
    }

    @computed
    get maximumNumberOfBlocks() {
        let count = 6;

        this.availabilities.forEach(d => {
            if (d.data.length > count) {
                count = d.data.length;
            }
        });

        return count;

    }

    @computed
    get selectedDuration() {
        return _.sumBy(this.selectedTimeBlocks, () => 30);
    }

    @computed
    get defaultType() {
        return this.studentLanguage && this.studentLanguage.defaultType;
    }

    @computed
    get availabilities() {
        const data = [];
        this.currentWeek.forEach((d, index) => {
            const found = this.cp.availabilities.data.find(f => (f.name === d.name && f.date === d.fullDate));
            if (index === 0) {
                if (found) {
                    const f = found.data.filter(l => (~~l.startMoment.format("H") > (~~moment().format("H") + 2)) || (l.startMoment.format("D M") !== moment().format("D M")));
                    data.push({...d, data: f});
                } else {
                    data.push({...d, data: []});
                }
            } else
                data.push({...d, data: (found && found.data) || []});
        });

        return data;
    }

    @computed
    get sessionStartTime() {
        const t = this.selectedTimeBlocks.sort((a, b) => a.start > b.start);
        return t[0].start;
    }

    @computed
    get sessionTimeBlocks() {
        return this.selectedTimeBlocks.map(s => ({scheduledAt: s.start, duration: 30}));
    }

    @computed
    get sessionsCost() {
        return this.defaultType ? (this.defaultType.cost * (this.selectedDuration / 30)) : 0;
    }

    @computed
    get haveEnoughCredit() {
        return this.sessionsCost <= balanceStore.creditBalance;
    }

    @computed
    get confirmationModalText() {
        if (!this.defaultType) {
            return `Please wait till admins finish setting up your request`;
        }

        if (this.haveEnoughCredit) {
          if (this.isCreditExpired)
            return "Your credit have expired";
            return `These sessions will cost you ${this.sessionsCost} credits`;
        }

        return `You don't have enough credit to book these sessions, it will cost you ${this.sessionsCost}, you still need ${this.sessionsCost - balanceStore.creditBalance}`;
    }

    @computed
    get confirmationModalQuestion() {
      console.log(this.defaultType);
        if (!this.defaultType) {
            return `Please wait till admins finish, if it takes too long contact them in order to process your request`;
        }
        if (this.haveEnoughCredit) {
          if (this.isCreditExpired)
            return "Your credit have expired, you need to purchase some more to extend it's expiry.";
            return `Please confirm your order`;
        }

        return `Please buy more credit in order to proceed`;
    }

    @action
    nextWeek = () => {
        if (this.selectedWeek < 8)
            this.selectedWeek++;
    };

    @action
    prevWeek = () => {
        if (this.selectedWeek > 0)
            this.selectedWeek--;
    };

    @action.bound
    switchModal() {
        if (this.studentLanguage.enabledTypes.length > 1) {
            this.popModal = !this.popModal;
        }
    }

    @action.bound
    switchConfirmationModal() {
        this.showConfirmationModal = !this.showConfirmationModal;
    }

    @action
    selectTimeBlock = (timeBlock) => {
        const l = this.selectedTimeBlocks.find(k => k === timeBlock);
        if (l)
            _.remove(this.selectedTimeBlocks, l);
        else
            this.selectedTimeBlocks.push(timeBlock);
    };

    @action
    isActive(timeBlock) {
        return !!this.selectedTimeBlocks.find(k => k === timeBlock);
    };

    @action
    selectLanguage = id => {
        this.selectedLanguageStore = id;
        this.selectedTimeBlocks = [];
    };

    @action
    toggleLanguage = (id) => {
        if (this.selectedLanguageStore && id === this.selectedLanguageStore) {

            this.selectedLanguageStore = null;
        } else {
            this.selectedLanguageStore = id;
        }

        this.selectedTimeBlocks = [];
    };

    @action.bound
    selectLanguageByCp = (id) => {
        const language = studentLanguageStore.data.find(d => d.cpId === id);
        if (language)
            this.selectLanguage(language.id);
    };

    /**
     * TODO: Add response handler
     */
    @action.bound
    bookSelectedSessions() {
        if (this.haveEnoughCredit) {
            const data = {
                studentLanguage: this.selectedLanguageStore,
                type: this.defaultType.id,
                timeBlocks: this.sessionTimeBlocks
            };
            this.sessionTimeBlocks.map(t => {
                const session = new SessionModel({
                    studentLanguageId: this.selectedLanguageStore,
                    duration: t.duration,
                    isCompleted: false,
                    scheduledAt: t.scheduledAt
                });
                this.selectedTimeBlocks.forEach(timeBlock => {
                    timeBlock.isAvailable = false;
                });
                this.studentLanguage.addPendingSessionHours(t.duration);
                this.studentLanguage.addCreditUsed(this.defaultType.cost);
                balanceStore.creditBalance -= this.defaultType.cost;

                sessionStore.add(session);
                return null;
            });


            service.bookSession(data)
                .then(resp => {
                    if (resp.ok) {
                        const {data} = resp;
                        data.map(s => {
                            const subSession = sessionStore.activeSessions.find(session => session.scheduledAt === s.scheduledAt && session.studentLanguageId === s.studentLanguage.id);
                            subSession.serializeData(s);
                            return null;
                        });
                    }
                });
            this.switchConfirmationModal();
            this.renderSuccess = true;
        } else {
            alert("Not enough credit");
        }
    }

    @action.bound
    goToPurchases() {
        this.switchConfirmationModal();
        routerStore.push("/purchase");
    }

    @action.bound
    switchSuccess() {
        this.renderSuccess = false;
        this.selectedTimeBlocks = [];
    }

    @action.bound
    proceedToDashboard() {
        this.switchSuccess();
        this.selectedLanguageStore = null;
        routerStore.push("/");
    }


}

const selectorStore = new SessionBookingStore();
window.selectorStore = selectorStore;

export default selectorStore;
