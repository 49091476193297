import React from "react";
import styled from "styled-components";


export const StyledContainer = styled.span`
    width: 16px;
    height: 16px;
    border-radius: ${24/2}px;
    background-color: #555555;
    color: white !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    margin-left: 4px;
`;

export const StyledAnchor = styled.a`
    color: white;
    text-decoration: none;
`;
