import React from "react";
import Screen from "@components/Screen";
import {inject, observer} from "mobx-react";
import languageAdditorStore from "@stores/ui/languageAdditorStore";
import Loader from "react-loading";
import colors from "@constants/colors";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import Stepper from "../../../components/Stepper";
import DashboardBlock from "../../../components/DashboardBlock";
import LanguageSelectStep from "./Steps/LanguageSelectStep";
import DialectSelectStep from "./Steps/DialectSelectStep";
import {StyledHeader} from "./Steps/styled";
import QuestionaireSelectStep from "./Steps/QuestionaireSelectStep";


@inject("languageAdditorStore")
@observer
class LanguageAdditorScreen extends React.Component {
  steps = [
    <LanguageSelectStep/>,
    <DialectSelectStep/>,
    <QuestionaireSelectStep/>
  ];

  render(): React.ReactNode {
    const {languageAdditorStore} = this.props;
    const {currentStep,loading} = languageAdditorStore;
    return (
      <Screen>
        <FlexBox direction={flexDirection.row} justify={flexProps.center} f={1}>
          <Screen.Column last full="horizontal" solo>
            {/*<StyledHeader>*/}
            {/*<span>Hi! Please fill out short questionnaire so we can match you with a conversation*/}
            {/*partner!</span></StyledHeader>*/}
            <FlexBox direction={flexDirection.row} justify={flexProps.center} margin={{top: 40}}>
              <Stepper length={this.steps.length} current={currentStep}/>
            </FlexBox>
            <DashboardBlock title=" ">
              {loading?<SubmitionStep/>:this.steps[currentStep - 1]}
            </DashboardBlock>
          </Screen.Column>
        </FlexBox>
      </Screen>
    );
  }
}

const SubmitionStep = ()=>(
  <FlexBox full="horizontal" align={flexProps.center}
           justify={flexProps.center} direction={flexDirection.column}
           pad={{horizontal: 80, mobile: {horizontal: "0"}}}>
    <DashboardBlock.Header>
      Please wait we are submitting your request.
    </DashboardBlock.Header>
    <FlexBox pad={{vertical:100}}>
      <Loader color={colors.secondary} type="bars"/>
    </FlexBox>
  </FlexBox>
);


export default LanguageAdditorScreen;
