import {action, computed, observable} from "mobx";
import _ from "lodash";
import {generateChartData} from "@utils/dataVisualization";
import colors from "@constants/colors";
import moment from "moment";
import {BaseModel} from "../BaseStore";
import userStore from "../userStore";
import studentLanguageStore from "./studentLanguageStore";

export class BalanceStore extends BaseModel {

  @observable
  creditBalance: number = 0;

  @observable
  creditExpiry: string = "";

  @computed
  get remainingHours(): number {
    const creditPerHour = 25;
    return Math.floor(this.creditBalance / creditPerHour);
  }

  @computed
  get completedHours(): number {
    return userStore.sessionHours;
  }

  @computed
  get conversationHours() {
    return Math.floor(this.creditBalance / 3);
  }

  @computed
  get curriculumHours() {
    return  Math.floor(this.creditBalance / 5);
  }

  @computed
  get totalHours(): number {
    return (this.remainingHours + studentLanguageStore.completedSessionHours + studentLanguageStore.pendingSessionHours);
  }

  @computed
  get expiryFormat(){
    return moment(this.creditExpiry).format("MMMM DD, YYYY")
  }

  @computed
  get creditExpiryDate(): Date {
    return this.creditExpiry
  }

  @computed
  get totalCreditBalance() {
    const {data} = studentLanguageStore;
    const totalUsed = _.sumBy(data, d => d.creditUsed);

    return totalUsed + this.creditBalance;
  }

  @computed
  get dataForGraph() {
    const {data} = studentLanguageStore;
    const toReturn = [];
    data.forEach(d => {
      const entry = {
        id: d.id,
        angle: d.creditUsed,
        label: `${d.language} | ${d.dialect}`,
        color: d.color
      };

      toReturn.push(entry);
    });

    return toReturn;
  }

  @computed
  get dataForRadialGraph() {
    return generateChartData({total: this.totalCreditBalance, values: this.dataForGraph});
  }

  @computed
  get dataForGraphLegends() {
    return [{id: 0, label: "Remaining Credits", color: colors.grey}, ...this.dataForGraph];
  }

  @action
  add = value => {
    this.creditBalance += value;
  };

  @action
  sub = value => {
    this.creditBalance -= value;
  };


}

const store = new BalanceStore();
window.balanceStore = store;
export default store;
