import React from "react";
import {inject, observer} from "mobx-react";
import moment from "moment";
import tzmoment from "moment-timezone";
import AccountInformationBlock from "@containers/dashboardBlocks/AccountInformationBlock";
import Screen from "@components/Screen";
import {UserStore} from "@stores/domain/userStore";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import TimeBlock from "@containers/dashboardBlocks/TimeBlock";

import UserInterestsBlock from "@containers/dashboardBlocks/UserInterestsBlock";
import BioBlock from "@containers/dashboardBlocks/BioBlock";
import {observable} from "mobx";
import {uploadFile} from "@config/s3";
import ImageUploader from "@components/ImageUploader";

type CpProfileScreenProps = {
    userStore: UserStore
}

@inject("userStore")
@observer
class CpProfileScreen extends React.Component<CpProfileScreenProps> {
    @observable
    controller = {file: ""};

    onImageChange = () => {
        const {userStore} = this.props;
        uploadFile(this.controller.file, "profile", userStore.email + (new Date().getTime())).then(resp =>
            userStore.updateImage(resp.key.split("/")[1])
        )
    };

    render(): React.ReactNode {
        const {userStore} = this.props;
        const {profilePicture} = userStore;

        const timeZoneType = tzmoment.tz.guess();
        const timezone = moment().format("Z");
        return (
            <Screen>
                <Screen.Column basis="1/3" direction={flexDirection.column} margin={{right: 20, mobile: {right: "0"}}}>
                    <FlexBox align={flexProps.center} pad={{vertical: 26}} justify={flexProps.center}>
                        <ImageUploader onFileChange={this.onImageChange} profileSection={true}
                                       controller={this.controller} defaultImage={profilePicture}
                                       round size="big"/>
                    </FlexBox>
                    <TimeBlock timeZoneType={timeZoneType} timezone={timezone}/>
                </Screen.Column>
                <Screen.Column basis="2/3"><AccountInformationBlock userStore={userStore}/>
                    <UserInterestsBlock userStore={userStore}/>
                    <BioBlock userStore={userStore}/>
                </Screen.Column>
            </Screen>
        );
    }
}

export default CpProfileScreen;
