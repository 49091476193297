import {action, observable, set, reaction, computed, toJS, isObservable, isObservableObject} from "mobx";
import _ from "lodash";
import authStore from "./authStore";
import {toast} from "../../components/Toast";


/**
 * Todo: Find a way to find if string is date
 */
export class BaseModel {

  @observable
  loading: boolean = false;

  @observable
  loaded: boolean = false;

  @observable
  id: number;

  loader: Promise;

  updater: Promise;

  deleter: Promise;

  parentStore: BaseStore;

  endPoint: string;

  @computed
  get iri() {
    return `/api/${this.endPoint}/${this.id}`;
  }

  constructor(props,parent) {
    if(parent)
      this.parentStore = parent;
    this.serializeData(props);
  }


  @action.bound
  load(): void {
    this.loading = true;
    this.loader()
      .then(resp => {
          if (resp.ok) {
            this.serializeData(resp.data);
          }
        }
      );
  }

  @action
  updatePromise = (data) => new Promise((resolve, reject) => {
    this.updater(data)
      .then(resp => resolve(resp))
      .catch(e => reject(e));
  });

  @action
  update = (data) => {

    console.log(data);
    // this.updater()
    //   .then(resp => {
    //     if (resp.ok) {
    //       this.updateLocally(data);
    //     } else
    //       throw Error(resp.error);
    //   })
    //   .catch();
  };

  @action
  delete = () => {
    this.parentStore.remove(this);
    this.deleter(this.id);
  };

  @action
  updateLocally = (data) => {

  };

  static get tableSchema() {
    return {};
  }

  @computed
  get schema() {
    return toJS(this);
  }

  /**
   * TransformJsonObjects to object observables
   * @param data
   * @param setLoadedOnDone
   */
  @action.bound
  serializeData(data, setLoadedOnDone = true) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (typeof data[key] !== "object" || data[key] === null)
          set(this, key, data[key]);
      }
    }
    if (!this.loaded && setLoadedOnDone)
      this.loaded = true;

  }

}


export class BaseStore {

  @observable loaded = false;

  @observable loading = false;

  @observable data: Array<BaseModel> = [];

  @observable filterValue = "";

  @observable sortParam = "";

  @observable sortType = "asc";

  shouldClearOnLogout = true;

  @computed
  get isEmpty() {
    return this.data.length < 1;
  }

  @computed
  get processedData() {
    if (this.sortParam) {
      const sortedData = _.sortBy(this.data, d => d[this.sortParam]);
      return this.sortType === "desc" ?
        sortedData.reverse() : sortedData;
    }
    return this.data;
  }

  @action
  sortBy = (param: string, type: string = "asc") => {
    this.sortParam = param;
    this.sortType = type;
  };

  loader: Promise;

  DataModel: BaseModel;


  @action.bound
  load(){
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.loader()
        .then(resp => {
          this.loading = false;
          if (resp.ok) {
            this.loaded = true;
            const {data} = resp;
            this.consume(data);
            resolve(resp);
          } else if (resp.problem === "CLIENT_ERROR") {
            toast.error("Something wrong happened, Please try again");
            reject(resp);
          } else {
            throw Error(resp.error);
          }
        }).catch(e => {
        this.loaded = false;
        this.loading = false;
        reject(e);
      });
    });
  }

  @action.bound
  init(data: ?Object) {
    if (typeof data === "undefined") {
      this.load();
    } else {
      this.loaded = true;
      if (data) {
        console.log(data);
        this.consume(data);
      }
    }
  }


  @action
  add(ob: Object) {
    this.data.push(new this.DataModel(ob));
  }

  //
  // @action
  // find(ob: BaseModel | number): ?BaseModel {
  //   const o = this.data.find(d => d.id === ob || d.id === ob.id);
  //   return o;
  // }

  @computed
  get find(): ?BaseModel {
    return (ob: BaseModel | number) => {
      const o = this.data.find(d => d.id === ob || d.id === ob.id);
      return o;
    };
  }

  @action
  findOrAdd(ob: Object | number) {
    const o = this.data.find(d => d.id === ob || d.id === ob.id);
    if (!o) {
      this.add(ob);
    }

    return o || this.data.find(d => d.id === ob.id);
  }


  @action.bound
  remove(model: BaseModel) {
    this.data = this.data.filter(s => s !== model && s.id !== model.id);
    // delete this.data.find(d=>d === model || d.id === model.id);
  }

  /**
   * Inject data arrays as a DataModel store
   * @param data
   */
  @action.bound
  consume(data: [Object]) {
    data.map(d => this.data.push(new this.DataModel(d)));
    this.react();
  };

  /**
   * TODO: Needs Modifications for Static Stores
   */
  react = () => {
    if (this.shouldClearOnLogout) {
      reaction(() => authStore.isLoggedIn, (value, mReaction) => {
        if (value === false) {
          this.clear();
          mReaction.dispose();
        }
      });
    }
  };

  /**
   * TransformJsonObjects to object observables
   * @param data
   * @param setLoadedOnDone
   */
  @action.bound
  serializeData(data, setLoadedOnDone = true) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (typeof data[key] !== "object")
          set(this, key, data[key]);
      }
    }

    if (this.loaded === false && setLoadedOnDone)
      this.loaded = true;
  }

  @computed
  get scheme() {
    return toJS(this);
  }

  @action
  clear = () => {
    this.data = [];
  };
}

class Test extends BaseModel {
  parentStore = testStore;

  constructor(props) {
    super();
    this.props = props;
  }
}

class TestStore extends BaseStore {
  constructor() {
    super();
    this.data = [new Test("test1"), new Test("test2"), new Test("test3")];
  }
}

const testStore = new TestStore();
window.testStore = testStore;
