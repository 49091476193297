import api from "@config/api";
import {action, computed, observable} from "mobx";
import _ from "lodash";
import studentLanguageStore from "@stores/domain/admin/studentLanguage";
import {BaseStore} from "../BaseStore";
import SessionModel from "../../models/sessionModel";
import cp from "./cp";
import student from "./student";

const service = {
    load: (): Promise => api.get("sessions"),
    update: (id) => (data): Promise=>api.put(`sessions/${id}`,data),
    add: (data): Promise => api.post("sessions",data)
};

export class Model extends SessionModel {
    parentStore = store;

    updater = service.update(this.id);

    adder = service.add;

    @observable
    student;

    @observable
    cp;

    @observable
    studentLanguageSpecialId;

    @computed
    get studentLanguage() {
        return null;
    }

    @computed
    get CP() {
        // const cpId = ~~_.trimStart(this.cp, "/api/conversation_partners/");
        // return cp.find(cpId);
        return this.StudentLanguage? this.StudentLanguage.cp : null;
    }

    @computed
    get Student() {
        const studentId = ~~_.trimStart(this.student, "/api/students/");
        return student.find(studentId);
    }

    @computed
    get StudentLanguage() {
        if (this.studentLanguageSpecialId)
            return studentLanguageStore.find(this.studentLanguageSpecialId);
        return null;
    }

    @action.bound
    setCP(id) {
        this.cp = `/api/conversation_partners/${id}`;
    }

    @action.bound
    setStudent(id) {
        this.student = `/api/students/${id}`;
    }

    @action.bound
    setLanguage(id) {
        this.studentLanguageSpecialId = id;
    }

    serializeData(data) {
        super.serializeData(data);
    }

    updateSchema: * =[
        "cp",
        "student"
    ]
}

class SessionStore extends BaseStore {
    DataModel = Model;

    loader = service.load;

    @computed
    get processedData() {
        if(this.sortParam === "date"){
            const sortedData = _.sortBy(this.data, d => d['scheduledAt']);
            return this.sortType === "desc" ?
                sortedData.reverse() : sortedData;
        }else
        if (this.sortParam) {
            const sortedData = _.sortBy(this.data, d => d[this.sortParam]);
            return this.sortType === "desc" ?
                sortedData.reverse() : sortedData;
        }
        return this.data;
    }
}

const store = new SessionStore();
window.adminsession = store;

export default store;
