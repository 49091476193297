import React from "react";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import BookingCalendar from "@components/BookingCalendar";
import {inject, observer} from "mobx-react";
import CpSelector from "@components/CpSelector";
import Icon from "@oovui/react-feather-icons";
import SessionCalendar from "@containers/SessionCalendar";
import {DashboardHeader, MultiColumnBlock} from "../../../components/DashboardBlock";
import Button from "../../../components/Button";
import {SelectionCalendar} from "../../../components/SelectionCalendar";


const SessionBookingBlock = inject("sessionBookingStore")(observer(({sessionBookingStore}) => {
  return (
    <MultiColumnBlock direction="column" title="Calendar - Select a date and time to book your sessions">
      <MultiColumnBlock.Block pColumn direction="row-responsive" align="center">
        {/* <DashboardHeader>View Cp Availability:</DashboardHeader> */}
        <CpSelectorSubBlock/>
      </MultiColumnBlock.Block>
      <CalendarSwitcherBlock store={sessionBookingStore}/>
    </MultiColumnBlock>
  );
}));

const CalendarSwitcherBlock = observer(({store}) => store.shouldRenderSuccess ?
  <SuccessBlock/> : store.shouldRenderBookingCalendar ? (
    <>
      <MultiColumnBlock.Block last pColumn direction="column">
        <BookingCalendar/>
      </MultiColumnBlock.Block>
      <ButtonSubBlock store={store}/>
    </>
  ) : <MultiColumnBlock.Block last pColumn direction="column">
    <SessionCalendar/>
  </MultiColumnBlock.Block>);

const ButtonSubBlock = observer(({store}) => {
  const {canBook} = store;
  return (
    <MultiColumnBlock.Block plain noPad>
      <FlexBox desktopHidden>
        <Button text="Confirm" withArrow disabled={!canBook} onClick={store.switchConfirmationModal}/>
      </FlexBox>
    </MultiColumnBlock.Block>);
});

const CpSelectorSubBlock = inject("studentLanguageStore")(observer(({studentLanguageStore}) => {
  return (
    <FlexBox direction="row-responsive"
             f={1}
             align="center"
             justify="center"
             wrap="wrap"
    >
      {studentLanguageStore.data.map((d,index) => <CpSelector index={index} key={d.id} dialect={d}/>)}
    </FlexBox>
  );
}));

const SuccessBlock = inject("sessionBookingStore")(observer(({sessionBookingStore}) => {
  const {selectedTimeBlocks, switchSuccess, proceedToDashboard} = sessionBookingStore;
  return (
    <MultiColumnBlock.Block last pColumn direction="column">
      <FlexBox direction={flexDirection.column} pad={{vertical: 10}}>
        <FlexBox direction={flexDirection.rowResponsive} pad={{horizontal: 50, bottom: 20, mobile: {horizontal: "0"}}}>
          <Icon type="check" size={20}/>
          <FlexBox f={1} margin={{left: 10, mobile: {left: 0}}} direction={flexDirection.column}>
            {selectedTimeBlocks.map(p => <SessionMessage key={p.id} availability={p}/>)}
          </FlexBox>
        </FlexBox>
        <FlexBox direction={flexDirection.rowResponsive} justify={flexProps.center}>
          <Button onClick={switchSuccess} text="Return To Calendar" withArrow
                  margin={{right: 30, mobile: {right: "0", bottom: 10}}}/>
          <Button onClick={proceedToDashboard} text="Continue To Dashboard" withArrow/>
        </FlexBox>
      </FlexBox>
    </MultiColumnBlock.Block>);
}));

const SessionMessage = ({availability}) => {
  const date = availability.startMoment.format("MMMM Do, YYYY");
  const time = availability.startMoment.format("h:mmA");
  return (
    <FlexBox margin={{bottom: 20}} direction={flexDirection.column}>
      <span>{"Your session on "}<strong>{date}</strong>{", at "}<strong>{time}</strong></span>
      {`has been scheduled successfully!`}
    </FlexBox>
  );
};

export const ConfirmationButton = inject("sessionBookingStore")(observer(({sessionBookingStore}) => {
  const {switchConfirmationModal, canBook} = sessionBookingStore;
  return (
    <FlexBox margin={{bottom: 20, top: 20}} mobileHidden>
      <Button block text="Confirm" disabled={!canBook} onClick={switchConfirmationModal} withArrow/>
    </FlexBox>
  );
}));

export default SessionBookingBlock;
