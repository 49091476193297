import React from "react";
import FlexBox from "@components/FlexBox";
import {observer, inject} from "mobx-react";
import {computed} from "mobx";
import {SIZE_MAP} from "../ProfileImage";
import {DashboardHeader} from "../DashboardBlock";
import {BorderedProfileImage, SelectorContainer} from "./styled";

@inject("sessionBookingStore")
@observer
class CpSelector extends React.Component {
  componentDidMount(): void {
    const {dialect, sessionBookingStore,index} = this.props;
    if(index==0) sessionBookingStore.selectLanguage(dialect.id);
  }

  @computed
  get grayedOut() {
    const {sessionBookingStore, dialect} = this.props;
    return sessionBookingStore.shouldRenderBookingCalendar && (sessionBookingStore.selectedLanguageStore !== dialect.id);
  }

  render() {
    const {dialect, sessionBookingStore,index} = this.props;
    // select the first CP

    return (
      <SelectorContainer onClick={() => sessionBookingStore.selectLanguage(dialect.id)}>
        <BorderedProfileImage
          grayedOut={this.grayedOut}
          color={dialect.color}
          margin={{right: 10}}
          src={dialect.cp.profilePicture}
          round size={SIZE_MAP.small}/>
        <FlexBox direction="column" justify="center">
          <DashboardHeader subHeader marginBottom={false}>
            {
              dialect.cp.name
            }
          </DashboardHeader>
          <DashboardHeader subHeader italic marginBottom={false}>{`Your ${dialect.dialect} CP`}</DashboardHeader>
        </FlexBox>
      </SelectorContainer>
    );
  }
}

export default CpSelector;
