import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import FormInput, {ObservedInput} from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import Button from "@components/Button";
import {cloneStore} from "@utils/mobx";
import Input from "@containers/Admin/Input";
import {Model} from "@stores/domain/admin/bundle";


@inject("adminBundleStore","sessionTypeStore")
@observer
class Edit extends React.Component {
    @observable
    _entity = null;

    componentDidMount(): void {
        const {adminBundleStore, match} = this.props;
        const id = ~~match.params.id;
        const entity = adminBundleStore.data.find(c => c.id === id);

        if (entity) {
            this._entity = cloneStore(entity, true);
        }
    }

    @action
    _submit = e => {
        e.preventDefault();
        this._entity.serializeAndUpdate({sessionType:"sessionTypeUri"}).then(this._goBack);
    };

    _goBackPrevention = e => {
        e.preventDefault();
        this._goBack()
    };

    _goBack = () => {
        const {history} = this.props;
        history.goBack();
    };

    render() {
        const {sessionTypeStore} = this.props;
        window.test = this;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock as="form" onSubmit={this._submit}
                                    title={`Editing "${this._entity.original.name}"`}>
                        <FlexBox direction="row-responsive" loading={this._entity.loading}>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                                <Input type="text" fill label="Name" validationRules="required|min:2" propName="name"
                                       store={this._entity}/>
                                <Input type="number" fill label="Credit Amount" validationRules="required"
                                       propName="creditAmount"
                                       store={this._entity}/>
                                <Input type="number" fill label="Duration" validationRules="required"
                                       propName="duration"
                                       placeholder="Duration In Minutes"
                                       store={this._entity}/>
                                <Input type="number" fill label="Price" validationRules="required" propName="price"
                                       store={this._entity}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1}>
                                <ObservedInput type="select" fill label="Session Type" options={sessionTypeStore.selectMap}
                                               value={this._entity.sessionTypeId}
                                               onChange={(l) => {
                                                   this._entity.sessionTypeId = ~~l.nativeEvent.target.value
                                               }}
                                />
                                <Input type="textarea" fill label="Description" validationRules="required"
                                       propName="description"
                                       store={this._entity} rows={4}/>
                                <Input type="checkbox" label="Active" propName="isActive" store={this._entity}
                                       withMargin={false}/>

                            </FlexBox>
                        </FlexBox>
                        <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                                 justify={flexProps.spaceBetween}>
                            <Button text="Cancel" onClick={this._goBackPrevention} withArrowLeft/>
                            <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );
        return (
            <div>loading</div>
        );
    }

}


export default Edit;
