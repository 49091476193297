import React from "react";
import {observer, inject} from "mobx-react";
import colors from "@constants/colors";
import Modal from "../../../components/Modal";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import {DashboardHeader} from "../../../components/DashboardBlock";
import {SessionCancelStore} from "../../../stores/ui/sessionCancelStore";
import Button from "../../../components/Button";


const SessionCancelModal = inject("sessionCancelStore")
(observer(({sessionCancelStore}: { sessionCancelStore: SessionCancelStore }) => (
    <Modal show={sessionCancelStore.showModal}>
        <FlexBox direction={flexDirection.column}>
            <DashboardHeader>{sessionCancelStore.modalMessage}</DashboardHeader>
            <DashboardHeader subHeader>Are you sure you want to cancel this session?</DashboardHeader>
            <FlexBox direction={flexDirection.row} justify={flexProps.spaceBetween} margin={{top:20}}>
                <Button text="Cancel Session" background={colors.primary} iconLeft="check" onClick={sessionCancelStore.confirm}/>
                <Button text="Cancel" iconLeft="x" onClick={sessionCancelStore.cancelConfirmation}/>
            </FlexBox>
        </FlexBox>
    </Modal>
)));


export default SessionCancelModal;
