import React, {Suspense} from "react";
import FlexBox from "@components/FlexBox";
import TopNavbar from "@components/TopNavbar";
import {observer} from "mobx-react";
import {Route, Switch} from "react-router-dom";
import DashboardMenu from "@containers/NavMenu";
import {mainRoutes} from "@config/routes";
import userStore from "@stores/domain/userStore";
import uiConfig from "@stores/ui/generalConfigStore";
import SessionCancelModal from "@containers/Modals/SessionCancelModal";
import SessionBookingConfirmationModal from "@containers/Modals/SessionBookingConfirmationModal";
import withLoader from "../../hoc/withLoader";
import NotFound from "../NotFound";
import FirstTimeReminderModal from "@containers/Modals/FirstTimeReminderModal";
import Intercom from "react-intercom";
import MobileNavMenu from "@containers/MobileNavMenu"

@withLoader(userStore, uiConfig)
@observer
export default class DashboardScreen extends React.Component {

  render() {
    return (
      <FlexBox overflow="hidden" direction="column" full="both">
        <TopNavbar/>
        <FlexBox direction="row" f={1}>
          <DashboardMenu menuRoutes={mainRoutes.get()}/>

          <Suspense fallback={() => null}>
            <Switch>
            {mainRoutes.get().map(r => !r.parent &&
              <Route key={r.path} path={r.path} exact={r.exact} component={r.component}/>)}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
          <Intercom appID="l57d9glj" user_id={userStore.id} email={userStore.email} name={userStore.fullName}/>
          <SessionCancelModal/>
          <SessionBookingConfirmationModal/>
          <FirstTimeReminderModal/>
          <MobileNavMenu menuRoutes={mainRoutes.get()}/>
        </FlexBox>
      </FlexBox>
    );
  }
}

