import {viewActionType as types} from "@constants/admin";

export default {
  firstName: {
    title: "First Name"
  },
  lastName: {
    title: "Last Name"
  },
  email: {
    title: "Email Address",
    type: types.email
  },
  country: {
    title: "Country of Residence",
    type: types.filter
  },
  age: {
    title: "Age"
  },
  skypeId: {
    title: "Skype"
  },
  profession: {
    title: "Field Of Work"
  },
  gender: {
    title: "Gender"
  },
  phoneNumber: {
    title: "Phone Number"
  },
  creditBalance: {
    title: "Credit Balance"
  },
  creditExpiresAt: {
    title: "Credit Expiry Date"
  },
  isFirstTime: {
    title: "First Time",
    type: types.boolean
  },
  hobbies: {
    title: "Hobbies",
    type: types.array
  },
  bio: {
    title: "Bio"
  }
};
