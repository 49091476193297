import React from "react";
import {inject, observer} from "mobx-react";
import {UserStore} from "../../../stores/domain/userStore";
import Screen from "../../../components/Screen";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import AccountInformationEditBlock from "../../../containers/dashboardBlocks/AccountInformationEditBlock";
import Button from "../../../components/Button";

type ProfileEditScreenProps = {
  userStore: UserStore
}

@inject("userStore")
@observer
class ProfileEditScreen extends React.Component<ProfileEditScreenProps> {

  render(): React.ReactNode {
    const {userStore} = this.props;
    return (
      <Screen>
        <Screen.Column basis="1/2" full="horizontal" >
          <AccountInformationEditBlock.Information/>
        </Screen.Column>
        <Screen.Column basis="1/2" full="horizontal" >
          <AccountInformationEditBlock.Hobbies/>
          <AccountInformationEditBlock.Bio/>
          <Button text="Submit" margin={{top:20}} onClick={userStore.updateProfile}/>
        </Screen.Column>
      </Screen>
    );
  }

}

export default ProfileEditScreen;
