import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexProps} from "@components/FlexBox";
import FormInput from "@components/Input";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Button from "@components/Button";
import {Model as StudentModel} from "@stores/domain/admin/student";
import Input from "@containers/Admin/Input";
import {cloneStore} from "@utils/mobx";
import {selectorCountries} from "@constants/countries";
import ImageUploader from "@components/ImageUploader";
import {uploadFile} from "@config/s3";
import {serverTimeFormat} from "@utils/date";

@inject("adminStudentStore")
@observer
class Add extends React.Component {

    @observable
    loading = false;

    @observable
    _entity = cloneStore(new StudentModel({hobbies: []}), true);

    @observable
    fileController = {file: ""};

    _submit = e => {
        e.preventDefault();
        const {history} = this.props;

        if (this.fileController.file.name) {
            uploadFile(this.fileController.file, "profile", this._entity.email+(new Date().getTime())).then(resp => {
                    this._entity.avatarFileName = resp.key.split("/")[1];
                    this._entity.serializeAndAdd().then(() => history.push(`${resp.data.id}`))
                }
            ).catch(error => console.log(error))
        } else
            this._entity.serializeAndAdd().then(resp => {
                history.push(`${resp.data.id}`);
            });
    };

    _goBack = e => {
        const {history} = this.props;
        e.preventDefault();
        history.goBack();
    };

    render() {
        window.test = this._entity;
        return (
            <Screen.Column last basis="full">
                <DashboardBlock as="form" onSubmit={this._submit} title="Add a new student">
                    <FlexBox direction="row-responsive" loading={this.loading}>
                        <FlexBox>
                            <ImageUploader controller={this.fileController} defaultImage={this._entity.profilePicture}/>
                        </FlexBox>
                        <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                            <Input type="text" fill label="First Name" validationRules="required|min:2"
                                   propName="firstName"
                                   store={this._entity}/>
                            <Input type="text" fill label="Last Name" validationRules="required|min:2"
                                   propName="lastName"
                                   store={this._entity}/>
                            <Input type="email" fill label="Email" validationRules="required|email" propName="email"
                                   store={this._entity}/>
                            <Input type="password" fill label="Password" validationRules="required|passwords"
                                   propName="password"
                                   store={this._entity}/>
                            <Input type="select" fill label="Country of Residence" propName="country"
                                   options={selectorCountries}
                                   store={this._entity}/>
                            <Input type="text" fill label="Skype Id" propName="skypeId" validationRules="min:5"
                                   store={this._entity}/>
                            <Input type="text" fill label="Field Of Work" propName="profession" validationRules="min:2"
                                   store={this._entity}/>
                            <Input type="text" fill label="Phone Number" propName="phone" validationRules="telephone"
                                   store={this._entity}/>
                            <FormInput label="Birthday" type="dateTimePicker" value={this._entity.birthdayMoment} onChange={this._entity.setBirthday}/>
                        </FlexBox>
                        <FlexBox direction="column" f={1}>
                            <Input type="number" label="Credit Balance" propName="creditBalance" store={this._entity}/>
                            <FormInput label="Credit Expiry"  type="dateTimePicker" value={this._entity.creditExpiryMoment} onChange={v=>{this._entity.creditExpiry = v.format(serverTimeFormat)}}/>
                            <Input type="textarea" label="Bio" propName="bio" store={this._entity} rows={4}/>
                            <FormInput.RadioGroup margin={{top: "0", bottom: 20}} title="Gender">
                                <Input type="radio" label="Male" value="male" propName="gender" store={this._entity}/>
                                <Input type="radio" label="Female" value="female" propName="gender"
                                       store={this._entity}/>
                            </FormInput.RadioGroup>
                            <Input type="checkbox" label="First Time" propName="isFirstTime" store={this._entity}
                                   withMargin={false}/>
                            <Input type="checkbox" label="Active" propName="isActive" store={this._entity} withMargin/>

                        </FlexBox>
                    </FlexBox>
                    <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                             justify={flexProps.spaceBetween}>
                        <Button text="Cancel" type="button" onClick={this._goBack} withArrowLeft/>
                        <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                    </FlexBox>
                </DashboardBlock>
            </Screen.Column>
        );
    }

}

export default Add;
