import {action, computed, observable} from "mobx";
import {currentWeek, generateHours} from "@utils/date";
import moment from "moment";

class SessionCalendar {
  @observable
  selectedWeek = 0;

  @computed
  get currentWeek() {
    return currentWeek(this.selectedWeek);
  }

  @computed
  get currentDateForCalendar(): string {
    return moment().format("MMMM YYYY").toUpperCase();
  }

  get allHours() {
    return generateHours(1, 24);
  }

  @action
  nextWeek = () => {
    if (this.selectedWeek < 8)
      this.selectedWeek++;
  };

  @action
  prevWeek = () => {
    if (this.selectedWeek > 0)
      this.selectedWeek--;
  };

}

const store = new SessionCalendar();

window.sessionCalendar = store;

export default store;
