import React from "react";
import styled, {css} from "styled-components";
import {edgeStyle} from "@utils/styles";
import type {EdgeType} from "@utils/styles";
import colors from "../../constants/colors";
import FlexBox from "../FlexBox";


export const SIZE_MAP = {
  small: "50px",
  medium: "100px",
  big: "250px",
};


const sizeStyle = css`
    width: ${props => SIZE_MAP[props.size] || props.size};
    height: ${props => SIZE_MAP[props.size] || props.size};
`;

const roundedStyle = css`
    border-radius: 50%;
`;

const StyledImage = styled(FlexBox)`
    background-color: ${colors.grey};
    border: none;
    ${props => props.size && sizeStyle}
    ${props => props.round && roundedStyle}
    ${props => props.margin && edgeStyle("margin", props.margin, props.marginMobile)}
`;


type ProfileImageType = {
  size?: $Values<typeof SIZE_MAP>,
  round?: boolean,
  src: string,
  alt?: string,
  margin?: EdgeType
}

const ProfileImage = (props: ProfileImageType) => {
  return (
    <StyledImage as="img" {...props}/>
  );
};

ProfileImage.defaultProps = {
  size: SIZE_MAP.medium,
  round: false,
  alt: "",
  margin: null
};


export default ProfileImage;
