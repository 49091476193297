import React from "react";
import Button from "../../../components/Button";
import {Modal} from "rsuite";
import 'rsuite/dist/styles/rsuite.min.css';
import {inject, observer} from "mobx-react";
import {UserStore} from "@stores/domain/userStore";


type ReminderScreenProps = {
    userStore: UserStore
}

class StudentReminderModal extends React.Component<ReminderScreenProps>  {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.close = this.close.bind(this);

    }
    close() {
        this.setState({ show: false });
    }

     render() {
         const {userStore} = this.props;
         if(userStore.availabilities.data.length<=0 || userStore.hasCredits === false || userStore.hasLanguages === false){
             // this.setState({ show: true });
         }
         return (
             <div className="modal-container">
                 <Modal show={this.state.show} onHide={this.close}>
                     <Modal.Header>
                         <Modal.Title>Reminder</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                         Before starting your first session, you need to add a language, set your availability and buy
                         corresponding credits
                     </Modal.Body>
                     <Modal.Footer>
                         <Button text={'OK'} onClick={this.close} appearance="primary"/>
                     </Modal.Footer>
                 </Modal>
             </div>
         );

    }
}
export default StudentReminderModal;
