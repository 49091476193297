import React from "react";
import FlexBox from "@components/FlexBox";
import Header from "@components/Header";
import {inject, observer} from "mobx-react";
import BalanceBlock from "@containers/dashboardBlocks/BalanceBlock";
import SessionBlock from "@containers/dashboardBlocks/SessionsBlock";
import ProgressBlock from "@containers/dashboardBlocks/ProgressBlock";
import {computed} from "mobx";
import {If, Then, Else} from "react-if";
import CpHoursBlock from "@containers/dashboardBlocks/CpHoursBlock";
import Index from "../../../components/Screen";
import LanguageTaughtBlock from "../../../containers/dashboardBlocks/LanguageTaughtBlock";
import StudentReminderModal from "@screens/DashboardScreen/StudentRerminderModal";
import AccountInformationBlock from "@containers/dashboardBlocks/AccountInformationBlock";

@inject("userStore", "uiConfig")
@observer
export default class DashboardHome extends React.Component {


  render() {
    const {userStore, uiConfig} = this.props;
    return (
      <Index header={
        <HeaderSection user={userStore}/>
      }>
        <FlexBox basis="full" margin={{right: 20, mobile: {right: "0"}}} direction="column">
          <StudentReminderModal userStore={userStore} />
          <If condition={uiConfig.renderForCp}>
            <Then>
              <CpHoursBlock/>
              <LanguageTaughtBlock/>
            </Then>
            <Else>
              <BalanceBlock/>
              <ProgressBlock/>
            </Else>
          </If>
        </FlexBox>
        <FlexBox basis="full" direction="column">
          <SessionBlock/>
        </FlexBox>
      </Index>
    );
  }
}

const HeaderSection = observer(({user}) => (
  <FlexBox noShrink margin={{horizontal: 30, mobile: {horizontal: 10}}} pad={{vertical: 30, mobile: {vertical: 10}}}>
    <Header underlined={false}>Welcome, {user.fullName}!</Header>
  </FlexBox>
));



