import {viewActionType as types} from "@constants/admin";


export default {
    "image": {
        title: "Logo",
        type: types.image
    },
    "name":{
        title: "Language Name",
    },
    "description":{
        title: "Description"
    },
    "code":{
        title: "Lang Code"
    },
};
