import {action, computed, observable} from "mobx";
import _ from "lodash";
import moment from "moment";
import {BaseModel} from "../domain/BaseStore";
import authStore from "../domain/authStore";
import studentLanguageStore from "../domain/student/studentLanguageStore";
import cpStudentLanguageStore from "../domain/cp/studentLanguageStore";
import balanceStore from "../domain/student/balanceStore";
import api from "../../config/api";

const service = {
  getSession: (id): Promise => api.get(`session/${id}`),
  getSessions: (): Promise => api.get("sessions"),
  deleteSession: (id): Promise => api.get(`session/${id}`),
  cancelSession: (id): Promise => api.post(`sessions/${id}/cancel`),
  updateSession: ({id, data}): Promise => api.patch(`session/${id}`, data)
};

export default class SessionModel extends BaseModel {
  loader = service.getSession;

  updater = service.updateSession;

  deleter = service.deleteSession;



  endPoint = "sessions";

  @observable
  cpId: number;

  @observable
  studentLanguageId: number;

  @observable
  studentUrl = null;

  @observable
  cpUrl = null;

  @observable
  code = null;

  @observable
  isCompleted = false;

  @observable
  scheduledAt = null;

  @observable
  type = null;

  @computed
  get studentLanguageStore() {
    if (authStore.role === "student") {
      return studentLanguageStore;
    }
    if (authStore.role === "cp") {
      return cpStudentLanguageStore;
    }
    return null;
  }

  @computed
  get studentLanguage() {
    return this.studentLanguageStore.find(this.studentLanguageId);
  }
  //
  // @computed
  // get color() {
  //   return this.studentLanguage.color;
  // }
  //
  // @computed
  // get cp() {
  //   return this.studentLanguage.cp;
  // }
  //
  // @computed
  // get student() {
  //   return this.studentLanguage.student;
  // }

  @computed
  get scheduledAtMoment() {
    return moment(this.scheduledAt);
  }

  @computed
  get startTime() {
    return this.scheduledAtMoment.format("LT");
  }

  @computed
  get date() {
    return this.scheduledAtMoment.format("DD-MM-YYYY");
  }

  @computed
  get endTime() {
    return this.scheduledAtMoment.add(this.duration, "m").format("LT");
  }


  @computed
  get moreThan24() {
    const now = moment();
    const diff = this.scheduledAtMoment.diff(now);
    const duration = moment.duration(diff);
    return duration.asHours() > 24;
  }

  @action
  cancel=()=>{
    this.studentLanguage.subPendingSessionHours(this.duration);
    if(authStore.role === "student")
      balanceStore.add(this.studentLanguage.defaultType.cost);
    this.parentStore.remove(this);
    service.cancelSession(this.id);
  };

  static get tableSchema(){
    return[
     
    ]
  }

  serializeData(data) {
    // const consumedData = _.omit(data, ["student"]);
    super.serializeData(data);
  }

}
