import {action, computed, observable} from "mobx";
import {create, persist} from "mobx-persist";
import api from "@config/api";
import localForage from "@config/localforage";
import {toast} from "@components/Toast";
import tzmoment from "moment-timezone";
import moment from "moment";
import userStore from "./userStore";
import languageStore from "./languageStore";
import routerStore from "../app/routerStore";
import languageLevelStore from "./languageLevelStore";
import bundleStore from "./student/bundleStore";
import sessionTypeStore from "@stores/domain/sessionTypeStore";

const service = {
  login: (data): Promise => api.post("login", data),
  logout: (): Promise => api.post("logout"),
  register: (data): Promise => api.post("register", data),
};


export class AuthStore {

  @observable loaded = true;

  @observable loading = false;

  @observable didRegAgree = false;

  @persist @observable id = -1;

  @persist @observable name = "";

  @persist @observable email = "";

  @persist @observable password = "";

  @observable country = "";

  @observable gender = "";

  @persist @observable token = null;

  @persist @observable role;

  @observable emailPasswordError = null;

  constructor() {
    if (!languageStore.loaded) {
      languageStore.load();
    }
    if (!sessionTypeStore.loaded && !sessionTypeStore.loading) {
      sessionTypeStore.load();
    }
    if (!languageLevelStore.loaded) {
      languageLevelStore.load();
    }if(!bundleStore.loaded){
      bundleStore.load();
    }
  }

  @computed
  get isLoggedIn() {
    return this.token !== null;
  }

  @computed
  get isAdmin(){
    return this.role === "admin";
  }

  @action
  setEmail(email: string) {
    this.email = email;
  }

  @action
  setName(name: string) {
    this.name = name;
  }

  @action
  setPassword(password: string) {
    this.password = password;
  }


  @action
  setCountry(value) {
    this.country = value;
  }

  @action
  setGender(value) {
    this.gender = value;
  }

  @action.bound
  switchRegistrationAgreement() {
    this.didRegAgree = !this.didRegAgree;
  }

  @action.bound
  login() {
    this.loading = true;
    const timezone = moment().format('Z');
    const timezoneName = tzmoment.tz.guess();
    service.login({email: this.email, password: this.password,timezone,timezoneName})
      .then(resp => {
          this.loading = false;
          this.loaded = true;
          if (resp.ok) {
            const {data} = resp;

            this.id = data.id;
            this.role = data.role;
            console.log("Token:",data.token);
            this.setToken(data.token);
            this.setAuthHeader(data.token);
            routerStore.replace("/");
            this.setName(data.name);
            if (this.role !== "admin")
              userStore.load();
            this.emailPasswordError = null;
          }else if(resp.problem === "CLIENT_ERROR"){
            this.emailPasswordError = "Email or password is incorrect";
          }
        }
      ).catch(e => {

      toast.error("Username or password are incorrect");
      }
    );
  };

  @action.bound
  register() {
    this.loading = true;
    service.register({
      email: this.email,
      password: this.password,
      name: this.name,
      country: this.country,
      gender: this.gender
    })
      .then(resp => {
        this.loading = false;
        this.loaded = true;
        if (resp.ok) {
          const {data} = resp;
          this.id = data.id;
          this.type = data.type;
          this.setToken(data.token);
          this.role = "student";
          routerStore.replace("/");
          this.setCurrentUser(data);
          userStore.load();
        }
      });
  }


  @action.bound
  logout() {
    this.removeAuthHeader();
    this.token = null;
    this.role = null;
    service.logout();
    routerStore.replace("/");
    location.reload();
  };

  @action
  setToken(token) {
    this.token = token;
  }

  setCurrentUser = (data) => {
    this.setAuthHeader(data.token);
  };

  setAuthHeader = (token) => {
    console.log("Auth Header",token);
    api.setHeader("X-AUTH-TOKEN", token);
  };

  removeAuthHeader = () => {
    delete api.headers["X-AUTH-TOKEN"];
    api.setHeader("X-AUTH-TOKEN","");
  };

  @action.bound
  test = () => {
    this.email = "student@example.com";
    this.password = "password";
    this.login();
  };


}

const authStore = new AuthStore();
window.authStore = authStore;

const hydrate = create({
  storage: localForage,
  jsonify: true
});

const storeEntry = window.btoa("aiinadsi189123");

hydrate(storeEntry, authStore, {loading: true,loaded:false}).then((r) => {
  if(authStore.token !== null){
    authStore.setAuthHeader(r.token);
  }
  authStore.loading = false;
  if(!authStore.isLoggedIn || authStore.role === "admin"){
    authStore.loaded = true;
  }
  if (authStore.role !== "admin" && authStore.isLoggedIn) {
    userStore.load();
  }
}).catch(e=>{
  authStore.loaded=true;
});


export default authStore;
