import authStore from "./domain/authStore";
import routerStore from "./app/routerStore";
import userStore from "./domain/userStore";
import sessionStore from "./domain/sessionStore";
import cpStore from "./domain/student/cpStore";
import balanceStore from "./domain/student/balanceStore";
import studentLanguageStore from "./domain/student/studentLanguageStore";
import cpStudentLanguageStore from "./domain/cp/studentLanguageStore";
import studentStore from "./domain/cp/studentStore";
import languageStore from "./domain/languageStore";
import bundleStore from "./domain/student/bundleStore";
import languageLevelStore from "./domain/languageLevelStore";
import sessionBookingStore from "./ui/sessionBookingStore";
import availabilitySelectionStore from "./ui/availabilitySelectionStore";
import registrationStore from "./domain/registrationStore";

import languageAdditorStore from "./ui/languageAdditorStore";

import uiConfig from "./ui/generalConfigStore";
import sessionCancelStore from "./ui/sessionCancelStore";
import sessionCalendarStore from "./ui/sessionCalendarStore";
import purchaseStore from "./ui/purchaseStore";

/** Admin Stores */
import adminCpStore from "./domain/admin/cp";
import adminStudentStore from "./domain/admin/student";
import adminSessionStore from "./domain/admin/sessions";
import adminBundleStore from "./domain/admin/bundle";
import adminStudentLanguageStore from "./domain/admin/studentLanguage";
import sessionTypeStore from "@stores/domain/sessionTypeStore";
import adminAdminStore from "@stores/domain/admin/admin";

/** Tooling Stores */

export const adminStores = {
  adminCpStore,
  adminStudentStore,
  adminSessionStore,
  adminBundleStore,
  adminStudentLanguageStore,
  adminAdminStore
};

export default {
  authStore,
  userStore,
  routerStore,
  sessionStore,
  cpStore,
  studentStore,
  studentLanguageStore,
  cpStudentLanguageStore,
  balanceStore,
  languageStore,
  languageLevelStore,
  bundleStore,
  sessionBookingStore,
  sessionCancelStore,
  registrationStore,
  availabilitySelectionStore,
  languageAdditorStore,
  uiConfig,
  purchaseStore,
  sessionCalendarStore,
  sessionTypeStore
};
