import React from "react";
import {Route, Switch} from "react-router-dom";
import Screen from "@components/Screen";
import DataScreens from "../DataScreens";
import AddScreen from "./Add";
import EditScreen from "./Edit";
import ViewScreen from "./View";
import CpStudent from "@screens/Admin/ConversationPartner/Students";
import CpSessions from "@screens/Admin/ConversationPartner/Sessions";
import CpDialects from "@screens/Admin/ConversationPartner/Dialects";



export default ({match}) => {
  const {path} = match;
  return (
    <Screen>
      <Switch>
        <Route path={path} exact component={DataScreens.ConversationPartners}/>
        <Route path={`${path}/add`} exact component={AddScreen}/>
        <Route path={`${path}/:id`} exact component={ViewScreen}/>
        <Route path={`${path}/:id/edit`} exact component={EditScreen}/>
        <Route path={`${path}/:id/students`} exact component={CpStudent}/>
        <Route path={`${path}/:id/sessions`} exact component={CpSessions}/>
        <Route path={`${path}/:id/dialects`} exact component={CpDialects}/>
      </Switch>
    </Screen>
  );
}
