import {computed, observable, action} from "mobx";
import moment, {Moment} from "moment";
import api from "@config/api";
import {BaseModel} from "../domain/BaseStore";
import languageStore from "../domain/languageStore";
import Availabilities, {AvailabilityBlock, AvailabilityDay} from "../domain/subStores/availabilitySubstore";
import {serverTimeFormat} from "@utils/date";

const service = {
  update: id => data => api.put(`conversation_partners/${id}`, data)
};

export default class CpModel extends BaseModel {

  endPoint = "conversation_partners";

  updater = service.update(this.id);



  @observable
  dialectId;

  @observable
  name;

  @observable
  status;

  @observable
  avatarFileName;

  @observable
  hobbies: [string] = [];

  @observable
  availabilities: [Availabilities] = [];

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get dialect() {
    const dialect = languageStore.findDialect(this.dialectId);
    return dialect;
  }

  @computed
  get language() {
    const language = languageStore.findByDialect(this.dialectId);
    return language;
  }

  @computed
  get profilePicture() {
    if (this.avatarFileName) {
      return api.assetUrl({filename: this.avatarFileName, folder: "profile"});
    }
    return this.avatarFileName || require("@assets/male-placeholder.jpg");
  }

  @computed
  get age() {
    if (this.dob)
      return moment().diff(moment(this.dob), "y");
    return null;
  }

  @computed
  get birthdayMoment():Moment{
    return moment(this.dob);
  }

  @action.bound
  setBirthday(moment:Moment){
    this.dob = moment.format(serverTimeFormat);
  }

  static get tableSchema(): * {
    return [
      "profilePicture",
      "firstName",
      "lastName",
      "email",
      "age",
      "phoneNumber",
      "gender",
      "earnings",
      "profession",
      "createdAt",
      "status"
    ];
  }

  serializeData(data, setLoadedOnDone = true) {
    super.serializeData(data, setLoadedOnDone);
    this.hobbies = data.hobbies;

    /** Checks if sessionAvailabilites present in request to apply for language CP */
    if (data.sessionAvailabilities) {
      this.availabilities = new Availabilities([]);

      /** Go through timeblocks to generate appropriate availability schema based on user timezone */
      data.sessionAvailabilities.forEach(availabilityEntry => {
        const availabilityBlock = new AvailabilityBlock(availabilityEntry);

        /** Fetch Availability day if present */
        let availability = this.availabilities.data.find(a => a.date === availabilityBlock.date);

        /** if not present create one and push it */
        if (!availability) {
          const randomNumber = Math.floor(Math.random() * 100);
          availability = new AvailabilityDay({
            id: randomNumber,
            day: availabilityBlock.day,
            date: availabilityBlock.date
          });
          this.availabilities.data.push(availability);
        }
        availability.add(availabilityBlock);
      });
    } else if (data.availabilities)
      this.availabilities = new Availabilities(data.availabilities);
    this.name = this.fullName;
  }
}

window.CpModel = CpModel;
