import {inject, observer} from "mobx-react";
import FlexBox,{flexDirection, flexProps} from "@components/FlexBox";
import DashboardBlock from "@components/DashboardBlock";
import React from "react";
import {StyledLanguageBlock} from "./styled";
import StepContinueButton from "./components/StepContinueButton";

const LanguageSelectStep = inject("languageAdditorStore", "languageStore")(observer(({languageAdditorStore, languageStore}) => {
  return (
    <FlexBox full="horizontal" align={flexProps.center}
             justify={flexProps.center} direction={flexDirection.column}>
      <DashboardBlock.Header>
          What language would you like to learn?
      </DashboardBlock.Header>
      <FlexBox direction="row-responsive" margin={{top: 50}}>
        {languageStore.availableLanguages.map(l => <LanguageBlock key={l.id} language={l} additorStore={languageAdditorStore}/>)}
      </FlexBox>
      <StepContinueButton disabled={!languageAdditorStore.canSelectDialect} onClick={languageAdditorStore.stepUp}/>
    </FlexBox>
  );
}));

const LanguageBlock = observer(({language, additorStore}) => {
  return (
    <StyledLanguageBlock onClick={() => additorStore.selectLanguage(language.id)}>
      <StyledLanguageBlock.Image
        active={language.id === additorStore.selectedLanguageId}
        src={language.image}>
      </StyledLanguageBlock.Image>
      <StyledLanguageBlock.Name>
        {language.name}
      </StyledLanguageBlock.Name>
    </StyledLanguageBlock>
  );
});

export default LanguageSelectStep;

