import React from "react";
import Icon from "@oovui/react-feather-icons";
import DashboardBlock from "@components/DashboardBlock";
import colors from "@constants/colors";
import {inject, observer} from "mobx-react";
import {If, Then, Else} from "react-if";
import Screen from "../../../components/Screen";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import {DashboardHeader} from "../../../components/DashboardBlock";
import {StyledParagraph} from "./Steps/styled";
import Button from "../../../components/Button";

@inject("userStore")
@observer
class SubmissionSuccessScreen extends React.Component {
  render(): React.ReactNode {
    const {history,userStore} = this.props;
    const {isFirstTime} = userStore;
    return (
      <Screen>
        <FlexBox direction={flexDirection.row} justify={flexProps.center} f={1}>
          <Screen.Column last full="horizontal" solo>
            <DashboardBlock title="Submission Successful !" align="center">
              <FlexBox direction="column" align={flexProps.center} justify={flexProps.center}
                       pad={{horizontal: 180, mobile: {horizontal: "0"}}}>
                <DashboardHeader subHeader>
                  Thanks!
                </DashboardHeader>
                <StyledParagraph>First-time user? Be sure to provide your availability and purchase credits. Otherwise, stay on the look out for an email introduction!</StyledParagraph>
                <FlexBox width={70} justify={flexProps.spaceBetween} margin={{vertical: 4}}>
                  <Icon type="bell" size={30} color={colors.textLight}/>
                  <Icon type="mail" size={30} color={colors.textLight}/>
                </FlexBox>
                <StyledParagraph>
                </StyledParagraph>
                <FlexBox margin={{top: 30}}>
                  <If condition={isFirstTime}>
                    <Then>
                      <Button text="Go to my Availability" withArrow onClick={() => history.replace("/availability")}/>
                    </Then>
                    <Else>
                      <Button text="Go to Dashboard" withArrow onClick={() => history.replace("/")}/>
                    </Else>
                  </If>
                </FlexBox>
              </FlexBox>
            </DashboardBlock>
          </Screen.Column>
        </FlexBox>
      </Screen>
    );
  }
}


export default SubmissionSuccessScreen;
