import React from "react";
import DashboardBlock, {DashboardHeader} from "@components/DashboardBlock";
import {observer, inject} from "mobx-react";
import colors from "@constants/colors";
import Icon from "@oovui/react-feather-icons";
import {Link,NavLink} from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import FlexBox, {flexProps} from "../../components/FlexBox";
import ProfileImage from "../../components/ProfileImage";
import {ProgressCpName} from "./styled";
import {StudentLanguageStore, StudentLanguageModel} from "../../stores/domain/student/studentLanguageStore";
import PlainButton from "../../components/PlainButton";


const ProgressBlock = observer(() => (
  <DashboardBlock title="Learning Progress" action={{text: "Ask for new CP", icon: "plus",onClick:()=>{window.open("mailto:pairing@natakallam.com")}}}>
    <ProgressFeed/>
  </DashboardBlock>
));


const ProgressFeed = inject("studentLanguageStore")(observer(({studentLanguageStore}: { studentLanguageStore: StudentLanguageStore }) => {
  if (studentLanguageStore.isEmpty)
    return <EmptyPlaceholder/>;
  return studentLanguageStore.allData.map(l => <ProgressEntry language={l}/>);
}));

const EmptyPlaceholder = () => (
  <FlexBox full="horizontal" direction="row">
    <Icon type="users" size={24}/>
    <FlexBox direction="column" margin={{left:10}}>
      <DashboardBlock.Value>You have no languages registered</DashboardBlock.Value>
      <p>You can for start add a language or buy credits</p>
    </FlexBox>
  </FlexBox>
);

const ProgressEntry = observer(({language}: { language: StudentLanguageModel }) => (
  <FlexBox key={language.id} full="horizontal" direction="column" margin={{bottom: 25}}>
    <DashboardHeader>{language.name} | {language.dialect}  {!language.cp?"(Pairing CP)":null}</DashboardHeader>
    <ProgressBar margin={{top: 10, bottom: 10}} total={10} progress={language.completedSessionHours} color={language.color}/>
    {language.cp ?<><DashboardHeader subHeader>Conversation Partner</DashboardHeader>
     <FlexBox margin={{top: 10}} direction="row" align={flexProps.center}>
      <ProfileImage
        src={language.cp.profilePicture || require("@assets/male-placeholder.jpg")}
        round
        size="small"
        margin={{right: 15}}
      />

      <ProgressCpName to={`cp/${language.cp.id}`}>{language.cp.name}</ProgressCpName>

    </FlexBox></>:null
    }
  </FlexBox>
));

export default ProgressBlock;

export const UnobservedProgressBlock = () => (
  <DashboardBlock title="Learning Progress" action={{text: "Ask for new CP", icon: "plus",onClick:()=>{window.open("mailto:pairing@natakallam.com")}}}>
    <UnobservedProgressEntry/>
  </DashboardBlock>
);

const UnobservedProgressEntry = () => (
  <>
    <FlexBox full="horizontal" direction="column" margin={{bottom: 25}}>
      <DashboardHeader>ARABIC | MODERN STANDARD ARABIC</DashboardHeader>
      <ProgressBar margin={{top: 10, bottom: 10}} total={100} progress={40} color={colors.gradient[2]}/>
      <DashboardHeader subHeader>Conversation Partner</DashboardHeader>
      <FlexBox direction="row" align={flexProps.center}>
        <ProfileImage
          src={require("@assets/male-placeholder.jpg")}
          round
          size="small"
          margin={{right: 15}}
        />
        <ProgressCpName>Test</ProgressCpName>
      </FlexBox>
    </FlexBox>
    <DashboardHeader>SPANISH | OFFICIAL</DashboardHeader>
    <ProgressBar margin={{top: 10, bottom: 10}} total={100} progress={20} color={colors.gradient[4]}/>
    <DashboardHeader subHeader>Conversation Partner</DashboardHeader>
    <FlexBox direction="row" align={flexProps.center} margin={{bottom: 25}}>
      <ProfileImage
        src={require("@assets/male-placeholder.jpg")}
        round
        size="small"
        margin={{right: 15}}
      />
      <ProgressCpName>Test 2</ProgressCpName>
    </FlexBox><DashboardHeader>SPANISH | OFFICIAL</DashboardHeader>
    <ProgressBar margin={{top: 10, bottom: 10}} total={100} progress={20} color={colors.gradient[4]}/>
    <DashboardHeader subHeader>Conversation Partner</DashboardHeader>
    <FlexBox direction="row" align={flexProps.center}>
      <ProfileImage
        src={require("@assets/male-placeholder.jpg")}
        round
        size="small"
        margin={{right: 15}}
      />
      <ProgressCpName>Test 2</ProgressCpName>
    </FlexBox>
  </>
);
