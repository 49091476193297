import {viewActionType as types} from "@constants/admin";


export default {
    "id": {
        title: "#"
    },
    "name":{
        title: "Name",
    },
    "cost":{
        title: "Session Cost",
        type:types.currency
    },
    "earning":{
        title: "CP Earning",
        type:types.currency
    },
};
