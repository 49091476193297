import React from "react";
import FlexBox from "@components/FlexBox";
import styled from "styled-components";
import colors from "@constants/colors";
import IconTough from "@oovui/react-feather-icons";
import {NavLink} from "react-router-dom";
import {StyledButton} from "./styled";
import { StyledIcon} from "@components/Button/styled";

const MenuItem = (props) => {
  const {active, icon, title} = props;
  return (
    <NavLink {...props}>
      <Container {...props}>
        <IconContainer>
          <IconTough type={icon || "airplay"} color={active ? "white" : "black"} size={16}/>
        </IconContainer>
        <span>{title}</span>
      </Container>
    </NavLink>
  );
};

const Icon = styled.div`
  background-color: white;
  height: 30px;
  width: 30px;
`;

const IconContainer = styled.div`
  margin-right:5px;
  height:16px;
  width:16px;
`;

const Container = styled(FlexBox).attrs({
  height: 40,
  pad: {
    horizontal: 20
  },
  align: "center"
})`
  cursor: pointer;
  ${({active}) => `background-color:${active ? colors.primary : colors.transparent};`}

  > span {
    height: 20px;
    font-family: Lato, sans-serif;
    font-size: 12px;
    letter-spacing: 0.15px;
    vertical-align: middle;
    line-height: 20px;
    ${({active}) => `
      color:${active ? colors.white : colors.black};
      `}
  }

  & ${Icon} {
    ${({active}) =>
  `background-color:${active ? colors.white : colors.black};`}
  }
`;

export const Button = ({plain, text, onClick, disabled, href, withArrow, iconLeft, iconRight, round, as, withArrowLeft, primary, ...props}: ButtonProps) => {

  return (
      <StyledButton
          as={as || "button"}
          round={round}
          plain={plain}
          disabled={disabled}
          href={disabled ? null : href}
          primary={primary}
          withArrowLeft={withArrowLeft}
          withArrow={withArrow}
          iconLeft={iconLeft}
          iconRight={iconRight}
          {...props}
          onClick={disabled ? () => {
          } : onClick}
      >
        {withArrowLeft ? <StyledIcon color={!plain ? "black" : colors.secondary} size={24} type="arrow-left"/> : null}
        {iconLeft ? <StyledIcon color={!plain ? "black" : colors.secondary} size={24} type={iconLeft} position="left"/> : null}
        <span>{text}</span>
        {iconRight ? <StyledIcon color={!plain ? "black" : colors.secondary} size={24} type={iconRight} position="right"/> : null}
        {withArrow ? <StyledIcon color={!plain ? "black" : colors.secondary} size={24} type="arrow-right"/> : null}
      </StyledButton>
  );
};

export default MenuItem;
