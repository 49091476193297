import {computed} from "mobx";

export const countries = [
  {"value": "AF", "group": "A", "text": "Afghanistan"}, {
    "value": "AL",
    "group": "A",
    "text": "Albania"
  }, {"value": "DZ", "group": "A", "text": "Algeria"}, {"value": "AD", "group": "A", "text": "Andorra"}, {
    "value": "AO",
    "group": "A",
    "text": "Angola"
  }, {"value": "AR", "group": "A", "text": "Argentina"}, {
    "value": "AM",
    "group": "A",
    "text": "Armenia"
  }, {"value": "AW", "group": "A", "text": "Aruba"}, {"value": "AU", "group": "A", "text": "Australia"}, {
    "value": "AT",
    "group": "A",
    "text": "Austria"
  }, {"value": "AZ", "group": "A", "text": "Azerbaijan"}, {
    "value": "BH",
    "group": "B",
    "text": "Bahrain"
  }, {"value": "BD", "group": "B", "text": "Bangladesh"}, {
    "value": "BY",
    "group": "B",
    "text": "Belarus"
  }, {"value": "BE", "group": "B", "text": "Belgium"}, {"value": "BZ", "group": "B", "text": "Belize"}, {
    "value": "BJ",
    "group": "B",
    "text": "Benin"
  }, {"value": "BT", "group": "B", "text": "Bhutan"}, {"value": "BO", "group": "B", "text": "Bolivia"}, {
    "value": "BA",
    "group": "B",
    "text": "Bosnia And Herzegovina"
  }, {"value": "BW", "group": "B", "text": "Botswana"}, {"value": "BR", "group": "B", "text": "Brazil"}, {
    "value": "IO",
    "group": "B",
    "text": "British Indian Ocean Territory"
  }, {"value": "BN", "group": "B", "text": "Brunei Darussalam"}, {
    "value": "BG",
    "group": "B",
    "text": "Bulgaria"
  }, {"value": "BF", "group": "B", "text": "Burkina Faso"}, {
    "value": "BI",
    "group": "B",
    "text": "Burundi"
  }, {"value": "KH", "group": "C", "text": "Cambodia"}, {
    "value": "CM",
    "group": "C",
    "text": "Cameroon"
  }, {"value": "CV", "group": "C", "text": "Cape Verde"}, {
    "value": "CF",
    "group": "C",
    "text": "Central African Republic"
  }, {"value": "TD", "group": "C", "text": "Chad"}, {"value": "CL", "group": "C", "text": "Chile"}, {
    "value": "CN",
    "group": "C",
    "text": "China"
  }, {"value": "CO", "group": "C", "text": "Colombia"}, {
    "value": "KM",
    "group": "C",
    "text": "Comoros"
  }, {"value": "CG", "group": "C", "text": "Congo"}, {
    "value": "CD",
    "group": "C",
    "text": "Congo, Democratic Republic"
  }, {"value": "CK", "group": "C", "text": "Cook Islands"}, {
    "value": "CR",
    "group": "C",
    "text": "Costa Rica"
  }, {"value": "CI", "group": "C", "text": "Cote D'Ivoire"}, {
    "value": "HR",
    "group": "C",
    "text": "Croatia"
  }, {"value": "CU", "group": "C", "text": "Cuba"}, {"value": "CY", "group": "C", "text": "Cyprus"}, {
    "value": "CZ",
    "group": "C",
    "text": "Czech Republic"
  }, {"value": "DK", "group": "D", "text": "Denmark"}, {
    "value": "DJ",
    "group": "D",
    "text": "Djibouti"
  }, {"value": "EC", "group": "E", "text": "Ecuador"}, {"value": "EG", "group": "E", "text": "Egypt"}, {
    "value": "SV",
    "group": "E",
    "text": "El Salvador"
  }, {"value": "GQ", "group": "E", "text": "Equatorial Guinea"}, {
    "value": "ER",
    "group": "E",
    "text": "Eritrea"
  }, {"value": "EE", "group": "E", "text": "Estonia"}, {
    "value": "ET",
    "group": "E",
    "text": "Ethiopia"
  }, {"value": "FK", "group": "F", "text": "Falkland Islands (Malvinas)"}, {
    "value": "FO",
    "group": "F",
    "text": "Faroe Islands"
  }, {"value": "FJ", "group": "F", "text": "Fiji"}, {"value": "FI", "group": "F", "text": "Finland"}, {
    "value": "FR",
    "group": "F",
    "text": "France"
  }, {"value": "PF", "group": "F", "text": "French Polynesia"}, {
    "value": "GA",
    "group": "G",
    "text": "Gabon"
  }, {"value": "GM", "group": "G", "text": "Gambia"}, {"value": "GE", "group": "G", "text": "Georgia"}, {
    "value": "DE",
    "group": "G",
    "text": "Germany"
  }, {"value": "GH", "group": "G", "text": "Ghana"}, {"value": "GI", "group": "G", "text": "Gibraltar"}, {
    "value": "GR",
    "group": "G",
    "text": "Greece"
  }, {"value": "GL", "group": "G", "text": "Greenland"}, {
    "value": "GT",
    "group": "G",
    "text": "Guatemala"
  }, {"value": "GN", "group": "G", "text": "Guinea"}, {
    "value": "GW",
    "group": "G",
    "text": "Guinea-Bissau"
  }, {"value": "GY", "group": "G", "text": "Guyana"}, {"value": "HT", "group": "H", "text": "Haiti"}, {
    "value": "HN",
    "group": "H",
    "text": "Honduras"
  }, {"value": "HK", "group": "H", "text": "Hong Kong"}, {
    "value": "HU",
    "group": "H",
    "text": "Hungary"
  }, {"value": "IS", "group": "I", "text": "Iceland"}, {"value": "IN", "group": "I", "text": "India"}, {
    "value": "ID",
    "group": "I",
    "text": "Indonesia"
  }, {"value": "IR", "group": "I", "text": "Iran, Islamic Republic Of"}, {
    "value": "IQ",
    "group": "I",
    "text": "Iraq"
  }, {"value": "IE", "group": "I", "text": "Ireland"}, {"value": "IS", "group": "I", "text": "Israel"},{"value": "IT", "group": "I", "text": "Italy"}, {
    "value": "JP",
    "group": "J",
    "text": "Japan"
  }, {"value": "JO", "group": "J", "text": "Jordan"}, {
    "value": "KE",
    "group": "K",
    "text": "Kenya"
  }, {"value": "KI", "group": "K", "text": "Kiribati"}, {"value": "KR", "group": "K", "text": "Korea"}, {
    "value": "KW",
    "group": "K",
    "text": "Kuwait"
  }, {"value": "KG", "group": "K", "text": "Kyrgyzstan"}, {
    "value": "LA",
    "group": "L",
    "text": "Lao People's Democratic Republic"
  }, {"value": "LV", "group": "L", "text": "Latvia"}, {"value": "LB", "group": "L", "text": "Lebanon"}, {
    "value": "LS",
    "group": "L",
    "text": "Lesotho"
  }, {"value": "LR", "group": "L", "text": "Liberia"}, {
    "value": "LY",
    "group": "L",
    "text": "Libyan Arab Jamahiriya"
  }, {"value": "LI", "group": "L", "text": "Liechtenstein"}, {
    "value": "LT",
    "group": "L",
    "text": "Lithuania"
  }, {"value": "LU", "group": "L", "text": "Luxembourg"}, {
    "value": "MO",
    "group": "M",
    "text": "Macao"
  }, {"value": "MK", "group": "M", "text": "Macedonia"}, {
    "value": "MG",
    "group": "M",
    "text": "Madagascar"
  }, {"value": "MW", "group": "M", "text": "Malawi"}, {"value": "MY", "group": "M", "text": "Malaysia"}, {
    "value": "MV",
    "group": "M",
    "text": "Maldives"
  }, {"value": "ML", "group": "M", "text": "Mali"}, {"value": "MT", "group": "M", "text": "Malta"}, {
    "value": "MH",
    "group": "M",
    "text": "Marshall Islands"
  }, {"value": "MR", "group": "M", "text": "Mauritania"}, {
    "value": "MU",
    "group": "M",
    "text": "Mauritius"
  }, {"value": "MX", "group": "M", "text": "Mexico"}, {
    "value": "FM",
    "group": "M",
    "text": "Micronesia, Federated States Of"
  }, {"value": "MD", "group": "M", "text": "Moldova"}, {"value": "MC", "group": "M", "text": "Monaco"}, {
    "value": "MN",
    "group": "M",
    "text": "Mongolia"
  }, {"value": "ME", "group": "M", "text": "Montenegro"}, {
    "value": "MA",
    "group": "M",
    "text": "Morocco"
  }, {"value": "MZ", "group": "M", "text": "Mozambique"}, {
    "value": "NA",
    "group": "N",
    "text": "Namibia"
  }, {"value": "NL", "group": "N", "text": "Netherlands"}, {
    "value": "NC",
    "group": "N",
    "text": "New Caledonia"
  }, {"value": "NZ", "group": "N", "text": "New Zealand"}, {
    "value": "NI",
    "group": "N",
    "text": "Nicaragua"
  }, {"value": "NE", "group": "N", "text": "Niger"}, {"value": "NG", "group": "N", "text": "Nigeria"}, {
    "value": "NU",
    "group": "N",
    "text": "Niue"
  }, {"value": "NF", "group": "N", "text": "Norfolk Island"}, {
    "value": "NO",
    "group": "N",
    "text": "Norway"
  }, {"value": "OM", "group": "A", "text": "Oman"}, {"value": "PK", "group": "P", "text": "Pakistan"}, {
    "value": "PS",
    "group": "PA",
    "text": "Palestine"
  }, {"value": "PA", "group": "P", "text": "Panama"}, {
    "value": "PG",
    "group": "P",
    "text": "Papua New Guinea"
  }, {"value": "PY", "group": "P", "text": "Paraguay"}, {"value": "PE", "group": "P", "text": "Peru"}, {
    "value": "PH",
    "group": "P",
    "text": "Philippines"
  }, {"value": "PL", "group": "P", "text": "Poland"}, {"value": "PT", "group": "P", "text": "Portugal"}, {
    "value": "QA",
    "group": "A",
    "text": "Qatar"
  }, {"value": "RO", "group": "R", "text": "Romania"}, {
    "value": "RU",
    "group": "R",
    "text": "Russian Federation"
  }, {"value": "RW", "group": "R", "text": "Rwanda"}, {"value": "WS", "group": "S", "text": "Samoa"}, {
    "value": "SM",
    "group": "S",
    "text": "San Marino"
  }, {"value": "ST", "group": "S", "text": "Sao Tome And Principe"}, {
    "value": "SA",
    "group": "S",
    "text": "Saudi Arabia"
  }, {"value": "SN", "group": "S", "text": "Senegal"}, {"value": "RS", "group": "S", "text": "Serbia"}, {
    "value": "SC",
    "group": "S",
    "text": "Seychelles"
  }, {"value": "SL", "group": "S", "text": "Sierra Leone"}, {
    "value": "SG",
    "group": "S",
    "text": "Singapore"
  }, {"value": "SK", "group": "S", "text": "Slovakia"}, {
    "value": "SI",
    "group": "S",
    "text": "Slovenia"
  }, {"value": "SB", "group": "S", "text": "Solomon Islands"}, {
    "value": "SO",
    "group": "S",
    "text": "Somalia"
  }, {"value": "ZA", "group": "S", "text": "South Africa"}, {
    "value": "ES",
    "group": "S",
    "text": "Spain"
  }, {"value": "LK", "group": "S", "text": "Sri Lanka"}, {"value": "SD", "group": "S", "text": "Sudan"}, {
    "value": "SR",
    "group": "S",
    "text": "Suriname"
  }, {"value": "SZ", "group": "S", "text": "Swaziland"}, {
    "value": "SE",
    "group": "S",
    "text": "Sweden"
  }, {"value": "CH", "group": "S", "text": "Switzerland"}, {
    "value": "SY",
    "group": "S",
    "text": "Syrian Arab Republic"
  }, {"value": "TW", "group": "T", "text": "Taiwan"}, {
    "value": "TJ",
    "group": "T",
    "text": "Tajikistan"
  }, {"value": "TZ", "group": "T", "text": "Tanzania"}, {
    "value": "TH",
    "group": "T",
    "text": "Thailand"
  }, {"value": "TL", "group": "T", "text": "Timor-Leste"}, {
    "value": "TG",
    "group": "T",
    "text": "Togo"
  }, {"value": "TK", "group": "T", "text": "Tokelau"}, {"value": "TO", "group": "T", "text": "Tonga"}, {
    "value": "TN",
    "group": "T",
    "text": "Tunisia"
  }, {"value": "TR", "group": "T", "text": "Turkey"}, {
    "value": "TM",
    "group": "T",
    "text": "Turkmenistan"
  }, {"value": "TV", "group": "T", "text": "Tuvalu"}, {"value": "UG", "group": "U", "text": "Uganda"}, {
    "value": "UA",
    "group": "U",
    "text": "Ukraine"
  }, {"value": "AE", "group": "U", "text": "United Arab Emirates"}, {
    "value": "GB",
    "group": "U",
    "text": "United Kingdom"
  }, {"value": "US", "group": "U", "text": "United States"}, {
    "value": "UY",
    "group": "U",
    "text": "Uruguay"
  }, {"value": "VU", "group": "V", "text": "Vanuatu"}, {
    "value": "VE",
    "group": "V",
    "text": "Venezuela"
  }, {"value": "VN", "group": "V", "text": "Viet Nam"}, {
    "value": "WF",
    "group": "W",
    "text": "Wallis And Futuna"
  }, {"value": "YE", "group": "Y", "text": "Yemen"}, {"value": "ZM", "group": "Z", "text": "Zambia"}, {
    "value": "ZW",
    "group": "Z",
    "text": "Zimbabwe"
  }];


export const selectorCountries = countries.map(c => ({value: c.text, name: c.text}));

