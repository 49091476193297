import bundle from "./bundle";
import cp from "./cp";
import studentLanguage from "./studentLanguage";
import sessions from "./sessions";
import student from "./student";

export default {
  bundle,
  cp,
  studentLanguage,
  sessions,
  student
}
