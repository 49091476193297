import React from "react";
import {inject, observer} from "mobx-react";
import {computed, observable, reaction} from "mobx";
import type {LanguageStore} from "@stores/domain/languageStore";
import FlexBox from "@components/FlexBox";
import Input, {ObservedInput} from "@components/Input";
import Button from "@components/Button";
import {getValue} from "@utils/input";
import api from "../../../../config/api";

/**
 * Admin form
 */
@inject("languageStore")
@observer
class CP extends React.Component {


  @observable
  _selectedLanguage = null;

  @observable
  firstName=null;

  @observable
  lastName=null;

  @observable
  email = null;


  componentDidMount(): void {
    const {data} = this.props.languageStore;
    if(data.length > 0){
      this._selectedLanguage = data[0].id;
    }else{
      reaction(()=>data.length,(d,r)=>{
        if(d>0){this._selectedLanguage = data[0].id;}
        r.dispose();
      });
    }
  }

  @computed
  get _dialects() {
    const {languageStore}: { languageStore: LanguageStore } = this.props;
    if (this._selectedLanguage) {
      return languageStore.find(this._selectedLanguage).dialects || [];
    }
    return [];
  }

  _selectLanguage = (e) => {
    this._selectedLanguage = ~~e.nativeEvent.target.value;
  };

  _onSubmit = e =>{
    e.preventDefault();
    console.log(this.firstName);
    api.post("users",{
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: "none",
      type: "cp"
    }).then(r=>console.log(r));
  };


  render(): React.ReactNode {
    const {languageStore} = this.props;
    return (
      <FlexBox full="both" direction="column">
        <h2>New CP</h2>
        <FlexBox as="form" direction="column" full="both" onSubmit={this._onSubmit}>
          <ObservedInput type="text" fill placeholder="First Name" onChange={e=>{this.firstName = getValue(e)}} required/>
          <ObservedInput type="text" fill placeholder="Last Name" onChange={e=>{this.lastName = getValue(e)}} required/>
          <ObservedInput type="text" fill placeholder="Email" onChange={e=>{this.email = getValue(e)}} required/>
          <ObservedInput type="select" fill placeholder="Language" required onChange={this._selectLanguage}
                         options={languageStore.data.map(d => ({value: d.id, name: d.name}))}/>
          <ObservedInput type="select" fill placeholder="Email" required
                 options={this._dialects.map(d => ({value: d.name, name: d.name}))}/>
          <Button text="Add"/>
        </FlexBox>
      </FlexBox>
    );
  }
}

export default CP;
