import React from "react";
import {StyledContainer,StyledAnchor} from "@components/Hint/styled";
import ReactTooltip from 'react-tooltip';

export default ({data,children}) => {
    const id = Math.random(0, 8000).toString();
    return(<>
    <StyledContainer data-tip data-for={id}>
        ?
    </StyledContainer>
        <ReactTooltip id={id}>
            <div style={{textAlign:"center",textTransform:"none"}}>{data||children}</div>
        </ReactTooltip>
        </>)
}
