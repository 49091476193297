import api from "@config/api";
import {BaseModel, BaseStore} from "../BaseStore";
import {observable} from "mobx";

const service = {
    fetch: () => api.get(`users?type=admin`),
    update: (id) => (data)=> api.put(`users/${id}`,data),
    load: (id) => ()=> api.get(`users/${id}`),
    add: (data) => api.post('users',data),
};

export class AdminModel extends BaseModel {

    @observable
    firstName;

    @observable
    lastName;

    @observable
    email;

    @observable
    password;

    @observable
    isActive;

    endPoint = "user_infos";

    parentStore = store;

    loader= service.load(this.id);

    updater = service.update(this.id);

    adder = service.add;

    basicPassword = "123";
    defaultType = "admin";

    updateSchema: * = [
        "firstName",
        "lastName",
        "email",
        "isActive"
    ];

}


class AdminStore extends BaseStore {

    DataModel = AdminModel;

    loader = service.fetch;

    consume(data: [Object]) {
        const d = data.filter(entry =>entry.id !== authStore.id);
        super.consume(d);
    }

    adder = "";
}

const store = new AdminStore();

export default store;
