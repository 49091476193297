import {viewActionType as types} from "@constants/admin";


export default {
    "isActive": {
        title: "Active",
        type: types.boolean
    },
    "name":{
        title: "Name",
    },
    "creditAmount":{
        title: "Credit Amount",
        type:types.currency
    },
    "price":{
        title: "Price",
        type: types.currency
    }
};
