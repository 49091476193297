import api from "@config/api";
import {action, computed, observable} from "mobx";
import moment from "moment";
import routerStore from "@stores/app/routerStore";
import {serverTimeFormat} from "@utils/date";
import {toast} from "@components/Toast";
import {BaseModel} from "./BaseStore";
import authStore from "./authStore";
import languageStore from "./student/studentLanguageStore";
import cpLanguageStore from "./cp/studentLanguageStore";
import balanceStore from "./student/balanceStore";
import Availabilities from "./subStores/availabilitySubstore";


const service = {
    getUser: ({id}): Promise => api.get(`user_infos/${id}`),
    putUser: ({id, data}): Promise => api.put(`user_infos/${id}`, data),
    getInitialUser: (): Promise => api.get("user_infos/initial")
};

/**
 * @extends BaseModel
 * @inheritDoc
 */
export class UserStore extends BaseModel {
    loader = service.getUser;

    loaded = false;


    endPoint = "user_infos";

    studentEndPoint = "students";


    @observable
    studentId;

    @observable
    skypeId;

    @observable
    firstName = null;

    @observable
    lastName = null;

    @observable
    dob = null;
    @observable
    prevLocation = "";
    @observable
    hobbies = [];
    @observable
    availabilities = [];
    @observable
    hasLanguages;
    @observable
    hasCredits;
    @observable firstName;
    @observable
    hobbyInputValue = "";

    @computed
    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    @computed
    get profilePicture() {
        if (this.avatarFileName) {
            return api.assetUrl({filename: this.avatarFileName, folder: "profile"});
        }
        return this.avatarFileName || require("@assets/male-placeholder.jpg");
    }

    @computed
    get birthday() {
        return moment(this.dob,serverTimeFormat).format("DD/MM/YYYY");
    }

    @computed
    get birthdaySelectorFormatted() {
        return this.dob ? moment(this.dob,serverTimeFormat).format("DD/MM/YYYY") : "";
    }

    @computed
    get studentIri() {
        return `/api/${this.studentEndPoint}/${this.studentId}`;
    }

    @computed
    get shouldShowReminder() {
        const {location} = routerStore;
        const {role} = authStore;
        if (location.pathname.includes("language") || location.pathname.includes("availability") || location.pathname.includes("purchase")) {
            return false;
        } else if (this.isFirstTime && (this.prevLocation !== location.pathname ) && role === "student") {
            return true;
        }
        return false;
    }

    @computed
    get router() {
        return routerStore;
    }

    @action
    setDob(value) {
        const d = moment(value);
        if (d.isValid()) {
            const date = moment(value, "DD/MM/YYYY");
            this.dob = date.utc(true).format(serverTimeFormat);
        }
    }

    @action.bound
    switchReminderModal() {
        const {location} = routerStore;
        this.prevLocation = location.pathname;
    }

    @action.bound
    setName(value) {
        const v = value.split(" ");
        this.firstName = v[0];
        this.lastName = v[1] ? v[1] : "";
    }

    @action.bound
    setFirstName(value) {
        this.firstName = value;
    }

    @action.bound
    setLastName(value) {
        this.lastName = value;
    }

    @action.bound
    setSkypeId(value) {
        this.skypeId = value;
    }

    @action.bound
    setProfession(value) {
        this.profession = value;
    }

    @action.bound
    setBio(value) {
        this.bio = value;
    }

    @action.bound
    load() {
        this.loading = true;

        service.getInitialUser()
            .then(resp => {
                if (resp.ok) {
                    const {data}: { data: ResponseType } = resp;
                    const {extraInformation} = data;
                    const balanceData = {
                        creditBalance: extraInformation.creditBalance,
                        creditExpiry: extraInformation.creditExpiry
                    };
                    this.hasCredits = extraInformation.creditBalance !== 0;

                    this.serializeData(data, false);
                    balanceStore.serializeData(balanceData);
                    this.hobbies = data.hobbies;
                    const {languages, id, studentLanguages} = extraInformation;
                    this.studentId = id;
                    if (authStore.role === "student") {
                        languageStore.consume(languages);
                        this.hasLanguages = languageStore.allData.length !== 0;
                    } else if (authStore.role === "cp") {
                        cpLanguageStore.consume(studentLanguages);
                    }
                    this.availabilities = new Availabilities(data.availabilities);
                    this.loaded = true;
                    this.loading = false;
                    if (this.isFirstTime && authStore.role === "student") {
                        if (languageStore.allData.length === 0) {
                            routerStore.replace("/language/add");
                        } else if (this.availabilities.availabilityBlocks.length === 0) {
                            routerStore.replace("/availability");
                        } else if (balanceStore.creditBalance === 0) {
                            routerStore.replace("/purchase");
                        }
                    }

                    authStore.loaded = true;
                } else if (resp.status === 401 || resp.status === 403 || resp.status === 404) {
                    authStore.setToken(null);
                    api.setHeader("X-AUTH-TOKEN", null);
                }
            });
        // .catch(e => {
        //   console.log(e);
        // });
    }

    @action.bound
    setHobbyInputValue(value) {
        this.hobbyInputValue = value;
    }

    @action.bound
    addHobby() {
        if (this.hobbyInputValue.trim()) {
            this.hobbies.push(this.hobbyInputValue.trim());
            this.hobbyInputValue = "";
        }
    }

    @action.bound
    removeHobby(hobby) {
        this.hobbies = this.hobbies.filter(h => h !== hobby);
    }

    @action.bound
    updateImage(imageName) {
      service.putUser({id: this.id, data: {avatarFileName: imageName}});
        this.avatarFileName = imageName;
    }

    @action.bound
    updateProfile() {
        const data = {
            hobbies: this.hobbies,
            profession: this.profession,
            dob: this.dob,
            skypeId: this.skypeId,
            bio: this.bio,
            firstName: this.firstName,
            lastName: this.lastName
        };
        routerStore.push("/profile");
        service.putUser({id: this.id, data})
            .then(() => toast.success("Profile updated"))
            .catch(e => console.log(e));
    }
}


const userStore = new UserStore();
window.userStore = userStore;

export default userStore;


type ResponseType = {
    id: number,
    availabilities: [],
    avatarFileName?: string,
    bio?: string,
    country?: string,
    dob?: string,
    email: string,
    extraInformation: {
        creditBalance: number,
        creditExpiry: string,
        focusOn: string,
        id: number,
        languages: [],
        lookingFor: string,
        purchases: [],
        sessions: [{
            code: string,
            cp: any,
            cpUrl?: string, dialect: { name: string, origin: string }, id: number, isCompleted: boolean, scheduledAt: string, studentUrl?: string, type: string
        }],
        sessionsPerWeek: number,
        subjectToCover: string,
        firstName: string,
        gender: "male" | "female", heardAboutUsFrom: string, hobbies: [string], id: number, isFirstTime: boolean, lastName: string, phoneNumber?: string, profession?: string, skypeId?: string, state?: string, title?: string
    }
};
