import React from "react";
import {StyledTag,StyledIcon} from "./styled";

type TagProps = {
  text: string,
  color?: string,
  onClose?: Function
}

const Tag = (props: TagProps) => {
  const {text,onClose} = props;
  return (
    <StyledTag {...props}>
      {text}
      {onClose?<StyledIcon color="white" type="x-circle" onClick={onClose} />:null}
    </StyledTag>
  );
};

Tag.defaultProps = {
  color: null,
  onClose: null
};

export default Tag;