import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import Icon from "@oovui/react-feather-icons";
import styled from "styled-components";
import FlexBox, {flexDirection} from "../FlexBox";
import {DayColumn, HeaderStyle, TimeBlockSessionStyle, TimeBlockStyle, HeaderContainerStyle} from "../Calendar/styled";
import DashboardBlock, {DashboardHeader} from "../DashboardBlock";


const BookingCalendar = inject("sessionBookingStore")(observer(({sessionBookingStore}) => {
  const {currentWeek} = sessionBookingStore;
  return (
    <FlexBox preventSelection direction={flexDirection.column}>
      <HeaderContainerStyle>
        <DashboardBlock.Header>
          {sessionBookingStore.currentDateForCalendar}
        </DashboardBlock.Header>
        <FlexBox>
          <IconButton color="black" type="chevron-left" size={24} onClick={sessionBookingStore.prevWeek}/>
          <DashboardBlock.Header style={{lineHeight: 1.5}}>
            {`${currentWeek[0].date} ${currentWeek[0].month}-${currentWeek[currentWeek.length - 1].date} ${currentWeek[currentWeek.length - 1].month}`}
          </DashboardBlock.Header>
          <IconButton color="black" type="chevron-right" size={24} onClick={sessionBookingStore.nextWeek}/>
        </FlexBox>
      </HeaderContainerStyle>
      <FlexBox
        direction={flexDirection.rowResponsive}>
        {
          sessionBookingStore.availabilities.map(day =>
            <AvailabilitiesColumn key={day.date} day={day} max={sessionBookingStore.maximumNumberOfBlocks}/>)
        };
      </FlexBox>
    </FlexBox>
  );
}));

const IconButton = styled(Icon)`
    cursor: pointer;
    background-color: transparent;
    border-radius: 100%;
    transition: 200ms all;
    margin: 0 10px;
    :hover{
        opacity: .6;
        background-color: lightgray;
        transition: 200ms all;
    }
`;

const AvailabilitiesColumn = observer(({day, max}) => {
  const emptyBlocks = [];
  for (let i = 0; i < (max - day.data.length); i++) {
    emptyBlocks.push(<TimeBlockStyle/>);
  }
  return (
    <DayColumn key={day.date}>
      <FlexBox>
        <HeaderStyle direction="column">
          <span>{day.name}</span>
          <DashboardHeader>{day.date}</DashboardHeader>
        </HeaderStyle>
      </FlexBox>
      {day.data.map(a => <TimeBlock key={a.id} availability={a} day={day.date}/>)}
      {emptyBlocks}
    </DayColumn>
  );
});


@inject("sessionBookingStore", "sessionStore")
@observer
class TimeBlock extends React.Component {

  @computed
  get isActive() {
    const {sessionBookingStore, availability} = this.props;
    return sessionBookingStore.selectedTimeBlocks.find(k => k === availability);
  }

  @computed
  get disabled() {
    const {availability} = this.props;
    return !availability.isAvailable;
  }

  selectForBooking = () => {
    const {sessionBookingStore, availability} = this.props;
    if (!this.disabled)
      sessionBookingStore.selectTimeBlock(availability);
  };


  render() {
    const {sessionBookingStore, availability} = this.props;
    return (
      <TimeBlockStyle disabled={this.disabled} onClick={this.selectForBooking}>
        <TimeBlockSessionStyle color={sessionBookingStore.color}>
          <TimeBlockSessionStyle.Content active={this.isActive}>
            <Icon type="clock" size={10}
                  color={this.isActive ? "white" : "black"}/><span>{`${availability.startString} - ${availability.endString}`}</span>
          </TimeBlockSessionStyle.Content>
        </TimeBlockSessionStyle>
      </TimeBlockStyle>
    );
  }
};


export default BookingCalendar;



