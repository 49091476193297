import React from "react";
import {computed, observable} from "mobx";
import {inject, observer} from "mobx-react";
import Table from "@components/Table";
import {Button, Icon, IconButton} from "rsuite";
import {withRouter} from "react-router-dom";
import Screen from "../../../components/Screen";
import {flexProps} from "../../../components/FlexBox";
import DashboardBlock from "../../../components/DashboardBlock";
import _ from "lodash";

@inject("adminStudentLanguageStore", "adminStudentStore", "adminCpStore", "routerStore")
@observer
class CpMatchedScreen extends React.Component {

    @observable
    shouldRender = false;

    componentDidMount(): void {
        const {adminStudentStore, adminCpStore} = this.props;
        if (!adminStudentStore.loaded && !adminStudentStore.loading)
            adminStudentStore.load();
        if (!adminCpStore.loaded && !adminCpStore.loading)
            adminCpStore.load();
        if (!this.store.loaded && !this.store.loading)
            this.store.load();

        setTimeout(() => {
            this.shouldRender = true;
        }, 200)
    }

    @computed
    get store() {
        const {adminStudentLanguageStore} = this.props;
        return adminStudentLanguageStore;
    }

    @computed
    get loading() {
        const {adminStudentStore, adminCpStore} = this.props;
        return this.store.loading || adminStudentStore.loading || adminCpStore.loading;
    }


    render(): React.ReactNode {
        const {routerStore} = this.props;

        return (
            <Screen full="both">
                <Screen.Column last basis="full">
                    <DashboardBlock title=" " full="both" direction="column" justify={flexProps.stretch}>
                        {this.shouldRender ?
                            <DataTable store={this.store} loading={this.loading} router={routerStore}/>
                            : null}
                    </DashboardBlock>
                </Screen.Column>
            </Screen>);
    }
}

const DataTable = observer(({store, router, loading}) => {
    return (
        <div style={{width: "100%", height: "100%"}}>
            <div
                style={{display: "none"}}>{store.processedMatchingData.map(d => `${d.id}${d.language.name}${d.student.name}${d.dialect.name}`)}</div>
            <Table sortType={store.sortType} rowHeight={50} height={680}
                   sortColumn={store.sortParam}
                   onSortColumn={store.sortBy}
                   data={store.processedMatchedData}
                   loading={store.loading}
                   virtualized
            >
                <Table.Column sortable resizable width={50}>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id"/>
                </Table.Column>
                <Table.Column sortable resizable width={130}>
                    <Table.HeaderCell>Student</Table.HeaderCell>
                    <NavCell dataKey="student" subDataKey="firstname" baseRoute="students" subDataString="name"
                             router={router}/>
                </Table.Column>
                <Table.Column sortable resizable width={130}>
                    <Table.HeaderCell>CP</Table.HeaderCell>
                    <NavCell dataKey="cp" subDataKey="firstname" baseRoute="cp" subDataString="name"
                             router={router}/>
                </Table.Column>
                <Table.Column sortable resizable width={100}>
                    <Table.HeaderCell>Language</Table.HeaderCell>
                    <NavCell dataKey="language" subDataKey="name" baseRoute="languages" subDataString="name"
                             router={router}/>
                </Table.Column>
                <Table.Column sortable resizable width={120}>
                    <Table.HeaderCell>Dialect</Table.HeaderCell>
                    <NavCell dataKey="dialect" subDataKey="id" subDataString="name" router={router}/>
                </Table.Column>
                <Table.Column sortable resizable width={120}>
                    <Table.HeaderCell>Level</Table.HeaderCell>
                    <Table.Cell dataKey="level"/>
                </Table.Column>
                <Table.Column sortable resizable width={100}>
                    <Table.HeaderCell>Credit Balance</Table.HeaderCell>
                    <SubCell dataKey="student.creditBalance" subDataKey="id" router={router}/>
                </Table.Column>
                <Table.Column sortable resizable width={130}>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.Cell dataKey="createdDate"/>
                </Table.Column>
                <Table.Column>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell/>
                </Table.Column>
            </Table>
        </div>
    );
});

const NavCell = ({rowData, dataKey, baseRoute, subDataKey, subDataString, router, ...props}) => {
    if (rowData[dataKey])
        return (
            <Table.Cell {...props}>
                <Button size="xs" onClick={() => {
                    if (router) router.push(`${baseRoute}/${rowData[dataKey].id}`);
                }}>{rowData[dataKey][subDataString]}</Button>
            </Table.Cell>
        );
    return null;
};

const ActionCell = withRouter(({rowData, dataKey, history, ...props}) => {
    return (
        <Table.Cell {...props}>
            <IconButton size="xs" onClick={() => {
                history.push(`/cp_matching/${rowData.id}`);
            }} icon={<Icon icon="eye"/>}/>
        </Table.Cell>
    );
});

const SubCell = ({rowData, dataKey, subDataString, ...props}) => {
    const data = _.get(rowData,dataKey);
    return (
        <Table.Cell {...props}>
            {data}
        </Table.Cell>
    );
};

export default CpMatchedScreen;
