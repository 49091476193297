import {observable, computed, action} from "mobx";
import bundleStore from "../domain/student/bundleStore";
import balanceStore from "../domain/student/balanceStore";
import userStore from "../domain/userStore";
import router from "../app/routerStore";
import api from "../../config/api";
import stripeService from "@config/stripe";

const service = {
  purchaseBundle: (data): Promise => api.post("/purchase", data)
};

class PurchaseStore {
  @observable
  selectedBundleId = null;

  @computed
  get selectedBundle() {
    return this.selectedBundleId ? bundleStore.find(this.selectedBundleId) : null;
  }

  @action.bound
  selectBundle(id) {
    this.selectedBundleId = id;
  }

  @observable
  _showSuccess = false;

  @computed
  get showSuccess(){
    return this._showSuccess && !this.selectedBundle;
  }


  @action.bound
  switchSuccess(){
    this._showSuccess = !this._showSuccess;
    this.selectedBundleId = null;
  }

  @action.bound
  goToDashboard(){
    router.push("/");
    this.switchSuccess();
  }

  @action.bound
  purchaseBundle() {
    const bundle = this.selectedBundleId;

    if(userStore.isFirstTime)
    {
      userStore.isFirstTime = false;
    }

    const data = {
      bundle,
      paid:true
    };

    service.purchaseBundle(data)
      .then(resp => {
        if (resp.ok) {
          const {data} = resp;
          const {success,stripeSession} = data;
          if(success) {
            stripeService.redirectCheckout(stripeSession);
          }
        }
      }).catch(e => console.log(e));
  }
}

const purchaseStore = new PurchaseStore();

export default purchaseStore;
