import React from "react";
import {observer, inject} from "mobx-react";
import colors from "@constants/colors";
import Modal from "../../../components/Modal";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import {DashboardHeader} from "../../../components/DashboardBlock";
import Button from "../../../components/Button";
import {UserStore} from "@stores/domain/userStore";


const FirstTimeReminderModal = inject("userStore")
(observer(({userStore}: { userStore: UserStore }) => (
    <Modal show={userStore.shouldShowReminder}>
        <FlexBox direction={flexDirection.column}>
            <DashboardHeader>Hello language lover!</DashboardHeader>
            <DashboardHeader subHeader>To get started, don’t forget to select a language, set your availability and purchase credits.</DashboardHeader>
            <FlexBox direction={flexDirection.row} justify={flexProps.end} margin={{top:20}}>
                <Button text="OK" background={colors.primary} iconLeft="check" onClick={userStore.switchReminderModal}/>
            </FlexBox>
        </FlexBox>
    </Modal>
)));


export default FirstTimeReminderModal;
