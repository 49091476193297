import api from "@config/api";
import {computed, action, set, observable} from "mobx";
import colors from "@constants/colors";
import _ from "lodash";
import {BaseModel, BaseStore} from "../BaseStore";
import studentStore from "./studentStore";
import sessionStore from "../sessionStore";
import languageStore, {LanguageDialect, LanguageModel} from "../languageStore";


const service = {
  getLanguages: (): Promise => api.get("languages"),
  getLanguage: (id): Promise => api.get(`languages/${id}`),
  updateLanguage: ({id, data}): Promise => api.post(`languages/${id}`, data),
};

export class StudentLanguageModel extends BaseModel {
  loader = service.getLanguage;

  updater = service.updateLanguage;

  deleter = service.deleteLanguage;

  parentStore = store;

  endPoint = "languages";

  @observable
  studentId;

  @observable
  id;


  @observable
  level;

  @observable
  dialectId;

  @observable
  color: string = "black";

  @observable
  enabledTypes = [];

  @observable
  completedSessionHours = 0;

  @observable
  totalSessionHours = 0;

  @observable
  pendingSessionHours = 0;

  @computed
  get student() {
    if (this.studentId)
      return studentStore.findOrAdd(this.studentId);
    return null;
  }

  @computed
  get defaultType(){
    return this.enabledTypes[0];
  }

  @computed
  get name() {
    const language = languageStore.findByDialect(this.dialectId);
    return language ? language.name : "loading";
  }

  @computed
  get plainDialect(): ?LanguageDialect {
    const dialect = languageStore.findDialect(this.dialectId);

    return dialect || {id: 0, name: "loading"};
  }

  @computed
  get dialect(): string {
    return this.plainDialect ? this.plainDialect.name : "loading";
  }

  @computed
  get plainLanguage(): ?LanguageModel {
    const language = languageStore.findByDialect(this.dialectId);

    return language || {id: 0, name: "loading"};
  }

  @computed
  get language(): string {
    return this.plainLanguage.name;
  }


  @computed
  get sessions() {
    return sessionStore.data.filter(s => s.studentLanguageId === this.id);
  }

  @computed
  get completedSessions() {
    return this.sessions.filter(s => s.isCompleted === true);
  }

  @computed
  get activeSessions() {
    return this.sessions.filter(s => s.isCompleted === false);
  }

  @computed
  get hoursCompleted() {
    return _.sumBy(this.completedSessions, s => s.duration) / 60;
  }


  @action
  addPendingSessionHours(duration){
    const d= duration /60;
    this.pendingSessionHours +=d;
    this.totalSessionHours +=d;
  }

  @action
  subPendingSessionHours(duration){
    const d = duration/60;
    this.pendingSessionHours -=d;
    this.totalSessionHours -=d;
  }

  constructor(args, index) {
    super(args);
    this.color = colors.gradient[index % 5];
    sessionStore.consume(args.activeSessions, this.id);
  }

  @action
  serializeData(data) {
    this.id = data.id;
    this.level = data.level.name;
    this.dialectId = data.languageDialect.id;
    this.enabledTypes = data.enabledTypes;
    this.completedSessionHours = data.completedSessionHours;
    this.pendingSessionHours = data.pendingSessionHours;
    this.totalSessionHours = data.totalSessionHours;
    if (data.student && typeof data.student !== "number") {
      studentStore.findOrAdd(data.student.user).dialectId = this.dialectId;
      this.studentId = data.student.user.id;
    }
  }
}

export class StudentLanguageStore extends BaseStore {

  loader = service.getLanguages;

  DataModel = StudentLanguageModel;

  @observable
  allData = [];

  @action
  consume(data: [Object]) {
    const filteredData = data.filter(d => d.cp !== null);
    filteredData.map(d => this.data.push(new StudentLanguageModel(d, this.data.length)));

    this.allData = [...this.data];
    const otherData = data.filter(d=>d.cp === null);
    otherData.map(d => this.allData.push(new StudentLanguageModel(d, this.allData.length)));
    this.react();
  }

  @action.bound
  clear() {
    this.data = [];
    this.allData = [];
  }


  @computed
  get completedSessionHours(){
    return _.sumBy(this.data,d=>d.completedSessionHours);
  }

  @computed
  get totalSessionHours(){
    return _.sumBy(this.data,d=>d.totalSessionHours);
  }

  @computed
  get pendingSessionHours(){
    return _.sumBy(this.data,d=>d.pendingSessionHours);
  }

}

const store = new StudentLanguageStore();
window.cpStudentLanguageStore = store;

export default store;