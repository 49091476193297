import styled, {css} from "styled-components";
import FlexBox from "../FlexBox";
import colors from "../../constants/colors";
import {edgeStyle} from "../../utils/styles";

const backgroundStyle = css`
    background-color: ${props => props.plain ? "transparent" : colors.white};
`;

const colorStyle = css`
    color: white;
`;

const nonBoldStyle = css`
    font-weight: normal;
`;

const boldStyle = css`
    font-weight: bold;
`;

const upperCaseStyle = css`
    text-transform: uppercase;
`;

const marginStyle = css`
    margin-bottom: ${props => props.marginBottom === true ? 10 : props.marginBottom}px;
`;

const nonMarginStyle = css`
    margin-bottom: 0;
`;

const italicStyle = css`
    font-style: italic;
`;

const centerTextStyle = css`
    text-align: center;
`;


/**
 * @component
 */
export const StyledBlock = styled(FlexBox).attrs((props) => ({

  ...props
}))`
    padding: 20px;
    ${props => !props.plain && backgroundStyle}
    ${props => props.noPad && css`padding:0px;`}
`;


/**
 * @component
 */
export const StyledHeader = styled.h4`
    font-family: Lato, sans-serif;
    font-size: 16px;
    margin-top:0;
    color: #000;
    ${props => props.light && colorStyle}
    ${props => props.subHeader && nonBoldStyle}
    ${props => !props.subHeader && upperCaseStyle}
    ${props => props.bold && boldStyle}
    ${props => (props.marginBottom && marginStyle) || nonMarginStyle}
    ${props => props.margin && edgeStyle("margin", props.margin, props.margin.mobile)}
    ${props => props.italic && italicStyle}
    ${props => props.centerText && centerTextStyle}
`;
