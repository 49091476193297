import React from "react";
import colors from "@constants/colors";
import {StyledTitle} from "./styled";
import FlexBox, {flexProps} from "../FlexBox";
import PlainButton from "../PlainButton";


type Props = {
  text: string,
  color?: string,
  borderColor?: string,
  center?: boolean,
  action?: {
    text: string,
    icon: string,
    color: string,
    onClick: Function
  }
}

const BlockHeader = ({children, action, text, color, borderColor,center}: Props)=>{
  return (
    <StyledTitle color={color} borderColor={borderColor}>
      <FlexBox direction="row"
               justify={center?flexProps.center:flexProps.spaceBetween}>
        <FlexBox f={1}>
        {text || children}
        </FlexBox>
        {action?<PlainButton color={colors.secondary} title={action.text} icon={action.icon} onClick={action.onClick}/>:null}
      </FlexBox>
    </StyledTitle>
  );
};

BlockHeader.defaultProps = {
  color: null,
  borderColor: null,
  action: null,
  center: false
};

export default BlockHeader;
