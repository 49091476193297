import {inject, observer} from "mobx-react";
import React from "react";
import FlexBox,{flexProps} from "../../../../../components/FlexBox";
import Button from "../../../../../components/Button";

const StepContinueButton = inject("languageAdditorStore")(observer(({disabled, onClick, goBack,submit, languageAdditorStore}) => (
  <FlexBox align={flexProps.center} justify={flexProps.center} margin={{vertical: 40}}
           direction="row-responsive">
    {goBack ? <Button withArrowLeft text="Back" backgroundColor="gray" onClick={languageAdditorStore.stepDown}
                      margin={{right: 20}}/> : null}
    <Button withArrow text={submit?"Submit":"Continue"} disabled={disabled} onClick={onClick}/>
  </FlexBox>
)));

export default StepContinueButton;