import {viewActionType as types} from "@constants/admin";

export default {
    isActive:{
        title:"Active",
        type: types.boolean
    },
    name:{
        title: "Name"
    },
    "sessionTypeObject.name":{
        title: "Session Type"
    },
    price:{
        title: "Price",
        type: types.currency
    },
    creditAmount:{
        title: "Credit Amount"
    },
    duration:{
        title: "Duration",
        prefix:"minutes"
    }
}
