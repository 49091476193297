import {observable, action, computed} from "mobx";
import generalConfigStore from "./generalConfigStore";
import {SessionModel} from "../domain/sessionStore";


export class SessionCancelStore {

  @observable
  selectedSession: SessionModel = null;

  @computed
  get showModal() {
    return !!this.selectedSession;
  }

  @computed
  get modalMessage() {
    if (this.showModal) {
      const isCp = generalConfigStore.renderForCp;
      if (isCp)
        return "Student will be refunded the credit of this session";
      if (this.selectedSession.moreThan24) {
        return "You will be refunded the credit of this session";
      }
      return "You will not be refunded the credit of this session";
    }
    return null;
  }

  @action
  selectSession = (session) => {
    this.selectedSession = session;
  };

  @action
  cancelConfirmation = () => {
    this.selectedSession = null;
  };

  @action
  confirm = () =>{
    this.selectedSession.cancel();
    this.selectedSession = null;
  }

}

const store = new SessionCancelStore();

export default store;