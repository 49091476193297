import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import Screen from "@components/Screen";
import DashboardBlock from "@components/DashboardBlock";
import FlexBox from "@components/FlexBox";
import ProfileImage from "@components/ProfileImage";
import Button from "@components/Button";
import {viewSchema} from "@schemas/Admin";
import ViewDetails from "@containers/Admin/ViewDetails";


@inject("adminCpStore")
@observer
class ViewCp extends React.Component {

    @computed
    get _cp() {
        const {adminCpStore, match} = this.props;
        const id = ~~match.params.id;
        const cp = adminCpStore.data.find(c => c.id === id);

        return cp || null;
    }

    componentDidMount(): void {
        const {adminCpStore} = this.props;
        if (!adminCpStore.loaded && !adminCpStore.loading) {
            adminCpStore.load();
        }
    }

    _onEdit = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/edit`)
    };

    _onStudentsClick = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/students`)
    };

    _onSessionsClick = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/sessions`)
    };

    _onDialectsClick = () => {
        const {history, match} = this.props;
        history.push(`${match.url}/dialects`)
    };

    render() {
        window.test = this._cp;
        if (this._cp && this._cp.dialects) {
            return (
                <Screen.Column last basis="full">
                    <DashboardBlock title={this._cp.fullName}
                                    action={{text: "Edit", icon: "edit", onClick: this._onEdit}}>
                        <FlexBox direction="row-responsive" full>
                            <FlexBox direction="column" margin={{right: 20, mobile: {right: "0", bottom: 20}}}>
                                <ProfileImage src={this._cp.profilePicture}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {bottom: 20}}}>
                                <ViewDetails schema={viewSchema.cp} entity={this._cp}/>
                            </FlexBox>
                            <FlexBox direction="column" childrenSpace={10}>
                                <Button text="Students" onClick={this._onStudentsClick}/>
                                <Button text="Sessions" onClick={this._onSessionsClick}/>
                                <Button text="Dialects" onClick={this._onDialectsClick}/>
                            </FlexBox>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );
        }

        return (
            <div>loading</div>
        );
    }
}


export default ViewCp;
