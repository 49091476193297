import viewSchemas from "./viewSchemas";
import tableSchemas from "./tableSchemas";

export const viewSchema = viewSchemas;
export const tableSchema = tableSchemas;

export default {
  viewSchema,
  tableSchemas
}
