import styled,{css} from "styled-components";


const buttonColorStyle = css`
        color: ${props=>props.color};
`;

const lightDarkColorStyle = css`
    color: ${props=>props.light?"rgba(255,255,255,0.8)":"rgba(0,0,0,0.8)"};
`;

const clickableStyle = css`
    cursor: pointer;
`


/**
 * @component
 */
export const StyledPlainButton = styled.button`
    font-size:16px;
    background-color: transparent;
    border:none;
    ${props=>!props.unClickable && clickableStyle}
    ${props=>props.color && buttonColorStyle ||lightDarkColorStyle}
    
`
