import {viewActionType as types} from "@constants/admin";


export default {
    name:{
        title: "Name"
    },
    origin:{
        title: "Origin"
    },
    isAvailable:{
        title: "Available",
        type: types.boolean
    },
    // enabledTypes:{
    //     title: "Session Types",
    //     type: types.array
    // }
};
