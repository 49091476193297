import DashboardScreen from "@screens/DashboardScreen";
import DashboardHome from "@screens/DashboardScreen/DashboardHome";
import ProfileScreen from "@screens/DashboardScreen/ProfileScreen";
import ProfileEditScreen from "@screens/DashboardScreen/ProfileEditScreen";
import CpProfileScreen from "@screens/DashboardScreen/CpProfileScreen";
import CpStudentScreen from "@screens/DashboardScreen/CpStudentScreen";
import StudentCpStore from "@screens/DashboardScreen/StudentCpScreen";
import SessionScreen from "@screens/DashboardScreen/SessionScreen";
import PurchaseCreditScreen from "@screens/DashboardScreen/PurchaseCreditScreen";
import LanguageAdditorScreen from "@screens/DashboardScreen/LanguageAdditorScreen";
import FirstTimeWalkthroughScreen from "@screens/DashboardScreen/FirstTimeWalkthroughScreen";
import LanguageAdditorSubmissionSuccessScreen
  from "@screens/DashboardScreen/LanguageAdditorScreen/SubmissionSuccessScreen";

import AvailabilityScreen from "@screens/DashboardScreen/AvailabilityScreen";

import LoginScreen from "@screens/Login";
import RegisterScreen from "@screens/Register";
import ForgotPasswordScreen from "@screens/ForgotPassword";
import ResetPasswordScreen from "@screens/ResetPassword";
/** Admin Only Routes */
import AdminCpMatchingStore from "@screens/Admin/CpMatchingScreen";
import AdminCpMatchingSubscreen from "@screens/Admin/CpMatchingScreen/CpMatchingEntityScreen";
import AdminCpMatchedStore from "@screens/Admin/CpMatchedScreen";
import AdminCpMatchedSubscreen from "@screens/Admin/CpMatchedScreen/CpMatchedEntityScreen";
import AdminCp from "@screens/Admin/ConversationPartner";
import AdminStudents from "@screens/Admin/Students";
import AdminSessions from "@screens/Admin/Sessions";
import AdminLanguages from "@screens/Admin/Languages";
import AdminBundles from "@screens/Admin/Bundles";
import AdminSessionType from "@screens/Admin/SessionType";
import AdminAdminStore from "@screens/Admin/Admins";

import authStore from "@stores/domain/authStore";
import {computed} from "mobx";

/**
 * Student Specific routes
 * @type {*[]}
 */
export const studentRoutes = [
  {
    name: "Dashboard",
    exact: true,
    path: "/",
    icon: "layout",
    component: DashboardScreen,
    inMenu: true,
    parent: true,
  }, {
    name: "DashboardHome",
    exact: true,
    path: "/",
    icon: "layout",
    component: DashboardHome,
  }, {
    name: "Profile",
    exact: true,
    path: "/profile",
    icon: "user",
    component: ProfileScreen,
    inMenu: true,
  },{
  name: "Profile Edit",
    exact:true,
    path: "/profile/edit",
    component: ProfileEditScreen,
    inMenu: false
  },
  {
    name: "Sessions",
    exact: true,
    path: "/sessions",
    icon: "calendar",
    component: SessionScreen,
    inMenu: true,
  },
  {
    name: "CpProfile",
    exact: true,
    path: "/cp/:id",
    component: StudentCpStore,
    inMenu: false
  }, {
    name: "LanguageAddition",
    exact: true,
    path: "/language/add",
    component: LanguageAdditorScreen,
    inMenu: false
  }, {
    name: "LanguageAdditionSuccess",
    exact: true,
    path: "/language/add/success",
    component: LanguageAdditorSubmissionSuccessScreen,
    inMenu: false
  }, {
    name: "FirstTimeWalkthroughScreen",
    exact: true,
    path: "/first_time",
    component: FirstTimeWalkthroughScreen,
    inMenu: false
  }, {
    name: "AvailabilityScreen",
    exact: true,
    path: "/availability",
    component: AvailabilityScreen,
    inMenu: false
  },{
    name: "PurchaseScreen",
    exact: true,
    path: "/purchase",
    component: PurchaseCreditScreen,
    inMenu: false
  }
];

/**
 * CP Specific Routes
 * @type {*[]}
 */
export const cpRoutes = [
  {
    name: "Dashboard",
    exact: true,
    path: "/",
    icon: "layout",
    component: DashboardScreen,
    inMenu: true,
    parent: true,
  }, {
    name: "DashboardHome",
    exact: true,
    path: "/",
    icon: "layout",
    component: DashboardHome,
  },{
    name: "Profile",
    exact: true,
    path: "/profile",
    icon:"user",
    component: CpProfileScreen,
    inMenu: true,
  },{
    name: "Profile Edit",
    exact:true,
    path: "/profile/edit",
    component: ProfileEditScreen,
    inMenu: false
  },{
    name: "AvailabilityScreen",
    exact: true,
    path: "/availability",
    component: AvailabilityScreen,
    inMenu: false
  },{
  name: "StudentScreen",
    exact: true,
    path: "/student/:id",
    component: CpStudentScreen,
    inMenu: false,
  }
];

/**
 * Admin Specific Routes
 * @type {Array}
 */
const adminDataRoutes =[];
// Object.keys(adminScreens).forEach(key=>{adminDataRoutes.push({
//   name: key,
//   exact:true,
//   path:`/${key.toLowerCase()}`,
//   icon: "layers",
//   component: adminScreens[key],
//   inMenu: true,
// })});


const adminRoutes = [{
  name: "Dashboard",
  exact: true,
  path: "/",
  icon: "layout",
  component: DashboardScreen,
  inMenu: true,
  parent: true,
},{
  name: "Admins",
  exact: false,
  path: "/admins",
  icon: "user",
  component: AdminAdminStore,
  inMenu: true,
  parent: false
},{
  name: "Cp Matching",
  exact: true,
  path: "/cp_matching",
  icon: "user",
  component: AdminCpMatchingStore,
  inMenu: true,
  parent: false
},{
  name: "Cp Matching",
  exact: true,
  path: "/cp_matching/:id",
  icon: "user",
  component: AdminCpMatchingSubscreen,
  inMenu: false,
  parent: false
},{
  name: "Active Pairs",
  exact: true,
  path: "/matches",
  icon: "user",
  component: AdminCpMatchedStore,
  inMenu: true,
  parent: false
},{
  name: "Matches",
  exact: true,
  path: "/matches/:id",
  icon: "user",
  component: AdminCpMatchedSubscreen,
  inMenu: false,
  parent: false
},{
  name: "CP",
  exact: false,
  path: "/cp",
  icon: "users",
  component: AdminCp,
  inMenu: true
},{
  name: "Students",
  exact: false,
  path: "/students",
  icon: "users",
  component: AdminStudents,
  inMenu: true
},{
  name: "Sessions",
  exact: false,
  path: "/sessions",
  icon: "calendar",
  component: AdminSessions,
  inMenu: true
},{
  name: "Dialects",
  exact: false,
  path: "/languages",
  icon: "activity",
  component: AdminLanguages,
  inMenu: true
},{
  name: "Bundles",
  exact: false,
  path: "/bundles",
  icon: "box",
  component: AdminBundles,
  inMenu: true
},{
  name: "Session Type",
  exact: false,
  path: "/session_types",
  icon: "activity",
  component: AdminSessionType,
  inMenu: true
}];

/**
 * Computed value to return routes based on user role
 * @type {any}
 */
export const mainRoutes = computed(() => {
  const {role} = authStore;
  switch (role) {
    case "student":
      return studentRoutes;
    case "cp":
      return cpRoutes;
    case "admin":
      return [...adminRoutes,...adminDataRoutes];
    default:
      return [];
  }
});

/**
 * Non authentication routes
 * @type {*[]}
 */
export const authRoutes = [
  {
    name: "Login",
    exact: true,
    path: "/",
    component: LoginScreen,
  }, {
    name: "LoginAlt",
    exact: true,
    path: "/login",
    component: LoginScreen,
  }, {
    name: "SignUp",
    exact: true,
    path: "/signup",
    component: RegisterScreen,
  },{
  name: "ForgotPassword",
    exact: true,
    path: "/forgot_password",
    component: ForgotPasswordScreen
  },{
  name: "ResetPassword",
    exact: true,
    path: "/reset_password^:token",
    component: ResetPasswordScreen
  }
];


export default {
  mainRoutes,
  authRoutes
};
