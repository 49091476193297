import React from "react";
import {inject, observer} from "mobx-react";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import {UserStore} from "@stores/domain/userStore";
import AccountInformationBlock from "@containers/dashboardBlocks/AccountInformationBlock";
import tzmoment from "moment-timezone";
import moment from "moment";
import TimeBlock from "@containers/dashboardBlocks/TimeBlock";
import Index from "@components/Screen";
import UserInterestsBlock from "@containers/dashboardBlocks/UserInterestsBlock";
import BioBlock from "@containers/dashboardBlocks/BioBlock";
import StudentReminderModal from "@screens/DashboardScreen/StudentRerminderModal";
import ImageUploader from "@components/ImageUploader";
import {observable} from "mobx";
import "rsuite/styles/icon.less"
import {uploadFile} from "@config/s3";

type ProfileScreenProps = {
    userStore: UserStore
}

@inject("userStore")
@observer
class ProfileScreen extends React.Component<ProfileScreenProps> {
    @observable
    controller = {file: ""};

    onImageChange = () => {
        const {userStore} = this.props;
        uploadFile(this.controller.file, "profile",userStore.email+(new Date().getTime())).then(resp =>
            userStore.updateImage(resp.key.split("/")[1])
        )
    };

    render() {
        const {userStore} = this.props;
        const {profilePicture} = userStore;

        const timeZoneType = tzmoment.tz.guess();
        const timezone = moment().format('Z');
        return (
            <Index>
                <StudentReminderModal userStore={userStore}/>
                <FlexBox basis="1/3" direction={flexDirection.column} margin={{right: 20, mobile: {right: "0"}}}>
                    <FlexBox align={flexProps.center} pad={{vertical: 26}} justify={flexProps.center}>
                        <ImageUploader onFileChange={this.onImageChange} profileSection={true} controller={this.controller} defaultImage={profilePicture}
                                       round size="big"/>
                    </FlexBox>
                    <TimeBlock timeZoneType={timeZoneType} timezone={timezone}/>
                </FlexBox>
                <FlexBox basis="2/3" direction={flexDirection.column}>
                    <AccountInformationBlock userStore={userStore}/>
                    <UserInterestsBlock userStore={userStore}/>
                    <BioBlock userStore={userStore}/>
                </FlexBox>
            </Index>
        );
    }
}


export default ProfileScreen;
