import React from "react";
import Badge from "@components/Badge";
import {observer,inject} from "mobx-react";
import DashboardBlock from "../../../components/DashboardBlock";
import {UserStore} from "../../../stores/domain/userStore";

const UserInterestsBlock = inject("routerStore")(observer(({userStore,type,routerStore}:{userStore:UserStore,type:"student"|"cp"}) => (
  <DashboardBlock wrap title={type === "cp"?"Interests":"Your Interests"} action={type==="cp"?null:{text: "Edit", icon: "edit",onClick:()=>routerStore.push("/profile/edit")}} direction="row">
    {userStore.hobbies && userStore.hobbies.map((m,index)=><Badge key={m} index={index} name={m}/>)}
  </DashboardBlock>
)));



export default UserInterestsBlock;