import React from "react";
import {observer} from "mobx-react";
import moment from "moment";
import FlexBox, {flexDirection} from "../FlexBox";
import {DayColumn, HeaderStyle, LeftBlockStyle, TimeBlockStyle} from "./styled";
import {DashboardHeader} from "../DashboardBlock";

function createCalendarTiming(startTime, A, targetHours) {
  const times = [];
  let m = A;
  for (let i = 0; i < targetHours; i += 1) {
    let toPush = startTime+i;
    if (toPush > 11 && toPush < 24) {
      m= "pm";
      if(toPush>12)
      toPush -= 12
    }else if(toPush === 24){
      toPush = 12;
      m="am"
    }else{
      m="am"
    }
    times.push(`${toPush} ${m}`);
  }

  return times;
}

function parseHourFromTiming(value: string) {
  let toReturn: string;
  let unit;
  if (value.includes("am")) {
    toReturn = value.substr(0, value.indexOf("am") - 1);
    toReturn = parseInt(toReturn, 0);
    unit = "am";
  } else {
    toReturn = value.substr(0, value.indexOf("pm") - 1);
    toReturn = parseInt(toReturn, 0) + 12;
    unit = "pm";
  }

  return {value: toReturn, unit};
}


function getCurrentWeek() {
  const days = [];
  const mtime= moment();
  for(let i = 0; i< 7 ; i++){
    mtime.add({days:1});
    days.push({name:mtime.format('ddd'),date:mtime.format('DD')});
  }

  return days;
}

// const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const days = getCurrentWeek();
const morning = createCalendarTiming(1, "am", 8);
let {value, unit} = parseHourFromTiming(morning[morning.length - 1]);
const afternoon = createCalendarTiming(value + 1, unit, 8);
const afternoonEnding = parseHourFromTiming(afternoon[afternoon.length - 1]);
value = afternoonEnding.value;
unit = afternoonEnding.unit;
const night = createCalendarTiming(value + 1, unit, 8);

const data = {morning, afternoon, night};


export const AvailabilitySelectionCalendar = ({timeRange}) => {
  return (
    <FlexBox direction={flexDirection.rowResponsive}>
      <DayColumn static>
        <LeftBlockStyle/>
        {
          data[timeRange].map((t)=><LeftBlockStyle>{t}</LeftBlockStyle>)
        }
      </DayColumn>
      {days.map((day) => (
        <DayColumn>
          <HeaderStyle>{day}</HeaderStyle>
          {data[timeRange].map(()=><TimeBlockStyle/>)}
        </DayColumn>)

      )}
    </FlexBox>
  );
};



export const SelectionCalendar = observer(({timeRange}) => {

  return (
    <FlexBox direction={flexDirection.rowResponsive}>
      <DayColumn static>
        <LeftBlockStyle/>
        {
          data[timeRange].map((t)=>
            <LeftBlockStyle>{t}</LeftBlockStyle>)
        }
      </DayColumn>
      {days.map((day) => (
        <DayColumn>
          <FlexBox>
            <HeaderStyle direction="column">
              <span>{day.name}</span>
              <DashboardHeader>{day.date}</DashboardHeader>
            </HeaderStyle>
          </FlexBox>
          {data[timeRange].map(()=><TimeBlockStyle/>)}
        </DayColumn>)
      )}
    </FlexBox>
  );
});



export default SelectionCalendar;
