import React from "react";
import FlexBox, {flexProps} from "@components/FlexBox";
import styled from "styled-components";
import BlockHeader from "../BlockHeader";
import {StyledBlock, StyledHeader} from "./styled";
import {AccountInformationRow} from "../../containers/dashboardBlocks/styled";


type PropType = {
  title?: string,
  plain?: boolean,
  align?: flexProps,
  justify?: flexProps,
  center?: boolean,
  direction?: "row" | "column",
  children?: [React.ReactNode],
  action?: {
    text: string,
    icon?: string,
    onClick?: Function
  }
}

type MultiColumnType = {
  title?: string,
  plain?: boolean,
  align?: flexProps,
  justify?: flexProps,
  direction?: "row" | "column",
  children?: [React.ReactNode],
  action?: {
    text: string,
    icon?: string,
    onClick?: Function
  }
}

type HeaderType = {
  children?: React.ReactNode | [React.ReactNode],
  subHeader?: boolean,
  light?: boolean,
  bold?: boolean,
  marginBottom?: boolean,
  italic?:boolean,
  centerText?:boolean,
  upperCase?:boolean
}


const DashboardBlock = (props: PropType) => {
  const {title, action, full,center,basis,margin,...blockProps} = props;
  return (
    <FlexBox noShrink full={full} basis={basis} margin={margin} direction="column">
      {title ? <BlockHeader text={title} center={center}  action={action}/> : null}
      <StyledBlock {...blockProps}>
      </StyledBlock>
    </FlexBox>
  );
};


DashboardBlock.Header = styled(FlexBox).attrs({f: 1})`
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
`;

DashboardBlock.Value = styled(FlexBox).attrs({f: 1})`
    text-align: left;
`;

export const MultiColumnBlock = (props: MultiColumnType) => {
  const {title, action} = props;

  return (
    <FlexBox noShrink direction="column">
      {title ? <BlockHeader text={title} action={action}/> : null}
      <FlexBox  {...props}/>
    </FlexBox>
  );
};

MultiColumnBlock.Block = (props) => {
  const {last, pColumn} = props;
  const margin = pColumn ? {
    bottom: last ? 40 : 20
  } : {
    right: last ? 0 : 20
  ,
    mobile: {
    right: "0",
      bottom: last ? "0": 20
  }};

  return (
    <StyledBlock {...props} margin={margin}>
    </StyledBlock>
  );
}
;

DashboardBlock.defaultProps = {
  title: null,
  plain: false,
  align: flexProps.start,
  justify: flexProps.start,
  direction: "column",
  children: null,
  action: null,
  center: false,
};

export default DashboardBlock;

export const DashboardHeader = (props: HeaderType) => {
  return (
    <StyledHeader {...props}/>
  );
};

DashboardHeader.defaultProps = {
  children: null,
  subHeader: false,
  light: false,
  bold: false,
  marginBottom: true,
  italic: false,
  centerText: false,
  upperCase:false
};
;
