import styled, {css} from "styled-components";
import colors from "@constants/colors";
import Icon from "@oovui/react-feather-icons";
import {breakpointStyle} from "@utils/styles";
import StyledFlex from "../FlexBox/styled";

/**
 * @component
 */
export const BaseButton = styled.a`
  font-family: Railway, sans-serif;
  text-align: center;
  text-decoration: none;
  cursor:  ${({disabled}) => disabled ? "not-allowed" : "pointer"};
  ${({plain, disabled, round}) => round ? `
    padding: 14px 17.5px;
    border-radius: 10px;
    background-color: ${disabled ? colors.textLight : colors.teal};
    border:none;
    color: ${colors.white};
    font-size: 18px;
    font-weight:500;
  ` : !plain ? `
    border-bottom: solid ${disabled ? colors.textLight : colors.orange} 2px;
    color: ${disabled ? colors.textLight : colors.navy};
    font-weight: 900;
    font-size: 17px;
    letter-spacing: 0.2px;
    line-height: 23px;
  ` : `
    color: ${disabled ? colors.textLight : colors.black};
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.36px;
    line-height: 20px;
  `};
`;

const nonDisabledStyle = css`
    background-color: ${props => props.backgroundColor ? (colors[props.backgroundColor] || props.backgroundColor) : colors.secondary};
    cursor: pointer;
`;

const disabledStyle = css`
    background-color: ${colors.disabled};
    cursor: not-allowed;
`;

const colorStyle = css`
    color: ${props => props.color ? (colors[props.color] || props.color) : props.plain ? colors.black : colors.white};
`;

const backgroundStyle = css`
    background-color: ${props => props.background};
`;

const widthStyle = css`
    min-width: ${props => props.width ? props.width : props.block ? `100%` : `170px`};
`;

const alignStyle = (props) => {
  if (props.iconLeft)
    return css`
      justify-content: flex-start;
    `;
  if (props.iconRight || props.withArrow || props.withArrowLeft)
    return css`
      justify-content: space-between;
    `;
  return css`
    justify-content: center;
`;
};

const borderStyle = css`
    border-radius: 6px;
`;

const primaryStyle = css`
    background-color: ${colors.primary};
`;

const iconMarginStyle = (position) => {
  const margin = 5;
  if (position === "right") {
    return css`
      margin-left: ${margin}px;
    `;
  }
  if (position === "left") {
    return css`
      margin-right: ${margin}px;
   `;
  }
};


const plainStyle = css`
    background-color:transparent;
    color:black;
`;

export const mobileStyle = breakpointStyle({
  content: css`
    margin-bottom: 20px;
    justify-content: center;
`
});

/**
 * @component
 */
export const StyledIcon = styled(Icon)`
    ${props => props.position && iconMarginStyle(props.position)}
`;


/**
 * @component
 */
export const StyledButton = styled(StyledFlex).attrs((props) => ({
  direction: "row",
  pad: {horizontal: props.block ? 20 : 15},
  align: "center",
  ...props
}))`
    font-family: Lato, sans-serif;
    height: 40px;
    font-size: 12px;
    border:none;
    text-decoration: none;
    ${props => (props.disabled && disabledStyle) || nonDisabledStyle}
    ${props => props.plain && plainStyle}
    ${props => props.round && borderStyle}
    ${colorStyle}
    ${props => (props.primary && !props.disabled) && primaryStyle}
    ${props => (!props.primary && props.background) && backgroundStyle}
    ${widthStyle}
    ${alignStyle}
    ${mobileStyle}
    
    &.active{
        color:${colors.white};
        background-color:${colors.primary};
        
        > ${StyledIcon} > svg{
          color:white;
        }
    }
`;

/**
 * @component
 */
export const Arrow = styled(BaseButton)`
  border-bottom: none;
  font-weight: bold;
  font-size: 28px;
  line-height: 18px;
`;
