import React from "react";
import {inject, observer} from "mobx-react";
import Modal from "@components/Modal";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import {ObservedInput} from "@components/Input";
import {action, computed, observable, reaction} from "mobx";
import languageStore from "@stores/domain/languageStore";
import Button from "@components/Button";
import {DashboardHeader} from "@components/DashboardBlock";
import {withRouter} from "react-router-dom";
import {InputLabel} from "@components/Input/styled";
import CpLanguageModel from "@stores/models/cpLanguageModel";
import {skills, structures} from "@constants/dialect";
import {cloneStore} from "@utils/mobx";
import {toast} from "@components/Toast";


class CpAddDialectModal extends React.Component {

    modalAction = "Add";

    @observable
    selectedLanguageId;
    @observable
    selectedDialectId;
    @observable
    selectedLevelId;

    prevLanguageHolder;

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(this.prevLanguageHolder !== this.selectedLanguageId){
            if(this.dialects.length > 0)
            this.cpDialect.dialectId = this.dialects[0].id;
            this.prevLanguageHolder = this.selectedLanguageId;
        }

    }

    componentWillUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {
        if(!this.props.show && nextProps.show){
            this.cpDialect = cloneStore(new CpLanguageModel());
            this.cpDialect.cpId = this.cp.id;
            this.selectedLanguageId = languageStore.data[0].id;
            let selectedDialect = languageStore.find(~~this.selectedLanguageId).dialects;
            selectedDialect && selectedDialect.length > 0 ? this.cpDialect.dialectId = selectedDialect[0].id : this.cpDialect.dialectId =  null;
        }
    }


    @observable
    cpDialect = cloneStore(new CpLanguageModel());



    @computed
    get cp() {
        const cpId = ~~this.props.match.params.id;
        const {adminCpStore} = this.props;

        const cp = adminCpStore.find(cpId);
        this.cpDialect.parentStore = cp;
        return cp;
    }

    @computed
    get languages() {
        this.selectedLanguageId = languageStore.data[0].id;
        return languageStore.data
    }

    @computed
    get dialects() {
        // let selectedDialect = languageStore.find(~~this.selectedLanguageId).dialects;
        // selectedDialect && selectedDialect.length > 0 ? this.cpDialect.dialectId = selectedDialect[0].id : this.cpDialect.dialectId =  null;
        return this.selectedLanguageId ? languageStore.find(~~this.selectedLanguageId).dialects : [];
    }

    componentDidMount(): void {
        const {languageLevelStore} = this.props;
        this.selectedLevelId = languageLevelStore.data[0].id;
        this.cpDialect.cpId = this.cp.id;
    }

    @action.bound
    onAction() {
        const {cancel} = this.props;
        // this.cp.addDialect({dialect: this.cpDialect,onDone:cancel});
        const existingDialect = this.cp.cpDialects.find(d => d.dialectId === this.cpDialect.dialectId);
        if (existingDialect) {
            console.log(existingDialect);
            toast.error(`This CP already have ${languageStore.findDialect(this.cpDialect.dialectId).name} assigned to him`);
        }else
            this.cpDialect.serializeAndAdd({
                language: "updateDialectId",
                levels: "updateLevels",
                cp: "updateCp"
            },this.cp.cpDialects).then(e=>{
                cancel();
            });
    }

    render() {
        const {languageLevelStore, show, cancel} = this.props;
        window.test = this.cpDialect;
        return (
            <Modal show={show}>
                <FlexBox direction={flexDirection.column}>
                    <DashboardHeader>{`${this.modalAction} Dialect`}</DashboardHeader>
                    <FlexBox direction={flexDirection.rowResponsive}>
                        <FlexBox direction={flexDirection.column} f={1} align={flexProps.stretch} margin={{right:20,mobile:{right:0}}}>
                            <InputLabel>Language</InputLabel>
                            <ObservedInput type="select" fill="horizontal"
                                           value={this.selectedLanguageId}
                                           onChange={(l) => {
                                               this.selectedLanguageId = ~~l.nativeEvent.target.value
                                           }}
                                           options={this.languages.map(l => ({value: l.id, name: l.name}))}/>
                            <InputLabel>Dialect</InputLabel>
                            <ObservedInput type="select" fill="horizontal"
                                           value={this.cpDialect.dialectId}
                                           onChange={(l) => {
                                               this.cpDialect.dialectId = ~~l.nativeEvent.target.value
                                           }}
                                           options={this.dialects.map(l => ({value: l.id, name: l.name}))}/>
                            <InputLabel>Level</InputLabel>
                            <FlexBox direction={flexDirection.column} margin={{top:10,bottom:20}}>
                                {
                                    languageLevelStore.data.map(l=>
                                        <ObservedInput type="checkbox" label={l.name} key={l.id} containerProps={checkboxStyle}
                                                       checked={this.cpDialect.levels.find(level => level.id === l.id)}
                                                       onClick={()=>this.cpDialect.addOrRemoveLevel(l.id)}
                                        />)
                                }
                            </FlexBox>
                        </FlexBox>
                        <FlexBox direction={flexDirection.column} f={1} >
                            <InputLabel>Structure</InputLabel>
                            <FlexBox direction={flexDirection.column} margin={{top:10,bottom:20}}>
                                <ObservedInput type="checkbox" label="Structured Session" containerProps={checkboxStyle} checked={this.cpDialect.structure.includes(structures.structuredSession)} onClick={()=>this.cpDialect.addOrRemoveStructure(structures.structuredSession)}/>
                                <ObservedInput type="checkbox" label="Flowing Session" containerProps={checkboxStyle} checked={this.cpDialect.structure.includes(structures.flowSession)} onClick={()=>this.cpDialect.addOrRemoveStructure(structures.flowSession)}/>
                            </FlexBox>
                            <InputLabel>Skills</InputLabel>
                            <FlexBox direction={flexDirection.column} margin={{top:10,bottom:30}}>
                                <ObservedInput type="checkbox" label="Reading" containerProps={checkboxStyle} checked={this.cpDialect.skills.includes(skills.reading)} onClick={()=>this.cpDialect.addOrRemoveSkills(skills.reading)}/>
                                <ObservedInput type="checkbox" label="Writing" containerProps={checkboxStyle} checked={this.cpDialect.skills.includes(skills.writing)} onClick={()=>this.cpDialect.addOrRemoveSkills(skills.writing)}/>
                                <ObservedInput type="checkbox" label="Speaking" containerProps={checkboxStyle} checked={this.cpDialect.skills.includes(skills.speaking)} onClick={()=>this.cpDialect.addOrRemoveSkills(skills.speaking)}/>
                                <ObservedInput type="checkbox" label="Listening" containerProps={checkboxStyle} checked={this.cpDialect.skills.includes(skills.listening)} onClick={()=>this.cpDialect.addOrRemoveSkills(skills.listening)}/>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox direction={flexDirection.row} full="horizontal" justify={flexProps.spaceBetween}>
                        <Button text="Cancel" withArrowLeft onClick={cancel}/>
                        <Button text={this.modalAction === "Edit"?"Save":this.modalAction} primary iconRight="check" onClick={this.onAction}
                                disabled={this.cp.loading}/>
                    </FlexBox>
                </FlexBox>
            </Modal>
        )
    }
}

const checkboxStyle = {style:{margin:0,marginRight:20}};


@withRouter
@inject("adminCpStore", "languageLevelStore")
@observer
export class CpEditDialectModal extends CpAddDialectModal{

    modalAction = "Edit";


    @action.bound
    onAction() {
        const {cancel} = this.props;
        const existingDialect = this.cp.cpDialects.find(d => d.dialectId === this.cpDialect.dialectId && d.id !==this.cpDialect.id);
        if (existingDialect) {
            console.log(existingDialect);
            toast.error(`This CP already have ${languageStore.findDialect(this.cpDialect.dialectId).name} assigned to him`);
        }else
        this.cpDialect.serializeAndUpdate({
            language: "updateDialectId",
            levels: "updateLevels"
        }).then(cancel);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const prevId = prevProps.id;
        const {id} = this.props;
        console.log(id);
        if(!prevId && id){
            const dialect = this.cp.cpDialects.find(d=>d.id === id);
            this.cpDialect = cloneStore(dialect,false);
            this.selectedLanguageId = this.cpDialect.language.id;
        }
        super.componentDidUpdate(prevProps,prevState,snapshot);
    }

    componentWillUpdate(nextProps: any, nextState: any, nextContext: *): void {
    }


}

export default withRouter(inject("adminCpStore", "languageLevelStore")(observer(CpAddDialectModal)));
