import React from "react";
import {RadialChart} from "react-vis/es";
import styled from "styled-components";
import FlexBox from "@components/FlexBox";
import {generateChartData, generateChartValues} from "@utils/dataVisualization";


const RadialBlock = ({vertical, data, value,label}) => {
  return (
    <FlexBox style={{position: "relative"}} align="center" justify="center"
             margin={{left: vertical ? 0 : 70, mobile: {left: "0"}}}>
      <RadialChart
        width={210}
        height={210}
        data={data}
        innerRadius={92}
        radius={103}
        colorType="literal"
        animation
      />
      <Value value={value} label={label}/>
    </FlexBox>
  );
};


export const Value = ({value, label}) => (
  <FlexBox align="center" style={{position: "absolute"}} direction="column">
    <StyledValue>{value}</StyledValue>
    <StyledLabel>{label}</StyledLabel>
  </FlexBox>
);

const StyledValue = styled.span`
    font-size: 40px;
`;
const StyledLabel = styled.span`
    font-size: 12px;
`;

export default RadialBlock;