import {viewActionType as types} from "@constants/admin";
import {DashboardHeader} from "@components/DashboardBlock";
import {Button, Tag} from "rsuite";
import React from "react";
import _ from "lodash";
import FlexBox from "@components/FlexBox";


const ViewDetails = ({schema,entity})=>
{
  const keys = _.keys(schema);
  return keys.map(k=>
    <ViewEntry entry={schema[k]} value={_.get(entity,k)}/>
  )
};

const ViewEntry = ({entry,value})=>{
  const type = entry.type;
  let val = value;
  if(typeof value === "undefined" || value === null)
    return <DashboardHeader>{entry.title}: <span
        style={{fontWeight: "300",color:"red"}}>NONE</span></DashboardHeader>
  if (type)
  switch (type) {
    case types.boolean:
      val = value?"Yes":"No";
    break;
    case types.currency:
      val = `${value} $`;
      break;
    case types.email:
      val = <a href={`mailto:${value}`}>{value}</a>;
      break;
    case types.link:
      val = <Button appearance="primary" size="xs">{value}</Button>;
      break;
    case types.filter:
      if(value)
      val = <Button appearance="primary" size="xs">{value}</Button>;
      break;
    case types.array:
      val =<FlexBox childrenSpace={5} wrap="wrap">{value.map(v=><Button appearance="primary" size="xs">{entry.property?v[entry.property]:v}</Button>)}</FlexBox>;
      break;
    default:
      break;
  }
  return <DashboardHeader>{entry.title}: <span
    style={{fontWeight: "300"}}>{entry.prefix?`${val} ${entry.prefix}`:val}</span></DashboardHeader>
};

export default ViewDetails;
