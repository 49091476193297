import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import {tableSchema} from "@schemas/Admin";
import StoreDataScreen from "@containers/Admin/StoreDataScreen";
import {SubDataTable} from "@containers/Admin/StoreDataTable";

const dataScreen = (data) => (Component) =>{
    return ()=><Component {...data}/>
};

@inject("adminCpStore")
@observer
class CpSessions extends React.Component{

    componentDidMount(): void {
        const {adminCpStore} = this.props;
        if (!adminCpStore.loaded && !adminCpStore.loading) {
            adminCpStore.load();
        }
    }

    @computed
    get _cp() {
        const {adminCpStore, match} = this.props;
        const id = ~~match.params.id;
        const cp = adminCpStore.data.find(c => c.id === id);

        return cp || null;
    }

    @computed
    get Component(){
        return dataScreen({schema:tableSchema.session,data:this._cp.sessions,baseRoute:"sessions"})(SubDataTable);
    }

    render(){
        window.test = this._cp;
        // const Component = dataScreen({schema:tableSchema.student,data:this._cp.students,baseRoute:"students"})(SubDataTable);
        return <this.Component/>;
    }
}

export default CpSessions;
