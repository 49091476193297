import {computed, observable} from "mobx";
import {BaseModel, BaseStore} from "../BaseStore";
import api from "../../../config/api";
import authStore from "../authStore";
import languageStore from "../languageStore";
import Availabilities from "../subStores/availabilitySubstore";
import CpModel from "../../models/cpModel";

const endpoints = {
  fetchUserCps: ({id})=> api.get(`user/${id}/list`)
};


export class CpStore extends BaseStore{
  loader = ()=>endpoints.fetchUserCps({id:authStore.id});

  shouldClearOnLogout = true;

  DataModel = CpModel;
}

const cpStore = new CpStore();
window.cpStore = cpStore;

export default cpStore;
