import React from "react";
import Icon from "@oovui/react-feather-icons";
import colors from "../../constants/colors";
import {StyledBadge} from "./styled";


type BadgeProps = {
  index?: number,
  icon?: string,
  name: string
}

// Todo: Add children margin to children
/**
 * Badge Component represents user badges
 * @param {number} index - index needed to identify color
 * @param {string} icon - icon name
 * @param {string} name - text to be shown under the badge
 * @return {React.Component}
 * @constructor
 * @example
 * <Badge index={0} icon="text"/>
 */
const Badge = ({index, icon, name}: BadgeProps) => (
  <StyledBadge.Container>
    <StyledBadge lead index={index}>
      <Icon type={icon} size={28} color={colors.whiteOpace(0.8)}/>
    </StyledBadge>
    <StyledBadge.Text>
      {name}
    </StyledBadge.Text>
  </StyledBadge.Container>
);

/**
 *
 * @type {{icon: string, index: number}}
 */
Badge.defaultProps = {
  index: 0,
  icon: "star"
};

export default Badge;
