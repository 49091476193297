import React from "react";
import Screen from "@components/Screen";
import {inject, observer} from "mobx-react";
import SessionBookingBlock from "@containers/dashboardBlocks/SessionBookingBlock";
import BalanceBlock from "@containers/dashboardBlocks/BalanceBlock";
import styled from "styled-components";
import colors from "@constants/colors";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import DashboardBlock from "../../../components/DashboardBlock";
import FlexBox, {flexDirection, flexProps} from "../../../components/FlexBox";
import {ConfirmationButton} from "../../../containers/dashboardBlocks/SessionBookingBlock";


@observer
class SessionScreen extends React.Component {

  render(): React.ReactNode {
    return (
      <Screen>
        <Screen.Column basis="3/4" direction="column">
          <SessionBookingBlock/>
        </Screen.Column>
        <Screen.Column last basis="1/4" direction="column">
          <BalanceBlock vertical/>
          <ConfirmationButton />
        </Screen.Column>
        <SessionModal/>
      </Screen>
    );
  }

}

const SessionModal = inject("sessionBookingStore")(observer(({sessionBookingStore}) => {
  return (<Modal show={sessionBookingStore.showModal} direction="column">
    <DashboardBlock.Header>Please choose session type</DashboardBlock.Header>
    {sessionBookingStore.studentLanguage && sessionBookingStore.studentLanguage.enabledTypes.map(
      t => {
        return (<TypeEntry type={t} submitter={sessionBookingStore.bookSelectedSessions} color={sessionBookingStore.studentLanguage.color}/>);
      }
    )}
    <Button text="Cancel" onClick={sessionBookingStore.switchModal} block/>
  </Modal>);
}));


const TypeEntry = observer(({type,color,submitter}) => (
  <StyledTypeEntry color={color} onClick={()=>submitter(type)}>
    <StyledTypeEntry.Title>
      {type.name}
    </StyledTypeEntry.Title>
    <StyledTypeEntry.Cost>
      {type.cost}€
    </StyledTypeEntry.Cost>
  </StyledTypeEntry>));


const StyledTypeEntry = styled(FlexBox).attrs({
  direction: flexDirection.row,
  align: flexProps.center,
  pad: {horizontal: 20}
})`
    height: 40px;
    background-color: ${colors.whiteDarkAccent};
    margin-top: 20px;
    cursor: pointer;
    color: black;
    transition: 200ms background-color ease,200ms color ease;
    :last-of-type{
      margin-bottom:20px;
    }
    
    &:hover{
      background-color: ${props=>props.color || colors.primary};
      color: white;
    }
`;

StyledTypeEntry.Title = styled(FlexBox)`
    font-weight: bold;
    flex: 1;
`;

StyledTypeEntry.Cost = styled(FlexBox)`
    opacity: 0.5;
`;


export default SessionScreen;
