import React from "react";
import Screen from "@components/Screen";
import FlexBox, {flexDirection, flexProps} from "@components/FlexBox";
import DashboardBlock from "@components/DashboardBlock";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import Button from "@components/Button";
import Input from "@containers/Admin/Input";
import {cloneStore} from "@utils/mobx";
import {ObservedInput} from "@components/Input";
import {InputLabel} from "@components/Input/styled";

@inject("languageStore", "sessionTypeStore")
@observer
class Edit extends React.Component {

    @observable
    loading = false;

    @observable
    _entity;

    componentDidMount(): void {
        const {match, languageStore} = this.props;
        const id = ~~match.params.id;
        const language = languageStore.findDialect(id);
        if (language) {
            this._entity = cloneStore(language, true);
        }
    }

    _submit = e => {
        e.preventDefault();
        this._entity.serializeAndUpdate({enabledTypes: 'enabledTypesUris'}).then(() => this._goBack());
    };

    _goBack = e => {
        const {history} = this.props;
        if (e)
            e.preventDefault();
        history.goBack();
    };

    render() {
        const {sessionTypeStore} = this.props;
        window.test = this._entity;
        if (this._entity)
            return (
                <Screen.Column last basis="full">
                    <div style={{display: "none"}}>{this._entity.enabledTypes.map(d => d.id)}</div>
                    <DashboardBlock as="form" onSubmit={this._submit} title="Add a new language">
                        <FlexBox direction="row-responsive" loading={this.loading}>
                            <FlexBox direction="column" f={1} margin={{right: 20, mobile: {right: "0"}}}>
                                <Input type="text" fill label="Name" propName="name"
                                       store={this._entity}/>
                                <Input type="text" fill label="Origin" propName="origin"
                                       store={this._entity}/>
                            </FlexBox>
                            <FlexBox direction="column" f={1}>
                                <InputLabel>Enabled Types</InputLabel>
                                <FlexBox direction={flexDirection.column} margin={{top: 10, bottom: 20}}>
                                    {
                                        sessionTypeStore.data.map(l =>
                                            <ObservedInput type="checkbox" label={l.name} key={l.id}
                                                           containerProps={{style: {margin: 0, marginRight: 20}}}
                                                           checked={!!this._entity.enabledTypes.find(level => level.id === l.id)}
                                                           onClick={() => this._entity.addOrRemoveType(l.id)}
                                            />)
                                    }
                                </FlexBox>
                                <Input type="checkbox" label="Available" propName="isAvailable" store={this._entity}
                                       withMargin={false}/>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox direction="row-responsive" f={1} align={flexProps.end} pad={{top: 20}}
                                 justify={flexProps.spaceBetween}>
                            <Button text="Cancel" type="button" onClick={this._goBack} withArrowLeft/>
                            <Button text="Submit" iconRight="check" disabled={this._entity.hasVError} primary/>
                        </FlexBox>
                    </DashboardBlock>
                </Screen.Column>
            );
        return (
            <div>loading</div>
        )
    }

}

export default Edit;
