import React from "react";

import styled from "styled-components";
import colors from "../../constants/colors";
import FlexBox from "@components/FlexBox";


/**
 * @component
 */
export const StyledBox = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 5px solid ${colors.orange};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 5px 7px;
  width: 100%;
`;

/**
 * @component
 */
export const Logo = styled.img`
  height: 60px;
  width: 60px;
`;

/**
 * @component
 */
export const LogoText = styled.h1`
  color: ${colors.black};
  font-family: Raleway, sans-serif;
  font-size: 20px;
  letter-spacing: 0.28px;
  line-height: 29px;
  margin: 0;
  width: 137px;
  font-weight: normal;
`;

/**
 * @component
 */
export const MidContainer = styled.div`
  align-items: center;
  flex: 1;
  font-family: "Raleway", sans-serif;
  height: 100%;
  justify-content: center;
`;

export const BurgerContainer = styled(FlexBox)`
  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before { background-color: ${colors.secondary}; }
`;
