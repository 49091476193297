import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import {DayColumn, HeaderStyle, LeftBlockStyle, TimeBlockStyle} from "@components/Calendar/styled";
import moment from "moment";
import {AvailabilitySelectionStore} from "@stores/ui/availabilitySelectionStore";
import FlexBox, {flexDirection, flexProps} from "../../components/FlexBox";
import Input from "../../components/Input";

const weekDays = moment.weekdaysShort();


const AvailabilityCalendar = inject("availabilitySelectionStore")(observer(({availabilitySelectionStore}) => {

  return (
    <FlexBox direction={flexDirection.column} full="horizontal">
      <HoursSelector store={availabilitySelectionStore}/>
      <FlexBox preventSelection direction={flexDirection.rowResponsive}
               onMouseDown={availabilitySelectionStore.startSelecting}
               onMouseUp={availabilitySelectionStore.stopSelecting}>

        <DayColumn static>
          <LeftBlockStyle/>
          {
            availabilitySelectionStore.selectedHours.map((t,index)=>
              <LeftBlockStyle>{index === 0?null:t.viewFormat}</LeftBlockStyle>)
          }
        </DayColumn>
        {weekDays.map(day =>
          <AvailabilityColumn day={day}/>)}
      </FlexBox>
    </FlexBox>
  );
}));

const HoursSelector = observer(({store}) => {
  const {selectActiveHours, selectedHourRange} = store;
  const {HOURS_RANGE} = AvailabilitySelectionStore;
  return (
    <FlexBox direction={flexDirection.rowResponsive} justify={flexProps.center} full="horizontal" margin={{bottom:40}}>
      <Input type="radio" label="Day" onClick={selectActiveHours(HOURS_RANGE.day)}
             checked={selectedHourRange === HOURS_RANGE.day}/>
      <Input type="radio" label="Evening" onClick={selectActiveHours(HOURS_RANGE.evening)}
             checked={selectedHourRange === HOURS_RANGE.evening}/>
      <Input type="radio" label="Night" onClick={selectActiveHours(HOURS_RANGE.night)}
             checked={selectedHourRange === HOURS_RANGE.night}/>
    </FlexBox>
  );
});

@inject("availabilitySelectionStore","userStore")
@observer
class AvailabilityColumn extends React.Component {
  @computed
  get day() {
    const {userStore} = this.props;
    return userStore.availabilities.data.find(d => d.name === this.props.day) || {name: this.props.day, data: []};
  }

  render() {
    const {availabilitySelectionStore} = this.props;
    return (
      <DayColumn key={this.day.name}>
        <FlexBox>
          <HeaderStyle>
            <span>{this.day.name}</span>
          </HeaderStyle>
        </FlexBox>
        {availabilitySelectionStore.selectedHours.map(h =>
          <TimeBlock day={this.day}
                     store={availabilitySelectionStore}
                     hour={h}/>
        )}
      </DayColumn>
    );
  }
}

@observer
class TimeBlock extends React.Component {

  @computed
  get isActive() {
    const {hour, day} = this.props;
    const {data} = day;
    return !!data.find(d => d.startComparison === hour.format);
  }

  onClick = () => {
    const {hour, day, store} = this.props;
    store.selectTimeSlot({hour, day});
  };

  onStartSelection = () => {
    const {hour, day, store} = this.props;
    store.startChoosing(this.isActive, {hour, day});
  };


  render(): React.ReactNode {
    return (
      <TimeBlockStyle active={this.isActive} onMouseDown={this.onStartSelection} onMouseEnter={this.onClick}/>
    );
  }
}

export default AvailabilityCalendar;
