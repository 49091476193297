import {viewActionType as types} from "@constants/admin";


export default {
    "dialect.name":{
        title: "Name"
    },
    "language.name":{
        title: "Language"
    },
    "levels":{
        type:types.array,
        title: "Levels",
        subkey:"name"
    },
    "structure":{
        type:types.array,
        title: "Structure"
    },
    "skills":{
        type:types.array,
        title: "Skills",
    }
};
