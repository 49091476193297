import styled, {css} from "styled-components";
import colors from "@constants/colors";
import Icon from "@oovui/react-feather-icons";
import FlexBox, {flexProps} from "../FlexBox";

const colorStyle = css`
    background-color: ${props => props.color};
`;


/**
 * @component
 */
export const StyledTag = styled(FlexBox).attrs({
  pad: {vertical:5,horizontal:10},
  align: flexProps.center,
  justify:flexProps.center
})`
    margin-right: 10px;
    color: white;
    background-color: ${colors.primary};
    margin-bottom: 10px;
    :last-child{
      margin-right: 0;
    }
    ${props => props.color && colorStyle}
`;

/**
 * @component
 */
export const StyledIcon = styled(Icon)`
    cursor: pointer;
    margin-left: 5px;
    :hover{
      opacity: 0.5;
    }
`;
