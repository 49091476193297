import {observable, action, computed} from "mobx";
import {BaseStore, BaseModel} from "../BaseStore";
import api from "../../../config/api";
import sessionTypeStore from "@stores/domain/sessionTypeStore";

const endpoints = {
  fetchBundles: () => api.get("bundles"),
};

class BundleModel extends BaseModel {
  @observable
  active;

  @observable
  activationData;

  @observable
  sessionTypeId;

  endPoint = "bundles"

  serializeData(data, setLoadedOnDone = true) {
    if(data.sessionType)
    this.sessionTypeId = data.sessionType.id;
    super.serializeData(data, setLoadedOnDone);
  }
}

class BundleStore extends BaseStore {
  loader = endpoints.fetchBundles;

  @observable
  selectedPackage = null;

  @computed
  get canProceed() {
    return !!this.selectedPackage;
  }

  @action.bound
  selectPackage(id) {
    this.selectedPackage = id;
  }

  consume(data: [Object]) {
    super.consume(data);
  }

  @computed
  get enabledBundles(){
   const plainActiveTypes = sessionTypeStore.enabledTypes.map(t=>t.id);
   return this.data.filter(b=>plainActiveTypes.includes(b.sessionTypeId) && b.isActive)
  }

  DataModel = BundleModel;
}

const bundleStore = window.bundleStore = new BundleStore();

export default bundleStore;
