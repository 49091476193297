import {viewActionType as types} from "@constants/admin";


export default {
    "id":{
        title: "Id"
    },
    "firstName": {
        title: "First Name",
    },
    "lastName":{
        title: "Last Name",
    },
    "email":{
        title: "Email"
    },
    "isActive":{
        title: "Active",
        type: types.boolean
    }
};
