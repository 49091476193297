import styled, {css} from "styled-components";
import colors from "@constants/colors";
import {edgeStyle} from "../../utils/styles";


const progressStyle = css`
    width:${props => props.progress}%;
`;

const backgroundSyle = css`
    background-color:${props => props.color || colors.primary};
`;

const borderStyle = css`
    border-radius: 12px;
`;

const progressAnimationStyle = css`
    transition: width 200ms ease-in;
`;

/**
 * @component
 */
export const StyledContainer = styled.div`
    height:12px;
    overflow: hidden;
    width:100%;
    position: relative;
    ${props => props.margin && edgeStyle("margin", props.margin, props.margin.mobile)}
    ${borderStyle}
`;

/**
 * @component
 */
export const StyledTotalBar = styled.div`
    width:100%;
    height: 100%;
    background-color: #C7CDD9;
    ${borderStyle}
`;

/**
 * @component
 */
export const StyledProgressBar = styled.div`
    height:100%;
    position:absolute;
    top:0;
    left:0;
    ${backgroundSyle}
    ${progressStyle}
    ${borderStyle}
    ${progressAnimationStyle}
`;
