import React from "react";
import {inject, observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import {tableSchema} from "@schemas/Admin";
import {SubDataTable} from "@containers/Admin/StoreDataTable";
import DashboardBlock from "@components/DashboardBlock";
import CpAddDialectModal, {CpEditDialectModal} from "@containers/Modals/CpAddDialectModal";

const dataScreen = (data) => (Component) => {
    return () => <Component {...data}/>
};

@inject("adminCpStore")
@observer
class CpDialects extends React.Component {

    @observable
    showModal = false;

    @observable
    showEditModal = false;

    @observable
    editionId = null;

    @computed
    get _cp() {
        const {adminCpStore, match} = this.props;
        const id = ~~match.params.id;
        const cp = adminCpStore.data.find(c => c.id === id);

        return cp || null;
    }

    @computed
    get Component() {
        return dataScreen({
            schema: tableSchema.cp_dialects,
            data: this._cp.cpDialects,
            baseRoute: "dialect",
            onEdit:this.switchEditModal,
            noView: true,
        })(SubDataTable);
    }

    componentDidMount(): void {
        const {adminCpStore} = this.props;
        if (!adminCpStore.loaded && !adminCpStore.loading) {
            adminCpStore.load();
        }
    }

    switchModal = ()=>{
        this.showModal = !this.showModal;
    };

    switchEditModal = (id) => () =>{
        if(id){
            this.editionId = id;
        }else{
            this.editionId = null;
        }
        this.showEditModal = !this.showEditModal;
    };

    closeEditModal= ()=>{
        this.editionId = null;
        this.showEditModal = false;
    };

    render() {
        return <>
            <DashboardBlock full="both" title=" " action={{
                text: "Add", icon: "plus", onClick: this.switchModal
            }}>
                <this.Component/>
            </DashboardBlock>
            <CpAddDialectModal show={this.showModal} cancel={this.switchModal}/>
            <CpEditDialectModal show={this.showEditModal} id={this.editionId} cancel={this.closeEditModal}/>
        </>
    }
}

export default CpDialects;
