import DashboardBlock from "@components/DashboardBlock";
import AnalogClock,{Themes} from "react-analog-clock";
import React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import FlexBox, {flexProps} from "../../../components/FlexBox";


const TimeBlock = observer(({timeZoneType, timezone}) => (
  <DashboardBlock title=" " direction="column">
    <DashboardBlock.Header>{timeZoneType}</DashboardBlock.Header>
    <DashboardBlock.Value>{`${timezone} GMT`}</DashboardBlock.Value>
    <ClockContainer pad={{top: 30}}>
      <AnalogClock width={200} theme={Themes.light} gmtOffset={timezone}/>
    </ClockContainer>
  </DashboardBlock>
));


const ClockContainer = styled(FlexBox).attrs({
  align: flexProps.center,
  justify: flexProps.center,
  direction: "column",
  full: "horizontal"
})`
    > * {
      -webkit-box-sizing: content-box;-moz-box-sizing: content-box;box-sizing: content-box;
    }
`;

export default TimeBlock;