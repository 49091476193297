import api from "@config/api";
import {action, computed, observable} from "mobx";
import colors from "@constants/colors";
import _ from "lodash";
import moment from "moment";
import {BaseModel, BaseStore} from "../BaseStore";
import type {CpModal} from "./cp";
import cpStore from "./cp";
import type {Model as StudentModel} from "./student";
import studentStore from "./student";
import sessionStore from "./sessions";
import languageStore, {LanguageDialect, LanguageModel} from "../languageStore";
import type {SessionModel} from "../sessionStore";


const service = {
    getLanguages: (): Promise => api.get("student_languages"),
    getLanguage: (id): Promise => api.get(`student_languages/${id}`),
    updateLanguage: ({id, data}): Promise => api.put(`student_languages/${id}`, data),
};

export class StudentLanguageModel extends BaseModel {
    loader = service.getLanguage;

    updater = service.updateLanguage;

    deleter = service.deleteLanguage;

    parentStore = store;

    endPoint: string = "languages";

    @observable
    cpId: number;

    @observable
    id: number;


    @observable
    level;

    @observable
    dialectId;

    @observable
    color: string = "black";

    @observable
    enabledTypes = [];

    @observable
    completedSessionHours = 0;

    @observable
    totalSessionHours = 0;

    @observable
    pendingSessionHours = 0;

    @observable
    creditUsed = 0;

    constructor(args, index) {
        super(args);
        this.color = colors.gradient[index % 5];
        // sessionStore.consume(args.activeSessions, this.id);
    }

    /**
     * Cp bound to this studentLanguage if existed
     */
    @computed
    get cp(): CpModal {
        if (this.cpId)
            return cpStore.find(this.cpId);
        return null;
    }

    /**
     * Student bound to this studentLanguage if existed
     */
    @computed
    get student(): StudentModel {
        if (this.studentId)
            return studentStore.find(this.studentId);
        return null;
    }

    @computed
    get defaultType() {
        return this.enabledTypes[0];
    }

    @computed
    get name(): string | "loading" {
        const language = languageStore.findByDialect(this.dialectId);
        return language ? language.name : "loading";
    }

    @computed
    get dialect(): ?LanguageDialect {
        const dialect = languageStore.findDialect(this.dialectId);

        return dialect;
    }

    @computed
    get dialectName(): string {
        return this.dialect ? this.dialect.name : "loading";
    }

    @computed
    get language(): ?LanguageModel {
        const language = languageStore.findByDialect(this.dialectId);

        return language || {id: 0, name: "loading"};
    }

    @computed
    get languageName(): string {
        return this.language.name;
    }

    @computed
    get sessions(): Array<SessionModel> {
        return sessionStore.data.filter(s => s.studentLanguageId === this.id);
    }

    @computed
    get completedSessions() {
        return this.sessions.filter(s => s.isCompleted === true);
    }

    @computed
    get activeSessions() {
        return this.sessions.filter(s => s.isCompleted === false);
    }

    @computed
    get hoursCompleted() {
        return _.sumBy(this.completedSessions, s => s.duration) / 60;
    }

    @computed
    get createdDate() {
        return moment(this.createdAt).format("DD-MM-YYYY");
    }

    @action
    addPendingSessionHours(duration) {
        const d = duration / 60;
        this.pendingSessionHours += d;
        this.totalSessionHours += d;
    }

    @action
    subPendingSessionHours(duration) {
        const d = duration / 60;
        this.pendingSessionHours -= d;
        this.totalSessionHours -= d;
    }

    @action
    assignCp = (id) => {
        this.cpId = id;
        service.updateLanguage({id: this.id, data: {cp: this.cp.iri}}).then(r => {
        })
            .catch(e => alert(e.message));
    };

    @action
    serializeData(data) {
        try {
            this.id = data.id;
            this.level = data.level.name;
            this.dialectId = data.languageDialect.id;
            this.enabledTypes = data.enabledTypes;
            this.completedSessionHours = data.completedSessionHours;
            this.totalSessionHours = data.totalSessionHours;
            this.pendingSessionHours = data.pendingSessionHours;
            this.focusOn = data.focusOn;
            this.lookingFor = data.lookingFor;
            this.whatToCover = data.whatToCover;
            this.additionalRequests = data.additionalRequests;
            this.createdAt = data.createdAt;
            this.creditUsed = data.creditUsed;
            if (data.cp && typeof data.cp !== "number") {
                this.cpId = data.cp.id;
            }
            if (data.student && typeof data.student !== "number") {
                this.studentId = data.student.id;
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export class StudentLanguageStore extends BaseStore {

    loader = service.getLanguages;

    DataModel = StudentLanguageModel;


    @computed
    get baseCpData() {
        return this.data.filter(d => d.cp !== null);
    }

    @computed
    get baseNoCpData() {
        return this.data.filter(d => d.cp === null);
    }

    @computed
    get cpData() {
        return (studentStore.loaded && this.baseCpData) || [];
    }

    @computed
    get noCpData() {
        return (studentStore.loaded && this.baseNoCpData) || [];
    }


    @computed
    get isEmpty() {
        return this.data.length < 1;
    }

    @computed
    get completedSessionHours() {
        return _.sumBy(this.data, d => d.completedSessionHours);
    }

    @computed
    get totalSessionHours() {
        return _.sumBy(this.data, d => d.totalSessionHours);
    }

    @computed
    get pendingSessionHours() {
        return _.sumBy(this.data, d => d.pendingSessionHours);
    }

    @computed
    get processedMatchingData() {
        const data = this.data.filter(a => !a.cp);
        if (this.matchingSortParam) {
            const sortedData = _.sortBy(data, d => _.get(d, this.matchingSortParam));
            return this.sortType === "desc" ?
                sortedData.reverse() : sortedData;
        }
        return data;
    }

    @computed
    get processedData() {
        if (this.sortParam) {
            const sortedData = _.sortBy(this.data, d => d[this.sortParam]);
            return this.sortType === "desc" ?
                sortedData.reverse() : sortedData;
        }
        return this.data;
    }

    @computed
    get processedMatchedData() {
        const data = this.data.filter(a => !!a.cp);
        if (this.matchingSortParam) {
            const sortedData = _.sortBy(data, d => _.get(d, this.matchingSortParam));
            return this.sortType === "desc" ?
                sortedData.reverse() : sortedData;
        }
        return data;
    }

    @action
    consume(data: [Object]) {
        data.map(d => this.data.push(new StudentLanguageModel(d, this.data.length)));

        this.react();
    }

    @action.bound
    clear() {
        this.data = [];
    }

    findByDialect(id) {
        return this.data.find(f => f.dialectId === id);
    }

    @action.bound
    add(ob) {
        this.data.push(new this.DataModel(ob));
    }

    @observable
    matchingSortParam;

    @action
    sortBy = (param: string, type: string = "asc") =>{
        console.log(param);
        switch (param) {
            case 'student':
                this.matchingSortParam = 'student.name';
                break;
            case 'dialect':
                this.matchingSortParam = 'dialect.name';
                break;
            case 'language':
                this.matchingSortParam = 'languageName';
                break;
            case 'createdDate':
                this.matchingSortParam = 'createdAt';
                break;
            default:
                this.matchingSortParam = param;
                break;
        }
        this.sortParam = param;
        this.sortType = type;
    };

}

const store = new StudentLanguageStore();
window.adminStudentLanguageStore = store;

export default store;
