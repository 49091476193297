import React from "react";
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import StudentModel from "@stores/models/studentModel";
import {SubDataTable} from "@containers/Admin/StoreDataTable";
import {viewActionType as types} from "@constants/admin";


const dataScreen = (data) => (Component) => {
    return () => <Component {...data}/>
};

const tableSchema = {
    languageName: {
        title: "Language"
    },
    dialectName: {
        title: "Dialect"
    },
    level: {
        title: "Level"
    },
    "cp.name": {
        title: "CP",
        type: types.link,
        baseRoute: "/cp",
        routeKey: "cp.id"
    },
    creditUsed:{
        title: "Used Credits",
        type:types.currency
    }
}

@inject("adminStudentStore")
@observer
class StudentLanguages extends React.Component {

    @computed
    get _student(): StudentModel {
        const {adminStudentStore, match} = this.props;
        const id = ~~match.params.id;
        const student = adminStudentStore.data.find(c => c.id === id);

        return student || null;
    }

    @computed
    get _languages() {
        return null;
    }

    @computed
    get Component() {
        return dataScreen({
            schema: tableSchema,
            noDelete: true,
            noEdit: true,
            data: this._student.studentLanguages,
            baseRoute: "cp_matching"
        })(SubDataTable);
    }

    componentDidMount(): void {
        const {adminStudentStore} = this.props;
        if (!adminStudentStore.loaded && !adminStudentStore.loading) {
            adminStudentStore.load();
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this._student)
            return <this.Component/>;
        return null;
    }
}

export default StudentLanguages;
