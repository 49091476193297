import styled,{css} from "styled-components";
import colors from "@constants/colors";
import FlexBox, {flexDirection, flexProps} from "../FlexBox";
import Button from "../Button";
import {breakpointStyle} from "../../utils/styles";


/**
 * @component
 * @type {StyledComponent<"h3", any, {}, never>}
 */
export const StyledHeader = styled.h3`
    color: ${colors.secondary};
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    transition: 200ms all;
`;

export const StyledText = styled.p`
    font-size: 16px;
    font-style: italic;
    color: ${colors.black};
    transition: 200ms all;
    ${props => props.bold && css`font-weight: bold`}
`;

export const StyledDescription = styled.p`
    font-size: 12px;
    margin: 15px 0 0 0;
`;


export const StyledPrice = styled(FlexBox).attrs(props=>({
  ...props,
  align: flexProps.center,
  justify: flexProps.center,
  pad: 15,
}))`
    border-radius: 0 0 5px 5px;
    background-color: ${colors.secondary};
    color: rgba(255,255,255,.8);
    width:100%;
    font-size: 18px;
    font-weight: 800;
    transition: 200ms all;
`;

const selectedStyle = css`
      background-color: ${colors.secondary};
      color: ${colors.white};
      & ${StyledHeader} {
          color:${colors.white};
      }
      
      & ${StyledText}{
          color: ${colors.white}
      }
      
      & ${StyledPrice}{
          background-color: ${colors.primary};
      }
`;


/**
 * @component
 */
export const StyledContainer = styled(FlexBox).attrs(props => ({
  ...props,
  direction: flexDirection.column,
  align: flexProps.center,
  margin: {right: 30, mobile: "0"}
}))`
    width:200px;
    height: 300px;
    background-color: ${colors.darkAccent};
    cursor: pointer;
    transition: 200ms all;
    border-radius: 5px;
    margin-bottom: 20px;
    
    ${breakpointStyle({content:css`width: auto;`})}
    
    :hover{
      ${selectedStyle}
    }
    
    ${props=> props.isSelected && selectedStyle}
`;

/**
 * @component
 */
export const StyledButton = styled(Button).attrs(props => ({...props, round: true}))`
    min-width:100%;
    color: ${props => props.isSelected ? "white" : "black"};
    background-color: ${props => props.isSelected ? colors.primary : "#CCD9E9"};
`;

