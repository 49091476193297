import moment from "moment";
import PropTypes from "prop-types";
import {AvailabilityBlock} from "../stores/domain/subStores/availabilitySubstore";

export const serverTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const datePropType = PropTypes.instanceOf(Date);

export const getNow = () => new Date();

export const addHour = (stringDate, hours, format = "YYYY-MM-DD hh:mm:ss") =>
  moment(stringDate)
    .add(hours, "hours")
    .format(format);

export const getEventHourRange = (stringDate, hours) =>
  `${moment(stringDate).format("h:mm")} - ${addHour(stringDate, hours, "h:mm a")}`;

export const getHourRange = (stringDate, hours) =>
  `${moment(stringDate).format("h:mm a")} - ${addHour(stringDate, hours, "h:mm a")}`;

export const getDate = stringDate => moment(stringDate).format("YYYY-MM-DD");

export const getDateHour = stringDate => moment(stringDate).format("YYYY-MM-DD hh:mm:ss");

export const getDateMinute = stringDate => moment(stringDate).format("YYYY-MM-DD hh:mm");

export const getDateMinuteWithSlashes = stringDate => moment(stringDate).format("YYYY/MM/DD - HH:mm");

export const getStringYear = stringDate => moment(stringDate).format("YYYY");

export const getStringMonth = stringDate => moment(stringDate).format("MMMM");

export const getNumberMonth = stringMonth =>
  moment()
    .month(stringMonth)
    .format("M");

export const getStringDay = stringDate => moment(stringDate).format("dddd DD");

export const getEventDate = stringDate => moment(stringDate).format("dddd, MMMM DD");

export const getTodayString = () => moment().format("ddd");

export const getDateTime = stringDate => moment(stringDate).toDate();

export const getDateWithSlashes = stringDate => moment(stringDate).format("YYYY/MM/DD");

export const compareDateTime = (a, b) =>
  moment(a).format("YYYY-MM-DD h:mm:ss") === moment(b).format("YYYY-MM-DD h:mm:ss");

export const getHistoryDate = stringDate => moment(stringDate).format("dddd, MMMM Do, YYYY");

export const currentWeek =
  (week=0) => {
    const days = [];
    const mtime = moment();
    if(week){
      mtime.add({week});
    }
    for (let i = 0; i < 7; i++) {
      days.push({name: mtime.format("ddd"), date: mtime.format("DD"), month: mtime.format("MMM"), fullDate: mtime.format("DD/MM/YYYY")});
      mtime.add({days: 1});
    }

    return days;
  };

export const generateHours =(start, end) => {
  if (!end) {
    const data = [];
    for (let i = 2; i < 18; i++) {
      let hour = start + Math.floor(i / 2) - 2;
      if (hour >= 24) {
        hour -= 24;
      }
      const minute = i % 2 ? 30 : 0;
      const m = moment();
      const time = `${m.format("YYYY-MM-DD")} ${hour}:${minute}:00`;
      const momentDate = moment(time);
      momentDate.month(m.month());
      momentDate.year(m.year());
      momentDate.day(m.day());
      data.push({time, format: momentDate.format("HH:mm"), viewFormat: momentDate.format("hh:mm a")});
    }
    return data;
  }

  const data = [];
  for (let i = 2; i < (end*2)+2; i++) {
    let hour = start + Math.floor(i / 2) - 2;
    if (hour >= 24) {
      hour -= 24;
    }
    const minute = i % 2 ? 30 : 0;
    const m = moment();
    const time = `${m.format("YYYY-MM-DD")} ${hour}:${minute}:00`;
    const momentDate = moment(time);
    momentDate.month(m.month());
    momentDate.year(m.year());
    momentDate.day(m.day());
    data.push({time, format: momentDate.format("HH:mm"), viewFormat: momentDate.format("hh:mm a")});
  }
  return data;

}


export const currentWeeklyAvailabilityTime = (availability:AvailabilityBlock) => {
  const currentTime = moment();
  const today = ~~currentTime.format("DD");
  const chosenDay = ~~availability.startMoment.format("DD");
  const year = ~~currentTime.format("YYYY");
  const currentMonth = ~~currentTime.format("MM");
  const maximumNumberOfDays = currentTime.daysInMonth();

  const month = chosenDay >= today && chosenDay <= maximumNumberOfDays? currentMonth :currentMonth + 1;

  const availabilityDay = availability.parentStore.name;
  const weeklyDay = currentWeek().find(d => d.name === availabilityDay);
  const monthlyDay = weeklyDay.date;

  const availabilityStartTime = availability.startString;
  const mMoment = moment(`${year},${month},${monthlyDay} ${availabilityStartTime}`, "YYYY,MM,DD HH:mm a");
  return mMoment.utc().format(serverTimeFormat);
};

export function getExpiredText(expiryDate) {
  let expireText = 'Expires';
  let today = moment(new Date()).format("MM DD, YYYY");
  expiryDate = moment(expiryDate).format("MM DD, YYYY");
  today = new Date(today).getTime();
  expiryDate = new Date(expiryDate).getTime();
  if(expiryDate < today){
    expireText = 'Expired';
  }
  return expireText;
}